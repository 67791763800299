import React from "react";
import styled from "styled-components/native";
import { Header } from "../components/Header";
import { Headline, Label, SubDisplay } from "../components/Text";
import { Wave } from "../components/atoms/Wave";
import { Footer } from "../components/Footer";
import { useNavigation } from "@react-navigation/native";
import { Button } from "../components/atoms/Button";
import { Image, Platform, View, StyleSheet } from "react-native";
import { Link } from "../components/atoms/Link";
import { BreakpointProps, lt } from "../components/breakpoints";
import { useDimensions } from "react-native-web-hooks";
import { SafeAreaView } from "react-native-safe-area-context";

const heroBg = require("../assets/images/backgrounds/wormhole-bg.png");
const shinyBg = require("../assets/images/marketing/shiny-bg.png");
const appleBadge = require("../assets/images/appstore-badge.png");
const playBadge = require("../assets/images/googleplay-badge.png");

const Container = styled.ScrollView``;

const HeroArea = styled.ImageBackground`
  height: 609px;
  background-color: ${props => props.theme.colors.primaryDarkest};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  text-align: center;
`;

const HeroImage = styled.Image`
  height: 350px;
  width: 640px;
  background: ${props => props.theme.colors.primaryDarkest};
  border: 8px solid ${props => props.theme.colors.primaryDarkest};
  border-radius: 16px;
  z-index: 2;
`;

const Features = styled.ImageBackground`
  position: relative;
`;

const FeatureArea = styled.View`
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  max-width: 1060px;
  justify-content: space-between;
  margin: auto;
  padding: 0 10px 200px;
`;

const FeatureItem = styled.View`
  flex: 1 0 33.33%;
  min-width: 320px;
  padding: 0 10px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
`;

const FeatureItemImg = styled.Image`
  height: 300px;
  width: 300px;
  border: 8px solid ${props => props.theme.colors.primaryDarkest};
  border-radius: 300px;
`;

const Future = styled.View`
  background: #112943;
  position: relative;
`;

const FutureArea = styled.View`
  max-width: 1060px;
  margin: auto;
  padding: 0 10px;
`;

const FutureItem = styled.View<BreakpointProps>`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  ${props =>
    lt(props, "large") &&
    `
    flex-direction: column;
  `}
`;

const FutureItemLeft = styled.View`
  margin-top: 40px;
  margin-bottom: 40px;
  width: 66.66%;
`;

const FutureItemRight = styled.View`
  text-align: center;
`;

const FutureItemImg = styled.Image`
  height: 300px;
  width: 300px;
  border: 8px solid ${props => props.theme.colors.white};
  border-radius: 300px;
`;

const CTAWrapper = styled.ImageBackground`
  background: ${props => props.theme.colors.neutralLightest};
  align-items: center;
  padding: 200px 0 100px;
  position: relative;
`;

const WaveBottom = styled.View`
  position: absolute;
  width: 100%;
  bottom: 0px;
`;

const WaveTop = styled.View`
  position: absolute;
  width: 100%;
  top: 0px;
  transform: rotate(180deg);
`;

export const LandingScreen = () => {
  const nav = useNavigation();
  const dimensions = useDimensions();

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
      <Container>
        <Header />
        <HeroArea source={heroBg}>
          <SubDisplay uppercase color="white">
            Now available for iOS and Android!
          </SubDisplay>
          <View
            style={{ marginTop: 10, marginBottom: 20, flexDirection: "row" }}
          >
            <Link href="https://apple.co/3d3BFFG">
              <Image
                style={{ marginRight: 5, height: 40, width: 120 }}
                source={appleBadge}
              />
            </Link>
            <Link href="https://bit.ly/3llTlAV">
              <Image
                style={{ marginLeft: 5, height: 40, width: 134 }}
                source={playBadge}
              />
            </Link>
          </View>
          <HeroImage
            source={require("../assets/images/marketing/dashboard.png")}
          />
          <WaveBottom>
            <Wave fill="white" duration={1500} />
          </WaveBottom>
        </HeroArea>
        <Features source={shinyBg} resizeMode="contain">
          <FeatureArea>
            <FeatureItem>
              <FeatureItemImg
                source={require("../assets/images/marketing/count.png")}
              />
              <View style={{ marginTop: 16, marginBottom: 8 }}>
                <Headline>Count 'em all</Headline>
              </View>
              <Label>
                Keep track of encounters and elapsed time, see shiny rates, and
                more!
              </Label>
            </FeatureItem>
            <FeatureItem>
              <FeatureItemImg
                source={require("../assets/images/marketing/collection.png")}
              />
              <View style={{ marginTop: 16, marginBottom: 8 }}>
                <Headline>Capture 'em all</Headline>
              </View>
              <Label>
                Import previously captured shinies, add extra details, and more!
              </Label>
            </FeatureItem>
            <FeatureItem>
              <FeatureItemImg
                source={require("../assets/images/marketing/dex.png")}
              />
              <View style={{ marginTop: 16, marginBottom: 8 }}>
                <Headline>Collect 'em all</Headline>
              </View>
              <Label>
                Watch your collection fill up on your way to becoming a Shiny
                King!
              </Label>
            </FeatureItem>
          </FeatureArea>
          <WaveBottom>
            <Wave fill="#102A43" duration={1500} />
          </WaveBottom>
        </Features>
        <Future>
          <FutureArea>
            <FutureItem dimensions={dimensions}>
              <FutureItemLeft>
                <View style={{ marginBottom: 16 }}>
                  <SubDisplay uppercase color="white">
                    The future of shiny collecting
                  </SubDisplay>
                </View>
                <Label color="white">
                  It started as a mobile counter app called Poketch to track
                  your hunts and has evolved into a fully featuerd shiny
                  collector platform. Come join as we continue our evolutionary
                  journey into a shiny collecting community. Better yet, join
                  our Twitch streams and help build it along the way!
                </Label>
              </FutureItemLeft>
              <FutureItemRight>
                <FutureItemImg
                  source={require("../assets/images/marketing/trainers.png")}
                />
              </FutureItemRight>
            </FutureItem>
          </FutureArea>
        </Future>
        <CTAWrapper source={shinyBg} resizeMode="contain">
          <WaveTop>
            <Wave fill="#102A43" duration={1500} />
          </WaveTop>
          <SubDisplay uppercase color="neutralDarkest">
            Ready to ShinyHunt?
          </SubDisplay>
          <Button
            style={{ width: 270, marginTop: 8 }}
            onPress={() => nav.navigate("SignIn")}
          >
            Let's go!
          </Button>
        </CTAWrapper>
        <Footer />
      </Container>
    </SafeAreaView>
  );
};
