import { CustomerInfo } from "react-native-purchases";

export enum UserTheme {
  DARK = "dark",
  LIGHT = "light",
  SHINY = "shiny"
}

export type UserObject = {
  uid: string;
  isAdmin?: boolean;
  avatar: string;
  displayName: string;
  email: string;
  onboarded: boolean;
  theme: UserTheme;
  revCatId?: string;

  roles: {
    isAdmin: boolean;
    isDonator: boolean;
    isGoldMember: boolean;
  };

  payment?: CustomerInfo;
};

export type GameObject = {
  label: string;
  value: string;
};

export type TargetObject = {
  label: string;
  value: string;
};

export type MethodObject = {
  label: string;
  value: string;
  id: number;
};

export enum HuntSetting {
  SHINY_CHARM = "shiny-charm",
  MASUDA = "masuda",
  LURE = "lure",
  DIGLETT = "diglett-bonus",
  COMMUNITY_DAY = "community-day",
  SPARKLING_POWER_3 = "sparkling-power-3"
}

export type HuntObject = {
  uid: string;
  created: number;
  elapsedTime: number;
  encounters: number;
  chainCount: number;
  battledCount: number;
  favorite: boolean;
  game: GameObject;
  incrementAmount: number;
  method: MethodObject;
  selectedSettings: HuntSetting[];
  shinyCharm: boolean;
  target: TargetObject;
  altered: true;
};

export type CollectionObject = {
  collected: number;
  nickname?: string;
  ballUsed?: string;
  gender?: string;
  journalNote?: string;
  reactionLink?: string;
  imported?: boolean;
} & HuntObject;
