import styled from "styled-components/native";

export const AuthFormContainer = styled.ImageBackground`
  height: 100%;
  background: ${props => props.theme.colors.primaryDarkest};
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AuthFormLogo = styled.Image`
  height: 40px;
  width: 210px;
  margin-bottom: 20px;
`;

export const AuthFormCard = styled.View`
  background: white;
  width: 350px;
  border-radius: 10px;
  padding: 40px;
`;

export const AuthFormInputs = styled.View`
  flex-direction: column;
  margin-bottom: 12px;
`;
