import { Pokemon } from "../constants/pokemon-new";
import { usePayments } from "../hooks/usePayments";
import { TargetObject } from "../model/model";

export const getSpriteSrc = (
  pokemon: Pokemon,
  animated?: boolean,
  isAdmin?: boolean,
  isPro?: boolean
) => {
  let sprite: string;

  if (pokemon) {
    if (animated) {
      if (isPro || isAdmin) {
        sprite = pokemon.shinyAnimatedHD;
      } else {
        sprite = pokemon.shinyStaticHD;
      }
    } else {
      if (isPro || isAdmin) {
        sprite = pokemon.shinyStaticHD;
      } else {
        sprite = pokemon.shinyStaticHD;
      }
    }
  } else {
    sprite = `https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/000.png`;
  }
  return sprite;
};

export const getSerebiiLink = (value: TargetObject) =>
  `https://serebii.net/pokemon/${value.label.toLowerCase()}`;

export const formatTimer = (time: number) => {
  if (time / (60 * 60) >= 1) {
    let hours = Math.floor(Math.floor(time / 60) / 60);
    let minutes = Math.floor(time / 60) - hours * 60;
    let seconds = Math.floor(time % 60);
    return hours + "h, " + minutes + "m, " + seconds + "s";
  } else if (time / 60 >= 1) {
    let minutes = Math.floor(time / 60);
    let seconds = Math.floor(time % 60);
    return minutes + "m, " + seconds + "s";
  } else {
    let seconds = Math.floor(time % 60);
    return seconds + "s";
  }
};

export const getSpriteSize = (pokemon: Pokemon) => {
  const sizes = [];

  if (pokemon && pokemon.size) {
    switch (pokemon.size) {
      case "xsmall":
        sizes[0] = "160px";
        sizes[1] = "160px";
        break;
      case "small":
        sizes[0] = "170px";
        sizes[1] = "170px";
        break;
      case "medium":
        sizes[0] = "180px";
        sizes[1] = "180px";
        break;
      case "large":
        sizes[0] = "190px";
        sizes[1] = "190px";
        break;
      case "xlarge":
        sizes[0] = "200px";
        sizes[1] = "200px";
        break;
      default:
        sizes[0] = "180px";
        sizes[1] = "180px";
    }
  } else {
    sizes[0] = "200px";
    sizes[1] = "200px";
  }

  return sizes;
};

type NavigationRoute = {
  name: string;
  state?: {
    routes: NavigationRoute[];
    index?: number;
  };
};

export function getActiveRoute(state: {
  index?: number;
  routes: NavigationRoute[];
}): string {
  const childRoute = state.routes[state.index || 0];

  if (childRoute.state) {
    return getActiveRoute(childRoute.state);
  } else {
    return childRoute.name;
  }
}

export const getAvatarImage = (image?: string) => {
  if (image) {
    return image.startsWith("http")
      ? image
      : `https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/${image}`;
  } else {
    return `https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/default-1.png`;
  }
};
