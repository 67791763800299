import { useEffect, useMemo } from "react";
import { Animated } from "react-native";
import styled, { useTheme } from "styled-components/native";

const SpinnerOuter = styled.View`
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
`;

const SpinnerWrapper = styled.View`
  position: relative;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
`;

const Ball = styled.View<{
  x: number;
  y: number;
}>`
  position: absolute;
  background-color: ${props => props.theme.colors.primary};
  top: ${props => `${props.y}px`};
  left: ${props => `${props.x}px`};
  width: 8px;
  height: 8px;
  border-radius: 3px;
`;

const AnimatedBall = Animated.createAnimatedComponent(Ball);

export const LoadingSpinner = () => {
  const animatedValues = useMemo(() => {
    const values: Animated.Value[] = [];
    for (let i = 0; i < 9; i++) {
      values.push(new Animated.Value(0));
    }
    return values;
  }, []);

  useEffect(() => {
    const loops = animatedValues.map((animatedValue, i) =>
      Animated.loop(
        Animated.sequence([
          Animated.timing(animatedValue, {
            toValue: 1,
            duration: 600,
            useNativeDriver: true
          }),
          Animated.timing(animatedValue, {
            toValue: 0,
            duration: 600,
            useNativeDriver: true
          })
        ])
      )
    );
    loops.forEach((loop, i) => setTimeout(() => loop.start(), i * 100));
    return () => loops.forEach(loop => loop.stop());
  }, []);

  return (
    <SpinnerOuter>
      <SpinnerWrapper>
        {animatedValues.map((animatedValue, i) => (
          <AnimatedBall
            key={i}
            x={(i % 3) * 16}
            y={Math.floor(i / 3) * 16}
            style={{
              opacity: animatedValue.interpolate({
                inputRange: [0, 1],
                outputRange: [1, 0]
              }),
              transform: [
                {
                  scale: animatedValue.interpolate({
                    inputRange: [0, 1],
                    outputRange: [1, 0]
                  })
                }
              ]
            }}
          />
        ))}
      </SpinnerWrapper>
    </SpinnerOuter>
  );
};
