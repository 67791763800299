import { createNativeStackNavigator } from "@react-navigation/native-stack";
import styled from "styled-components/native";
import { CollectionDetail } from "./CollectionDetail";
import { CollectionList } from "./CollectionList";

const Stack = createNativeStackNavigator();

export const CollectionScreen = () => (
  <Stack.Navigator
    initialRouteName="CollectionList"
    screenOptions={{ headerShown: false }}
  >
    <Stack.Screen name="CollectionList" component={CollectionList} />
    <Stack.Screen name="CollectionDetail" component={CollectionDetail} />
  </Stack.Navigator>
);
