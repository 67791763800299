import { useNavigation } from "@react-navigation/native";
import {
  NativeStackNavigationProp,
  NativeStackScreenProps
} from "@react-navigation/native-stack";
import { useEffect, useState } from "react";
import {
  Alert,
  Linking,
  Platform,
  Modal,
  View,
  ScrollView,
  Pressable
} from "react-native";
import { useDimensions } from "react-native-web-hooks";
import styled, { useTheme } from "styled-components/native";
import { IconButton } from "../components/atoms/Button";
import { LoadingSpinner } from "../components/atoms/LoadingSpinner";
import {
  formatTimer,
  getSpriteSize,
  getSpriteSrc
} from "../components/helpers";
import {
  BackIcon,
  EditIcon,
  ExternalIcon,
  HeartIcon,
  TrashIcon
} from "../components/Icon";
import { NewHuntmodal } from "../components/modals/NewHuntModal";
import { Body, Caption, Display, Label, SubDisplay } from "../components/Text";
import { GENDERS, HUNT_SETTING_LABELS, POKEBALLS } from "../constants/options";
import { POKEMON } from "../constants/pokemon-new";
import { useFirestore } from "../hooks/useFirestore";
import { CollectionStackParamList, Nav } from "../navTypes";
import { BreakpointProps, gt, gte, lt } from "../components/breakpoints";
import { HuntSetting } from "../model/model";
import { usePayments } from "../hooks/usePayments";

const marathonImage = require("../assets/images/backgrounds/bdsp-marathon-bg.png");
const marathonPodiumImage = require("../assets/images/podiums/bdsp-marathon.png");

const Container = styled.View`
  justify-content: flex-start;
  flex: 1;
  background-color: ${p => p.theme.colors.background};
  padding: 24px;
`;

const CollectionDetailContainer = styled.View<BreakpointProps>`
  position: relative;
  background: ${props => props.theme.colors.surface};
  border-radius: 10px;
  overflow: hidden;
  margin-top: 20px;

  ${p =>
    gte(p, "medium")
      ? `
    flex-direction: row;
  height: 490px;
  `
      : ``};
`;

const CollectionDetailCard = styled.ImageBackground<BreakpointProps>`
  background: ${props => props.theme.colors.surface};
  position: relative;

  ${p =>
    lt(p, "medium")
      ? `
    height: 300px;
    width: 100%;
  `
      : `flex: 1`};
`;

const Header = styled.View`
  position: absolute;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  z-index: 1;
`;

const HeaderInfo = styled.View`
  text-align: left;
`;

const Main = styled.View`
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Podium = styled.ImageBackground<{ screenSize: number }>`
  height: 250px;
  width: 250px;
  margin: 25px;
  align-items: center;
  justify-content: center;

  ${props =>
    props.screenSize >= props.theme.breaks.medium &&
    `
    height: 350px;
    width: 350px;
    margin: 0;
  `}
`;

const Sprite = styled.Image<{ size: string[] }>`
  width: ${props => props.size[0]};
  height: ${props => props.size[1]};
`;

const Footer = styled.View`
  position: absolute;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  bottom: 0;
`;

const FooterInfoLeft = styled.View`
  text-align: left;
`;

const FooterInfoRight = styled.View`
  text-align: right;
`;

const CollectionInfo = styled.View<BreakpointProps>`
  padding: 20px;
  ${p =>
    lt(p, "medium")
      ? `
    width: 100%;
  `
      : `
        width: 300px;
      `};
`;

const CollectionInfoList = styled.View``;

const CollectionInfoHeader = styled.View`
  margin-bottom: 10px;
`;

const CollectionInfoItem = styled.View`
  justify-content: space-between;
  padding-bottom: 4px;
  margin-bottom: 4px;
  flex-direction: row;
  border: 1px solid ${props => props.theme.colors.primary};
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
`;

const FavoriteButton = styled.Pressable`
  padding: 0;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
`;

export const ShowcaseDetail: React.FC<NativeStackScreenProps<any>> = ({
  route
}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { collections, deleteCollection, updateCollection } = useFirestore();
  const nav =
    useNavigation<NativeStackNavigationProp<CollectionStackParamList>>();
  const dimensions = useDimensions();
  const collection = collections?.find(c => c.uid === route.params?.uid);
  const theme = useTheme();
  const { user } = useFirestore();
  const { isPro } = usePayments();

  const setFavorite = () => {
    if (collection) {
      updateCollection(collection.uid, {
        favorite: !collection.favorite
      });
    }
  };

  useEffect(() => {
    const badData = (collection as any)?.huntSettings as HuntSetting[];

    if (badData && collection) {
      if (
        !collection?.selectedSettings ||
        badData.length !== collection?.selectedSettings.length
      ) {
        updateCollection(collection.uid, {
          selectedSettings: badData
        });
      }
    }
  }, []);

  if (!collection) {
    return <LoadingSpinner />;
  }

  const pokemon = POKEMON.find(p => p.value === collection.target.value);

  const removeCollection = () => {
    if (Platform.OS === "web") {
      if (window.confirm("Are you sure you want to delete this collection?")) {
        deleteCollection(collection.uid);
        nav.navigate("CollectionList");
      }
    } else {
      Alert.alert(
        "Are you sure?",
        "You cannot recover collections once they are deleted.",
        [
          { text: "Cancel", style: "cancel" },
          {
            text: "Delete Collection",
            onPress: () => {
              deleteCollection(collection.uid);
              nav.navigate("CollectionList");
            },
            style: "destructive"
          }
        ]
      );
    }
  };

  return (
    <ScrollView style={{ backgroundColor: theme.colors.background }}>
      <Container>
        <View style={{ flexDirection: "row" }}>
          <IconButton
            outline
            onPress={() => nav.navigate("CollectionList")}
            Icon={BackIcon}
            style={{ marginRight: 16 }}
          />

          <IconButton
            outline
            onPress={() => setEditModalOpen(true)}
            Icon={EditIcon}
            style={{ marginRight: 16 }}
          />

          <IconButton
            outline
            onPress={removeCollection}
            Icon={TrashIcon}
            style={{ marginLeft: "auto" }}
          />
        </View>
        <CollectionDetailContainer dimensions={dimensions}>
          <CollectionDetailCard source={marathonImage} dimensions={dimensions}>
            <Header>
              <HeaderInfo>
                <SubDisplay color="white" weight="semibold" uppercase>
                  {collection.nickname || collection.target.label}
                </SubDisplay>
              </HeaderInfo>
              <FavoriteButton onPress={setFavorite}>
                <HeartIcon
                  size={30}
                  primaryFill={collection.favorite ? "success" : "neutral"}
                  primaryFillHover="primary"
                  secondaryFill="white"
                />
              </FavoriteButton>
            </Header>
            <Main>
              {!!pokemon && (
                <Podium
                  screenSize={dimensions.window.width}
                  source={marathonPodiumImage}
                >
                  <Sprite
                    resizeMode="contain"
                    size={getSpriteSize(pokemon)}
                    source={{
                      uri: getSpriteSrc(pokemon, true, !!user?.isAdmin, isPro)
                    }}
                  />
                </Podium>
              )}
            </Main>
            <Footer>
              <FooterInfoLeft>
                {collection.elapsedTime !== 0 && (
                  <View>
                    <SubDisplay color="white">
                      {formatTimer(collection.elapsedTime)}
                    </SubDisplay>
                    <Label color="white">total time</Label>
                  </View>
                )}
              </FooterInfoLeft>
              <FooterInfoRight>
                {collection.encounters !== 0 && (
                  <View>
                    <Display
                      style={{ marginBottom: -8, textAlign: "right" }}
                      color="white"
                    >
                      {collection.encounters}
                    </Display>
                    <Label color="white">encounters</Label>
                  </View>
                )}
              </FooterInfoRight>
            </Footer>
          </CollectionDetailCard>
          <CollectionInfo dimensions={dimensions}>
            <CollectionInfoList>
              <CollectionInfoHeader>
                <Caption color="primary" weight="semibold" uppercase>
                  Hunt Details
                </Caption>
              </CollectionInfoHeader>
              <CollectionInfoItem>
                <Body color="primaryDarkest">Pokémon</Body>
                <Body color="primaryDarkest">{collection.target.label}</Body>
              </CollectionInfoItem>
              <CollectionInfoItem>
                <Body color="primaryDarkest">Game</Body>
                <Body color="primaryDarkest">{collection.game.label}</Body>
              </CollectionInfoItem>
              <CollectionInfoItem>
                <Body color="primaryDarkest">Method</Body>
                <Body color="primaryDarkest">{collection.method.label}</Body>
              </CollectionInfoItem>
              <CollectionInfoItem>
                <Body color="primaryDarkest">Collected Date</Body>
                <Body color="primaryDarkest">
                  {collection.collected
                    ? new Date(collection.collected).toLocaleString()
                    : "Forgotten"}
                </Body>
              </CollectionInfoItem>
            </CollectionInfoList>

            {!!collection.selectedSettings?.length && (
              <CollectionInfoList>
                <CollectionInfoHeader style={{ marginTop: 16 }}>
                  <Caption color="primary" weight="semibold" uppercase>
                    Hunt Settings
                  </Caption>
                </CollectionInfoHeader>
                {collection.selectedSettings.map((setting, i) => (
                  <CollectionInfoItem key={i}>
                    <Body color="primaryDarkest">
                      {HUNT_SETTING_LABELS[setting]}
                    </Body>
                  </CollectionInfoItem>
                ))}
              </CollectionInfoList>
            )}
            {!!(collection.ballUsed || collection.gender) && (
              <CollectionInfoList>
                <CollectionInfoHeader style={{ marginTop: 16 }}>
                  <Caption color="primary" weight="semibold" uppercase>
                    Pokémon Info
                  </Caption>
                </CollectionInfoHeader>
                {!!collection.ballUsed && (
                  <CollectionInfoItem>
                    <Body color="primaryDarkest">Ball</Body>
                    <Body color="primaryDarkest">
                      {
                        POKEBALLS.find(b => b.value === collection.ballUsed)
                          ?.label
                      }
                    </Body>
                  </CollectionInfoItem>
                )}
                {!!collection.gender && (
                  <CollectionInfoItem>
                    <Body color="primaryDarkest">Gender</Body>
                    <Body color="primaryDarkest">
                      {GENDERS.find(b => b.value === collection.gender)?.label}
                    </Body>
                  </CollectionInfoItem>
                )}
              </CollectionInfoList>
            )}
            {!!collection.journalNote && (
              <CollectionInfoList>
                <CollectionInfoHeader style={{ marginTop: 16 }}>
                  <Caption color="primary" weight="semibold" uppercase>
                    Notes
                  </Caption>
                </CollectionInfoHeader>
                <CollectionInfoItem>
                  <Body color="primaryDarkest">{collection.journalNote}</Body>
                </CollectionInfoItem>
              </CollectionInfoList>
            )}
            {!!collection.reactionLink && (
              <CollectionInfoList>
                <CollectionInfoHeader
                  style={{
                    marginTop: 16,
                    alignItems: "center",
                    flexDirection: "row"
                  }}
                >
                  <Caption color="primary" weight="semibold" uppercase>
                    Reaction Link
                  </Caption>
                  <Pressable
                    onPress={() =>
                      Linking.openURL(collection.reactionLink as string)
                    }
                    style={{ marginLeft: 4 }}
                  >
                    <ExternalIcon size={16} primaryFill="primary" />
                  </Pressable>
                </CollectionInfoHeader>
                <CollectionInfoItem>
                  <Body color="primaryDarkest">{collection.reactionLink}</Body>
                </CollectionInfoItem>
              </CollectionInfoList>
            )}
          </CollectionInfo>
        </CollectionDetailContainer>

        <NewHuntmodal
          visible={editModalOpen}
          close={() => setEditModalOpen(false)}
          collection={collection}
          isImport
        />
      </Container>
    </ScrollView>
  );
};
