import { useEffect, useMemo, useState } from "react";
import {
  FlatList,
  ListRenderItemInfo,
  Pressable,
  StyleSheet,
  Image,
  ImageBackground,
  Modal,
  View
} from "react-native";
import { useDimensions } from "react-native-web-hooks";
import styled, { useTheme } from "styled-components/native";
import { IconButton } from "../components/atoms/Button";
import { SearchInput } from "../components/atoms/Input";
import { LoadingSpinner } from "../components/atoms/LoadingSpinner";
import { getBp, lt } from "../components/breakpoints";
import { getSpriteSize, getSpriteSrc } from "../components/helpers";
import {
  CloseIcon,
  MonitorIcon,
  RefreshIcon,
  SortAscIcon,
  SortDescIcon
} from "../components/Icon";
import { AlertModal } from "../components/modals/AlertModal";
import {
  SortOverlay,
  SortOverlayActions,
  SortOverlayFooter,
  SortOverlayHeader,
  SortOverlayIconButton,
  SortOverlayRadioLabel,
  SortOverlayRadioList
} from "../components/molecules/SortOverlay";
import { Body, Caption, Headline, Title } from "../components/Text";
import { POKEMON, Pokemon } from "../constants/pokemon-new";
import { useFirestore } from "../hooks/useFirestore";
import { usePayments } from "../hooks/usePayments";
const marathonImage = require("../assets/images/backgrounds/bdsp-marathon-bg.png");

const Container = styled.View`
  justify-content: flex-start;
  flex: 1;
  background-color: ${p => p.theme.colors.background};
`;

const pokemonSortOptions: {
  label: string;
  value: keyof Pokemon;
}[] = [
  {
    label: "Dex No",
    value: "order"
  },
  {
    label: "Name",
    value: "label"
  }
];

const colsMap = {
  small: 4,
  medium: 5,
  large: 6,
  xlarge: 7
};

export const ShinyDexScreen = () => {
  const [sortKey, setSortKey] = useState(pokemonSortOptions[0]);
  const [sortAsc, setSortAsc] = useState(false);
  const { collections } = useFirestore();
  const [collectedMap, setCollectedMap] = useState<{ [key: string]: number }>(
    {}
  );
  const [sorted, setSorted] = useState<Pokemon[]>();
  const [searchInput, setSearchInput] = useState("");
  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [statsModalOpen, setStatsModalOpen] = useState(false);
  const theme = useTheme();
  const dimensions = useDimensions();
  const { user } = useFirestore();
  const { isPro } = usePayments();

  const bp = getBp({ dimensions, theme });
  const numCols = bp ? colsMap[bp] : 2;

  useEffect(() => {
    const map: { [key: string]: number } = {};
    collections?.forEach(c => {
      const val = c.target.value;
      if (!map[val]) {
        map[val] = 0;
      }
      map[val]++;
    });
    setCollectedMap(map);
  }, [collections]);

  useEffect(() => {
    const newSorted = [...POKEMON];

    newSorted.sort((a, b) => {
      const aVal = (a[sortKey.value] as string) || "";
      const bVal = (b[sortKey.value] as string) || "";

      const compare = aVal.toString().localeCompare(bVal.toString());
      return sortAsc ? -1 * compare : compare;
    });
    setSorted(newSorted);
  }, [sortAsc, sortKey]);

  const pokemonStyleSheet = useMemo(() => {
    const small = lt({ dimensions, theme }, "small");

    return theme
      ? StyleSheet.create({
          container: {
            position: "relative",
            width: `${(1 / numCols) * 100}%`,
            height: small ? 200 : 200,
            padding: 5
          },
          background: {
            borderRadius: 10,
            overflow: "hidden",
            height: "100%",
            width: "100%"
          },
          header: {
            padding: 10,
            position: "absolute",
            zIndex: 1
          },
          main: {
            position: "absolute",
            height: "100%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center"
          },
          podium: {
            height: "100%",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center"
          },
          footer: {
            position: "absolute",
            alignItems: "flex-end",
            justifyContent: "space-between",
            padding: 10,
            width: "100%",
            bottom: 0,
            zIndex: 1
          }
        })
      : null;
  }, [theme, dimensions]);

  const renderPokemonItem = useMemo(
    () =>
      pokemonStyleSheet
        ? ({ item }: ListRenderItemInfo<Pokemon>) => (
            <View style={pokemonStyleSheet.container}>
              <ImageBackground
                style={pokemonStyleSheet.background}
                source={marathonImage}
              >
                <View style={pokemonStyleSheet.header}>
                  <Title color="white" weight="semibold">
                    {item.label}
                  </Title>
                  <Caption color="white">#{item.dexNo}</Caption>
                </View>
                <View style={pokemonStyleSheet.main}>
                  <View style={pokemonStyleSheet.podium}>
                    <Image
                      resizeMode="contain"
                      resizeMethod="scale"
                      style={{
                        tintColor: !collectedMap[item.value]
                          ? "#ffffff"
                          : undefined,
                        width: parseInt(
                          getSpriteSize(item)[0].replace("px", "")
                        ),
                        height: parseInt(
                          getSpriteSize(item)[1].replace("px", "")
                        ),
                        maxHeight: "100%",
                        maxWidth: "100%"
                      }}
                      source={{
                        uri: getSpriteSrc(item, false, !!user?.isAdmin, isPro)
                      }}
                    />
                  </View>
                </View>
                {!!collectedMap[item.value] && (
                  <View style={pokemonStyleSheet.footer}>
                    <Headline color="white">
                      {collectedMap[item.value]}
                    </Headline>
                    <Caption color="white"> collected</Caption>
                  </View>
                )}
              </ImageBackground>
            </View>
          )
        : null,
    [pokemonStyleSheet, collectedMap, user]
  );

  if (!sorted) {
    return <LoadingSpinner />;
  }

  const filtered = sorted.filter(pokemon =>
    pokemon.label.toLowerCase().includes(searchInput.toLowerCase())
  );
  const SortIcon = sortAsc ? SortAscIcon : SortDescIcon;
  const isSorted = sortAsc || sortKey !== pokemonSortOptions[0];

  return (
    <Container>
      <View style={{ padding: 20, flexDirection: "row" }}>
        <IconButton
          outline
          onPress={() => setSortModalOpen(!sortModalOpen)}
          Icon={SortIcon}
          style={{ marginRight: 12 }}
        />

        <IconButton
          outline
          onPress={() => setStatsModalOpen(!statsModalOpen)}
          Icon={MonitorIcon}
          style={{ marginRight: 12 }}
        />

        <SearchInput
          onChangeText={setSearchInput}
          value={searchInput}
          placeholder="Search..."
        />
      </View>

      {sortModalOpen && (
        <SortOverlay>
          <SortOverlayHeader>
            <Caption weight="semibold">SORT OPTIONS</Caption>
            <SortOverlayActions>
              <SortOverlayIconButton onPress={() => setSortModalOpen(false)}>
                <CloseIcon
                  primaryFill="primaryLight"
                  primaryFillHover="primaryDarkest"
                  size={20}
                />
              </SortOverlayIconButton>
            </SortOverlayActions>
          </SortOverlayHeader>

          <SortOverlayRadioList>
            {pokemonSortOptions.map((option, index) => (
              <SortOverlayRadioLabel
                checked={sortKey === option}
                key={index}
                onPress={() => setSortKey(option)}
              >
                <Body weight="semibold" color="primaryDark">
                  {option.label}
                </Body>
              </SortOverlayRadioLabel>
            ))}
          </SortOverlayRadioList>
          <SortOverlayFooter>
            <Pressable onPress={() => setSortAsc(!sortAsc)}>
              <SortIcon
                primaryFill={isSorted ? "primaryDarkest" : "primaryLight"}
                secondaryFill={isSorted ? "primaryDarkest" : "primaryLight"}
                primaryFillHover="primaryDarkest"
                secondaryFillHover="primaryDarkest"
                size={20}
              />
            </Pressable>

            <Pressable
              onPress={() => {
                setSortAsc(false);
                setSortKey(pokemonSortOptions[0]);
              }}
              disabled={!isSorted}
            >
              <RefreshIcon
                primaryFill="primaryLight"
                secondaryFill="white"
                primaryFillHover="primaryDark"
                secondaryFillHover="white"
                size={20}
              />
            </Pressable>
          </SortOverlayFooter>
        </SortOverlay>
      )}
      <FlatList
        key={`shinydex${numCols}`}
        numColumns={numCols}
        data={filtered}
        keyExtractor={item => item.value}
        style={{ paddingHorizontal: 20, paddingBottom: 20 }}
        removeClippedSubviews
        windowSize={2}
        renderItem={renderPokemonItem}
      />

      <Modal
        visible={statsModalOpen}
        onRequestClose={() => setStatsModalOpen(false)}
        transparent
      >
        <AlertModal
          close={() => setStatsModalOpen(false)}
          title="DEX STATS COMING SOON"
          desc="This section is being actively worked on. Join aDrive's Twitch streams and Discord to get involved in the process and check progress."
        />
      </Modal>
    </Container>
  );
};
