import { DefaultTheme, TextTheme } from "styled-components/native";

export const textTheme: TextTheme = {
  fonts: {
    header: "SairaExtraCondensed",
    body: "OpenSans"
  },
  fontSizes: {
    //header test
    display: 48, //h1
    subdisplay: 32, //h2
    headline: 24, //h3
    title: 20, //h4
    subtitle: 18, //h5
    //paragraph text
    label: 16,
    body: 14,
    caption: 12,
    legal: 11
  },
  weights: {
    light: "_300Light",
    regular: "_400Regular",
    medium: "_500Medium",
    semibold: "_600SemiBold",
    bold: "_700Bold"
  },
  shadows: {
    header: "0 15px 15px rgba(0,0,0,0.05)",
    light: "1px 1px 20px rgba(0,0,0,0.1)",
    dark: "1px 1px 10px rgba(0,0,0,0.2)"
  },
  spacing: {
    units: ["0", "4px", "8px"]
  },
  breaks: {
    small: 576,
    medium: 768,
    large: 992,
    xlarge: 1200
  }
};

export const lightTheme: DefaultTheme = {
  ...textTheme,
  colors: {
    //primary colors
    primaryLightest: "#E0E8F9",
    primaryLighter: "#BED0F7",
    primaryLight: "#98AEEB",
    primary: "#647ACB",
    primaryDark: "#4055A8",
    primaryDarker: "#2D3A8C",
    primaryDarkest: "#19216C",
    //secodnary colors
    secondaryLightest: "#FFFBEA",
    secondaryLighter: "#FFF3C4",
    secondaryLight: "#FADB5F",
    secondary: "#F0B429",
    secondaryDark: "#DE911D",
    secondaryDarker: "#B44D12",
    secondaryDarkest: "#8D2B0B",
    //tertiary colors
    tertiaryLightest: "#FFE0F0",
    tertiaryLighter: "#FAB8D9",
    tertiaryLight: "#F191C1",
    tertiary: "#DA4A91",
    tertiaryDark: "#AD2167",
    tertiaryDarker: "#781244",
    tertiaryDarkest: "#5C0B33",
    //success colors
    successLightest: "#F8FFED",
    successLighter: "#C1EAC5",
    successLight: "#7BC47F",
    success: "#3F9142",
    successDark: "#2F8132",
    successDarker: "#0E5814",
    successDarkest: "#05400A",
    //warning colors
    warningLightest: "#FFFBEA",
    warningLighter: "#FFF3C4",
    warningLight: "#FADB5F",
    warning: "#F0B429",
    warningDark: "#DE911D",
    warningDarker: "#B44D12",
    warningDarkest: "#8D2B0B",
    //danger colors
    dangerLightest: "#FFE3E3",
    dangerLighter: "#FFBDBD",
    dangerLight: "#F86A6A",
    danger: "#E12D39",
    dangerDark: "#CF1124",
    dangerDarker: "#8A041A",
    dangerDarkest: "#610316",
    //neutral colors
    neutralLightest: "#e4e4e7",
    neutralLighter: "#d4d4d8",
    neutralLight: "#a1a1aa",
    neutral: "#71717a",
    neutralDark: "#52525b",
    neutralDarker: "#3f3f46",
    neutralDarkest: "#27272a",
    //system colors
    white: "#FFFFFF",
    background: "#f4f4f5",
    surface: "#FFFFFF",
    black: "#000",
    text: "#000",
    inverseText: "#FFFFFF",
    greenScreen: "#00B140"
  }
};

export const darkTheme: DefaultTheme = {
  ...textTheme,
  colors: {
    //primary colors
    primaryDarkest: "#E0E8F9",
    primaryDarker: "#BED0F7",
    primaryDark: "#98AEEB",
    primary: "#647ACB",
    primaryLight: "#4055A8",
    primaryLighter: "#2D3A8C",
    primaryLightest: "#19216C",
    //secodnary colors
    secondaryDarkest: "#FFFBEA",
    secondaryDarker: "#FFF3C4",
    secondaryDark: "#FADB5F",
    secondary: "#F0B429",
    secondaryLight: "#DE911D",
    secondaryLighter: "#B44D12",
    secondaryLightest: "#8D2B0B",
    //tertiary colors
    tertiaryDarkest: "#FFE0F0",
    tertiaryDarker: "#FAB8D9",
    tertiaryDark: "#F191C1",
    tertiary: "#DA4A91",
    tertiaryLight: "#AD2167",
    tertiaryLighter: "#781244",
    tertiaryLightest: "#5C0B33",
    //success colors
    successDarkest: "#F8FFED",
    successDarker: "#C1EAC5",
    successDark: "#7BC47F",
    success: "#3F9142",
    successLight: "#2F8132",
    successLighter: "#0E5814",
    successLightest: "#05400A",
    //warning colors
    warningDarkest: "#FFFBEA",
    warningDarker: "#FFF3C4",
    warningDark: "#FADB5F",
    warning: "#F0B429",
    warningLight: "#DE911D",
    warningLighter: "#B44D12",
    warningLightest: "#8D2B0B",
    //danger colors
    dangerDarkest: "#FFE3E3",
    dangerDarker: "#FFBDBD",
    dangerDark: "#F86A6A",
    danger: "#E12D39",
    dangerLight: "#CF1124",
    dangerLighter: "#8A041A",
    dangerLightest: "#610316",
    //neutral colors
    neutralLightest: "#27272a",
    neutralLighter: "#3f3f46",
    neutralLight: "#52525b",
    neutral: "#71717a",
    neutralDark: "#a1a1aa",
    neutralDarker: "#d4d4d8",
    neutralDarkest: "#e4e4e7",
    //system colors
    black: "#000",
    background: "#18181b",
    surface: "#27272a",
    white: "#fafafa",
    text: "#f4f4f5",
    inverseText: "#18181b",
    greenScreen: "#00B140"
  }
};

export const shinyTheme: DefaultTheme = {
  ...textTheme,
  colors: {
    //primary colors
    primaryDarkest: "#FFFBEA",
    primaryDarker: "#FFF3C4",
    primaryDark: "#FADB5F",
    primary: "#F0B429",
    primaryLight: "#DE911D",
    primaryLighter: "#B44D12",
    primaryLightest: "#8D2B0B",
    //secodnary colors
    secondaryDarkest: "#FFFBEA",
    secondaryDarker: "#FFF3C4",
    secondaryDark: "#FADB5F",
    secondary: "#F0B429",
    secondaryLight: "#DE911D",
    secondaryLighter: "#B44D12",
    secondaryLightest: "#8D2B0B",
    //tertiary colors
    tertiaryDarkest: "#FFE0F0",
    tertiaryDarker: "#FAB8D9",
    tertiaryDark: "#F191C1",
    tertiary: "#DA4A91",
    tertiaryLight: "#AD2167",
    tertiaryLighter: "#781244",
    tertiaryLightest: "#5C0B33",
    //success colors
    successDarkest: "#F8FFED",
    successDarker: "#C1EAC5",
    successDark: "#7BC47F",
    success: "#3F9142",
    successLight: "#2F8132",
    successLighter: "#0E5814",
    successLightest: "#05400A",
    //warning colors
    warningDarkest: "#FFFBEA",
    warningDarker: "#FFF3C4",
    warningDark: "#FADB5F",
    warning: "#F0B429",
    warningLight: "#DE911D",
    warningLighter: "#B44D12",
    warningLightest: "#8D2B0B",
    //danger colors
    dangerDarkest: "#FFE3E3",
    dangerDarker: "#FFBDBD",
    dangerDark: "#F86A6A",
    danger: "#E12D39",
    dangerLight: "#CF1124",
    dangerLighter: "#8A041A",
    dangerLightest: "#610316",
    //neutral colors
    neutralLightest: "#292524",
    neutralLighter: "#44403c",
    neutralLight: "#57534e",
    neutral: "#78716c",
    neutralDark: "#a8a29e",
    neutralDarker: "#d6d3d1",
    neutralDarkest: "#e7e5e4",
    //system colors
    black: "#000",
    background: "#1c1917",
    surface: "#292524",
    white: "#fff",
    text: "#fff",
    inverseText: "#000",
    greenScreen: "#00B140"
  }
};
