import { MutableRefObject, useRef } from "react";
import { TextInput, TextInputProps } from "react-native";
import { useActive, useFocus, useHover } from "react-native-web-hooks";
import styled, { useTheme } from "styled-components/native";
import { textDefaults } from "../Text";

const StyledInput = styled.TextInput<{
  isFocused: boolean;
  isActive: boolean;
}>`
  ${props => textDefaults(props.theme, { noTextFix: true })}
  height: 38px;
  width: 100%;
  padding: 2px 8px;
  border: 2px solid ${props => props.theme.colors.primary}
  border-radius: 4px;
  margin-bottom: 16px;
  background-color: ${props => props.theme.colors.surface};
`;

export const Input: React.FC<
  TextInputProps & { externalRef?: MutableRefObject<TextInput | null> }
> = ({ externalRef, ...props }) => {
  const internalRef = useRef(null);

  const refToUse = externalRef !== undefined ? externalRef : internalRef;

  const isFocused = useFocus(refToUse);
  const isActive = useActive(refToUse);
  const theme = useTheme();

  return (
    <StyledInput
      {...props}
      isFocused={isFocused}
      isActive={isActive}
      ref={refToUse}
      placeholderTextColor={theme.colors.neutralDark}
    />
  );
};

const StyledSearchInput = styled.TextInput<{
  isFocused: boolean;
  isActive: boolean;
}>`
  ${props => textDefaults(props.theme, { noTextFix: true })}
  height: 40px;
  flex: 1;
  border: 2px solid ${props => props.theme.colors.primaryLight};
  border-radius: 20px;
  background-color: ${props => props.theme.colors.surface};
  padding: 0 45px 0 20px;

  ${({ isFocused, isActive, theme }) =>
    (isFocused || isActive) &&
    `
    border: 2px solid ${theme.colors.primaryDark};
  `};
`;

export const SearchInput: React.FC<TextInputProps> = props => {
  const ref = useRef(null);
  const isFocused = useFocus(ref);
  const isActive = useActive(ref);
  const theme = useTheme();

  return (
    <StyledSearchInput
      {...props}
      isFocused={isFocused}
      isActive={isActive}
      placeholder="Search..."
      ref={ref}
      placeholderTextColor={theme.colors.neutralDark}
    />
  );
};
