import styled from "styled-components/native";
import { textDefaults } from "../Text";

export const SortOverlay = styled.View`
  position: absolute;
  margin-top: 40px;
  background: ${props => props.theme.colors.surface};
  box-shadow: ${props => props.theme.shadows.light};
  border-radius: 10px;
  padding: 20px;
  z-index: 5;
  width: 270px;
  left: 20px;
`;

export const SortOverlayHeader = styled.View`
  justify-content: space-between;
  margin-bottom: 10px;
  flex-direction: row;
  width: 100%;
`;

export const SortOverlayActions = styled.View`
  margin-top: -5px;
  margin-right: -5px;
`;

export const SortOverlayIconButton = styled.Pressable`
  padding: 0;
  justify-content: center;
  align-items: center;
`;

export const SortOverlayRadioList = styled.View`
  flex-direction: column;
  margin: 10px 0 0;
`;

export const SortOverlayRadioLabel = styled.Pressable<{ checked: boolean }>`
  height: 40px;
  margin-bottom: 7px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: ${props => props.theme.colors.background};
  border: 2px solid
    ${props =>
      props.checked
        ? props.theme.colors.success
        : props.theme.colors.background};
  ${p => textDefaults(p.theme, { color: "primaryDarkest" })}
`;

export const SortOverlayFooter = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;
