export type Pokemon = {
  label: string;
  value: string;
  order: string;
  dexNo: string;
  size: string;
  name: string;
  form: string;
  normalAnimated: string;
  normalAnimatedHD: string;
  normalStatic: string;
  normalStaticHD: string;
  shinyAnimated: string;
  shinyAnimatedHD: string;
  shinyStatic: string;
  shinyStaticHD: string;
  games: string;
  altForms: string;
  shinyColors: string;
  shinyLocked: string;
};

export const POKEMON: Pokemon[] = [
  {
    label: "Bulbasaur",
    value: "recvLbIO3ew4OpunI",
    order: "0001",
    dexNo: "001",
    size: "small",
    name: "bulbasaur",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/bulbasaur.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/001.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/bulbasaur.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/001.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/bulbasaur.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/001.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/bulbasaur.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/001.png",
    games:
      'Pokémon Red,Pokémon Blue,Pokémon Yellow,Pokémon FireRed,Pokémon LeagGreen,Pokémon HeartGold,Pokémon SoulSilver,Pokémon X,Pokémon Y,Pokémon GO,Pokémon Ultra Sun,Pokémon Ultra Moon,"Pokémon Let\'s Go, Pikachu!","Pokémon Let\'s Go, Eevee!"',
    altForms: "",
    shinyColors: "Green",
    shinyLocked: ""
  },
  {
    label: "Ivysaur",
    value: "recwUhKSbby5FmDmH",
    order: "0002",
    dexNo: "002",
    size: "small",
    name: "ivysaur",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ivysaur.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/002.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ivysaur.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/002.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ivysaur.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/002.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ivysaur.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/002.png",
    games: "Pokémon Red",
    altForms: "",
    shinyColors: "Green",
    shinyLocked: ""
  },
  {
    label: "Venasaur",
    value: "recWs60DUs6OZR9T0",
    order: "0003",
    dexNo: "003",
    size: "large",
    name: "venasaur",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/venasaur.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/003.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/venasaur.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/003.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/venasaur.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/003.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/venasaur.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/003.png",
    games: "Pokémon Red",
    altForms: "mega",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Charmander",
    value: "recktQ0DuerCFzTAZ",
    order: "0004",
    dexNo: "004",
    size: "small",
    name: "charmander",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/charmander.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/004.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/charmander.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/004.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/charmander.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/004.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/charmander.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/004.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Charmeleon",
    value: "rec2ug3u9cJ3ChXY4",
    order: "0005",
    dexNo: "005",
    size: "medium",
    name: "charmeleon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/charmeleon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/005.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/charmeleon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/005.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/charmeleon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/005.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/charmeleon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/005.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Charizard",
    value: "recj0COPDOzRKMRjZ",
    order: "0006",
    dexNo: "006",
    size: "large",
    name: "charizard",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/charizard.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/006.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/charizard.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/006.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/charizard.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/006.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/charizard.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/006.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Squirtle",
    value: "recqbJ47ysoy5gNzu",
    order: "0007",
    dexNo: "007",
    size: "xsmall",
    name: "squirtle",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/squirtle.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/007.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/squirtle.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/007.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/squirtle.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/007.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/squirtle.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/007.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Wartortle",
    value: "recGIqM2XbujyPxse",
    order: "0008",
    dexNo: "008",
    size: "small",
    name: "wartortle",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/wartortle.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/008.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/wartortle.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/008.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/wartortle.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/008.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/wartortle.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/008.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Blastoise",
    value: "recGVlwbrpYPEuHHs",
    order: "0009",
    dexNo: "009",
    size: "large",
    name: "blastoise",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/blastoise.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/009.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/blastoise.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/009.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/blastoise.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/009.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/blastoise.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/009.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Caterpie",
    value: "recUwcf7bXBZaNaPK",
    order: "0010",
    dexNo: "010",
    size: "xsmall",
    name: "caterpie",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/caterpie.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/010.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/caterpie.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/010.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/caterpie.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/010.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/caterpie.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/010.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Metapod",
    value: "recf3JLqWa9yJ8At2",
    order: "0011",
    dexNo: "011",
    size: "small",
    name: "metapod",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/metapod.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/011.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/metapod.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/011.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/metapod.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/011.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/metapod.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/011.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Butterfree",
    value: "rec7ciLFNGzMftsL5",
    order: "0012",
    dexNo: "012",
    size: "medium",
    name: "butterfree",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/butterfree.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/012.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/butterfree.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/012.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/butterfree.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/012.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/butterfree.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/012.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Weedle",
    value: "recjtAV3u2RKeZ3yk",
    order: "0013",
    dexNo: "013",
    size: "xsmall",
    name: "weedle",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/weedle.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/013.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/weedle.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/013.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/weedle.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/013.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/weedle.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/013.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Kakuna",
    value: "recmkgCCaintzYhQw",
    order: "0014",
    dexNo: "014",
    size: "small",
    name: "kakuna",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/kakuna.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/014.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/kakuna.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/014.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/kakuna.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/014.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/kakuna.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/014.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Beedrill",
    value: "reciRoGyYITR2q0Il",
    order: "0015",
    dexNo: "015",
    size: "small",
    name: "beedrill",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/beedrill.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/015.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/beedrill.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/015.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/beedrill.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/015.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/beedrill.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/015.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pidgey",
    value: "recgkr2LqM193Gxl6",
    order: "0016",
    dexNo: "016",
    size: "xsmall",
    name: "pidgey",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pidgey.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/016.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pidgey.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/016.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pidgey.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/016.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pidgey.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/016.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pidgeotto",
    value: "recc13i2KysM5IZoa",
    order: "0017",
    dexNo: "017",
    size: "medium",
    name: "pidgeotto",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pidgeotto.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/017.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pidgeotto.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/017.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pidgeotto.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/017.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pidgeotto.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/017.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pidgeot",
    value: "recn7IJAvEChEG3Rf",
    order: "0018",
    dexNo: "018",
    size: "medium",
    name: "pidgeot",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pidgeot.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/018.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pidgeot.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/018.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pidgeot.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/018.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pidgeot.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/018.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Rattata",
    value: "reci9YBXVSvTk59nt",
    order: "0019",
    dexNo: "019",
    size: "xsmall",
    name: "rattata",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/rattata.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/019.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/rattata.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/019.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/rattata.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/019.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/rattata.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/019.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Rattata (Alola Form)",
    value: "recIhBBOu5DYbSriM",
    order: "0019",
    dexNo: "019",
    size: "xsmall",
    name: "rattata",
    form: "alola",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/rattata-alola.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/019-alola.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/rattata-alola.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/019-alola.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/rattata-alola.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/019-alola.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/rattata-alola.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/019-alola.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Raticate",
    value: "rec8xyw5pRfjNT0oJ",
    order: "0020",
    dexNo: "020",
    size: "small",
    name: "raticate",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/raticate.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/020.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/raticate.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/020.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/raticate.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/020.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/raticate.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/020.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Raticate (Alola Form)",
    value: "recYjTHdaYjn1GT5Y",
    order: "0020",
    dexNo: "020",
    size: "small",
    name: "raticate",
    form: "alola",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/raticate-alola.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/020-alola.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/raticate-alola.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/020-alola.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/raticate-alola.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/020-alola.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/raticate-alola.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/020-alola.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Spearow",
    value: "recgQhQht7gPWc8IV",
    order: "0021",
    dexNo: "021",
    size: "xsmall",
    name: "spearow",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/spearow.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/021.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/spearow.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/021.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/spearow.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/021.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/spearow.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/021.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Fearow",
    value: "recWChUuexCY2GDr4",
    order: "0022",
    dexNo: "022",
    size: "medium",
    name: "fearow",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/fearow.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/022.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/fearow.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/022.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/fearow.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/022.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/fearow.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/022.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ekans",
    value: "rec5xHX6zzyrZzURk",
    order: "0023",
    dexNo: "023",
    size: "large",
    name: "ekans",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ekans.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/023.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ekans.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/023.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ekans.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/023.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ekans.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/023.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Arbok",
    value: "recojHncKnCY7aKOG",
    order: "0024",
    dexNo: "024",
    size: "xlarge",
    name: "arbok",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/arbok.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/024.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/arbok.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/024.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/arbok.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/024.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/arbok.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/024.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pikachu",
    value: "rec2MQvoWLvNI2wig",
    order: "0025",
    dexNo: "025",
    size: "xsmall",
    name: "pikachu",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pikachu.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/025.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pikachu.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/025.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pikachu.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/025.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pikachu.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/025.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Raichu",
    value: "rec9IjixkSFQ8QssC",
    order: "0026",
    dexNo: "026",
    size: "small",
    name: "raichu",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/raichu.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/026.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/raichu.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/026.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/raichu.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/026.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/raichu.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/026.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Raichu (Alola Form)",
    value: "recOdnptvLJw0gVfx",
    order: "0026",
    dexNo: "026",
    size: "small",
    name: "raichu",
    form: "alola",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/raichu-alola.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/026-alola.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/raichu-alola.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/026-alola.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/raichu-alola.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/026-alola.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/raichu-alola.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/026-alola.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sandshrew",
    value: "recgzqcGPLx1osK7k",
    order: "0027",
    dexNo: "027",
    size: "small",
    name: "sandshrew",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sandshrew.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/027.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sandshrew.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/027.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sandshrew.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/027.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sandshrew.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/027.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sandshrew (Alola Form)",
    value: "recoJgqjGp6LCuUqd",
    order: "0027",
    dexNo: "027",
    size: "small",
    name: "sandshrew",
    form: "alola",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sandshrew-alola.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/027-alola.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sandshrew-alola.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/027-alola.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sandshrew-alola.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/027-alola.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sandshrew-alola.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/027-alola.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sandslash",
    value: "recHy8AnambQGlzxo",
    order: "0028",
    dexNo: "028",
    size: "small",
    name: "sandslash",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sandslash.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/028.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sandslash.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/028.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sandslash.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/028.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sandslash.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/028.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sandslash (Alola Form)",
    value: "recluV4yxfNlE4jHH",
    order: "0028",
    dexNo: "028",
    size: "small",
    name: "sandslash",
    form: "alola",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sandslash-alola.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/028-alola.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sandslash-alola.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/028-alola.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sandslash-alola.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/028-alola.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sandslash-alola.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/028-alola.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Nidoran♀",
    value: "recIkrqAcUuQagScc",
    order: "0029",
    dexNo: "029",
    size: "xsmall",
    name: "nidoran",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/nidoran.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/029.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/nidoran.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/029.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/nidoran.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/029.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/nidoran.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/029.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Nidorina",
    value: "recRfMc3xPRkm5Y8x",
    order: "0030",
    dexNo: "030",
    size: "small",
    name: "nidorina",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/nidorina.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/030.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/nidorina.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/030.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/nidorina.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/030.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/nidorina.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/030.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Nidoqueen",
    value: "recNvUw1ucbdOdCIT",
    order: "0031",
    dexNo: "031",
    size: "medium",
    name: "nidoqueen",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/nidoqueen.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/031.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/nidoqueen.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/031.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/nidoqueen.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/031.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/nidoqueen.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/031.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Nidoran♂",
    value: "recGcKqEi54NMvycz",
    order: "0032",
    dexNo: "032",
    size: "xsmall",
    name: "nidoran",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/nidoran.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/032.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/nidoran.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/032.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/nidoran.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/032.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/nidoran.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/032.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Nidorino",
    value: "recIDRADcCEkImlKn",
    order: "0033",
    dexNo: "033",
    size: "small",
    name: "nidorino",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/nidorino.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/033.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/nidorino.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/033.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/nidorino.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/033.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/nidorino.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/033.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Nidoking",
    value: "recuHGxJ3pb3JNzoi",
    order: "0034",
    dexNo: "034",
    size: "medium",
    name: "nidoking",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/nidoking.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/034.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/nidoking.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/034.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/nidoking.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/034.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/nidoking.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/034.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Clefairy",
    value: "recUYYfMSKRyN1W75",
    order: "0035",
    dexNo: "035",
    size: "small",
    name: "clefairy",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/clefairy.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/035.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/clefairy.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/035.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/clefairy.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/035.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/clefairy.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/035.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Clefable",
    value: "rec9B9hF74nfgb9is",
    order: "0036",
    dexNo: "036",
    size: "medium",
    name: "clefable",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/clefable.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/036.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/clefable.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/036.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/clefable.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/036.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/clefable.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/036.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Vulpix",
    value: "recnb4dcLcknPFFB5",
    order: "0037",
    dexNo: "037",
    size: "small",
    name: "vulpix",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/vulpix.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/037.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/vulpix.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/037.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/vulpix.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/037.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/vulpix.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/037.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Vulpix (Alola Form)",
    value: "recTu7IZjuIAh86vS",
    order: "0037",
    dexNo: "037",
    size: "small",
    name: "vulpix",
    form: "alola",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/vulpix-alola.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/037-alola.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/vulpix-alola.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/037-alola.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/vulpix-alola.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/037-alola.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/vulpix-alola.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/037-alola.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ninetales",
    value: "recz6hhZGfFMzKsea",
    order: "0038",
    dexNo: "038",
    size: "medium",
    name: "ninetales",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ninetales.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/038.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ninetales.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/038.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ninetales.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/038.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ninetales.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/038.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ninetales (Alola Form)",
    value: "recNxhTaOzmlYcDhC",
    order: "0038",
    dexNo: "038",
    size: "medium",
    name: "ninetales",
    form: "alola",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ninetales-alola.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/038-alola.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ninetales-alola.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/038-alola.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ninetales-alola.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/038-alola.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ninetales-alola.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/038-alola.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Jigglypuff",
    value: "recTWwCKUb5LUAO6m",
    order: "0039",
    dexNo: "039",
    size: "xsmall",
    name: "jigglypuff",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/jigglypuff.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/039.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/jigglypuff.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/039.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/jigglypuff.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/039.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/jigglypuff.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/039.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Wigglytuff",
    value: "recZhLUSFR16D5ErN",
    order: "0040",
    dexNo: "040",
    size: "small",
    name: "wigglytuff",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/wigglytuff.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/040.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/wigglytuff.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/040.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/wigglytuff.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/040.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/wigglytuff.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/040.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Zubat",
    value: "recOUiB01s1ocTzBB",
    order: "0041",
    dexNo: "041",
    size: "small",
    name: "zubat",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/zubat.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/041.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/zubat.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/041.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/zubat.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/041.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/zubat.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/041.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Golbat",
    value: "reczTl6AFfWgSgWuE",
    order: "0042",
    dexNo: "042",
    size: "large",
    name: "golbat",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/golbat.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/042.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/golbat.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/042.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/golbat.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/042.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/golbat.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/042.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Oddish",
    value: "recENNJ1hINmPmqki",
    order: "0043",
    dexNo: "043",
    size: "xsmall",
    name: "oddish",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/oddish.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/043.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/oddish.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/043.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/oddish.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/043.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/oddish.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/043.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gloom",
    value: "recWiM6gnnGLVOsUI",
    order: "0044",
    dexNo: "044",
    size: "small",
    name: "gloom",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gloom.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/044.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gloom.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/044.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gloom.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/044.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gloom.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/044.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Vileplume",
    value: "recMuDV5YHvYbzvpu",
    order: "0045",
    dexNo: "045",
    size: "medium",
    name: "vileplume",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/vileplume.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/045.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/vileplume.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/045.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/vileplume.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/045.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/vileplume.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/045.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Paras",
    value: "recW0HRr2N3YKFnrz",
    order: "0046",
    dexNo: "046",
    size: "xsmall",
    name: "paras",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/paras.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/046.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/paras.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/046.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/paras.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/046.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/paras.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/046.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Parasect",
    value: "recMebUn8vz7keXjW",
    order: "0047",
    dexNo: "047",
    size: "small",
    name: "parasect",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/parasect.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/047.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/parasect.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/047.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/parasect.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/047.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/parasect.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/047.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Venonat",
    value: "rec2rE5mE6xaykt7T",
    order: "0048",
    dexNo: "048",
    size: "small",
    name: "venonat",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/venonat.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/048.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/venonat.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/048.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/venonat.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/048.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/venonat.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/048.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Venomoth",
    value: "rec1PFtE9CgmKrl77",
    order: "0049",
    dexNo: "049",
    size: "medium",
    name: "venomoth",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/venomoth.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/049.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/venomoth.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/049.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/venomoth.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/049.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/venomoth.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/049.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Diglett",
    value: "recN9wL2eNKJgmTFW",
    order: "0050",
    dexNo: "050",
    size: "xsmall",
    name: "diglett",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/diglett.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/050.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/diglett.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/050.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/diglett.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/050.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/diglett.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/050.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Diglett (Alola Form)",
    value: "recDRzq40TqKzcs2N",
    order: "0050",
    dexNo: "050",
    size: "xsmall",
    name: "diglett",
    form: "alola",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/diglett-alola.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/050-alola.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/diglett-alola.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/050-alola.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/diglett-alola.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/050-alola.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/diglett-alola.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/050-alola.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dugtrio",
    value: "rec8Y6LzlfNEtctax",
    order: "0051",
    dexNo: "051",
    size: "small",
    name: "dugtrio",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dugtrio.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/051.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dugtrio.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/051.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dugtrio.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/051.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dugtrio.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/051.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dugtrio (Alola Form)",
    value: "recWN9qKvo15kTJv3",
    order: "0051",
    dexNo: "051",
    size: "small",
    name: "dugtrio",
    form: "alola",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dugtrio-alola.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/051-alola.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dugtrio-alola.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/051-alola.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dugtrio-alola.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/051-alola.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dugtrio-alola.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/051-alola.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Meowth",
    value: "recDTaBJHI12PBHVi",
    order: "0052",
    dexNo: "052",
    size: "xsmall",
    name: "meowth",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/meowth.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/052.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/meowth.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/052.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/meowth.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/052.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/meowth.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/052.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Meowth (Alola Form)",
    value: "recunQJvcqj9gwXBZ",
    order: "0052",
    dexNo: "052",
    size: "xsmall",
    name: "meowth",
    form: "alola",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/meowth-alola.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/052-alola.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/meowth-alola.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/052-alola.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/meowth-alola.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/052-alola.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/meowth-alola.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/052-alola.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Meowth (Galarian Form)",
    value: "rec0bnH0Iq4sndHBs",
    order: "0052",
    dexNo: "052",
    size: "xsmall",
    name: "meowth",
    form: "galar",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/meowth-galar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/052-galar.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/meowth-galar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/052-galar.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/meowth-galar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/052-galar.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/meowth-galar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/052-galar.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Persian",
    value: "recCGWaNtR5Ocyf1i",
    order: "0053",
    dexNo: "053",
    size: "small",
    name: "persian",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/persian.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/053.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/persian.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/053.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/persian.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/053.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/persian.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/053.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Persian (Alola Form)",
    value: "recg25De3wnRXXzfO",
    order: "0053",
    dexNo: "053",
    size: "small",
    name: "persian",
    form: "alola",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/persian-alola.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/053-alola.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/persian-alola.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/053-alola.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/persian-alola.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/053-alola.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/persian-alola.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/053-alola.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Psyduck",
    value: "rec11Gwo2HkKWu4Pn",
    order: "0054",
    dexNo: "054",
    size: "small",
    name: "psyduck",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/psyduck.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/054.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/psyduck.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/054.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/psyduck.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/054.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/psyduck.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/054.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Golduck",
    value: "recOiMW9AStOVZ6q0",
    order: "0055",
    dexNo: "055",
    size: "large",
    name: "golduck",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/golduck.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/055.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/golduck.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/055.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/golduck.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/055.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/golduck.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/055.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mankey",
    value: "recEUX7asxsXxmpv2",
    order: "0056",
    dexNo: "056",
    size: "xsmall",
    name: "mankey",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mankey.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/056.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mankey.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/056.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mankey.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/056.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mankey.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/056.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Primeape",
    value: "reczPFkkPcgVtAnK4",
    order: "0057",
    dexNo: "057",
    size: "small",
    name: "primeape",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/primeape.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/057.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/primeape.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/057.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/primeape.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/057.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/primeape.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/057.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Growlithe",
    value: "reclhXAZmchFuISwG",
    order: "0058",
    dexNo: "058",
    size: "small",
    name: "growlithe",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/growlithe.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/058.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/growlithe.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/058.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/growlithe.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/058.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/growlithe.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/058.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Growlithe (Hisui Form)",
    value: "recAU3JCrq0r5alNE",
    order: "0058",
    dexNo: "058",
    size: "small",
    name: "growlithe",
    form: "hisui",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/growlithe-hisui.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/058-hisui.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/growlithe-hisui.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/058-hisui.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/growlithe-hisui.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/058-hisui.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/growlithe-hisui.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/058-hisui.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Arcanine",
    value: "recnKYNKulRAlvd0T",
    order: "0059",
    dexNo: "059",
    size: "large",
    name: "arcanine",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/arcanine.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/059.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/arcanine.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/059.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/arcanine.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/059.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/arcanine.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/059.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Arcanine (Hisui Form)",
    value: "rec3HlHenIOpG8bHL",
    order: "0059",
    dexNo: "059",
    size: "large",
    name: "arcanine",
    form: "hisui",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/arcanine-hisui.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/059-hisui.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/arcanine-hisui.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/059-hisui.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/arcanine-hisui.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/059-hisui.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/arcanine-hisui.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/059-hisui.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Poliwag",
    value: "recEIBhCNa4GwQfJd",
    order: "0060",
    dexNo: "060",
    size: "small",
    name: "poliwag",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/poliwag.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/060.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/poliwag.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/060.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/poliwag.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/060.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/poliwag.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/060.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Poliwhirl",
    value: "recuMlptWlFJTxFeb",
    order: "0061",
    dexNo: "061",
    size: "small",
    name: "poliwhirl",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/poliwhirl.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/061.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/poliwhirl.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/061.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/poliwhirl.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/061.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/poliwhirl.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/061.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Poliwrath",
    value: "recSC1Ljbl28zTBv0",
    order: "0062",
    dexNo: "062",
    size: "medium",
    name: "poliwrath",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/poliwrath.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/062.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/poliwrath.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/062.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/poliwrath.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/062.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/poliwrath.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/062.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Abra",
    value: "rec7rRJBzHzQnclq3",
    order: "0063",
    dexNo: "063",
    size: "small",
    name: "abra",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/abra.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/063.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/abra.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/063.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/abra.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/063.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/abra.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/063.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Kadabra",
    value: "recrsJpQdk1Wqm1Ob",
    order: "0064",
    dexNo: "064",
    size: "medium",
    name: "kadabra",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/kadabra.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/064.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/kadabra.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/064.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/kadabra.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/064.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/kadabra.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/064.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Alakazam",
    value: "recD9Q1w12b3hDcvF",
    order: "0065",
    dexNo: "065",
    size: "medium",
    name: "alakazam",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/alakazam.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/065.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/alakazam.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/065.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/alakazam.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/065.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/alakazam.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/065.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Machop",
    value: "recV7WthL5MaubiqU",
    order: "0066",
    dexNo: "066",
    size: "small",
    name: "machop",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/machop.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/066.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/machop.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/066.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/machop.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/066.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/machop.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/066.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Machoke",
    value: "recMNwVRq0GL3SyNE",
    order: "0067",
    dexNo: "067",
    size: "medium",
    name: "machoke",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/machoke.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/067.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/machoke.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/067.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/machoke.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/067.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/machoke.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/067.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Machamp",
    value: "recGgPxkqNd3CH1mX",
    order: "0068",
    dexNo: "068",
    size: "large",
    name: "machamp",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/machamp.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/068.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/machamp.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/068.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/machamp.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/068.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/machamp.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/068.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Bellsprout",
    value: "recoQoRIZn495d9MD",
    order: "0069",
    dexNo: "069",
    size: "small",
    name: "bellsprout",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/bellsprout.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/069.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/bellsprout.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/069.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/bellsprout.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/069.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/bellsprout.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/069.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Weepinbell",
    value: "recAl7tqSe6OFuk4r",
    order: "0070",
    dexNo: "070",
    size: "small",
    name: "weepinbell",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/weepinbell.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/070.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/weepinbell.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/070.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/weepinbell.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/070.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/weepinbell.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/070.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Victreebel",
    value: "recwiDkD8SSxE7bZR",
    order: "0071",
    dexNo: "071",
    size: "large",
    name: "victreebel",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/victreebel.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/071.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/victreebel.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/071.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/victreebel.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/071.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/victreebel.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/071.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tentacool",
    value: "rec8xUeCD1SzOKjnu",
    order: "0072",
    dexNo: "072",
    size: "small",
    name: "tentacool",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tentacool.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/072.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tentacool.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/072.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tentacool.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/072.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tentacool.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/072.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tentacruel",
    value: "rec0KqPxcZDIxQBjf",
    order: "0073",
    dexNo: "073",
    size: "large",
    name: "tentacruel",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tentacruel.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/073.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tentacruel.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/073.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tentacruel.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/073.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tentacruel.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/073.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Geodude",
    value: "recePyPV2HiBSXqBx",
    order: "0074",
    dexNo: "074",
    size: "xsmall",
    name: "geodude",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/geodude.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/074.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/geodude.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/074.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/geodude.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/074.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/geodude.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/074.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Geodude (Alola Form)",
    value: "recPjgfaQHHOm7Mop",
    order: "0074",
    dexNo: "074",
    size: "xsmall",
    name: "geodude",
    form: "alola",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/geodude-alola.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/074-alola.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/geodude-alola.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/074-alola.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/geodude-alola.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/074-alola.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/geodude-alola.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/074-alola.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Graveler",
    value: "rec8ZnaMfQQBXcYME",
    order: "0075",
    dexNo: "075",
    size: "small",
    name: "graveler",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/graveler.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/075.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/graveler.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/075.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/graveler.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/075.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/graveler.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/075.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Graveler (Alola Form)",
    value: "recPm6r9n0K9sQXdS",
    order: "0075",
    dexNo: "075",
    size: "small",
    name: "graveler",
    form: "alola",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/graveler-alola.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/075-alola.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/graveler-alola.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/075-alola.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/graveler-alola.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/075-alola.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/graveler-alola.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/075-alola.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Golem",
    value: "rec4iqfSjc9W8Wln4",
    order: "0076",
    dexNo: "076",
    size: "medium",
    name: "golem",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/golem.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/076.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/golem.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/076.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/golem.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/076.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/golem.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/076.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Golem (Alola Form)",
    value: "rec8GgwK01f5BCIBV",
    order: "0076",
    dexNo: "076",
    size: "medium",
    name: "golem",
    form: "alola",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/golem-alola.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/076-alola.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/golem-alola.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/076-alola.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/golem-alola.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/076-alola.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/golem-alola.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/076-alola.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ponyta",
    value: "recawzBuwZ1Lm5DmN",
    order: "0077",
    dexNo: "077",
    size: "small",
    name: "ponyta",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ponyta.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/077.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ponyta.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/077.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ponyta.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/077.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ponyta.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/077.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ponyta (Galarian Form)",
    value: "recfF7lXLQ45Vc86h",
    order: "0077",
    dexNo: "077",
    size: "small",
    name: "ponyta",
    form: "galar",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ponyta-galar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/077-galar.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ponyta-galar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/077-galar.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ponyta-galar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/077-galar.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ponyta-galar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/077-galar.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Rapidash",
    value: "rechC19rmL1yNAVbK",
    order: "0078",
    dexNo: "078",
    size: "large",
    name: "rapidash",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/rapidash.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/078.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/rapidash.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/078.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/rapidash.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/078.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/rapidash.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/078.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Rapidash (Galarian Form)",
    value: "recCHw9qAdvetHM9y",
    order: "0078",
    dexNo: "078",
    size: "large",
    name: "rapidash",
    form: "galar",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/rapidash-galar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/078-galar.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/rapidash-galar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/078-galar.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/rapidash-galar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/078-galar.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/rapidash-galar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/078-galar.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Slowpoke",
    value: "recnNvOze0qxJxwnP",
    order: "0079",
    dexNo: "079",
    size: "medium",
    name: "slowpoke",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/slowpoke.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/079.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/slowpoke.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/079.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/slowpoke.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/079.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/slowpoke.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/079.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Slowpoke (Galarian Form)",
    value: "recDwk32i42QvsrVP",
    order: "0079",
    dexNo: "079",
    size: "medium",
    name: "slowpoke",
    form: "galar",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/slowpoke-galar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/079-galar.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/slowpoke-galar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/079-galar.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/slowpoke-galar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/079-galar.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/slowpoke-galar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/079-galar.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Slowbro",
    value: "recv9sbdZ8oU5A6nH",
    order: "0080",
    dexNo: "080",
    size: "large",
    name: "slowbro",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/slowbro.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/080.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/slowbro.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/080.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/slowbro.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/080.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/slowbro.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/080.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Slowbro (Galarian Form)",
    value: "recSDhzMxnjAu6b7a",
    order: "0080",
    dexNo: "080",
    size: "large",
    name: "slowbro",
    form: "galar",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/slowbro-galar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/080-galar.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/slowbro-galar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/080-galar.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/slowbro-galar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/080-galar.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/slowbro-galar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/080-galar.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Magnemite",
    value: "recvHc7g0jCvHutuG",
    order: "0081",
    dexNo: "081",
    size: "xsmall",
    name: "magnemite",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/magnemite.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/081.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/magnemite.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/081.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/magnemite.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/081.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/magnemite.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/081.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Magneton",
    value: "recdgE5wNWFMM4QZr",
    order: "0082",
    dexNo: "082",
    size: "small",
    name: "magneton",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/magneton.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/082.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/magneton.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/082.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/magneton.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/082.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/magneton.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/082.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Farfetch'd",
    value: "recHYYkVn6CULcPmf",
    order: "0083",
    dexNo: "083",
    size: "small",
    name: "farfetchd",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/farfetchd.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/083.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/farfetchd.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/083.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/farfetchd.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/083.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/farfetchd.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/083.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Farfetch'd (Galarian Form)",
    value: "reccbmYfvobF8sprg",
    order: "0083",
    dexNo: "083",
    size: "small",
    name: "farfetchd",
    form: "galar",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/farfetchd-galar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/083-galar.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/farfetchd-galar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/083-galar.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/farfetchd-galar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/083-galar.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/farfetchd-galar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/083-galar.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Doduo",
    value: "recR0jBvpee522d2S",
    order: "0084",
    dexNo: "084",
    size: "medium",
    name: "doduo",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/doduo.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/084.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/doduo.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/084.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/doduo.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/084.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/doduo.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/084.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dodrio",
    value: "recMGiOOqzdcGPqNZ",
    order: "0085",
    dexNo: "085",
    size: "large",
    name: "dodrio",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dodrio.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/085.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dodrio.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/085.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dodrio.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/085.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dodrio.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/085.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Seel",
    value: "rec1kKtxD9DULytX6",
    order: "0086",
    dexNo: "086",
    size: "medium",
    name: "seel",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/seel.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/086.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/seel.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/086.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/seel.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/086.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/seel.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/086.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dewgong",
    value: "recXC4Yi10a8J9Vw7",
    order: "0087",
    dexNo: "087",
    size: "large",
    name: "dewgong",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dewgong.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/087.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dewgong.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/087.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dewgong.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/087.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dewgong.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/087.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Grimer",
    value: "recl1TE3WRtE4xQSh",
    order: "0088",
    dexNo: "088",
    size: "small",
    name: "grimer",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/grimer.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/088.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/grimer.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/088.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/grimer.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/088.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/grimer.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/088.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Grimer (Alola Form)",
    value: "recpQJp46rOr3hsG3",
    order: "0088",
    dexNo: "088",
    size: "small",
    name: "grimer",
    form: "alola",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/grimer-alola.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/088-alola.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/grimer-alola.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/088-alola.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/grimer-alola.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/088-alola.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/grimer-alola.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/088-alola.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Muk",
    value: "recQejJHx7tUSVLQ3",
    order: "0089",
    dexNo: "089",
    size: "medium",
    name: "muk",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/muk.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/089.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/muk.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/089.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/muk.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/089.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/muk.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/089.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Muk (Alola Form)",
    value: "recBROu5QEe0jXS9m",
    order: "0089",
    dexNo: "089",
    size: "medium",
    name: "muk",
    form: "alola",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/muk-alola.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/089-alola.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/muk-alola.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/089-alola.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/muk-alola.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/089-alola.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/muk-alola.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/089-alola.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Shellder",
    value: "recXadeBr4WIa7yh2",
    order: "0090",
    dexNo: "090",
    size: "xsmall",
    name: "shellder",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/shellder.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/090.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/shellder.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/090.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/shellder.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/090.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/shellder.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/090.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cloyster",
    value: "rec2pW1DGfC5OD0XC",
    order: "0091",
    dexNo: "091",
    size: "medium",
    name: "cloyster",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cloyster.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/091.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cloyster.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/091.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cloyster.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/091.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cloyster.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/091.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gastly",
    value: "reclFoRdIUT1H4ixD",
    order: "0092",
    dexNo: "092",
    size: "medium",
    name: "gastly",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gastly.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/092.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gastly.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/092.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gastly.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/092.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gastly.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/092.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Haunter",
    value: "reckPFEgQKwNww9eV",
    order: "0093",
    dexNo: "093",
    size: "large",
    name: "haunter",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/haunter.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/093.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/haunter.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/093.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/haunter.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/093.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/haunter.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/093.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gengar",
    value: "rec7CyQkvENQ21OKS",
    order: "0094",
    dexNo: "094",
    size: "medium",
    name: "gengar",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gengar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/094.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gengar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/094.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gengar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/094.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gengar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/094.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Onix",
    value: "recMHN5LYWrLV5GhG",
    order: "0095",
    dexNo: "095",
    size: "xlarge",
    name: "onix",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/onix.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/095.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/onix.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/095.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/onix.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/095.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/onix.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/095.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Drowzee",
    value: "recZyrDqUuS4PRSDn",
    order: "0096",
    dexNo: "096",
    size: "small",
    name: "drowzee",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/drowzee.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/096.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/drowzee.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/096.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/drowzee.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/096.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/drowzee.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/096.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Hypno",
    value: "recVX0msLAbpLYkW6",
    order: "0097",
    dexNo: "097",
    size: "large",
    name: "hypno",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/hypno.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/097.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/hypno.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/097.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/hypno.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/097.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/hypno.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/097.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Krabby",
    value: "recXiDb69Le5bqkvW",
    order: "0098",
    dexNo: "098",
    size: "xsmall",
    name: "krabby",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/krabby.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/098.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/krabby.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/098.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/krabby.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/098.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/krabby.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/098.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Kingler",
    value: "reca9Q7nBEbLr2CKy",
    order: "0099",
    dexNo: "099",
    size: "medium",
    name: "kingler",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/kingler.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/099.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/kingler.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/099.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/kingler.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/099.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/kingler.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/099.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Voltorb",
    value: "recD2F8HwxBKelKsH",
    order: "0100",
    dexNo: "100",
    size: "xsmall",
    name: "voltorb",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/voltorb.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/100.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/voltorb.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/100.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/voltorb.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/100.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/voltorb.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/100.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Voltorb (Hisui Form)",
    value: "recXLk6Zee2wkIkj5",
    order: "0100",
    dexNo: "100",
    size: "xsmall",
    name: "voltorb",
    form: "hisui",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/voltorb-hisui.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/100-hisui.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/voltorb-hisui.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/100-hisui.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/voltorb-hisui.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/100-hisui.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/voltorb-hisui.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/100-hisui.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Electrode",
    value: "recUVSHWc6eVUTI0q",
    order: "0101",
    dexNo: "101",
    size: "medium",
    name: "electrode",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/electrode.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/101.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/electrode.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/101.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/electrode.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/101.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/electrode.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/101.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Electrode (Hisui Form)",
    value: "recjHM9xnGRH6IRoW",
    order: "0101",
    dexNo: "101",
    size: "medium",
    name: "electrode",
    form: "hisui",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/electrode-hisui.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/101-hisui.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/electrode-hisui.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/101-hisui.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/electrode-hisui.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/101-hisui.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/electrode-hisui.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/101-hisui.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Exeggcute",
    value: "rectVtOIHR0ANOutg",
    order: "0102",
    dexNo: "102",
    size: "xsmall",
    name: "exeggcute",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/exeggcute.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/102.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/exeggcute.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/102.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/exeggcute.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/102.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/exeggcute.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/102.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Exeggutor",
    value: "recuoVYkkQviwcAZu",
    order: "0103",
    dexNo: "103",
    size: "large",
    name: "exeggutor",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/exeggutor.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/103.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/exeggutor.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/103.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/exeggutor.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/103.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/exeggutor.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/103.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Exeggutor (Alola Form)",
    value: "recsZeSzIfnKkdKBx",
    order: "0103",
    dexNo: "103",
    size: "large",
    name: "exeggutor",
    form: "alola",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/exeggutor-alola.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/103-alola.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/exeggutor-alola.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/103-alola.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/exeggutor-alola.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/103-alola.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/exeggutor-alola.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/103-alola.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cubone",
    value: "recprWklMggwPhpTi",
    order: "0104",
    dexNo: "104",
    size: "xsmall",
    name: "cubone",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cubone.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/104.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cubone.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/104.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cubone.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/104.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cubone.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/104.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Marowak",
    value: "recpqFVuPRq6qI2uI",
    order: "0105",
    dexNo: "105",
    size: "small",
    name: "marowak",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/marowak.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/105.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/marowak.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/105.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/marowak.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/105.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/marowak.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/105.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Marowak (Alola Form)",
    value: "recuVDPukhfPTS3KL",
    order: "0105",
    dexNo: "105",
    size: "small",
    name: "marowak",
    form: "alola",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/marowak-alola.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/105-alola.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/marowak-alola.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/105-alola.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/marowak-alola.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/105-alola.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/marowak-alola.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/105-alola.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Hitmonlee",
    value: "recPsxgi8tSvtmerN",
    order: "0106",
    dexNo: "106",
    size: "medium",
    name: "hitmonlee",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/hitmonlee.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/106.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/hitmonlee.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/106.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/hitmonlee.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/106.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/hitmonlee.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/106.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Hitmonchan",
    value: "recdCeowfr5Y0Qh1w",
    order: "0107",
    dexNo: "107",
    size: "medium",
    name: "hitmonchan",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/hitmonchan.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/107.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/hitmonchan.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/107.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/hitmonchan.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/107.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/hitmonchan.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/107.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Lickitung",
    value: "recLS6hNRodHsHQEs",
    order: "0108",
    dexNo: "108",
    size: "medium",
    name: "lickitung",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/lickitung.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/108.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/lickitung.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/108.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/lickitung.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/108.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/lickitung.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/108.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Koffing",
    value: "recZVTJUsuPxLRbHU",
    order: "0109",
    dexNo: "109",
    size: "small",
    name: "koffing",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/koffing.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/109.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/koffing.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/109.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/koffing.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/109.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/koffing.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/109.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Weezing",
    value: "recKk3HKtbmYE7CmM",
    order: "0110",
    dexNo: "110",
    size: "medium",
    name: "weezing",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/weezing.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/110.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/weezing.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/110.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/weezing.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/110.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/weezing.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/110.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Weezing (Galarian Form)",
    value: "recLVc1cV2f5T4tSS",
    order: "0110",
    dexNo: "110",
    size: "medium",
    name: "weezing",
    form: "galar",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/weezing-galar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/110-galar.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/weezing-galar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/110-galar.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/weezing-galar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/110-galar.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/weezing-galar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/110-galar.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Rhyhorn",
    value: "recsGVutwWXdgiyiJ",
    order: "0111",
    dexNo: "111",
    size: "small",
    name: "rhyhorn",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/rhyhorn.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/111.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/rhyhorn.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/111.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/rhyhorn.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/111.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/rhyhorn.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/111.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Rhydon",
    value: "recNogZOd6HjlOkId",
    order: "0112",
    dexNo: "112",
    size: "large",
    name: "rhydon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/rhydon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/112.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/rhydon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/112.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/rhydon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/112.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/rhydon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/112.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Chansey",
    value: "recJeRJVIgzvvYbJm",
    order: "0113",
    dexNo: "113",
    size: "medium",
    name: "chansey",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/chansey.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/113.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/chansey.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/113.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/chansey.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/113.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/chansey.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/113.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tangela",
    value: "recjpBTD9RLftTfcA",
    order: "0114",
    dexNo: "114",
    size: "small",
    name: "tangela",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tangela.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/114.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tangela.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/114.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tangela.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/114.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tangela.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/114.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Kangaskhan",
    value: "recklpIuagBjoUZG1",
    order: "0115",
    dexNo: "115",
    size: "xlarge",
    name: "kangaskhan",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/kangaskhan.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/115.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/kangaskhan.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/115.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/kangaskhan.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/115.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/kangaskhan.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/115.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Horsea",
    value: "rechOxJLxV20YaWbB",
    order: "0116",
    dexNo: "116",
    size: "xsmall",
    name: "horsea",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/horsea.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/116.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/horsea.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/116.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/horsea.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/116.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/horsea.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/116.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Seadra",
    value: "recm3w2ryC31fGm6y",
    order: "0117",
    dexNo: "117",
    size: "medium",
    name: "seadra",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/seadra.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/117.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/seadra.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/117.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/seadra.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/117.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/seadra.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/117.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Goldeen",
    value: "recanG2qvdktSqi4s",
    order: "0118",
    dexNo: "118",
    size: "small",
    name: "goldeen",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/goldeen.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/118.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/goldeen.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/118.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/goldeen.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/118.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/goldeen.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/118.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Seaking",
    value: "recPdNs16wlcNYosP",
    order: "0119",
    dexNo: "119",
    size: "medium",
    name: "seaking",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/seaking.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/119.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/seaking.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/119.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/seaking.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/119.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/seaking.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/119.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Staryu",
    value: "recAfAf7C6FppgRd9",
    order: "0120",
    dexNo: "120",
    size: "small",
    name: "staryu",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/staryu.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/120.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/staryu.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/120.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/staryu.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/120.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/staryu.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/120.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Starmie",
    value: "recbrU2DMxDSuOEib",
    order: "0121",
    dexNo: "121",
    size: "medium",
    name: "starmie",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/starmie.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/121.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/starmie.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/121.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/starmie.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/121.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/starmie.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/121.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mr. Mime",
    value: "recUho6SaBjXu2kyl",
    order: "0122",
    dexNo: "122",
    size: "medium",
    name: "mr-mime",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mr-mime.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/122.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mr-mime.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/122.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mr-mime.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/122.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mr-mime.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/122.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mr. Mime (Galarian Form)",
    value: "recIe8o2umtHBR4mB",
    order: "0122",
    dexNo: "122",
    size: "medium",
    name: "mr-mime",
    form: "galar",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mr-mime-galar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/122-galar.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mr-mime-galar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/122-galar.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mr-mime-galar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/122-galar.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mr-mime-galar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/122-galar.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Scyther",
    value: "recgkafbQFRRsVcNl",
    order: "0123",
    dexNo: "123",
    size: "medium",
    name: "scyther",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/scyther.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/123.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/scyther.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/123.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/scyther.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/123.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/scyther.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/123.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Jynx",
    value: "recYSYpqTIMl5oAWS",
    order: "0124",
    dexNo: "124",
    size: "medium",
    name: "jynx",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/jynx.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/124.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/jynx.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/124.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/jynx.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/124.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/jynx.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/124.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Electabuzz",
    value: "recMJ9Sj4FM3SI4uD",
    order: "0125",
    dexNo: "125",
    size: "medium",
    name: "electabuzz",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/electabuzz.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/125.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/electabuzz.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/125.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/electabuzz.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/125.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/electabuzz.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/125.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Magmar",
    value: "rece6iEiKQlFs5Pta",
    order: "0126",
    dexNo: "126",
    size: "medium",
    name: "magmar",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/magmar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/126.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/magmar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/126.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/magmar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/126.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/magmar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/126.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pinsir",
    value: "rectu6yMyxphFvzzQ",
    order: "0127",
    dexNo: "127",
    size: "medium",
    name: "pinsir",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pinsir.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/127.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pinsir.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/127.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pinsir.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/127.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pinsir.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/127.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tauros",
    value: "recdliJ1oPONdOUgC",
    order: "0128",
    dexNo: "128",
    size: "medium",
    name: "tauros",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tauros.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/128.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tauros.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/128.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tauros.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/128.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tauros.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/128.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tauros (Paldea Form)",
    value: "reckOfvlQzpnyLwwq",
    order: "0128",
    dexNo: "128",
    size: "medium",
    name: "tauros",
    form: "paldea",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tauros-paldea.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/128-paldea.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tauros-paldea.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/128-paldea.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tauros-paldea.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/128-paldea.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tauros-paldea.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/128-paldea.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Magikarp",
    value: "recL5OQ2RsPhb9WXk",
    order: "0129",
    dexNo: "129",
    size: "small",
    name: "magikarp",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/magikarp.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/129.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/magikarp.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/129.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/magikarp.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/129.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/magikarp.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/129.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gyarados",
    value: "recgDbKROYH8iGrn5",
    order: "0130",
    dexNo: "130",
    size: "xlarge",
    name: "gyarados",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gyarados.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/130.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gyarados.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/130.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gyarados.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/130.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gyarados.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/130.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Lapras",
    value: "recRhFyCaQvy46kNc",
    order: "0131",
    dexNo: "131",
    size: "xlarge",
    name: "lapras",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/lapras.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/131.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/lapras.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/131.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/lapras.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/131.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/lapras.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/131.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ditto",
    value: "recGbYYi0Ofs6kuNd",
    order: "0132",
    dexNo: "132",
    size: "xsmall",
    name: "ditto",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ditto.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/132.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ditto.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/132.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ditto.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/132.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ditto.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/132.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Eevee",
    value: "recvlXyo96lIHDYDE",
    order: "0133",
    dexNo: "133",
    size: "xsmall",
    name: "eevee",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/eevee.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/133.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/eevee.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/133.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/eevee.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/133.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/eevee.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/133.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Vaporeon",
    value: "recelRDUlce3IxfJp",
    order: "0134",
    dexNo: "134",
    size: "small",
    name: "vaporeon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/vaporeon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/134.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/vaporeon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/134.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/vaporeon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/134.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/vaporeon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/134.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Jolteon",
    value: "rec1uI2XrXa4xi7EZ",
    order: "0135",
    dexNo: "135",
    size: "small",
    name: "jolteon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/jolteon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/135.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/jolteon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/135.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/jolteon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/135.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/jolteon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/135.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Flareon",
    value: "recXhrlXLNOg040iF",
    order: "0136",
    dexNo: "136",
    size: "small",
    name: "flareon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/flareon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/136.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/flareon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/136.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/flareon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/136.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/flareon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/136.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Porygon",
    value: "recWtbz1GkJx80T37",
    order: "0137",
    dexNo: "137",
    size: "small",
    name: "porygon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/porygon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/137.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/porygon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/137.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/porygon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/137.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/porygon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/137.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Omanyte",
    value: "recVBYamVhWR3vfBP",
    order: "0138",
    dexNo: "138",
    size: "xsmall",
    name: "omanyte",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/omanyte.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/138.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/omanyte.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/138.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/omanyte.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/138.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/omanyte.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/138.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Omastar",
    value: "recFZ1rkGFkJUZZcQ",
    order: "0139",
    dexNo: "139",
    size: "small",
    name: "omastar",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/omastar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/139.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/omastar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/139.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/omastar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/139.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/omastar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/139.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Kabuto",
    value: "recQaovH3kJniyX2p",
    order: "0140",
    dexNo: "140",
    size: "xsmall",
    name: "kabuto",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/kabuto.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/140.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/kabuto.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/140.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/kabuto.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/140.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/kabuto.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/140.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Kabutops",
    value: "recBYcgTTXqIIwGPW",
    order: "0141",
    dexNo: "141",
    size: "medium",
    name: "kabutops",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/kabutops.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/141.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/kabutops.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/141.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/kabutops.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/141.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/kabutops.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/141.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Aerodactyl",
    value: "receHgkJKEJkv0aYR",
    order: "0142",
    dexNo: "142",
    size: "large",
    name: "aerodactyl",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/aerodactyl.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/142.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/aerodactyl.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/142.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/aerodactyl.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/142.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/aerodactyl.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/142.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Snorlax",
    value: "recMD2UxXNOyYoYL2",
    order: "0143",
    dexNo: "143",
    size: "xlarge",
    name: "snorlax",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/snorlax.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/143.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/snorlax.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/143.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/snorlax.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/143.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/snorlax.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/143.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Articuno",
    value: "recSYLKzOb33Pu4ze",
    order: "0144",
    dexNo: "144",
    size: "large",
    name: "articuno",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/articuno.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/144.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/articuno.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/144.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/articuno.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/144.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/articuno.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/144.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Articuno (Galarian Form)",
    value: "recuc2Tl4ZHMwB9XA",
    order: "0144",
    dexNo: "144",
    size: "large",
    name: "articuno",
    form: "galar",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/articuno-galar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/144-galar.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/articuno-galar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/144-galar.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/articuno-galar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/144-galar.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/articuno-galar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/144-galar.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Zapdos",
    value: "recHGFFwr6BhPAR7M",
    order: "0145",
    dexNo: "145",
    size: "large",
    name: "zapdos",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/zapdos.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/145.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/zapdos.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/145.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/zapdos.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/145.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/zapdos.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/145.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Zapdos (Galarian Form)",
    value: "recF7cD7FbadBoK8L",
    order: "0145",
    dexNo: "145",
    size: "large",
    name: "zapdos",
    form: "galar",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/zapdos-galar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/145-galar.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/zapdos-galar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/145-galar.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/zapdos-galar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/145-galar.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/zapdos-galar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/145-galar.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Moltres",
    value: "recRkkF3THvhcxVPt",
    order: "0146",
    dexNo: "146",
    size: "large",
    name: "moltres",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/moltres.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/146.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/moltres.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/146.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/moltres.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/146.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/moltres.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/146.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Moltres (Galarian Form)",
    value: "rec6rFk6Gvo020qKC",
    order: "0146",
    dexNo: "146",
    size: "large",
    name: "moltres",
    form: "galar",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/moltres-galar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/146-galar.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/moltres-galar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/146-galar.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/moltres-galar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/146-galar.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/moltres-galar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/146-galar.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dratini",
    value: "recYjozzBTtg2Y6za",
    order: "0147",
    dexNo: "147",
    size: "large",
    name: "dratini",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dratini.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/147.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dratini.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/147.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dratini.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/147.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dratini.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/147.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dragonair",
    value: "rec3iKAadCfi8P3y8",
    order: "0148",
    dexNo: "148",
    size: "xlarge",
    name: "dragonair",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dragonair.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/148.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dragonair.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/148.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dragonair.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/148.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dragonair.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/148.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dragonite",
    value: "rechA5iHdkk4PacOp",
    order: "0149",
    dexNo: "149",
    size: "xlarge",
    name: "dragonite",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dragonite.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/149.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dragonite.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/149.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dragonite.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/149.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dragonite.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/149.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mewtwo",
    value: "recEcWrGsi9yxpic6",
    order: "0150",
    dexNo: "150",
    size: "large",
    name: "mewtwo",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mewtwo.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/150.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mewtwo.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/150.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mewtwo.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/150.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mewtwo.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/150.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mew",
    value: "recjy5WntwPHCPXb3",
    order: "0151",
    dexNo: "151",
    size: "xsmall",
    name: "mew",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mew.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/151.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mew.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/151.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mew.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/151.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mew.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/151.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Chikorita",
    value: "recaIfRNA4k9K2uuB",
    order: "0152",
    dexNo: "152",
    size: "small",
    name: "chikorita",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/chikorita.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/152.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/chikorita.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/152.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/chikorita.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/152.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/chikorita.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/152.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Bayleef",
    value: "rec00E36jLEdY8g28",
    order: "0153",
    dexNo: "153",
    size: "medium",
    name: "bayleef",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/bayleef.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/153.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/bayleef.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/153.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/bayleef.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/153.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/bayleef.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/153.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Meganium",
    value: "rec0mes7Se6oP5h40",
    order: "0154",
    dexNo: "154",
    size: "large",
    name: "meganium",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/meganium.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/154.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/meganium.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/154.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/meganium.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/154.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/meganium.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/154.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cyndaquil",
    value: "recsuWTyHz7lhyUY3",
    order: "0155",
    dexNo: "155",
    size: "xsmall",
    name: "cyndaquil",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cyndaquil.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/155.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cyndaquil.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/155.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cyndaquil.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/155.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cyndaquil.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/155.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Quilava",
    value: "rechtffRMCzqZ14pD",
    order: "0156",
    dexNo: "156",
    size: "small",
    name: "quilava",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/quilava.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/156.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/quilava.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/156.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/quilava.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/156.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/quilava.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/156.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Typhlosion",
    value: "rec572N4ud4RzTAJw",
    order: "0157",
    dexNo: "157",
    size: "large",
    name: "typhlosion",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/typhlosion.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/157.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/typhlosion.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/157.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/typhlosion.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/157.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/typhlosion.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/157.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Typhlosion (Hisui Form)",
    value: "recbEOjBkxlYgDL0A",
    order: "0157",
    dexNo: "157",
    size: "large",
    name: "typhlosion",
    form: "hisui",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/typhlosion-hisui.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/157-hisui.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/typhlosion-hisui.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/157-hisui.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/typhlosion-hisui.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/157-hisui.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/typhlosion-hisui.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/157-hisui.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Totodile",
    value: "recd3dUoQEcPI7jdN",
    order: "0158",
    dexNo: "158",
    size: "small",
    name: "totodile",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/totodile.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/158.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/totodile.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/158.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/totodile.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/158.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/totodile.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/158.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Croconaw",
    value: "reccJBBwAkOQRR0kf",
    order: "0159",
    dexNo: "159",
    size: "medium",
    name: "croconaw",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/croconaw.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/159.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/croconaw.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/159.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/croconaw.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/159.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/croconaw.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/159.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Feraligatr",
    value: "rectb3HyHkaI49YEd",
    order: "0160",
    dexNo: "160",
    size: "xlarge",
    name: "feraligatr",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/feraligatr.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/160.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/feraligatr.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/160.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/feraligatr.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/160.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/feraligatr.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/160.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sentret",
    value: "recnJUL8vVC0UKsOx",
    order: "0161",
    dexNo: "161",
    size: "small",
    name: "sentret",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sentret.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/161.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sentret.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/161.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sentret.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/161.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sentret.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/161.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Furret",
    value: "recWAUjArnW8P9Rue",
    order: "0162",
    dexNo: "162",
    size: "large",
    name: "furret",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/furret.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/162.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/furret.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/162.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/furret.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/162.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/furret.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/162.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Hoothoot",
    value: "reccMbxE9838R5mk2",
    order: "0163",
    dexNo: "163",
    size: "small",
    name: "hoothoot",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/hoothoot.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/163.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/hoothoot.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/163.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/hoothoot.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/163.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/hoothoot.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/163.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Noctowl",
    value: "recluXQ4qbMad26Te",
    order: "0164",
    dexNo: "164",
    size: "large",
    name: "noctowl",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/noctowl.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/164.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/noctowl.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/164.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/noctowl.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/164.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/noctowl.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/164.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ledyba",
    value: "recBFHwHpNtIkaXtA",
    order: "0165",
    dexNo: "165",
    size: "small",
    name: "ledyba",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ledyba.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/165.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ledyba.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/165.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ledyba.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/165.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ledyba.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/165.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ledian",
    value: "recb54V79GKx12mPg",
    order: "0166",
    dexNo: "166",
    size: "medium",
    name: "ledian",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ledian.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/166.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ledian.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/166.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ledian.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/166.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ledian.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/166.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Spinarak",
    value: "rec7ZVoPrKKvccAkJ",
    order: "0167",
    dexNo: "167",
    size: "xsmall",
    name: "spinarak",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/spinarak.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/167.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/spinarak.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/167.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/spinarak.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/167.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/spinarak.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/167.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ariados",
    value: "recXZr6Yg9DEDwLIK",
    order: "0168",
    dexNo: "168",
    size: "medium",
    name: "ariados",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ariados.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/168.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ariados.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/168.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ariados.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/168.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ariados.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/168.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Crobat",
    value: "recNhP8Tj7P2m0KL0",
    order: "0169",
    dexNo: "169",
    size: "large",
    name: "crobat",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/crobat.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/169.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/crobat.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/169.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/crobat.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/169.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/crobat.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/169.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Chinchou",
    value: "recmk9nCE8Gd2YmlL",
    order: "0170",
    dexNo: "170",
    size: "xsmall",
    name: "chinchou",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/chinchou.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/170.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/chinchou.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/170.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/chinchou.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/170.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/chinchou.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/170.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Lanturn",
    value: "recrLBB7wkEDxGHrx",
    order: "0171",
    dexNo: "171",
    size: "medium",
    name: "lanturn",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/lanturn.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/171.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/lanturn.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/171.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/lanturn.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/171.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/lanturn.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/171.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pichu",
    value: "reczZAgk8W122gp87",
    order: "0172",
    dexNo: "172",
    size: "xsmall",
    name: "pichu",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pichu.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/172.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pichu.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/172.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pichu.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/172.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pichu.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/172.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cleffa",
    value: "recCB4ETsxu6GVtox",
    order: "0173",
    dexNo: "173",
    size: "xsmall",
    name: "cleffa",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cleffa.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/173.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cleffa.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/173.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cleffa.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/173.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cleffa.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/173.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Igglybuff",
    value: "recasLYs4OTUMjUop",
    order: "0174",
    dexNo: "174",
    size: "xsmall",
    name: "igglybuff",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/igglybuff.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/174.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/igglybuff.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/174.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/igglybuff.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/174.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/igglybuff.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/174.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Togepi",
    value: "recMkqOwiT2TLtWKU",
    order: "0175",
    dexNo: "175",
    size: "xsmall",
    name: "togepi",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/togepi.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/175.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/togepi.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/175.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/togepi.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/175.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/togepi.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/175.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Togetic",
    value: "recbmFsF7wDkMswrd",
    order: "0176",
    dexNo: "176",
    size: "small",
    name: "togetic",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/togetic.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/176.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/togetic.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/176.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/togetic.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/176.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/togetic.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/176.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Natu",
    value: "recEKuysAjmwRkcEM",
    order: "0177",
    dexNo: "177",
    size: "xsmall",
    name: "natu",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/natu.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/177.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/natu.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/177.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/natu.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/177.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/natu.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/177.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Xatu",
    value: "recbQsV8dWTbZMmjy",
    order: "0178",
    dexNo: "178",
    size: "medium",
    name: "xatu",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/xatu.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/178.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/xatu.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/178.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/xatu.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/178.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/xatu.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/178.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mareep",
    value: "recCNRaZxcqjiugOp",
    order: "0179",
    dexNo: "179",
    size: "small",
    name: "mareep",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mareep.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/179.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mareep.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/179.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mareep.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/179.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mareep.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/179.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Flaaffy",
    value: "recCf19ZXyIn7xUFy",
    order: "0180",
    dexNo: "180",
    size: "small",
    name: "flaaffy",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/flaaffy.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/180.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/flaaffy.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/180.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/flaaffy.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/180.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/flaaffy.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/180.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ampharos",
    value: "recwmboG9DeUI8R8F",
    order: "0181",
    dexNo: "181",
    size: "medium",
    name: "ampharos",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ampharos.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/181.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ampharos.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/181.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ampharos.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/181.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ampharos.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/181.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Bellossom",
    value: "recXe49r14tmTYTDo",
    order: "0182",
    dexNo: "182",
    size: "xsmall",
    name: "bellossom",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/bellossom.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/182.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/bellossom.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/182.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/bellossom.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/182.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/bellossom.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/182.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Marill",
    value: "rec7sIZF98ua6UY9L",
    order: "0183",
    dexNo: "183",
    size: "xsmall",
    name: "marill",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/marill.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/183.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/marill.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/183.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/marill.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/183.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/marill.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/183.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Azumarill",
    value: "recHica4qtouOB9QC",
    order: "0184",
    dexNo: "184",
    size: "small",
    name: "azumarill",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/azumarill.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/184.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/azumarill.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/184.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/azumarill.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/184.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/azumarill.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/184.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sudowoodo",
    value: "recrkg0bptsUsQ1jw",
    order: "0185",
    dexNo: "185",
    size: "medium",
    name: "sudowoodo",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sudowoodo.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/185.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sudowoodo.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/185.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sudowoodo.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/185.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sudowoodo.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/185.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Politoed",
    value: "reckCiB3gEEsqp5oO",
    order: "0186",
    dexNo: "186",
    size: "medium",
    name: "politoed",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/politoed.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/186.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/politoed.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/186.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/politoed.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/186.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/politoed.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/186.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Hoppip",
    value: "recE0URMouQswxVcF",
    order: "0187",
    dexNo: "187",
    size: "xsmall",
    name: "hoppip",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/hoppip.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/187.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/hoppip.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/187.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/hoppip.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/187.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/hoppip.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/187.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Skiploom",
    value: "recuo0zAsiIjmRhmZ",
    order: "0188",
    dexNo: "188",
    size: "small",
    name: "skiploom",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/skiploom.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/188.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/skiploom.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/188.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/skiploom.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/188.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/skiploom.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/188.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Jumpluff",
    value: "recC7gQT8IbpaImOX",
    order: "0189",
    dexNo: "189",
    size: "small",
    name: "jumpluff",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/jumpluff.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/189.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/jumpluff.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/189.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/jumpluff.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/189.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/jumpluff.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/189.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Aipom",
    value: "rec56IwBQGeKDknSI",
    order: "0190",
    dexNo: "190",
    size: "small",
    name: "aipom",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/aipom.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/190.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/aipom.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/190.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/aipom.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/190.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/aipom.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/190.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sunkern",
    value: "rec8qiYLkzosL6uOF",
    order: "0191",
    dexNo: "191",
    size: "xsmall",
    name: "sunkern",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sunkern.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/191.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sunkern.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/191.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sunkern.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/191.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sunkern.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/191.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sunflora",
    value: "recAyxIR2OMbGlCZ7",
    order: "0192",
    dexNo: "192",
    size: "small",
    name: "sunflora",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sunflora.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/192.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sunflora.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/192.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sunflora.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/192.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sunflora.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/192.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Yanma",
    value: "recvm3f1jJaUMJRXW",
    order: "0193",
    dexNo: "193",
    size: "medium",
    name: "yanma",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/yanma.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/193.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/yanma.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/193.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/yanma.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/193.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/yanma.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/193.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Wooper",
    value: "recWaRxtcPBSKEP2X",
    order: "0194",
    dexNo: "194",
    size: "xsmall",
    name: "wooper",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/wooper.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/194.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/wooper.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/194.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/wooper.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/194.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/wooper.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/194.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Wooper (Paldea Form)",
    value: "rec4DE9Pr3BsXjtmj",
    order: "0194",
    dexNo: "194",
    size: "xsmall",
    name: "wooper",
    form: "paldea",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/wooper-paldea.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/194-paldea.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/wooper-paldea.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/194-paldea.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/wooper-paldea.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/194-paldea.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/wooper-paldea.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/194-paldea.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Quagsire",
    value: "recFHMVmdnfsGTjzm",
    order: "0195",
    dexNo: "195",
    size: "medium",
    name: "quagsire",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/quagsire.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/195.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/quagsire.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/195.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/quagsire.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/195.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/quagsire.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/195.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Espeon",
    value: "recwEk33w19MyEWky",
    order: "0196",
    dexNo: "196",
    size: "small",
    name: "espeon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/espeon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/196.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/espeon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/196.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/espeon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/196.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/espeon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/196.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Umbreon",
    value: "recdx5DoMwWCULXR6",
    order: "0197",
    dexNo: "197",
    size: "small",
    name: "umbreon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/umbreon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/197.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/umbreon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/197.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/umbreon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/197.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/umbreon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/197.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Murkrow",
    value: "recsrsF1A0cocW3iE",
    order: "0198",
    dexNo: "198",
    size: "xsmall",
    name: "murkrow",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/murkrow.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/198.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/murkrow.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/198.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/murkrow.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/198.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/murkrow.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/198.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Slowking",
    value: "recet9VMaqcCoTTxT",
    order: "0199",
    dexNo: "199",
    size: "large",
    name: "slowking",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/slowking.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/199.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/slowking.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/199.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/slowking.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/199.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/slowking.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/199.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Slowking (Galarian Form)",
    value: "recCgMbOQwuZJs4Zf",
    order: "0199",
    dexNo: "199",
    size: "large",
    name: "slowking",
    form: "galar",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/slowking-galar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/199-galar.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/slowking-galar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/199-galar.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/slowking-galar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/199-galar.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/slowking-galar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/199-galar.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Misdreavus",
    value: "recl2F5sANZNK2cDe",
    order: "0200",
    dexNo: "200",
    size: "small",
    name: "misdreavus",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/misdreavus.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/200.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/misdreavus.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/200.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/misdreavus.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/200.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/misdreavus.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/200.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Unown",
    value: "recXYdyg43ZNchzFf",
    order: "0201",
    dexNo: "201",
    size: "xsmall",
    name: "unown",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/unown.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/201.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/unown.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/201.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/unown.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/201.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/unown.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/201.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Wobbuffet",
    value: "rechOVrdPwsLXYq31",
    order: "0202",
    dexNo: "202",
    size: "medium",
    name: "wobbuffet",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/wobbuffet.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/202.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/wobbuffet.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/202.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/wobbuffet.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/202.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/wobbuffet.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/202.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Girafarig",
    value: "recnY7VadaSkzH8jq",
    order: "0203",
    dexNo: "203",
    size: "medium",
    name: "girafarig",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/girafarig.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/203.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/girafarig.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/203.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/girafarig.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/203.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/girafarig.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/203.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pineco",
    value: "reccQWA19BVh7KCtv",
    order: "0204",
    dexNo: "204",
    size: "small",
    name: "pineco",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pineco.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/204.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pineco.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/204.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pineco.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/204.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pineco.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/204.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Forretress",
    value: "rec30UtoXeXksM0nW",
    order: "0205",
    dexNo: "205",
    size: "medium",
    name: "forretress",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/forretress.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/205.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/forretress.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/205.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/forretress.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/205.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/forretress.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/205.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dunsparce",
    value: "recfzYdzFD0SzXweA",
    order: "0206",
    dexNo: "206",
    size: "medium",
    name: "dunsparce",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dunsparce.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/206.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dunsparce.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/206.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dunsparce.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/206.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dunsparce.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/206.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gligar",
    value: "recLnDe0OumGO3wsv",
    order: "0207",
    dexNo: "207",
    size: "medium",
    name: "gligar",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gligar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/207.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gligar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/207.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gligar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/207.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gligar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/207.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Steelix",
    value: "recZXtRENtXjkWah0",
    order: "0208",
    dexNo: "208",
    size: "xlarge",
    name: "steelix",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/steelix.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/208.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/steelix.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/208.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/steelix.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/208.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/steelix.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/208.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Snubbull",
    value: "recYomonO2RR3Jn93",
    order: "0209",
    dexNo: "209",
    size: "small",
    name: "snubbull",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/snubbull.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/209.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/snubbull.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/209.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/snubbull.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/209.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/snubbull.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/209.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Granbull",
    value: "recDUpl0a0Cz4TmJh",
    order: "0210",
    dexNo: "210",
    size: "medium",
    name: "granbull",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/granbull.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/210.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/granbull.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/210.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/granbull.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/210.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/granbull.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/210.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Qwilfish",
    value: "rec2n4WBAUVc36fUS",
    order: "0211",
    dexNo: "211",
    size: "xsmall",
    name: "qwilfish",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/qwilfish.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/211.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/qwilfish.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/211.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/qwilfish.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/211.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/qwilfish.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/211.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Qwilfish (Hisui Form)",
    value: "recC8RBrus9HFbXIF",
    order: "0211",
    dexNo: "211",
    size: "small",
    name: "qwilfish",
    form: "hisui",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/qwilfish-hisui.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/211-hisui.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/qwilfish-hisui.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/211-hisui.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/qwilfish-hisui.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/211-hisui.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/qwilfish-hisui.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/211-hisui.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Scizor",
    value: "reccORbyxWXLRJLZY",
    order: "0212",
    dexNo: "212",
    size: "large",
    name: "scizor",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/scizor.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/212.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/scizor.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/212.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/scizor.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/212.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/scizor.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/212.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Shuckle",
    value: "recVcaV6H6s1opXWw",
    order: "0213",
    dexNo: "213",
    size: "small",
    name: "shuckle",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/shuckle.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/213.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/shuckle.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/213.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/shuckle.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/213.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/shuckle.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/213.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Heracross",
    value: "recZKgpvXaGXe3yk9",
    order: "0214",
    dexNo: "214",
    size: "medium",
    name: "heracross",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/heracross.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/214.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/heracross.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/214.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/heracross.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/214.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/heracross.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/214.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sneasel",
    value: "recdB2GQ6WaAAFadu",
    order: "0215",
    dexNo: "215",
    size: "small",
    name: "sneasel",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sneasel.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/215.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sneasel.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/215.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sneasel.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/215.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sneasel.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/215.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sneasel (Hisui Form)",
    value: "recXXo7kMx4iB3DkU",
    order: "0215",
    dexNo: "215",
    size: "small",
    name: "sneasel",
    form: "hisui",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sneasel-hisui.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/215-hisui.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sneasel-hisui.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/215-hisui.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sneasel-hisui.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/215-hisui.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sneasel-hisui.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/215-hisui.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Teddiursa",
    value: "reclj6V9i0t88tku3",
    order: "0216",
    dexNo: "216",
    size: "small",
    name: "teddiursa",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/teddiursa.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/216.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/teddiursa.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/216.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/teddiursa.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/216.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/teddiursa.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/216.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ursaring",
    value: "recQ2j1jbsLG2tKJe",
    order: "0217",
    dexNo: "217",
    size: "large",
    name: "ursaring",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ursaring.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/217.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ursaring.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/217.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ursaring.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/217.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ursaring.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/217.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Slugma",
    value: "recheGZQSX4S4aGdI",
    order: "0218",
    dexNo: "218",
    size: "small",
    name: "slugma",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/slugma.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/218.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/slugma.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/218.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/slugma.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/218.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/slugma.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/218.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Magcargo",
    value: "recgEWLhqZmHkDxM2",
    order: "0219",
    dexNo: "219",
    size: "small",
    name: "magcargo",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/magcargo.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/219.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/magcargo.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/219.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/magcargo.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/219.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/magcargo.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/219.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Swinub",
    value: "recRCVEVpQvWg2Szh",
    order: "0220",
    dexNo: "220",
    size: "xsmall",
    name: "swinub",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/swinub.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/220.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/swinub.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/220.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/swinub.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/220.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/swinub.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/220.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Piloswine",
    value: "recjBvqJ2Awa6uiUq",
    order: "0221",
    dexNo: "221",
    size: "medium",
    name: "piloswine",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/piloswine.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/221.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/piloswine.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/221.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/piloswine.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/221.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/piloswine.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/221.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Corsola",
    value: "recrCfBnwZZsuH4yr",
    order: "0222",
    dexNo: "222",
    size: "small",
    name: "corsola",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/corsola.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/222.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/corsola.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/222.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/corsola.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/222.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/corsola.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/222.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Corsola (Galarian Form)",
    value: "rec7tpZGk62gihBWm",
    order: "0222",
    dexNo: "222",
    size: "small",
    name: "corsola",
    form: "galar",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/corsola-galar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/222-galar.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/corsola-galar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/222-galar.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/corsola-galar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/222-galar.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/corsola-galar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/222-galar.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Remoraid",
    value: "recqNARCFM2ozvxFF",
    order: "0223",
    dexNo: "223",
    size: "small",
    name: "remoraid",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/remoraid.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/223.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/remoraid.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/223.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/remoraid.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/223.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/remoraid.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/223.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Octillery",
    value: "recK4PD5rnwhIiDlf",
    order: "0224",
    dexNo: "224",
    size: "small",
    name: "octillery",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/octillery.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/224.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/octillery.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/224.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/octillery.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/224.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/octillery.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/224.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Delibird",
    value: "rec7Czh39HKU80REg",
    order: "0225",
    dexNo: "225",
    size: "small",
    name: "delibird",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/delibird.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/225.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/delibird.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/225.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/delibird.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/225.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/delibird.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/225.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mantine",
    value: "recBNzgASUOaZsw4b",
    order: "0226",
    dexNo: "226",
    size: "xlarge",
    name: "mantine",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mantine.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/226.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mantine.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/226.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mantine.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/226.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mantine.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/226.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Skarmory",
    value: "rece8A2DjrAjHXIb2",
    order: "0227",
    dexNo: "227",
    size: "large",
    name: "skarmory",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/skarmory.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/227.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/skarmory.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/227.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/skarmory.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/227.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/skarmory.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/227.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Houndour",
    value: "recV20GbokbnmrwJ9",
    order: "0228",
    dexNo: "228",
    size: "small",
    name: "houndour",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/houndour.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/228.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/houndour.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/228.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/houndour.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/228.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/houndour.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/228.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Houndoom",
    value: "recaAfqHojyJVHfmD",
    order: "0229",
    dexNo: "229",
    size: "medium",
    name: "houndoom",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/houndoom.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/229.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/houndoom.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/229.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/houndoom.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/229.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/houndoom.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/229.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Kingdra",
    value: "recgQ5mEoJw8jZ8wa",
    order: "0230",
    dexNo: "230",
    size: "large",
    name: "kingdra",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/kingdra.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/230.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/kingdra.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/230.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/kingdra.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/230.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/kingdra.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/230.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Phanpy",
    value: "recWmX278UgYQPhjg",
    order: "0231",
    dexNo: "231",
    size: "xsmall",
    name: "phanpy",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/phanpy.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/231.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/phanpy.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/231.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/phanpy.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/231.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/phanpy.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/231.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Donphan",
    value: "recRQbsd579oJgOxR",
    order: "0232",
    dexNo: "232",
    size: "medium",
    name: "donphan",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/donphan.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/232.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/donphan.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/232.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/donphan.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/232.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/donphan.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/232.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Porygon2",
    value: "reccOEMFr02f680qv",
    order: "0233",
    dexNo: "233",
    size: "small",
    name: "porygon2",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/porygon2.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/233.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/porygon2.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/233.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/porygon2.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/233.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/porygon2.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/233.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Stantler",
    value: "recZqENziokyJfJmQ",
    order: "0234",
    dexNo: "234",
    size: "medium",
    name: "stantler",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/stantler.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/234.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/stantler.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/234.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/stantler.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/234.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/stantler.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/234.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Smeargle",
    value: "recrpU0va6GEEbmHc",
    order: "0235",
    dexNo: "235",
    size: "medium",
    name: "smeargle",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/smeargle.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/235.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/smeargle.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/235.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/smeargle.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/235.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/smeargle.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/235.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tyrogue",
    value: "recPxjAER9f0CfNQW",
    order: "0236",
    dexNo: "236",
    size: "small",
    name: "tyrogue",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tyrogue.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/236.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tyrogue.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/236.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tyrogue.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/236.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tyrogue.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/236.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Hitmontop",
    value: "recVhGdgRVhGh9fOz",
    order: "0237",
    dexNo: "237",
    size: "medium",
    name: "hitmontop",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/hitmontop.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/237.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/hitmontop.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/237.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/hitmontop.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/237.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/hitmontop.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/237.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Smoochum",
    value: "recfsJXmrPdfd83Er",
    order: "0238",
    dexNo: "238",
    size: "xsmall",
    name: "smoochum",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/smoochum.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/238.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/smoochum.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/238.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/smoochum.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/238.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/smoochum.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/238.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Elekid",
    value: "recuicLoDWhYGeI8u",
    order: "0239",
    dexNo: "239",
    size: "small",
    name: "elekid",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/elekid.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/239.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/elekid.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/239.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/elekid.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/239.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/elekid.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/239.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Magby",
    value: "rec6B4hmzEdu7USWO",
    order: "0240",
    dexNo: "240",
    size: "small",
    name: "magby",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/magby.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/240.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/magby.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/240.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/magby.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/240.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/magby.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/240.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Miltank",
    value: "rechkLPAcD1i7tD4D",
    order: "0241",
    dexNo: "241",
    size: "medium",
    name: "miltank",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/miltank.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/241.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/miltank.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/241.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/miltank.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/241.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/miltank.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/241.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Blissey",
    value: "recLFJQBAfoof9BlC",
    order: "0242",
    dexNo: "242",
    size: "medium",
    name: "blissey",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/blissey.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/242.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/blissey.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/242.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/blissey.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/242.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/blissey.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/242.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Raikou",
    value: "recZh7Kcd4kb27yRr",
    order: "0243",
    dexNo: "243",
    size: "large",
    name: "raikou",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/raikou.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/243.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/raikou.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/243.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/raikou.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/243.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/raikou.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/243.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Entei",
    value: "reclm1yeoT9MCt0B8",
    order: "0244",
    dexNo: "244",
    size: "xlarge",
    name: "entei",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/entei.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/244.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/entei.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/244.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/entei.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/244.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/entei.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/244.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Suicune",
    value: "rec9jK5LpTjvoA6eI",
    order: "0245",
    dexNo: "245",
    size: "large",
    name: "suicune",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/suicune.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/245.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/suicune.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/245.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/suicune.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/245.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/suicune.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/245.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Larvitar",
    value: "recuLlIdxalJZ635h",
    order: "0246",
    dexNo: "246",
    size: "small",
    name: "larvitar",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/larvitar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/246.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/larvitar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/246.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/larvitar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/246.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/larvitar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/246.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pupitar",
    value: "recGcpkglGOfEWXij",
    order: "0247",
    dexNo: "247",
    size: "medium",
    name: "pupitar",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pupitar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/247.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pupitar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/247.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pupitar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/247.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pupitar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/247.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tyranitar",
    value: "recNuv5gapjlONzls",
    order: "0248",
    dexNo: "248",
    size: "large",
    name: "tyranitar",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tyranitar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/248.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tyranitar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/248.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tyranitar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/248.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tyranitar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/248.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Lugia",
    value: "rec7CU6ldMMxNujYp",
    order: "0249",
    dexNo: "249",
    size: "xlarge",
    name: "lugia",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/lugia.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/249.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/lugia.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/249.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/lugia.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/249.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/lugia.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/249.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ho-oh",
    value: "recd7VVbl1vsKXz5D",
    order: "0250",
    dexNo: "250",
    size: "xlarge",
    name: "ho-oh",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ho-oh.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/250.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ho-oh.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/250.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ho-oh.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/250.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ho-oh.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/250.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Celebi",
    value: "recO4I3bjwFgbXlJE",
    order: "0251",
    dexNo: "251",
    size: "small",
    name: "celebi",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/celebi.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/251.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/celebi.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/251.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/celebi.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/251.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/celebi.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/251.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Treecko",
    value: "recmNE7B24quy0Os6",
    order: "0252",
    dexNo: "252",
    size: "xsmall",
    name: "treecko",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/treecko.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/252.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/treecko.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/252.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/treecko.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/252.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/treecko.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/252.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Grovyle",
    value: "reci3Dy1NHuXaVmsB",
    order: "0253",
    dexNo: "253",
    size: "small",
    name: "grovyle",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/grovyle.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/253.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/grovyle.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/253.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/grovyle.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/253.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/grovyle.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/253.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sceptile",
    value: "recFQOHFukgfRwTH7",
    order: "0254",
    dexNo: "254",
    size: "large",
    name: "sceptile",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sceptile.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/254.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sceptile.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/254.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sceptile.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/254.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sceptile.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/254.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Torchic",
    value: "recK7gFlsjWe4pIiG",
    order: "0255",
    dexNo: "255",
    size: "xsmall",
    name: "torchic",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/torchic.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/255.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/torchic.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/255.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/torchic.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/255.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/torchic.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/255.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Combusken",
    value: "recrh5z5HRhHqqfsp",
    order: "0256",
    dexNo: "256",
    size: "small",
    name: "combusken",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/combusken.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/256.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/combusken.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/256.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/combusken.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/256.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/combusken.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/256.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Blaziken",
    value: "recOdASYKmSk821wf",
    order: "0257",
    dexNo: "257",
    size: "large",
    name: "blaziken",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/blaziken.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/257.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/blaziken.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/257.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/blaziken.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/257.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/blaziken.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/257.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mudkip",
    value: "recGZVEZDNteHnWtC",
    order: "0258",
    dexNo: "258",
    size: "xsmall",
    name: "mudkip",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mudkip.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/258.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mudkip.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/258.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mudkip.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/258.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mudkip.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/258.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Marshtomp",
    value: "rec7dWgjrDpoVHBA2",
    order: "0259",
    dexNo: "259",
    size: "small",
    name: "marshtomp",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/marshtomp.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/259.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/marshtomp.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/259.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/marshtomp.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/259.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/marshtomp.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/259.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Swampert",
    value: "recZBjOuMQqSZnODR",
    order: "0260",
    dexNo: "260",
    size: "medium",
    name: "swampert",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/swampert.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/260.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/swampert.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/260.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/swampert.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/260.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/swampert.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/260.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Poochyena",
    value: "recbCOwaAb5FBohsK",
    order: "0261",
    dexNo: "261",
    size: "xsmall",
    name: "poochyena",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/poochyena.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/261.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/poochyena.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/261.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/poochyena.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/261.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/poochyena.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/261.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mightyena",
    value: "recWdbjbrrsNRg9Mr",
    order: "0262",
    dexNo: "262",
    size: "small",
    name: "mightyena",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mightyena.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/262.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mightyena.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/262.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mightyena.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/262.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mightyena.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/262.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Zigzagoon",
    value: "recONTdf4ML4vH2IF",
    order: "0263",
    dexNo: "263",
    size: "xsmall",
    name: "zigzagoon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/zigzagoon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/263.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/zigzagoon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/263.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/zigzagoon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/263.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/zigzagoon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/263.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Zigzagoon (Galarian Form)",
    value: "rec4d4Kw3HFru8pX5",
    order: "0263",
    dexNo: "263",
    size: "xsmall",
    name: "zigzagoon",
    form: "galar",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/zigzagoon-galar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/263-galar.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/zigzagoon-galar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/263-galar.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/zigzagoon-galar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/263-galar.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/zigzagoon-galar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/263-galar.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Linoone",
    value: "recXrTFWyMsi2gjTM",
    order: "0264",
    dexNo: "264",
    size: "xsmall",
    name: "linoone",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/linoone.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/264.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/linoone.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/264.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/linoone.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/264.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/linoone.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/264.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },

  {
    label: "Linoone (Galarian Form)",
    value: "recau3Z9dqE5Drcuq",
    order: "0264",
    dexNo: "264",
    size: "xsmall",
    name: "linoone",
    form: "galar",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/linoone-galar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/264-galar.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/linoone-galar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/264-galar.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/linoone-galar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/264-galar.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/linoone-galar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/264-galar.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Wurmple",
    value: "recgcXwQizahusdjh",
    order: "0265",
    dexNo: "265",
    size: "xsmall",
    name: "wurmple",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/wurmple.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/265.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/wurmple.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/265.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/wurmple.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/265.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/wurmple.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/265.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Silcoon",
    value: "rec2ur2ZZLWKt9onQ",
    order: "0266",
    dexNo: "266",
    size: "small",
    name: "silcoon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/silcoon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/266.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/silcoon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/266.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/silcoon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/266.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/silcoon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/266.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Beautifly",
    value: "recvSpNEI22kNjYPL",
    order: "0267",
    dexNo: "267",
    size: "small",
    name: "beautifly",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/beautifly.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/267.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/beautifly.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/267.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/beautifly.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/267.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/beautifly.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/267.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cascoon",
    value: "recub2lCUr8gmDCLx",
    order: "0268",
    dexNo: "268",
    size: "small",
    name: "cascoon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cascoon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/268.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cascoon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/268.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cascoon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/268.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cascoon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/268.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dustox",
    value: "recCvANFLl1mEFe29",
    order: "0269",
    dexNo: "269",
    size: "medium",
    name: "dustox",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dustox.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/269.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dustox.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/269.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dustox.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/269.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dustox.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/269.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Lotad",
    value: "recsouSU1t8FaV7aI",
    order: "0270",
    dexNo: "270",
    size: "xsmall",
    name: "lotad",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/lotad.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/270.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/lotad.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/270.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/lotad.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/270.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/lotad.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/270.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Lombre",
    value: "recKrAEOqTj658KV4",
    order: "0271",
    dexNo: "271",
    size: "medium",
    name: "lombre",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/lombre.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/271.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/lombre.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/271.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/lombre.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/271.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/lombre.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/271.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ludicolo",
    value: "rec5ZOL98YA2v7mKI",
    order: "0272",
    dexNo: "272",
    size: "medium",
    name: "ludicolo",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ludicolo.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/272.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ludicolo.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/272.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ludicolo.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/272.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ludicolo.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/272.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Seedot",
    value: "rectR2M4oYt0XfMXi",
    order: "0273",
    dexNo: "273",
    size: "xsmall",
    name: "seedot",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/seedot.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/273.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/seedot.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/273.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/seedot.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/273.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/seedot.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/273.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Nuzleaf",
    value: "recjK2miwJ38yEyHW",
    order: "0274",
    dexNo: "274",
    size: "small",
    name: "nuzleaf",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/nuzleaf.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/274.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/nuzleaf.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/274.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/nuzleaf.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/274.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/nuzleaf.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/274.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Shiftry",
    value: "recBSPHCAdrSggNAi",
    order: "0275",
    dexNo: "275",
    size: "medium",
    name: "shiftry",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/shiftry.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/275.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/shiftry.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/275.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/shiftry.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/275.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/shiftry.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/275.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Taillow",
    value: "recMocRazczDCzKdG",
    order: "0276",
    dexNo: "276",
    size: "xsmall",
    name: "taillow",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/taillow.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/276.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/taillow.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/276.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/taillow.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/276.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/taillow.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/276.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Swellow",
    value: "reckvwNFKU3MeRDWV",
    order: "0277",
    dexNo: "277",
    size: "small",
    name: "swellow",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/swellow.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/277.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/swellow.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/277.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/swellow.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/277.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/swellow.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/277.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Wingull",
    value: "recjkWaDZ0yqVGzNl",
    order: "0278",
    dexNo: "278",
    size: "small",
    name: "wingull",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/wingull.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/278.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/wingull.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/278.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/wingull.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/278.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/wingull.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/278.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pelipper",
    value: "recM1wyg7lGv1hSRK",
    order: "0279",
    dexNo: "279",
    size: "medium",
    name: "pelipper",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pelipper.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/279.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pelipper.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/279.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pelipper.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/279.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pelipper.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/279.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ralts",
    value: "reciwgH8JlNExKBB8",
    order: "0280",
    dexNo: "280",
    size: "xsmall",
    name: "ralts",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ralts.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/280.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ralts.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/280.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ralts.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/280.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ralts.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/280.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Kirlia",
    value: "recQOWAIJ9BFoBetY",
    order: "0281",
    dexNo: "281",
    size: "small",
    name: "kirlia",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/kirlia.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/281.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/kirlia.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/281.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/kirlia.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/281.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/kirlia.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/281.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gardevoir",
    value: "recx4hQywvl4z2VHO",
    order: "0282",
    dexNo: "282",
    size: "large",
    name: "gardevoir",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gardevoir.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/282.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gardevoir.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/282.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gardevoir.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/282.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gardevoir.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/282.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Surskit",
    value: "recf5KwejF29CJwVu",
    order: "0283",
    dexNo: "283",
    size: "xsmall",
    name: "surskit",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/surskit.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/283.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/surskit.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/283.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/surskit.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/283.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/surskit.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/283.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Masquerain",
    value: "recqTYHiLV0R5rYjw",
    order: "0284",
    dexNo: "284",
    size: "small",
    name: "masquerain",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/masquerain.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/284.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/masquerain.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/284.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/masquerain.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/284.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/masquerain.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/284.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Shroomish",
    value: "rec1YLblbzc5tFM6W",
    order: "0285",
    dexNo: "285",
    size: "xsmall",
    name: "shroomish",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/shroomish.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/285.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/shroomish.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/285.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/shroomish.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/285.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/shroomish.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/285.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Breloom",
    value: "recoZptgyLLUPqdKB",
    order: "0286",
    dexNo: "286",
    size: "medium",
    name: "breloom",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/breloom.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/286.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/breloom.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/286.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/breloom.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/286.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/breloom.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/286.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Slakoth",
    value: "recsC5IU3Z892OF7M",
    order: "0287",
    dexNo: "287",
    size: "small",
    name: "slakoth",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/slakoth.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/287.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/slakoth.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/287.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/slakoth.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/287.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/slakoth.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/287.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Vigoroth",
    value: "recqol4JxoiB11Ixw",
    order: "0288",
    dexNo: "288",
    size: "medium",
    name: "vigoroth",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/vigoroth.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/288.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/vigoroth.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/288.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/vigoroth.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/288.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/vigoroth.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/288.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Slaking",
    value: "recR2teK2VYTFCuko",
    order: "0289",
    dexNo: "289",
    size: "large",
    name: "slaking",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/slaking.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/289.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/slaking.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/289.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/slaking.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/289.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/slaking.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/289.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Nincada",
    value: "recVFAfeD1nffB5nc",
    order: "0290",
    dexNo: "290",
    size: "xsmall",
    name: "nincada",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/nincada.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/290.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/nincada.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/290.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/nincada.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/290.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/nincada.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/290.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ninjask",
    value: "recm8X1f6aWPnXTCB",
    order: "0291",
    dexNo: "291",
    size: "small",
    name: "ninjask",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ninjask.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/291.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ninjask.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/291.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ninjask.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/291.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ninjask.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/291.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Shedinja",
    value: "rec4Qher0GyzF2NVZ",
    order: "0292",
    dexNo: "292",
    size: "small",
    name: "shedinja",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/shedinja.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/292.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/shedinja.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/292.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/shedinja.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/292.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/shedinja.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/292.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Whismur",
    value: "rec9ut8g3em9dZgHB",
    order: "0293",
    dexNo: "293",
    size: "small",
    name: "whismur",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/whismur.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/293.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/whismur.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/293.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/whismur.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/293.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/whismur.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/293.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Loudred",
    value: "rec6tCJWUvWT081BK",
    order: "0294",
    dexNo: "294",
    size: "small",
    name: "loudred",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/loudred.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/294.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/loudred.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/294.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/loudred.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/294.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/loudred.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/294.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Exploud",
    value: "rec0M1iB4q0LznvOj",
    order: "0295",
    dexNo: "295",
    size: "medium",
    name: "exploud",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/exploud.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/295.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/exploud.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/295.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/exploud.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/295.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/exploud.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/295.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Makuhita",
    value: "recEFMTIpzJlYqBVp",
    order: "0296",
    dexNo: "296",
    size: "small",
    name: "makuhita",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/makuhita.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/296.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/makuhita.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/296.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/makuhita.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/296.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/makuhita.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/296.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Hariyama",
    value: "recxpMSDEQqwfBnUz",
    order: "0297",
    dexNo: "297",
    size: "xlarge",
    name: "hariyama",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/hariyama.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/297.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/hariyama.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/297.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/hariyama.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/297.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/hariyama.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/297.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Azurill",
    value: "recRzBmyDbzQN6qPj",
    order: "0298",
    dexNo: "298",
    size: "xsmall",
    name: "azurill",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/azurill.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/298.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/azurill.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/298.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/azurill.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/298.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/azurill.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/298.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Nosepass",
    value: "rec2zuLVmIojUyTVa",
    order: "0299",
    dexNo: "299",
    size: "small",
    name: "nosepass",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/nosepass.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/299.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/nosepass.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/299.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/nosepass.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/299.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/nosepass.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/299.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Skitty",
    value: "recQAW7T7E5iZmqT3",
    order: "0300",
    dexNo: "300",
    size: "small",
    name: "skitty",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/skitty.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/300.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/skitty.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/300.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/skitty.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/300.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/skitty.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/300.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Delcatty",
    value: "recoNTsKHEj2eXRdw",
    order: "0301",
    dexNo: "301",
    size: "medium",
    name: "delcatty",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/delcatty.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/301.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/delcatty.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/301.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/delcatty.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/301.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/delcatty.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/301.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sableye",
    value: "rec5hoq2vzXWoufuh",
    order: "0302",
    dexNo: "302",
    size: "xsmall",
    name: "sableye",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sableye.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/302.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sableye.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/302.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sableye.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/302.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sableye.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/302.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mawile",
    value: "rec047V4KllTOsBz1",
    order: "0303",
    dexNo: "303",
    size: "small",
    name: "mawile",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mawile.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/303.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mawile.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/303.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mawile.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/303.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mawile.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/303.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Aron",
    value: "recSjJGGbNYl1ZHCJ",
    order: "0304",
    dexNo: "304",
    size: "xsmall",
    name: "aron",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/aron.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/304.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/aron.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/304.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/aron.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/304.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/aron.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/304.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Lairon",
    value: "recbwVRZ9vylWLXFU",
    order: "0305",
    dexNo: "305",
    size: "small",
    name: "lairon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/lairon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/305.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/lairon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/305.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/lairon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/305.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/lairon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/305.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Aggron",
    value: "recbcBMKnypJYpLc8",
    order: "0306",
    dexNo: "306",
    size: "xlarge",
    name: "aggron",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/aggron.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/306.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/aggron.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/306.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/aggron.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/306.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/aggron.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/306.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Meditite",
    value: "recNh1JaL7DQamPQ0",
    order: "0307",
    dexNo: "307",
    size: "small",
    name: "meditite",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/meditite.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/307.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/meditite.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/307.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/meditite.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/307.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/meditite.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/307.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Medicham",
    value: "recVzrvbD5cilpRzn",
    order: "0308",
    dexNo: "308",
    size: "medium",
    name: "medicham",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/medicham.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/308.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/medicham.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/308.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/medicham.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/308.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/medicham.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/308.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Electrike",
    value: "recdDRpvDjhKb5lHs",
    order: "0309",
    dexNo: "309",
    size: "small",
    name: "electrike",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/electrike.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/309.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/electrike.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/309.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/electrike.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/309.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/electrike.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/309.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Manectric",
    value: "rectajdORexfydFRG",
    order: "0310",
    dexNo: "310",
    size: "medium",
    name: "manectric",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/manectric.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/310.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/manectric.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/310.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/manectric.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/310.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/manectric.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/310.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Plusle",
    value: "recRVLvmhkGFPh46C",
    order: "0311",
    dexNo: "311",
    size: "xsmall",
    name: "plusle",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/plusle.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/311.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/plusle.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/311.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/plusle.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/311.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/plusle.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/311.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Minun",
    value: "recd3JwsAoVTawhJL",
    order: "0312",
    dexNo: "312",
    size: "xsmall",
    name: "minun",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/minun.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/312.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/minun.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/312.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/minun.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/312.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/minun.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/312.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Volbeat",
    value: "recpK9Gj6DpjrOH4Z",
    order: "0313",
    dexNo: "313",
    size: "small",
    name: "volbeat",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/volbeat.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/313.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/volbeat.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/313.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/volbeat.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/313.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/volbeat.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/313.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Illumise",
    value: "reckmuH66roxOtYQw",
    order: "0314",
    dexNo: "314",
    size: "small",
    name: "illumise",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/illumise.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/314.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/illumise.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/314.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/illumise.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/314.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/illumise.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/314.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Roselia",
    value: "recvfSzngyO9bRM7D",
    order: "0315",
    dexNo: "315",
    size: "xsmall",
    name: "roselia",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/roselia.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/315.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/roselia.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/315.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/roselia.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/315.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/roselia.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/315.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gulpin",
    value: "rec2HYPT03DqGWDY9",
    order: "0316",
    dexNo: "316",
    size: "xsmall",
    name: "gulpin",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gulpin.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/316.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gulpin.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/316.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gulpin.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/316.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gulpin.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/316.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Swalot",
    value: "recloPfCIUD2kiSmk",
    order: "0317",
    dexNo: "317",
    size: "large",
    name: "swalot",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/swalot.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/317.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/swalot.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/317.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/swalot.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/317.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/swalot.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/317.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Carvanha",
    value: "recei33OjVssd0iau",
    order: "0318",
    dexNo: "318",
    size: "small",
    name: "carvanha",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/carvanha.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/318.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/carvanha.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/318.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/carvanha.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/318.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/carvanha.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/318.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sharpedo",
    value: "recm9AWTTx8j5XuNF",
    order: "0319",
    dexNo: "319",
    size: "large",
    name: "sharpedo",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sharpedo.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/319.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sharpedo.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/319.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sharpedo.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/319.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sharpedo.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/319.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Wailmer",
    value: "recNaYr3apdenbHHq",
    order: "0320",
    dexNo: "320",
    size: "large",
    name: "wailmer",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/wailmer.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/320.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/wailmer.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/320.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/wailmer.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/320.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/wailmer.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/320.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Wailord",
    value: "rec210Cz1HTZUe5hM",
    order: "0321",
    dexNo: "321",
    size: "xlarge",
    name: "wailord",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/wailord.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/321.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/wailord.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/321.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/wailord.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/321.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/wailord.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/321.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Numel",
    value: "recj5q6K6HQDCbDff",
    order: "0322",
    dexNo: "322",
    size: "small",
    name: "numel",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/numel.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/322.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/numel.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/322.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/numel.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/322.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/numel.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/322.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Camerupt",
    value: "recaJw09fBlQWEaW1",
    order: "0323",
    dexNo: "323",
    size: "large",
    name: "camerupt",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/camerupt.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/323.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/camerupt.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/323.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/camerupt.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/323.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/camerupt.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/323.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Torkoal",
    value: "recAune4rxMNevSfr",
    order: "0324",
    dexNo: "324",
    size: "xsmall",
    name: "torkoal",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/torkoal.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/324.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/torkoal.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/324.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/torkoal.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/324.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/torkoal.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/324.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Spoink",
    value: "recdg5x2U5Czch4Dn",
    order: "0325",
    dexNo: "325",
    size: "small",
    name: "spoink",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/spoink.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/325.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/spoink.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/325.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/spoink.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/325.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/spoink.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/325.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Grumpig",
    value: "rec4zNGwx3YVcow3d",
    order: "0326",
    dexNo: "326",
    size: "small",
    name: "grumpig",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/grumpig.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/326.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/grumpig.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/326.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/grumpig.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/326.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/grumpig.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/326.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Spinda",
    value: "recXkZPCZuJCZXBoM",
    order: "0327",
    dexNo: "327",
    size: "medium",
    name: "spinda",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/spinda.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/327.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/spinda.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/327.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/spinda.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/327.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/spinda.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/327.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Trapinch",
    value: "recJezZ2FmgtmzfZv",
    order: "0328",
    dexNo: "328",
    size: "small",
    name: "trapinch",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/trapinch.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/328.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/trapinch.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/328.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/trapinch.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/328.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/trapinch.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/328.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Vibrava",
    value: "reckHpXQZDrug5jwn",
    order: "0329",
    dexNo: "329",
    size: "medium",
    name: "vibrava",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/vibrava.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/329.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/vibrava.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/329.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/vibrava.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/329.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/vibrava.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/329.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Flygon",
    value: "recEpigTGKeMwt8DV",
    order: "0330",
    dexNo: "330",
    size: "large",
    name: "flygon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/flygon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/330.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/flygon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/330.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/flygon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/330.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/flygon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/330.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cacnea",
    value: "rec6igrcpVOta0dt6",
    order: "0331",
    dexNo: "331",
    size: "xsmall",
    name: "cacnea",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cacnea.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/331.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cacnea.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/331.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cacnea.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/331.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cacnea.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/331.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cacturne",
    value: "reclAt2Lbwfey6nsl",
    order: "0332",
    dexNo: "332",
    size: "medium",
    name: "cacturne",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cacturne.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/332.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cacturne.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/332.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cacturne.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/332.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cacturne.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/332.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Swablu",
    value: "rechjxkDTsBRXQXNU",
    order: "0333",
    dexNo: "333",
    size: "xsmall",
    name: "swablu",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/swablu.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/333.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/swablu.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/333.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/swablu.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/333.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/swablu.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/333.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Altaria",
    value: "recl7J0LuSoKTpNzB",
    order: "0334",
    dexNo: "334",
    size: "medium",
    name: "altaria",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/altaria.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/334.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/altaria.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/334.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/altaria.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/334.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/altaria.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/334.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Zangoose",
    value: "recXTChgeOFSkpQwx",
    order: "0335",
    dexNo: "335",
    size: "medium",
    name: "zangoose",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/zangoose.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/335.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/zangoose.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/335.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/zangoose.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/335.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/zangoose.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/335.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Seviper",
    value: "recwXKTNR1e5Nn0T9",
    order: "0336",
    dexNo: "336",
    size: "xlarge",
    name: "seviper",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/seviper.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/336.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/seviper.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/336.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/seviper.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/336.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/seviper.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/336.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Lunatone",
    value: "recl6vXbYndQKyjG9",
    order: "0337",
    dexNo: "337",
    size: "small",
    name: "lunatone",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/lunatone.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/337.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/lunatone.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/337.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/lunatone.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/337.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/lunatone.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/337.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Solrock",
    value: "recpT75kkJMmq4xQE",
    order: "0338",
    dexNo: "338",
    size: "medium",
    name: "solrock",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/solrock.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/338.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/solrock.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/338.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/solrock.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/338.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/solrock.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/338.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Barboach",
    value: "rec8hv1Uv3wZnv5zP",
    order: "0339",
    dexNo: "339",
    size: "xsmall",
    name: "barboach",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/barboach.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/339.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/barboach.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/339.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/barboach.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/339.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/barboach.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/339.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Whiscash",
    value: "recdDF1QxO4f2ewW0",
    order: "0340",
    dexNo: "340",
    size: "small",
    name: "whiscash",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/whiscash.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/340.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/whiscash.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/340.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/whiscash.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/340.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/whiscash.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/340.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Corphish",
    value: "recXZAefJQP6jwHnB",
    order: "0341",
    dexNo: "341",
    size: "small",
    name: "corphish",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/corphish.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/341.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/corphish.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/341.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/corphish.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/341.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/corphish.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/341.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Crawdaunt",
    value: "recWCoofQNsEubIka",
    order: "0342",
    dexNo: "342",
    size: "medium",
    name: "crawdaunt",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/crawdaunt.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/342.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/crawdaunt.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/342.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/crawdaunt.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/342.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/crawdaunt.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/342.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Baltoy",
    value: "recqKSYF3VtZnAGjy",
    order: "0343",
    dexNo: "343",
    size: "xsmall",
    name: "baltoy",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/baltoy.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/343.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/baltoy.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/343.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/baltoy.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/343.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/baltoy.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/343.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Claydol",
    value: "recSIUE7yoEAZVkXk",
    order: "0344",
    dexNo: "344",
    size: "medium",
    name: "claydol",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/claydol.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/344.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/claydol.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/344.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/claydol.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/344.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/claydol.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/344.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Lileep",
    value: "reciOg3aUKLilQliq",
    order: "0345",
    dexNo: "345",
    size: "small",
    name: "lileep",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/lileep.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/345.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/lileep.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/345.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/lileep.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/345.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/lileep.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/345.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cradily",
    value: "recRK36ok31SD3ydo",
    order: "0346",
    dexNo: "346",
    size: "medium",
    name: "cradily",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cradily.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/346.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cradily.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/346.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cradily.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/346.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cradily.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/346.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Anorith",
    value: "recyiHaVS8oFF6DKK",
    order: "0347",
    dexNo: "347",
    size: "small",
    name: "anorith",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/anorith.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/347.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/anorith.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/347.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/anorith.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/347.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/anorith.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/347.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Armaldo",
    value: "recb1WZRg1v5gPgEj",
    order: "0348",
    dexNo: "348",
    size: "medium",
    name: "armaldo",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/armaldo.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/348.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/armaldo.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/348.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/armaldo.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/348.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/armaldo.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/348.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Feebas",
    value: "recWwCqQ2UGpiy6tG",
    order: "0349",
    dexNo: "349",
    size: "small",
    name: "feebas",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/feebas.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/349.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/feebas.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/349.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/feebas.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/349.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/feebas.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/349.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Milotic",
    value: "rectaLvmjwATgxSzn",
    order: "0350",
    dexNo: "350",
    size: "xlarge",
    name: "milotic",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/milotic.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/350.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/milotic.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/350.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/milotic.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/350.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/milotic.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/350.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Castform",
    value: "rectwpHX0dVPFkK5E",
    order: "0351",
    dexNo: "351",
    size: "xsmall",
    name: "castform",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/castform.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/351.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/castform.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/351.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/castform.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/351.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/castform.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/351.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Kecleon",
    value: "recuPH13LN5uAGc1I",
    order: "0352",
    dexNo: "352",
    size: "small",
    name: "kecleon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/kecleon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/352.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/kecleon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/352.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/kecleon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/352.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/kecleon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/352.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Shuppet",
    value: "recGq6rtRuC1ZtgDt",
    order: "0353",
    dexNo: "353",
    size: "small",
    name: "shuppet",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/shuppet.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/353.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/shuppet.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/353.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/shuppet.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/353.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/shuppet.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/353.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Banette",
    value: "recQGm5SMop5GFrXv",
    order: "0354",
    dexNo: "354",
    size: "medium",
    name: "banette",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/banette.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/354.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/banette.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/354.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/banette.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/354.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/banette.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/354.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Duskull",
    value: "rec2NUL9XqWuSFR6d",
    order: "0355",
    dexNo: "355",
    size: "small",
    name: "duskull",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/duskull.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/355.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/duskull.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/355.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/duskull.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/355.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/duskull.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/355.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dusclops",
    value: "rec1i6RwFXtwYTEf9",
    order: "0356",
    dexNo: "356",
    size: "large",
    name: "dusclops",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dusclops.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/356.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dusclops.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/356.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dusclops.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/356.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dusclops.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/356.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tropius",
    value: "reca7a8p4D7dP1qxX",
    order: "0357",
    dexNo: "357",
    size: "large",
    name: "tropius",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tropius.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/357.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tropius.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/357.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tropius.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/357.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tropius.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/357.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Chimecho",
    value: "recd8aCzJXKHnrbik",
    order: "0358",
    dexNo: "358",
    size: "small",
    name: "chimecho",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/chimecho.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/358.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/chimecho.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/358.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/chimecho.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/358.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/chimecho.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/358.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Absol",
    value: "rec2hGBAh6hZAuzLv",
    order: "0359",
    dexNo: "359",
    size: "medium",
    name: "absol",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/absol.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/359.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/absol.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/359.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/absol.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/359.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/absol.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/359.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Wynaut",
    value: "recQnBvNfEEDRE3Wo",
    order: "0360",
    dexNo: "360",
    size: "small",
    name: "wynaut",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/wynaut.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/360.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/wynaut.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/360.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/wynaut.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/360.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/wynaut.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/360.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Snorunt",
    value: "recjydJOYwjhj7k4k",
    order: "0361",
    dexNo: "361",
    size: "small",
    name: "snorunt",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/snorunt.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/361.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/snorunt.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/361.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/snorunt.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/361.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/snorunt.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/361.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Glalie",
    value: "recvq4cubCTAz2uoP",
    order: "0362",
    dexNo: "362",
    size: "medium",
    name: "glalie",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/glalie.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/362.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/glalie.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/362.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/glalie.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/362.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/glalie.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/362.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Spheal",
    value: "recuU7DddkPlQ50gl",
    order: "0363",
    dexNo: "363",
    size: "small",
    name: "spheal",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/spheal.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/363.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/spheal.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/363.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/spheal.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/363.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/spheal.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/363.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sealeo",
    value: "rec5CFnueTuLTVZsV",
    order: "0364",
    dexNo: "364",
    size: "medium",
    name: "sealeo",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sealeo.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/364.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sealeo.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/364.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sealeo.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/364.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sealeo.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/364.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Walrein",
    value: "reciSaQE09vQGNee7",
    order: "0365",
    dexNo: "365",
    size: "medium",
    name: "walrein",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/walrein.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/365.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/walrein.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/365.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/walrein.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/365.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/walrein.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/365.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Clamperl",
    value: "rec1O5PR4seCAUbB0",
    order: "0366",
    dexNo: "366",
    size: "xsmall",
    name: "clamperl",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/clamperl.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/366.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/clamperl.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/366.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/clamperl.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/366.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/clamperl.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/366.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Huntail",
    value: "recDiEv2YBbKiz5y4",
    order: "0367",
    dexNo: "367",
    size: "large",
    name: "huntail",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/huntail.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/367.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/huntail.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/367.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/huntail.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/367.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/huntail.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/367.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gorebyss",
    value: "recJXuehWP6zsaKUu",
    order: "0368",
    dexNo: "368",
    size: "large",
    name: "gorebyss",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gorebyss.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/368.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gorebyss.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/368.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gorebyss.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/368.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gorebyss.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/368.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Relicanth",
    value: "recBacnTcz1OI1DYN",
    order: "0369",
    dexNo: "369",
    size: "small",
    name: "relicanth",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/relicanth.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/369.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/relicanth.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/369.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/relicanth.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/369.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/relicanth.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/369.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Luvdisc",
    value: "recBkopn1ygak2gDy",
    order: "0370",
    dexNo: "370",
    size: "small",
    name: "luvdisc",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/luvdisc.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/370.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/luvdisc.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/370.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/luvdisc.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/370.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/luvdisc.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/370.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Bagon",
    value: "rec9gYbohHAJc4Nnm",
    order: "0371",
    dexNo: "371",
    size: "small",
    name: "bagon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/bagon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/371.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/bagon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/371.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/bagon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/371.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/bagon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/371.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Shelgon",
    value: "recWrOUMXsLaOKNij",
    order: "0372",
    dexNo: "372",
    size: "medium",
    name: "shelgon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/shelgon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/372.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/shelgon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/372.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/shelgon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/372.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/shelgon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/372.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Salamence",
    value: "rec8xbfoqzuwpz7Zt",
    order: "0373",
    dexNo: "373",
    size: "medium",
    name: "salamence",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/salamence.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/373.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/salamence.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/373.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/salamence.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/373.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/salamence.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/373.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Beldum",
    value: "recKr3CByB9puQUd2",
    order: "0374",
    dexNo: "374",
    size: "small",
    name: "beldum",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/beldum.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/374.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/beldum.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/374.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/beldum.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/374.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/beldum.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/374.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Metang",
    value: "recsWXMlfA114hsLp",
    order: "0375",
    dexNo: "375",
    size: "medium",
    name: "metang",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/metang.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/375.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/metang.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/375.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/metang.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/375.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/metang.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/375.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Metagross",
    value: "recpRLVqMcUgd1wDy",
    order: "0376",
    dexNo: "376",
    size: "large",
    name: "metagross",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/metagross.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/376.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/metagross.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/376.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/metagross.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/376.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/metagross.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/376.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Regirock",
    value: "recIwsGr4urNhrTuA",
    order: "0377",
    dexNo: "377",
    size: "large",
    name: "regirock",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/regirock.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/377.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/regirock.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/377.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/regirock.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/377.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/regirock.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/377.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Regice",
    value: "recScDs0Sbrs5xf3w",
    order: "0378",
    dexNo: "378",
    size: "large",
    name: "regice",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/regice.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/378.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/regice.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/378.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/regice.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/378.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/regice.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/378.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Registeel",
    value: "recIs1CSPntiOMVkn",
    order: "0379",
    dexNo: "379",
    size: "large",
    name: "registeel",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/registeel.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/379.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/registeel.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/379.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/registeel.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/379.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/registeel.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/379.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Latias",
    value: "recA8Qv9Hw3ktozis",
    order: "0380",
    dexNo: "380",
    size: "medium",
    name: "latias",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/latias.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/380.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/latias.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/380.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/latias.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/380.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/latias.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/380.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Latios",
    value: "recIRrKMC0PkRTeIk",
    order: "0381",
    dexNo: "381",
    size: "large",
    name: "latios",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/latios.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/381.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/latios.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/381.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/latios.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/381.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/latios.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/381.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Kyogre",
    value: "rec1KgdVK1GQv7ZxI",
    order: "0382",
    dexNo: "382",
    size: "xlarge",
    name: "kyogre",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/kyogre.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/382.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/kyogre.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/382.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/kyogre.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/382.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/kyogre.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/382.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Groudon",
    value: "recaYbgKlEP3U1EzH",
    order: "0383",
    dexNo: "383",
    size: "xlarge",
    name: "groudon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/groudon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/383.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/groudon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/383.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/groudon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/383.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/groudon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/383.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Rayquaza",
    value: "rechMQv1PMmSPXfHP",
    order: "0384",
    dexNo: "384",
    size: "xlarge",
    name: "rayquaza",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/rayquaza.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/384.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/rayquaza.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/384.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/rayquaza.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/384.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/rayquaza.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/384.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Jirachi",
    value: "recYmBjz4QSG12P7E",
    order: "0385",
    dexNo: "385",
    size: "xsmall",
    name: "jirachi",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/jirachi.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/385.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/jirachi.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/385.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/jirachi.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/385.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/jirachi.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/385.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Deoxys",
    value: "recN1lWJ8o3YTyv6S",
    order: "0386",
    dexNo: "386",
    size: "large",
    name: "deoxys",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/deoxys.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/386.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/deoxys.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/386.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/deoxys.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/386.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/deoxys.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/386.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Turtwig",
    value: "recyDf1Sj13HDNw84",
    order: "0387",
    dexNo: "387",
    size: "xsmall",
    name: "turtwig",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/turtwig.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/387.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/turtwig.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/387.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/turtwig.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/387.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/turtwig.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/387.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Grotle",
    value: "recWyRNErzDHuY9kT",
    order: "0388",
    dexNo: "388",
    size: "medium",
    name: "grotle",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/grotle.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/388.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/grotle.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/388.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/grotle.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/388.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/grotle.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/388.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Torterra",
    value: "recrmLv7VDvQGWHYI",
    order: "0389",
    dexNo: "389",
    size: "xlarge",
    name: "torterra",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/torterra.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/389.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/torterra.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/389.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/torterra.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/389.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/torterra.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/389.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Chimchar",
    value: "rec0dj2pP40bWq6cG",
    order: "0390",
    dexNo: "390",
    size: "xsmall",
    name: "chimchar",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/chimchar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/390.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/chimchar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/390.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/chimchar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/390.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/chimchar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/390.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Monferno",
    value: "reczqKmhew1cdxFDW",
    order: "0391",
    dexNo: "391",
    size: "small",
    name: "monferno",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/monferno.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/391.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/monferno.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/391.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/monferno.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/391.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/monferno.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/391.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Infernape",
    value: "recNPHi19uzb4I2O5",
    order: "0392",
    dexNo: "392",
    size: "medium",
    name: "infernape",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/infernape.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/392.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/infernape.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/392.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/infernape.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/392.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/infernape.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/392.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Piplup",
    value: "recQq88AMdbNswFhk",
    order: "0393",
    dexNo: "393",
    size: "xsmall",
    name: "piplup",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/piplup.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/393.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/piplup.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/393.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/piplup.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/393.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/piplup.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/393.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Prinplup",
    value: "recy9U5FRq554ZBkS",
    order: "0394",
    dexNo: "394",
    size: "small",
    name: "prinplup",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/prinplup.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/394.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/prinplup.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/394.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/prinplup.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/394.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/prinplup.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/394.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Empoleon",
    value: "recFGsxxHOQRf7i5L",
    order: "0395",
    dexNo: "395",
    size: "large",
    name: "empoleon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/empoleon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/395.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/empoleon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/395.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/empoleon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/395.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/empoleon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/395.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Starly",
    value: "recbr1vecIXFmEnqT",
    order: "0396",
    dexNo: "396",
    size: "xsmall",
    name: "starly",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/starly.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/396.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/starly.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/396.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/starly.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/396.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/starly.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/396.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Staravia",
    value: "rec5IMyIN1WJSl58L",
    order: "0397",
    dexNo: "397",
    size: "small",
    name: "staravia",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/staravia.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/397.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/staravia.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/397.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/staravia.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/397.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/staravia.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/397.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Staraptor",
    value: "recX6uBUAYEQKTA5a",
    order: "0398",
    dexNo: "398",
    size: "medium",
    name: "staraptor",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/staraptor.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/398.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/staraptor.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/398.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/staraptor.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/398.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/staraptor.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/398.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Bidoof",
    value: "recfI4C9fdbRSq72N",
    order: "0399",
    dexNo: "399",
    size: "xsmall",
    name: "bidoof",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/bidoof.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/399.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/bidoof.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/399.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/bidoof.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/399.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/bidoof.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/399.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Bibarel",
    value: "rec4ICQVkcYQl4zED",
    order: "0400",
    dexNo: "400",
    size: "small",
    name: "bibarel",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/bibarel.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/400.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/bibarel.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/400.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/bibarel.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/400.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/bibarel.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/400.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Kricketot",
    value: "recBykz6qHV38P5qz",
    order: "0401",
    dexNo: "401",
    size: "xsmall",
    name: "kricketot",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/kricketot.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/401.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/kricketot.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/401.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/kricketot.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/401.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/kricketot.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/401.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Kricketune",
    value: "recNHMX2S926C3gk0",
    order: "0402",
    dexNo: "402",
    size: "small",
    name: "kricketune",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/kricketune.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/402.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/kricketune.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/402.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/kricketune.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/402.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/kricketune.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/402.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Shinx",
    value: "recWa254t76VvLFCb",
    order: "0403",
    dexNo: "403",
    size: "xsmall",
    name: "shinx",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/shinx.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/403.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/shinx.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/403.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/shinx.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/403.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/shinx.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/403.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Luxio",
    value: "recYQoR2sJTEyzO0U",
    order: "0404",
    dexNo: "404",
    size: "small",
    name: "luxio",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/luxio.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/404.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/luxio.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/404.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/luxio.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/404.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/luxio.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/404.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Luxray",
    value: "recPG3mOi2iEFbIjv",
    order: "0405",
    dexNo: "405",
    size: "medium",
    name: "luxray",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/luxray.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/405.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/luxray.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/405.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/luxray.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/405.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/luxray.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/405.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Budew",
    value: "recNq2sg5PXHQtTlq",
    order: "0406",
    dexNo: "406",
    size: "xsmall",
    name: "budew",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/budew.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/406.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/budew.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/406.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/budew.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/406.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/budew.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/406.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Roserade",
    value: "recNjS1ujaqys5HWT",
    order: "0407",
    dexNo: "407",
    size: "small",
    name: "roserade",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/roserade.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/407.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/roserade.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/407.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/roserade.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/407.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/roserade.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/407.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cranidos",
    value: "rec7FkvIYVaMFQXHx",
    order: "0408",
    dexNo: "408",
    size: "small",
    name: "cranidos",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cranidos.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/408.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cranidos.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/408.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cranidos.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/408.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cranidos.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/408.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Rampardos",
    value: "rec9zhFL1WhAjD891",
    order: "0409",
    dexNo: "409",
    size: "large",
    name: "rampardos",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/rampardos.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/409.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/rampardos.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/409.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/rampardos.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/409.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/rampardos.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/409.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Shieldon",
    value: "recI51LD3Wcf0tAct",
    order: "0410",
    dexNo: "410",
    size: "xsmall",
    name: "shieldon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/shieldon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/410.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/shieldon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/410.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/shieldon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/410.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/shieldon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/410.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Bastiodon",
    value: "recsyv0ZShSyUZbiC",
    order: "0411",
    dexNo: "411",
    size: "medium",
    name: "bastiodon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/bastiodon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/411.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/bastiodon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/411.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/bastiodon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/411.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/bastiodon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/411.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Burmy",
    value: "recfbU7xNtyyOtaUA",
    order: "0412",
    dexNo: "412",
    size: "xsmall",
    name: "burmy",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/burmy.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/412.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/burmy.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/412.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/burmy.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/412.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/burmy.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/412.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Wormadam",
    value: "recNNHmYOKCwOP9I1",
    order: "0413",
    dexNo: "413",
    size: "xsmall",
    name: "wormadam",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/wormadam.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/413.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/wormadam.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/413.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/wormadam.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/413.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/wormadam.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/413.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mothim",
    value: "rec2QzeutOfyleFf9",
    order: "0414",
    dexNo: "414",
    size: "small",
    name: "mothim",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mothim.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/414.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mothim.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/414.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mothim.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/414.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mothim.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/414.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Combee",
    value: "recokyyo2TP6ZdMbe",
    order: "0415",
    dexNo: "415",
    size: "xsmall",
    name: "combee",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/combee.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/415.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/combee.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/415.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/combee.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/415.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/combee.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/415.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Vespiquen",
    value: "rectnPmZsKUeCLbdz",
    order: "0416",
    dexNo: "416",
    size: "medium",
    name: "vespiquen",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/vespiquen.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/416.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/vespiquen.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/416.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/vespiquen.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/416.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/vespiquen.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/416.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pachirisu",
    value: "recrIXKwMZ9cVBEkC",
    order: "0417",
    dexNo: "417",
    size: "xsmall",
    name: "pachirisu",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pachirisu.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/417.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pachirisu.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/417.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pachirisu.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/417.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pachirisu.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/417.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Buizel",
    value: "recT0KIT3dB1I8Hff",
    order: "0418",
    dexNo: "418",
    size: "small",
    name: "buizel",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/buizel.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/418.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/buizel.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/418.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/buizel.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/418.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/buizel.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/418.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Floatzel",
    value: "recB6eOdyAkINEjNW",
    order: "0419",
    dexNo: "419",
    size: "medium",
    name: "floatzel",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/floatzel.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/419.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/floatzel.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/419.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/floatzel.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/419.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/floatzel.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/419.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cherubi",
    value: "receduRhERSgfjKze",
    order: "0420",
    dexNo: "420",
    size: "xsmall",
    name: "cherubi",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cherubi.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/420.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cherubi.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/420.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cherubi.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/420.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cherubi.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/420.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cherrim",
    value: "recs9tZaRnOXpt9F6",
    order: "0421",
    dexNo: "421",
    size: "xsmall",
    name: "cherrim",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cherrim.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/421.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cherrim.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/421.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cherrim.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/421.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cherrim.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/421.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Shellos",
    value: "reclLtkiTaGUcMH3d",
    order: "0422",
    dexNo: "422",
    size: "xsmall",
    name: "shellos",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/shellos.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/422.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/shellos.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/422.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/shellos.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/422.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/shellos.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/422.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gastrodon",
    value: "recuH9h5N7IJKc2PW",
    order: "0423",
    dexNo: "423",
    size: "small",
    name: "gastrodon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gastrodon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/423.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gastrodon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/423.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gastrodon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/423.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gastrodon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/423.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ambipom",
    value: "recZDEYqCoNFWTuEM",
    order: "0424",
    dexNo: "424",
    size: "medium",
    name: "ambipom",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ambipom.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/424.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ambipom.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/424.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ambipom.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/424.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ambipom.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/424.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Drifloon",
    value: "recvnJUHqgCYZGPQx",
    order: "0425",
    dexNo: "425",
    size: "xsmall",
    name: "drifloon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/drifloon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/425.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/drifloon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/425.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/drifloon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/425.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/drifloon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/425.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Drifblim",
    value: "rec24RABeTRziWfuX",
    order: "0426",
    dexNo: "426",
    size: "medium",
    name: "drifblim",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/drifblim.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/426.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/drifblim.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/426.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/drifblim.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/426.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/drifblim.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/426.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Buneary",
    value: "recIheHV3dRxZdOYq",
    order: "0427",
    dexNo: "427",
    size: "xsmall",
    name: "buneary",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/buneary.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/427.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/buneary.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/427.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/buneary.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/427.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/buneary.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/427.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Lopunny",
    value: "recku17n0Fs04o5JQ",
    order: "0428",
    dexNo: "428",
    size: "medium",
    name: "lopunny",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/lopunny.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/428.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/lopunny.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/428.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/lopunny.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/428.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/lopunny.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/428.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mismagius",
    value: "recBka8bR2U8bd6hn",
    order: "0429",
    dexNo: "429",
    size: "small",
    name: "mismagius",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mismagius.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/429.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mismagius.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/429.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mismagius.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/429.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mismagius.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/429.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Honchkrow",
    value: "recIeflBw5V8BR8E6",
    order: "0430",
    dexNo: "430",
    size: "small",
    name: "honchkrow",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/honchkrow.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/430.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/honchkrow.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/430.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/honchkrow.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/430.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/honchkrow.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/430.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Glameow",
    value: "recOTGvTwTtmmISVQ",
    order: "0431",
    dexNo: "431",
    size: "xsmall",
    name: "glameow",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/glameow.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/431.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/glameow.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/431.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/glameow.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/431.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/glameow.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/431.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Purugly",
    value: "reclh7s3DDORACLot",
    order: "0432",
    dexNo: "432",
    size: "small",
    name: "purugly",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/purugly.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/432.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/purugly.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/432.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/purugly.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/432.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/purugly.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/432.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Chingling",
    value: "recDvhoHwCv4CyzrV",
    order: "0433",
    dexNo: "433",
    size: "xsmall",
    name: "chingling",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/chingling.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/433.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/chingling.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/433.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/chingling.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/433.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/chingling.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/433.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Stunky",
    value: "recTVlbJHUYn7Qmfv",
    order: "0434",
    dexNo: "434",
    size: "xsmall",
    name: "stunky",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/stunky.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/434.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/stunky.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/434.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/stunky.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/434.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/stunky.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/434.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Skuntank",
    value: "recyCPwExfSlvOhNb",
    order: "0435",
    dexNo: "435",
    size: "small",
    name: "skuntank",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/skuntank.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/435.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/skuntank.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/435.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/skuntank.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/435.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/skuntank.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/435.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Bronzor",
    value: "receOYIYKh41VgxyS",
    order: "0436",
    dexNo: "436",
    size: "xsmall",
    name: "bronzor",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/bronzor.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/436.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/bronzor.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/436.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/bronzor.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/436.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/bronzor.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/436.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Bronzong",
    value: "recPVjYF1hxqCJXOs",
    order: "0437",
    dexNo: "437",
    size: "medium",
    name: "bronzong",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/bronzong.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/437.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/bronzong.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/437.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/bronzong.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/437.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/bronzong.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/437.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Bonsly",
    value: "recZ5cwLzNOid7MYo",
    order: "0438",
    dexNo: "438",
    size: "xsmall",
    name: "bonsly",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/bonsly.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/438.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/bonsly.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/438.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/bonsly.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/438.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/bonsly.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/438.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mime Jr.",
    value: "recHf25Sft5eXKoNK",
    order: "0439",
    dexNo: "439",
    size: "small",
    name: "mime-jr",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mime-jr.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/439.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mime-jr.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/439.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mime-jr.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/439.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mime-jr.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/439.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Happiny",
    value: "recpB3vADvL7lhDJd",
    order: "0440",
    dexNo: "440",
    size: "small",
    name: "happiny",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/happiny.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/440.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/happiny.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/440.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/happiny.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/440.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/happiny.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/440.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Chatot",
    value: "rechL7DCwwRFLMBQi",
    order: "0441",
    dexNo: "441",
    size: "xsmall",
    name: "chatot",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/chatot.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/441.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/chatot.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/441.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/chatot.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/441.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/chatot.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/441.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Spiritomb",
    value: "recV52LTV0seAlNrY",
    order: "0442",
    dexNo: "442",
    size: "small",
    name: "spiritomb",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/spiritomb.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/442.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/spiritomb.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/442.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/spiritomb.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/442.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/spiritomb.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/442.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gible",
    value: "reckkTetChmbFmUmG",
    order: "0443",
    dexNo: "443",
    size: "small",
    name: "gible",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gible.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/443.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gible.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/443.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gible.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/443.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gible.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/443.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gabite",
    value: "recFmSPugPAPdlCc5",
    order: "0444",
    dexNo: "444",
    size: "medium",
    name: "gabite",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gabite.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/444.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gabite.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/444.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gabite.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/444.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gabite.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/444.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Garchomp",
    value: "recmbzOXUJxdBoDJ8",
    order: "0445",
    dexNo: "445",
    size: "large",
    name: "garchomp",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/garchomp.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/445.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/garchomp.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/445.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/garchomp.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/445.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/garchomp.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/445.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Munchlax",
    value: "recoMGkH9Kwj2Xw8I",
    order: "0446",
    dexNo: "446",
    size: "small",
    name: "munchlax",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/munchlax.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/446.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/munchlax.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/446.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/munchlax.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/446.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/munchlax.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/446.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Riolu",
    value: "recMMBmgjDYsjoXLC",
    order: "0447",
    dexNo: "447",
    size: "small",
    name: "riolu",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/riolu.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/447.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/riolu.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/447.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/riolu.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/447.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/riolu.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/447.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Lucario",
    value: "reclgFhBRVpKqq2km",
    order: "0448",
    dexNo: "448",
    size: "medium",
    name: "lucario",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/lucario.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/448.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/lucario.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/448.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/lucario.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/448.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/lucario.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/448.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Hippopotas",
    value: "recvywQrTJx0dvu5Q",
    order: "0449",
    dexNo: "449",
    size: "small",
    name: "hippopotas",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/hippopotas.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/449.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/hippopotas.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/449.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/hippopotas.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/449.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/hippopotas.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/449.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Hippowdon",
    value: "recZrUpvTQzq16KPe",
    order: "0450",
    dexNo: "450",
    size: "large",
    name: "hippowdon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/hippowdon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/450.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/hippowdon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/450.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/hippowdon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/450.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/hippowdon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/450.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Skorupi",
    value: "recEI6W2zRCB8SeVp",
    order: "0451",
    dexNo: "451",
    size: "small",
    name: "skorupi",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/skorupi.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/451.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/skorupi.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/451.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/skorupi.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/451.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/skorupi.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/451.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Drapion",
    value: "recZxAMTzfpThOShm",
    order: "0452",
    dexNo: "452",
    size: "medium",
    name: "drapion",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/drapion.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/452.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/drapion.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/452.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/drapion.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/452.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/drapion.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/452.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Croagunk",
    value: "rec317gjZtKEiFsby",
    order: "0453",
    dexNo: "453",
    size: "small",
    name: "croagunk",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/croagunk.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/453.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/croagunk.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/453.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/croagunk.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/453.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/croagunk.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/453.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Toxicroak",
    value: "reclHdYM9cnHL6c7M",
    order: "0454",
    dexNo: "454",
    size: "medium",
    name: "toxicroak",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/toxicroak.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/454.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/toxicroak.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/454.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/toxicroak.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/454.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/toxicroak.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/454.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Carnivine",
    value: "rec1kO6OvcL2mfJlq",
    order: "0455",
    dexNo: "455",
    size: "medium",
    name: "carnivine",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/carnivine.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/455.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/carnivine.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/455.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/carnivine.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/455.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/carnivine.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/455.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Finneon",
    value: "rechIlARpoXEQADkR",
    order: "0456",
    dexNo: "456",
    size: "xsmall",
    name: "finneon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/finneon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/456.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/finneon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/456.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/finneon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/456.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/finneon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/456.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Lumineon",
    value: "recgy2Wx9Fh6OrNAn",
    order: "0457",
    dexNo: "457",
    size: "medium",
    name: "lumineon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/lumineon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/457.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/lumineon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/457.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/lumineon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/457.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/lumineon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/457.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mantyke",
    value: "recgNjJc7I9MCjZyC",
    order: "0458",
    dexNo: "458",
    size: "small",
    name: "mantyke",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mantyke.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/458.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mantyke.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/458.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mantyke.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/458.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mantyke.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/458.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Snover",
    value: "recfHV3K5oSGQxSZF",
    order: "0459",
    dexNo: "459",
    size: "small",
    name: "snover",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/snover.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/459.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/snover.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/459.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/snover.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/459.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/snover.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/459.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Abomasnow",
    value: "recvWHD9giedR4zOZ",
    order: "0460",
    dexNo: "460",
    size: "xlarge",
    name: "abomasnow",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/abomasnow.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/460.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/abomasnow.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/460.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/abomasnow.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/460.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/abomasnow.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/460.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Weavile",
    value: "reca6S10ogeK2j2Vx",
    order: "0461",
    dexNo: "461",
    size: "medium",
    name: "weavile",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/weavile.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/461.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/weavile.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/461.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/weavile.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/461.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/weavile.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/461.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Magnezone",
    value: "rec6nDt7r2D023oTb",
    order: "0462",
    dexNo: "462",
    size: "medium",
    name: "magnezone",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/magnezone.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/462.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/magnezone.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/462.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/magnezone.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/462.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/magnezone.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/462.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Lickilicky",
    value: "recmN7EppLEWajEsz",
    order: "0463",
    dexNo: "463",
    size: "large",
    name: "lickilicky",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/lickilicky.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/463.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/lickilicky.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/463.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/lickilicky.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/463.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/lickilicky.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/463.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Rhyperior",
    value: "recSGSXHn4bVati3S",
    order: "0464",
    dexNo: "464",
    size: "xlarge",
    name: "rhyperior",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/rhyperior.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/464.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/rhyperior.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/464.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/rhyperior.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/464.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/rhyperior.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/464.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tangrowth",
    value: "reczOVuJiDYdufeXQ",
    order: "0465",
    dexNo: "465",
    size: "large",
    name: "tangrowth",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tangrowth.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/465.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tangrowth.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/465.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tangrowth.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/465.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tangrowth.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/465.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Electivire",
    value: "reclpoBGXxXlLUXMn",
    order: "0466",
    dexNo: "466",
    size: "large",
    name: "electivire",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/electivire.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/466.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/electivire.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/466.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/electivire.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/466.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/electivire.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/466.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Magmortar",
    value: "recYHPOwpdGrVxLin",
    order: "0467",
    dexNo: "467",
    size: "large",
    name: "magmortar",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/magmortar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/467.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/magmortar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/467.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/magmortar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/467.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/magmortar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/467.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Togekiss",
    value: "rechaaBMu5CN48SCN",
    order: "0468",
    dexNo: "468",
    size: "medium",
    name: "togekiss",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/togekiss.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/468.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/togekiss.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/468.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/togekiss.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/468.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/togekiss.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/468.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Yanmega",
    value: "recdllzPCnDrTM9P1",
    order: "0469",
    dexNo: "469",
    size: "large",
    name: "yanmega",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/yanmega.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/469.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/yanmega.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/469.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/yanmega.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/469.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/yanmega.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/469.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Leafeon",
    value: "recGixOGxKKbDd3JT",
    order: "0470",
    dexNo: "470",
    size: "small",
    name: "leafeon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/leafeon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/470.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/leafeon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/470.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/leafeon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/470.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/leafeon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/470.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Glaceon",
    value: "recWlzC138fe9qmGi",
    order: "0471",
    dexNo: "471",
    size: "small",
    name: "glaceon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/glaceon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/471.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/glaceon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/471.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/glaceon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/471.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/glaceon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/471.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gliscor",
    value: "recup4hE7avz02Ei3",
    order: "0472",
    dexNo: "472",
    size: "large",
    name: "gliscor",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gliscor.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/472.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gliscor.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/472.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gliscor.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/472.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gliscor.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/472.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mamoswine",
    value: "recT6iFErufCYmYDP",
    order: "0473",
    dexNo: "473",
    size: "xlarge",
    name: "mamoswine",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mamoswine.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/473.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mamoswine.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/473.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mamoswine.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/473.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mamoswine.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/473.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Porygon-Z",
    value: "recZiEfUz4lAjYjCh",
    order: "0474",
    dexNo: "474",
    size: "small",
    name: "porygon-z",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/porygon-z.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/474.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/porygon-z.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/474.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/porygon-z.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/474.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/porygon-z.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/474.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gallade",
    value: "recASXK6rX5MjX1ho",
    order: "0475",
    dexNo: "475",
    size: "large",
    name: "gallade",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gallade.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/475.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gallade.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/475.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gallade.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/475.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gallade.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/475.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Probopass",
    value: "reciDm5JkZE6jy3z3",
    order: "0476",
    dexNo: "476",
    size: "medium",
    name: "probopass",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/probopass.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/476.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/probopass.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/476.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/probopass.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/476.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/probopass.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/476.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dusknoir",
    value: "recI2K3BTAYZMGb85",
    order: "0477",
    dexNo: "477",
    size: "xlarge",
    name: "dusknoir",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dusknoir.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/477.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dusknoir.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/477.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dusknoir.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/477.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dusknoir.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/477.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Froslass",
    value: "recUcQyMEpNLTI6XL",
    order: "0478",
    dexNo: "478",
    size: "medium",
    name: "froslass",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/froslass.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/478.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/froslass.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/478.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/froslass.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/478.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/froslass.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/478.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Rotom",
    value: "reckjwBhXF3k9GamB",
    order: "0479",
    dexNo: "479",
    size: "xsmall",
    name: "rotom",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/rotom.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/479.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/rotom.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/479.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/rotom.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/479.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/rotom.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/479.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Uxie",
    value: "recgd5CAXF6XOAXxe",
    order: "0480",
    dexNo: "480",
    size: "xsmall",
    name: "uxie",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/uxie.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/480.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/uxie.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/480.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/uxie.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/480.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/uxie.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/480.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mesprit",
    value: "recC3DTbbAQZ9S87o",
    order: "0481",
    dexNo: "481",
    size: "xsmall",
    name: "mesprit",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mesprit.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/481.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mesprit.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/481.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mesprit.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/481.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mesprit.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/481.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Azelf",
    value: "rec7lWQyu82SKuhvB",
    order: "0482",
    dexNo: "482",
    size: "xsmall",
    name: "azelf",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/azelf.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/482.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/azelf.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/482.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/azelf.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/482.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/azelf.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/482.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dialga",
    value: "recULujYQ849DmFPs",
    order: "0483",
    dexNo: "483",
    size: "xlarge",
    name: "dialga",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dialga.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/483.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dialga.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/483.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dialga.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/483.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dialga.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/483.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Palkia",
    value: "recVL8qY0c1rMQeBO",
    order: "0484",
    dexNo: "484",
    size: "xlarge",
    name: "palkia",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/palkia.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/484.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/palkia.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/484.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/palkia.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/484.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/palkia.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/484.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Heatran",
    value: "reclc4cuK4MB0cvXG",
    order: "0485",
    dexNo: "485",
    size: "large",
    name: "heatran",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/heatran.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/485.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/heatran.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/485.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/heatran.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/485.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/heatran.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/485.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Regigigas",
    value: "rec2lM6ZkCXUUD0Fq",
    order: "0486",
    dexNo: "486",
    size: "xlarge",
    name: "regigigas",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/regigigas.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/486.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/regigigas.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/486.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/regigigas.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/486.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/regigigas.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/486.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Giratina",
    value: "recBsocuN0APIRZfV",
    order: "0487",
    dexNo: "487",
    size: "xlarge",
    name: "giratina",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/giratina.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/487.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/giratina.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/487.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/giratina.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/487.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/giratina.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/487.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cresselia",
    value: "recxuoXAf5TL32XWu",
    order: "0488",
    dexNo: "488",
    size: "medium",
    name: "cresselia",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cresselia.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/488.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cresselia.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/488.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cresselia.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/488.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cresselia.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/488.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Phione",
    value: "rec4Z6ZnRTBfmcn4w",
    order: "0489",
    dexNo: "489",
    size: "xsmall",
    name: "phione",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/phione.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/489.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/phione.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/489.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/phione.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/489.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/phione.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/489.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Manaphy",
    value: "recIaMPwmPpIReOJe",
    order: "0490",
    dexNo: "490",
    size: "xsmall",
    name: "manaphy",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/manaphy.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/490.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/manaphy.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/490.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/manaphy.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/490.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/manaphy.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/490.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Darkrai",
    value: "rec60eZyjnKCAY5l5",
    order: "0491",
    dexNo: "491",
    size: "medium",
    name: "darkrai",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/darkrai.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/491.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/darkrai.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/491.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/darkrai.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/491.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/darkrai.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/491.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Shaymin",
    value: "recmfhgDqgv9AzVHm",
    order: "0492",
    dexNo: "492",
    size: "xsmall",
    name: "shaymin",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/shaymin.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/492.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/shaymin.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/492.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/shaymin.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/492.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/shaymin.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/492.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Arceus",
    value: "rec5RSPyS2f4Yja6J",
    order: "0493",
    dexNo: "493",
    size: "xlarge",
    name: "arceus",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/arceus.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/493.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/arceus.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/493.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/arceus.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/493.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/arceus.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/493.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Victini",
    value: "recsZJGPuEhFvUFwO",
    order: "0494",
    dexNo: "494",
    size: "xsmall",
    name: "victini",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/victini.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/494.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/victini.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/494.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/victini.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/494.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/victini.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/494.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Snivy",
    value: "recKKKyDiBweyJnfD",
    order: "0495",
    dexNo: "495",
    size: "small",
    name: "snivy",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/snivy.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/495.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/snivy.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/495.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/snivy.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/495.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/snivy.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/495.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Servine",
    value: "recwpmam9vecEpNsZ",
    order: "0496",
    dexNo: "496",
    size: "small",
    name: "servine",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/servine.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/496.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/servine.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/496.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/servine.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/496.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/servine.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/496.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Serperior",
    value: "recme7Cqhiv8OhipA",
    order: "0497",
    dexNo: "497",
    size: "xlarge",
    name: "serperior",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/serperior.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/497.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/serperior.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/497.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/serperior.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/497.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/serperior.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/497.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tepig",
    value: "recNiZYK68n3X6hFx",
    order: "0498",
    dexNo: "498",
    size: "xsmall",
    name: "tepig",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tepig.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/498.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tepig.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/498.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tepig.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/498.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tepig.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/498.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pignite",
    value: "recIiPYw9DPAdjsyG",
    order: "0499",
    dexNo: "499",
    size: "small",
    name: "pignite",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pignite.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/499.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pignite.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/499.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pignite.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/499.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pignite.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/499.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Emboar",
    value: "recvvH8lCrOFmhW2a",
    order: "0500",
    dexNo: "500",
    size: "large",
    name: "emboar",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/emboar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/500.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/emboar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/500.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/emboar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/500.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/emboar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/500.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Oshawott",
    value: "recuHNDE5PC17kMOs",
    order: "0501",
    dexNo: "501",
    size: "xsmall",
    name: "oshawott",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/oshawott.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/501.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/oshawott.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/501.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/oshawott.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/501.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/oshawott.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/501.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dewott",
    value: "recZADrjkaFcKNB1P",
    order: "0502",
    dexNo: "502",
    size: "small",
    name: "dewott",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dewott.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/502.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dewott.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/502.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dewott.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/502.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dewott.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/502.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Samurott",
    value: "rec3l2nhWE3h1S9XB",
    order: "0503",
    dexNo: "503",
    size: "medium",
    name: "samurott",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/samurott.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/503.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/samurott.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/503.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/samurott.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/503.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/samurott.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/503.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Samurott (Hisui Form)",
    value: "recMfeeglbUhAmkME",
    order: "0503",
    dexNo: "503",
    size: "medium",
    name: "samurott",
    form: "hisui",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/samurott-hisui.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/503-hisui.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/samurott-hisui.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/503-hisui.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/samurott-hisui.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/503-hisui.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/samurott-hisui.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/503-hisui.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Patrat",
    value: "rec6zmc73N412AAzo",
    order: "0504",
    dexNo: "504",
    size: "xsmall",
    name: "patrat",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/patrat.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/504.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/patrat.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/504.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/patrat.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/504.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/patrat.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/504.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Watchog",
    value: "recHrjX0VkJGuAaMI",
    order: "0505",
    dexNo: "505",
    size: "medium",
    name: "watchog",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/watchog.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/505.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/watchog.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/505.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/watchog.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/505.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/watchog.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/505.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Lillipup",
    value: "recwrMJ2nYzt3Kz4d",
    order: "0506",
    dexNo: "506",
    size: "xsmall",
    name: "lillipup",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/lillipup.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/506.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/lillipup.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/506.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/lillipup.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/506.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/lillipup.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/506.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Herdier",
    value: "recGmSaiRdB1p9wR6",
    order: "0507",
    dexNo: "507",
    size: "small",
    name: "herdier",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/herdier.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/507.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/herdier.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/507.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/herdier.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/507.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/herdier.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/507.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Stoutland",
    value: "recwdP2jQQWWt31eg",
    order: "0508",
    dexNo: "508",
    size: "medium",
    name: "stoutland",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/stoutland.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/508.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/stoutland.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/508.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/stoutland.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/508.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/stoutland.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/508.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Purrloin",
    value: "recaHAmOoLfFZAzrT",
    order: "0509",
    dexNo: "509",
    size: "xsmall",
    name: "purrloin",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/purrloin.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/509.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/purrloin.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/509.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/purrloin.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/509.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/purrloin.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/509.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Liepard",
    value: "rec8h8ZQyEAb4BADh",
    order: "0510",
    dexNo: "510",
    size: "medium",
    name: "liepard",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/liepard.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/510.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/liepard.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/510.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/liepard.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/510.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/liepard.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/510.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pansage",
    value: "reclFmg5RhBtRc5n2",
    order: "0511",
    dexNo: "511",
    size: "small",
    name: "pansage",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pansage.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/511.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pansage.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/511.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pansage.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/511.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pansage.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/511.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Simisage",
    value: "recBp71sEvnWsgL2k",
    order: "0512",
    dexNo: "512",
    size: "medium",
    name: "simisage",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/simisage.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/512.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/simisage.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/512.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/simisage.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/512.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/simisage.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/512.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pansear",
    value: "recyjPgPbN9TJgIfJ",
    order: "0513",
    dexNo: "513",
    size: "small",
    name: "pansear",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pansear.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/513.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pansear.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/513.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pansear.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/513.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pansear.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/513.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Simisear",
    value: "recO6CUBmcpxlpegS",
    order: "0514",
    dexNo: "514",
    size: "small",
    name: "simisear",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/simisear.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/514.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/simisear.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/514.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/simisear.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/514.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/simisear.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/514.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Panpour",
    value: "recAe2YuNtcFtNxQI",
    order: "0515",
    dexNo: "515",
    size: "small",
    name: "panpour",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/panpour.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/515.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/panpour.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/515.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/panpour.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/515.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/panpour.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/515.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Simipour",
    value: "reckRN9BgvNwvWA0k",
    order: "0516",
    dexNo: "516",
    size: "small",
    name: "simipour",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/simipour.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/516.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/simipour.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/516.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/simipour.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/516.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/simipour.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/516.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Munna",
    value: "rectXXPmBJFuUhcyI",
    order: "0517",
    dexNo: "517",
    size: "small",
    name: "munna",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/munna.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/517.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/munna.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/517.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/munna.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/517.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/munna.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/517.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Musharna",
    value: "recMosfVCqXtHWXsX",
    order: "0518",
    dexNo: "518",
    size: "medium",
    name: "musharna",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/musharna.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/518.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/musharna.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/518.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/musharna.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/518.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/musharna.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/518.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pidove",
    value: "recDpXXObiad2Hzsg",
    order: "0519",
    dexNo: "519",
    size: "xsmall",
    name: "pidove",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pidove.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/519.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pidove.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/519.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pidove.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/519.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pidove.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/519.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tranquill",
    value: "rec5PPqvSNne05bNR",
    order: "0520",
    dexNo: "520",
    size: "small",
    name: "tranquill",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tranquill.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/520.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tranquill.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/520.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tranquill.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/520.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tranquill.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/520.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Unfezant",
    value: "rec6zSD4nMT66Z6ug",
    order: "0521",
    dexNo: "521",
    size: "medium",
    name: "unfezant",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/unfezant.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/521.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/unfezant.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/521.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/unfezant.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/521.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/unfezant.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/521.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Blitzle",
    value: "recdGCv3my4uB6By5",
    order: "0522",
    dexNo: "522",
    size: "small",
    name: "blitzle",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/blitzle.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/522.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/blitzle.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/522.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/blitzle.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/522.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/blitzle.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/522.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Zebstrika",
    value: "rec8HElVRYqNt4Ac3",
    order: "0523",
    dexNo: "523",
    size: "large",
    name: "zebstrika",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/zebstrika.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/523.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/zebstrika.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/523.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/zebstrika.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/523.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/zebstrika.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/523.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Roggenrola",
    value: "recuvTCNpiMUNbbLP",
    order: "0524",
    dexNo: "524",
    size: "xsmall",
    name: "roggenrola",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/roggenrola.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/524.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/roggenrola.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/524.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/roggenrola.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/524.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/roggenrola.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/524.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Boldore",
    value: "recZFzqT8jk2PyiZd",
    order: "0525",
    dexNo: "525",
    size: "small",
    name: "boldore",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/boldore.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/525.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/boldore.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/525.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/boldore.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/525.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/boldore.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/525.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gigalith",
    value: "rec7Iq0kiiUrIPXtx",
    order: "0526",
    dexNo: "526",
    size: "large",
    name: "gigalith",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gigalith.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/526.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gigalith.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/526.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gigalith.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/526.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gigalith.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/526.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Woobat",
    value: "recOu2m74twqQsq30",
    order: "0527",
    dexNo: "527",
    size: "xsmall",
    name: "woobat",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/woobat.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/527.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/woobat.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/527.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/woobat.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/527.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/woobat.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/527.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Swoobat",
    value: "recN1H4Dqyw8iByMu",
    order: "0528",
    dexNo: "528",
    size: "small",
    name: "swoobat",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/swoobat.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/528.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/swoobat.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/528.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/swoobat.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/528.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/swoobat.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/528.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Drilbur",
    value: "recSDPGMEgePYUiNL",
    order: "0529",
    dexNo: "529",
    size: "xsmall",
    name: "drilbur",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/drilbur.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/529.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/drilbur.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/529.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/drilbur.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/529.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/drilbur.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/529.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Excadrill",
    value: "rec5U8ENP2f0hWnux",
    order: "0530",
    dexNo: "530",
    size: "small",
    name: "excadrill",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/excadrill.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/530.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/excadrill.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/530.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/excadrill.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/530.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/excadrill.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/530.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Audino",
    value: "recHSmFRT2S4Q72ni",
    order: "0531",
    dexNo: "531",
    size: "medium",
    name: "audino",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/audino.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/531.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/audino.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/531.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/audino.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/531.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/audino.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/531.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Timburr",
    value: "recP4hPNI5RPKHzhB",
    order: "0532",
    dexNo: "532",
    size: "small",
    name: "timburr",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/timburr.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/532.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/timburr.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/532.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/timburr.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/532.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/timburr.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/532.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gurdurr",
    value: "recQsF4iWdpVUjtX1",
    order: "0533",
    dexNo: "533",
    size: "medium",
    name: "gurdurr",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gurdurr.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/533.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gurdurr.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/533.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gurdurr.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/533.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gurdurr.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/533.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Conkeldurr",
    value: "recaF12sCcsDkfKQH",
    order: "0534",
    dexNo: "534",
    size: "medium",
    name: "conkeldurr",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/conkeldurr.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/534.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/conkeldurr.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/534.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/conkeldurr.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/534.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/conkeldurr.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/534.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tympole",
    value: "rec0rdxkeKBJrQLkD",
    order: "0535",
    dexNo: "535",
    size: "xsmall",
    name: "tympole",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tympole.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/535.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tympole.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/535.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tympole.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/535.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tympole.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/535.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Palpitoad",
    value: "recardgVgHbxgahTM",
    order: "0536",
    dexNo: "536",
    size: "small",
    name: "palpitoad",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/palpitoad.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/536.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/palpitoad.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/536.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/palpitoad.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/536.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/palpitoad.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/536.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Seismitoad",
    value: "rechOXUftioWo44zr",
    order: "0537",
    dexNo: "537",
    size: "medium",
    name: "seismitoad",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/seismitoad.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/537.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/seismitoad.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/537.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/seismitoad.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/537.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/seismitoad.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/537.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Throh",
    value: "rec4yrBMuAX6tCltb",
    order: "0538",
    dexNo: "538",
    size: "medium",
    name: "throh",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/throh.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/538.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/throh.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/538.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/throh.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/538.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/throh.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/538.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sawk",
    value: "rec4mIu9l9SBR7p7V",
    order: "0539",
    dexNo: "539",
    size: "medium",
    name: "sawk",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sawk.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/539.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sawk.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/539.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sawk.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/539.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sawk.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/539.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sewaddle",
    value: "recvqE5kFXGsgTn7p",
    order: "0540",
    dexNo: "540",
    size: "xsmall",
    name: "sewaddle",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sewaddle.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/540.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sewaddle.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/540.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sewaddle.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/540.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sewaddle.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/540.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Swadloon",
    value: "recZPkLos4xFqanzB",
    order: "0541",
    dexNo: "541",
    size: "xsmall",
    name: "swadloon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/swadloon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/541.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/swadloon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/541.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/swadloon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/541.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/swadloon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/541.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Leavanny",
    value: "recpVaQXt8wg2TDeC",
    order: "0542",
    dexNo: "542",
    size: "medium",
    name: "leavanny",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/leavanny.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/542.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/leavanny.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/542.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/leavanny.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/542.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/leavanny.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/542.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Venipede",
    value: "recWTBj4FOnMqb8Nc",
    order: "0543",
    dexNo: "543",
    size: "xsmall",
    name: "venipede",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/venipede.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/543.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/venipede.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/543.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/venipede.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/543.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/venipede.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/543.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Whirlipede",
    value: "recovWBeZACa32CCB",
    order: "0544",
    dexNo: "544",
    size: "medium",
    name: "whirlipede",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/whirlipede.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/544.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/whirlipede.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/544.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/whirlipede.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/544.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/whirlipede.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/544.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Scolipede",
    value: "recZw1PfdrqoyzRfy",
    order: "0545",
    dexNo: "545",
    size: "xlarge",
    name: "scolipede",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/scolipede.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/545.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/scolipede.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/545.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/scolipede.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/545.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/scolipede.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/545.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cottonee",
    value: "recQmZZBrgP8IFvFQ",
    order: "0546",
    dexNo: "546",
    size: "xsmall",
    name: "cottonee",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cottonee.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/546.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cottonee.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/546.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cottonee.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/546.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cottonee.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/546.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Whimsicott",
    value: "recNYnpAS5QVAxvO5",
    order: "0547",
    dexNo: "547",
    size: "small",
    name: "whimsicott",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/whimsicott.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/547.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/whimsicott.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/547.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/whimsicott.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/547.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/whimsicott.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/547.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Petilil",
    value: "recvn2l9CTy4QSEuS",
    order: "0548",
    dexNo: "548",
    size: "xsmall",
    name: "petilil",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/petilil.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/548.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/petilil.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/548.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/petilil.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/548.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/petilil.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/548.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Lilligant",
    value: "recvoyteuWqPsYgKA",
    order: "0549",
    dexNo: "549",
    size: "medium",
    name: "lilligant",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/lilligant.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/549.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/lilligant.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/549.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/lilligant.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/549.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/lilligant.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/549.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Lilligant (Hisui Form)",
    value: "recUkNePUbaF9up5j",
    order: "0549",
    dexNo: "549",
    size: "medium",
    name: "lilligant",
    form: "hisui",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/lilligant-hisui.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/549-hisui.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/lilligant-hisui.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/549-hisui.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/lilligant-hisui.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/549-hisui.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/lilligant-hisui.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/549-hisui.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Basculin",
    value: "rec8d9ugwmucDbLON",
    order: "0550",
    dexNo: "550",
    size: "small",
    name: "basculin",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/basculin.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/550.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/basculin.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/550.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/basculin.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/550.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/basculin.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/550.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sandile",
    value: "recW1alSQhlPcrTEn",
    order: "0551",
    dexNo: "551",
    size: "small",
    name: "sandile",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sandile.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/551.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sandile.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/551.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sandile.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/551.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sandile.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/551.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Krokorok",
    value: "recCmhtcluM0y4JJ6",
    order: "0552",
    dexNo: "552",
    size: "small",
    name: "krokorok",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/krokorok.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/552.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/krokorok.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/552.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/krokorok.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/552.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/krokorok.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/552.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Krookodile",
    value: "recJNsePfuQVsHPGc",
    order: "0553",
    dexNo: "553",
    size: "medium",
    name: "krookodile",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/krookodile.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/553.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/krookodile.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/553.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/krookodile.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/553.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/krookodile.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/553.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Darumaka",
    value: "recsci6F9eg5onl2t",
    order: "0554",
    dexNo: "554",
    size: "small",
    name: "darumaka",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/darumaka.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/554.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/darumaka.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/554.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/darumaka.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/554.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/darumaka.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/554.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Darumaka (Galarian Form)",
    value: "recTilzzicsH2aGqi",
    order: "0554",
    dexNo: "554",
    size: "small",
    name: "darumaka",
    form: "galar",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/darumaka-galar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/554-galar.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/darumaka-galar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/554-galar.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/darumaka-galar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/554-galar.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/darumaka-galar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/554-galar.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Darmanitan",
    value: "recs8lSanIA43HLo0",
    order: "0555",
    dexNo: "555",
    size: "medium",
    name: "darmanitan",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/darmanitan.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/555.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/darmanitan.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/555.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/darmanitan.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/555.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/darmanitan.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/555.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Darmanitan (Galarian Form)",
    value: "reciRYhtv9122RKNZ",
    order: "0555",
    dexNo: "555",
    size: "medium",
    name: "darmanitan",
    form: "galar",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/darmanitan-galar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/555-galar.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/darmanitan-galar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/555-galar.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/darmanitan-galar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/555-galar.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/darmanitan-galar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/555-galar.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Maractus",
    value: "recko9zY1HTnODEcS",
    order: "0556",
    dexNo: "556",
    size: "small",
    name: "maractus",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/maractus.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/556.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/maractus.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/556.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/maractus.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/556.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/maractus.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/556.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dwebble",
    value: "rec1niIuqFs1POVEn",
    order: "0557",
    dexNo: "557",
    size: "xsmall",
    name: "dwebble",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dwebble.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/557.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dwebble.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/557.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dwebble.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/557.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dwebble.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/557.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Crustle",
    value: "recw0QP6TgnKARjEP",
    order: "0558",
    dexNo: "558",
    size: "medium",
    name: "crustle",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/crustle.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/558.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/crustle.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/558.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/crustle.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/558.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/crustle.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/558.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Scraggy",
    value: "rec3SmjEg96qALy4v",
    order: "0559",
    dexNo: "559",
    size: "small",
    name: "scraggy",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/scraggy.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/559.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/scraggy.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/559.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/scraggy.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/559.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/scraggy.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/559.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Scrafty",
    value: "rec4JJSFI1LRRr06A",
    order: "0560",
    dexNo: "560",
    size: "medium",
    name: "scrafty",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/scrafty.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/560.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/scrafty.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/560.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/scrafty.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/560.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/scrafty.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/560.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sigilyph",
    value: "reckxYr9zFob939ox",
    order: "0561",
    dexNo: "561",
    size: "medium",
    name: "sigilyph",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sigilyph.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/561.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sigilyph.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/561.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sigilyph.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/561.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sigilyph.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/561.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Yamask",
    value: "recvtkpfpv2w38ehW",
    order: "0562",
    dexNo: "562",
    size: "xsmall",
    name: "yamask",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/yamask.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/562.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/yamask.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/562.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/yamask.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/562.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/yamask.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/562.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Yamask (Galarian Form)",
    value: "rechrs7YsajAX8Bsd",
    order: "0562",
    dexNo: "562",
    size: "xsmall",
    name: "yamask",
    form: "galar",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/yamask-galar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/562-galar.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/yamask-galar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/562-galar.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/yamask-galar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/562-galar.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/yamask-galar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/562-galar.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cofagrigus",
    value: "recuPiqqvUtgEgYFP",
    order: "0563",
    dexNo: "563",
    size: "large",
    name: "cofagrigus",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cofagrigus.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/563.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cofagrigus.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/563.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cofagrigus.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/563.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cofagrigus.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/563.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tirtouga",
    value: "reccf5mLnio4IblU4",
    order: "0564",
    dexNo: "564",
    size: "small",
    name: "tirtouga",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tirtouga.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/564.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tirtouga.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/564.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tirtouga.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/564.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tirtouga.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/564.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Carracosta",
    value: "recpZ5hnS9TUvBB5E",
    order: "0565",
    dexNo: "565",
    size: "medium",
    name: "carracosta",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/carracosta.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/565.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/carracosta.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/565.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/carracosta.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/565.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/carracosta.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/565.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Archen",
    value: "recoNDu7dNkSlpdyR",
    order: "0566",
    dexNo: "566",
    size: "xsmall",
    name: "archen",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/archen.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/566.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/archen.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/566.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/archen.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/566.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/archen.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/566.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Archeops",
    value: "recRg2ipwxEN9ScxA",
    order: "0567",
    dexNo: "567",
    size: "medium",
    name: "archeops",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/archeops.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/567.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/archeops.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/567.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/archeops.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/567.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/archeops.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/567.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Trubbish",
    value: "recXaD19rNGSqxt8y",
    order: "0568",
    dexNo: "568",
    size: "small",
    name: "trubbish",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/trubbish.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/568.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/trubbish.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/568.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/trubbish.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/568.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/trubbish.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/568.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Garbodor",
    value: "recWg4dlQu8KOTmWs",
    order: "0569",
    dexNo: "569",
    size: "large",
    name: "garbodor",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/garbodor.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/569.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/garbodor.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/569.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/garbodor.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/569.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/garbodor.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/569.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Zorua",
    value: "recxOcLPIefr5mohC",
    order: "0570",
    dexNo: "570",
    size: "small",
    name: "zorua",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/zorua.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/570.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/zorua.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/570.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/zorua.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/570.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/zorua.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/570.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Zorua (Hisui Form)",
    value: "recUefgiQB2ZZFKZe",
    order: "0570",
    dexNo: "570",
    size: "small",
    name: "zorua",
    form: "hisui",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/zorua-hisui.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/570-hisui.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/zorua-hisui.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/570-hisui.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/zorua-hisui.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/570-hisui.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/zorua-hisui.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/570-hisui.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Zoroark",
    value: "recPIQqxrhwsqJA3l",
    order: "0571",
    dexNo: "571",
    size: "large",
    name: "zoroark",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/zoroark.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/571.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/zoroark.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/571.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/zoroark.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/571.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/zoroark.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/571.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Zoroark (Hisui Form)",
    value: "recifpXLY3LZ7eeRH",
    order: "0571",
    dexNo: "571",
    size: "large",
    name: "zoroark",
    form: "hisui",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/zoroark-hisui.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/571-hisui.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/zoroark-hisui.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/571-hisui.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/zoroark-hisui.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/571-hisui.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/zoroark-hisui.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/571-hisui.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Minccino",
    value: "recyPUNPtqeJ1nIM9",
    order: "0572",
    dexNo: "572",
    size: "xsmall",
    name: "minccino",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/minccino.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/572.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/minccino.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/572.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/minccino.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/572.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/minccino.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/572.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cinccino",
    value: "recsC7Daf0UK0FFXn",
    order: "0573",
    dexNo: "573",
    size: "xsmall",
    name: "cinccino",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cinccino.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/573.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cinccino.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/573.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cinccino.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/573.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cinccino.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/573.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gothita",
    value: "recle6LobR3K3nMAi",
    order: "0574",
    dexNo: "574",
    size: "xsmall",
    name: "gothita",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gothita.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/574.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gothita.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/574.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gothita.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/574.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gothita.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/574.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gothorita",
    value: "rec6NeDhFA0SIpWP8",
    order: "0575",
    dexNo: "575",
    size: "small",
    name: "gothorita",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gothorita.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/575.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gothorita.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/575.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gothorita.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/575.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gothorita.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/575.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gothitelle",
    value: "rec43zvdba1sj7wjv",
    order: "0576",
    dexNo: "576",
    size: "medium",
    name: "gothitelle",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gothitelle.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/576.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gothitelle.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/576.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gothitelle.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/576.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gothitelle.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/576.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Solosis",
    value: "recokLUMgCKAIdIlM",
    order: "0577",
    dexNo: "577",
    size: "xsmall",
    name: "solosis",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/solosis.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/577.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/solosis.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/577.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/solosis.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/577.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/solosis.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/577.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Duosion",
    value: "recM3l6ZYem9htHwH",
    order: "0578",
    dexNo: "578",
    size: "small",
    name: "duosion",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/duosion.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/578.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/duosion.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/578.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/duosion.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/578.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/duosion.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/578.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Reuniclus",
    value: "rec4Hn1mfqwRh2ldI",
    order: "0579",
    dexNo: "579",
    size: "small",
    name: "reuniclus",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/reuniclus.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/579.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/reuniclus.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/579.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/reuniclus.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/579.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/reuniclus.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/579.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ducklett",
    value: "reclgjIzMy1y3Xpxb",
    order: "0580",
    dexNo: "580",
    size: "xsmall",
    name: "ducklett",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ducklett.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/580.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ducklett.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/580.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ducklett.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/580.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ducklett.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/580.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Swanna",
    value: "recdTLnNPWe0CsJq3",
    order: "0581",
    dexNo: "581",
    size: "medium",
    name: "swanna",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/swanna.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/581.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/swanna.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/581.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/swanna.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/581.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/swanna.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/581.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Vanillite",
    value: "recI6ZljUNQTfrPub",
    order: "0582",
    dexNo: "582",
    size: "xsmall",
    name: "vanillite",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/vanillite.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/582.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/vanillite.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/582.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/vanillite.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/582.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/vanillite.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/582.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Vanillish",
    value: "rec3Y24MkDRrSImCX",
    order: "0583",
    dexNo: "583",
    size: "medium",
    name: "vanillish",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/vanillish.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/583.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/vanillish.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/583.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/vanillish.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/583.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/vanillish.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/583.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Vanilluxe",
    value: "recPXZrcXgXQbrRdW",
    order: "0584",
    dexNo: "584",
    size: "medium",
    name: "vanilluxe",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/vanilluxe.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/584.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/vanilluxe.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/584.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/vanilluxe.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/584.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/vanilluxe.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/584.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Deerling",
    value: "recOrpmtWYY6iv7q0",
    order: "0585",
    dexNo: "585",
    size: "small",
    name: "deerling",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/deerling.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/585.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/deerling.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/585.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/deerling.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/585.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/deerling.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/585.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sawsbuck",
    value: "recrgowiAz5sfmRre",
    order: "0586",
    dexNo: "586",
    size: "large",
    name: "sawsbuck",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sawsbuck.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/586.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sawsbuck.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/586.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sawsbuck.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/586.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sawsbuck.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/586.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Emolga",
    value: "recVhDx4yRfOyxK1k",
    order: "0587",
    dexNo: "587",
    size: "xsmall",
    name: "emolga",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/emolga.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/587.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/emolga.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/587.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/emolga.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/587.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/emolga.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/587.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Karrablast",
    value: "recnY8NZTBq5z5BPy",
    order: "0588",
    dexNo: "588",
    size: "xsmall",
    name: "karrablast",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/karrablast.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/588.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/karrablast.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/588.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/karrablast.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/588.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/karrablast.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/588.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Escavalier",
    value: "recWqHEERGjOkFG0H",
    order: "0589",
    dexNo: "589",
    size: "small",
    name: "escavalier",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/escavalier.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/589.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/escavalier.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/589.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/escavalier.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/589.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/escavalier.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/589.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Foongus",
    value: "recJ3ezSv1Qqi5T2z",
    order: "0590",
    dexNo: "590",
    size: "xsmall",
    name: "foongus",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/foongus.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/590.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/foongus.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/590.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/foongus.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/590.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/foongus.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/590.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Amoonguss",
    value: "recDwfcdZp4aIvUWp",
    order: "0591",
    dexNo: "591",
    size: "small",
    name: "amoonguss",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/amoonguss.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/591.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/amoonguss.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/591.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/amoonguss.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/591.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/amoonguss.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/591.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Frillish",
    value: "rec8Hs4gr4mVExXsQ",
    order: "0592",
    dexNo: "592",
    size: "medium",
    name: "frillish",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/frillish.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/592.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/frillish.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/592.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/frillish.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/592.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/frillish.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/592.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Jellicent",
    value: "recPqkyjo7A7T2gd2",
    order: "0593",
    dexNo: "593",
    size: "xlarge",
    name: "jellicent",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/jellicent.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/593.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/jellicent.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/593.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/jellicent.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/593.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/jellicent.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/593.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Alomomola",
    value: "reck0V2D1XMiEzQBv",
    order: "0594",
    dexNo: "594",
    size: "medium",
    name: "alomomola",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/alomomola.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/594.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/alomomola.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/594.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/alomomola.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/594.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/alomomola.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/594.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Joltik",
    value: "recif7ukwsrDWYy4V",
    order: "0595",
    dexNo: "595",
    size: "xsmall",
    name: "joltik",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/joltik.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/595.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/joltik.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/595.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/joltik.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/595.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/joltik.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/595.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Galvantula",
    value: "recmfyMjvOMeUR9te",
    order: "0596",
    dexNo: "596",
    size: "small",
    name: "galvantula",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/galvantula.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/596.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/galvantula.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/596.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/galvantula.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/596.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/galvantula.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/596.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ferroseed",
    value: "recQpTep6kuRU0ueb",
    order: "0597",
    dexNo: "597",
    size: "small",
    name: "ferroseed",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ferroseed.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/597.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ferroseed.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/597.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ferroseed.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/597.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ferroseed.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/597.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ferrothorn",
    value: "rec1aYWsZLKgtqCSB",
    order: "0598",
    dexNo: "598",
    size: "small",
    name: "ferrothorn",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ferrothorn.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/598.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ferrothorn.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/598.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ferrothorn.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/598.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ferrothorn.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/598.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Klink",
    value: "recoNuN8UFhXGXB3H",
    order: "0599",
    dexNo: "599",
    size: "xsmall",
    name: "klink",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/klink.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/599.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/klink.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/599.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/klink.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/599.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/klink.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/599.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Klang",
    value: "recg0QiJqkfagYO42",
    order: "0600",
    dexNo: "600",
    size: "small",
    name: "klang",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/klang.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/600.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/klang.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/600.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/klang.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/600.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/klang.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/600.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Klinklang",
    value: "recqDhayVLbYhUf9Q",
    order: "0601",
    dexNo: "601",
    size: "small",
    name: "klinklang",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/klinklang.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/601.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/klinklang.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/601.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/klinklang.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/601.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/klinklang.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/601.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tynamo",
    value: "rec8EPHVaoqqoTlaN",
    order: "0602",
    dexNo: "602",
    size: "xsmall",
    name: "tynamo",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tynamo.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/602.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tynamo.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/602.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tynamo.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/602.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tynamo.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/602.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Eelektrik",
    value: "rec0z45Bcq6Oi4YxS",
    order: "0603",
    dexNo: "603",
    size: "medium",
    name: "eelektrik",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/eelektrik.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/603.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/eelektrik.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/603.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/eelektrik.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/603.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/eelektrik.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/603.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Eelektross",
    value: "recqOyZm32tvhPrPM",
    order: "0604",
    dexNo: "604",
    size: "xlarge",
    name: "eelektross",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/eelektross.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/604.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/eelektross.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/604.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/eelektross.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/604.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/eelektross.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/604.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Elgyem",
    value: "recFphrO4dSm4yvIo",
    order: "0605",
    dexNo: "605",
    size: "xsmall",
    name: "elgyem",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/elgyem.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/605.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/elgyem.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/605.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/elgyem.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/605.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/elgyem.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/605.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Beheeyem",
    value: "recijjCm10M5iaVnW",
    order: "0606",
    dexNo: "606",
    size: "small",
    name: "beheeyem",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/beheeyem.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/606.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/beheeyem.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/606.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/beheeyem.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/606.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/beheeyem.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/606.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Litwick",
    value: "recjid9x9RepvaUFm",
    order: "0607",
    dexNo: "607",
    size: "xsmall",
    name: "litwick",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/litwick.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/607.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/litwick.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/607.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/litwick.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/607.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/litwick.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/607.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Lampent",
    value: "rec0DMgvG5UsOZn9i",
    order: "0608",
    dexNo: "608",
    size: "small",
    name: "lampent",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/lampent.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/608.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/lampent.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/608.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/lampent.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/608.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/lampent.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/608.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Chandelure",
    value: "rec9U61jBj3YHoOlF",
    order: "0609",
    dexNo: "609",
    size: "small",
    name: "chandelure",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/chandelure.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/609.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/chandelure.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/609.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/chandelure.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/609.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/chandelure.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/609.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Axew",
    value: "recZbrqKathB5V75K",
    order: "0610",
    dexNo: "610",
    size: "small",
    name: "axew",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/axew.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/610.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/axew.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/610.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/axew.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/610.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/axew.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/610.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Fraxure",
    value: "recPV0nMF9FCE7OAX",
    order: "0611",
    dexNo: "611",
    size: "small",
    name: "fraxure",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/fraxure.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/611.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/fraxure.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/611.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/fraxure.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/611.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/fraxure.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/611.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Haxorus",
    value: "receg64lG3Q4MYHvi",
    order: "0612",
    dexNo: "612",
    size: "large",
    name: "haxorus",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/haxorus.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/612.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/haxorus.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/612.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/haxorus.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/612.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/haxorus.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/612.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cubchoo",
    value: "rec6zypEOtdYJFYBk",
    order: "0613",
    dexNo: "613",
    size: "xsmall",
    name: "cubchoo",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cubchoo.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/613.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cubchoo.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/613.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cubchoo.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/613.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cubchoo.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/613.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Beartic",
    value: "reccdzzJhFgXPW6bQ",
    order: "0614",
    dexNo: "614",
    size: "xlarge",
    name: "beartic",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/beartic.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/614.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/beartic.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/614.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/beartic.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/614.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/beartic.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/614.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cryogonal",
    value: "recSYszIGCrkcw2tT",
    order: "0615",
    dexNo: "615",
    size: "medium",
    name: "cryogonal",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cryogonal.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/615.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cryogonal.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/615.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cryogonal.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/615.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cryogonal.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/615.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Shelmet",
    value: "recOoAoXomxKcoeXh",
    order: "0616",
    dexNo: "616",
    size: "xsmall",
    name: "shelmet",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/shelmet.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/616.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/shelmet.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/616.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/shelmet.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/616.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/shelmet.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/616.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Accelgor",
    value: "rech8KgSrkTXYli1W",
    order: "0617",
    dexNo: "617",
    size: "small",
    name: "accelgor",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/accelgor.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/617.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/accelgor.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/617.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/accelgor.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/617.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/accelgor.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/617.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Stunfisk",
    value: "recC61HIOZIqVERZT",
    order: "0618",
    dexNo: "618",
    size: "small",
    name: "stunfisk",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/stunfisk.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/618.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/stunfisk.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/618.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/stunfisk.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/618.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/stunfisk.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/618.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Stunfisk (Galarian Form)",
    value: "recgAPHsQ9tmVuXDt",
    order: "0618",
    dexNo: "618",
    size: "small",
    name: "stunfisk",
    form: "galar",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/stunfisk-galar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/618-galar.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/stunfisk-galar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/618-galar.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/stunfisk-galar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/618-galar.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/stunfisk-galar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/618-galar.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mienfoo",
    value: "recGYrm29HadFFJ68",
    order: "0619",
    dexNo: "619",
    size: "small",
    name: "mienfoo",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mienfoo.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/619.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mienfoo.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/619.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mienfoo.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/619.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mienfoo.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/619.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mienshao",
    value: "recK5rzB4zby4jdS6",
    order: "0620",
    dexNo: "620",
    size: "medium",
    name: "mienshao",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mienshao.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/620.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mienshao.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/620.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mienshao.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/620.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mienshao.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/620.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Druddigon",
    value: "recdgpMHlASD3rhAN",
    order: "0621",
    dexNo: "621",
    size: "large",
    name: "druddigon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/druddigon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/621.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/druddigon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/621.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/druddigon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/621.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/druddigon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/621.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Golett",
    value: "recyXsmVyJ0FNWW2O",
    order: "0622",
    dexNo: "622",
    size: "small",
    name: "golett",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/golett.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/622.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/golett.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/622.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/golett.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/622.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/golett.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/622.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Golurk",
    value: "recIikKVJQs9j9K1S",
    order: "0623",
    dexNo: "623",
    size: "xlarge",
    name: "golurk",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/golurk.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/623.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/golurk.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/623.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/golurk.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/623.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/golurk.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/623.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pawniard",
    value: "rec7ptiMXvk9jcN6d",
    order: "0624",
    dexNo: "624",
    size: "xsmall",
    name: "pawniard",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pawniard.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/624.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pawniard.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/624.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pawniard.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/624.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pawniard.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/624.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Bisharp",
    value: "recUSHeUPGzvrbKoI",
    order: "0625",
    dexNo: "625",
    size: "large",
    name: "bisharp",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/bisharp.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/625.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/bisharp.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/625.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/bisharp.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/625.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/bisharp.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/625.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Bouffalant",
    value: "rec3kK8tJgTyaST6G",
    order: "0626",
    dexNo: "626",
    size: "large",
    name: "bouffalant",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/bouffalant.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/626.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/bouffalant.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/626.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/bouffalant.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/626.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/bouffalant.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/626.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Rufflet",
    value: "recF9wChpvfeeVPbf",
    order: "0627",
    dexNo: "627",
    size: "xsmall",
    name: "rufflet",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/rufflet.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/627.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/rufflet.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/627.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/rufflet.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/627.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/rufflet.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/627.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Braviary",
    value: "recr4Mj9cYlXp4NYc",
    order: "0628",
    dexNo: "628",
    size: "medium",
    name: "braviary",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/braviary.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/628.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/braviary.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/628.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/braviary.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/628.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/braviary.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/628.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Braviary (Hisui Form)",
    value: "recKWxrfTAnAYG1wp",
    order: "0628",
    dexNo: "628",
    size: "medium",
    name: "braviary",
    form: "hisui",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/braviary-hisui.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/628-hisui.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/braviary-hisui.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/628-hisui.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/braviary-hisui.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/628-hisui.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/braviary-hisui.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/628-hisui.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Vullaby",
    value: "recjbcabhpHpK089h",
    order: "0629",
    dexNo: "629",
    size: "xsmall",
    name: "vullaby",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/vullaby.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/629.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/vullaby.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/629.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/vullaby.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/629.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/vullaby.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/629.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mandibuzz",
    value: "rec8NOaMp9eLTmkL1",
    order: "0630",
    dexNo: "630",
    size: "medium",
    name: "mandibuzz",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mandibuzz.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/630.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mandibuzz.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/630.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mandibuzz.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/630.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mandibuzz.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/630.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Heatmor",
    value: "rec7QGCc4lnMDL3jb",
    order: "0631",
    dexNo: "631",
    size: "medium",
    name: "heatmor",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/heatmor.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/631.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/heatmor.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/631.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/heatmor.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/631.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/heatmor.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/631.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Durant",
    value: "recjeopyF6qWdMpNu",
    order: "0632",
    dexNo: "632",
    size: "xsmall",
    name: "durant",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/durant.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/632.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/durant.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/632.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/durant.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/632.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/durant.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/632.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Deino",
    value: "recWwLJRf2ygQbuxR",
    order: "0633",
    dexNo: "633",
    size: "small",
    name: "deino",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/deino.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/633.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/deino.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/633.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/deino.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/633.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/deino.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/633.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Zweilous",
    value: "reciwfR2cOKZX91nN",
    order: "0634",
    dexNo: "634",
    size: "medium",
    name: "zweilous",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/zweilous.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/634.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/zweilous.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/634.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/zweilous.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/634.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/zweilous.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/634.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Hydreigon",
    value: "rec3fcZFVzgpKJAtV",
    order: "0635",
    dexNo: "635",
    size: "large",
    name: "hydreigon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/hydreigon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/635.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/hydreigon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/635.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/hydreigon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/635.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/hydreigon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/635.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Larvesta",
    value: "recGuV1KOGEV6piK7",
    order: "0636",
    dexNo: "636",
    size: "medium",
    name: "larvesta",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/larvesta.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/636.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/larvesta.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/636.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/larvesta.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/636.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/larvesta.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/636.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Volcarona",
    value: "rec116A6nApEscVto",
    order: "0637",
    dexNo: "637",
    size: "large",
    name: "volcarona",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/volcarona.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/637.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/volcarona.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/637.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/volcarona.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/637.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/volcarona.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/637.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cobalion",
    value: "recZnU93W39tWlh4p",
    order: "0638",
    dexNo: "638",
    size: "xlarge",
    name: "cobalion",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cobalion.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/638.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cobalion.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/638.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cobalion.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/638.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cobalion.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/638.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Terrakion",
    value: "rec4TtPN4AYpwea1j",
    order: "0639",
    dexNo: "639",
    size: "large",
    name: "terrakion",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/terrakion.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/639.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/terrakion.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/639.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/terrakion.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/639.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/terrakion.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/639.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Virizion",
    value: "recnhAbz4QFOYAb29",
    order: "0640",
    dexNo: "640",
    size: "large",
    name: "virizion",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/virizion.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/640.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/virizion.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/640.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/virizion.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/640.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/virizion.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/640.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tornadus",
    value: "recZXqgpFPeEgGkr7",
    order: "0641",
    dexNo: "641",
    size: "medium",
    name: "tornadus",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tornadus.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/641.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tornadus.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/641.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tornadus.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/641.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tornadus.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/641.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Thundurus",
    value: "recHMSxlUI9MtNMq9",
    order: "0642",
    dexNo: "642",
    size: "medium",
    name: "thundurus",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/thundurus.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/642.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/thundurus.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/642.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/thundurus.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/642.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/thundurus.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/642.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Reshiram",
    value: "recN246bnSZHZ9XLA",
    order: "0643",
    dexNo: "643",
    size: "xlarge",
    name: "reshiram",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/reshiram.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/643.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/reshiram.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/643.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/reshiram.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/643.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/reshiram.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/643.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Zekrom",
    value: "recg22cuS8resKw51",
    order: "0644",
    dexNo: "644",
    size: "xlarge",
    name: "zekrom",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/zekrom.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/644.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/zekrom.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/644.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/zekrom.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/644.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/zekrom.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/644.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Landorus",
    value: "recgQdWduifjnuQ7A",
    order: "0645",
    dexNo: "645",
    size: "medium",
    name: "landorus",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/landorus.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/645.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/landorus.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/645.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/landorus.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/645.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/landorus.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/645.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Kyurem",
    value: "recSv5zp8bazdcjvO",
    order: "0646",
    dexNo: "646",
    size: "xlarge",
    name: "kyurem",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/kyurem.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/646.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/kyurem.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/646.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/kyurem.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/646.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/kyurem.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/646.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Keldeo",
    value: "recMoOLYWqTYSIgYo",
    order: "0647",
    dexNo: "647",
    size: "medium",
    name: "keldeo",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/keldeo.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/647.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/keldeo.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/647.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/keldeo.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/647.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/keldeo.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/647.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Meloetta",
    value: "recwBcT7k8HCYSchY",
    order: "0648",
    dexNo: "648",
    size: "small",
    name: "meloetta",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/meloetta.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/648.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/meloetta.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/648.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/meloetta.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/648.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/meloetta.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/648.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Genesect",
    value: "recxQlhFViapeGVIv",
    order: "0649",
    dexNo: "649",
    size: "medium",
    name: "genesect",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/genesect.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/649.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/genesect.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/649.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/genesect.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/649.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/genesect.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/649.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Chespin",
    value: "recnXLDqlcEPPME7R",
    order: "0650",
    dexNo: "650",
    size: "xsmall",
    name: "chespin",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/chespin.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/650.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/chespin.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/650.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/chespin.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/650.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/chespin.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/650.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Quilladin",
    value: "recbw3h6xiEoGh2Kz",
    order: "0651",
    dexNo: "651",
    size: "small",
    name: "quilladin",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/quilladin.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/651.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/quilladin.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/651.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/quilladin.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/651.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/quilladin.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/651.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Chesnaught",
    value: "recYwam9GmHrnWGaR",
    order: "0652",
    dexNo: "652",
    size: "large",
    name: "chesnaught",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/chesnaught.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/652.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/chesnaught.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/652.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/chesnaught.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/652.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/chesnaught.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/652.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Fennekin",
    value: "recP4GAkqSCXi5t6W",
    order: "0653",
    dexNo: "653",
    size: "xsmall",
    name: "fennekin",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/fennekin.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/653.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/fennekin.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/653.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/fennekin.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/653.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/fennekin.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/653.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Braixen",
    value: "recqPaSqr6nqj79lK",
    order: "0654",
    dexNo: "654",
    size: "small",
    name: "braixen",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/braixen.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/654.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/braixen.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/654.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/braixen.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/654.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/braixen.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/654.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Delphox",
    value: "rec20PnQvyGq9EnT4",
    order: "0655",
    dexNo: "655",
    size: "medium",
    name: "delphox",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/delphox.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/655.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/delphox.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/655.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/delphox.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/655.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/delphox.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/655.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Froakie",
    value: "recHoWO8nvouQKyOF",
    order: "0656",
    dexNo: "656",
    size: "xsmall",
    name: "froakie",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/froakie.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/656.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/froakie.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/656.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/froakie.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/656.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/froakie.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/656.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Frogadier",
    value: "recYy5o0pIYc6n38y",
    order: "0657",
    dexNo: "657",
    size: "small",
    name: "frogadier",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/frogadier.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/657.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/frogadier.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/657.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/frogadier.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/657.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/frogadier.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/657.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Greninja",
    value: "recUylaK9QftdhS5b",
    order: "0658",
    dexNo: "658",
    size: "medium",
    name: "greninja",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/greninja.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/658.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/greninja.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/658.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/greninja.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/658.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/greninja.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/658.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Bunnelby",
    value: "recCukffIf0MEgveI",
    order: "0659",
    dexNo: "659",
    size: "xsmall",
    name: "bunnelby",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/bunnelby.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/659.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/bunnelby.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/659.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/bunnelby.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/659.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/bunnelby.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/659.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Diggersby",
    value: "recU8eafuXjAOBqsm",
    order: "0660",
    dexNo: "660",
    size: "small",
    name: "diggersby",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/diggersby.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/660.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/diggersby.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/660.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/diggersby.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/660.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/diggersby.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/660.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Fletchling",
    value: "recdfdxpj7YAvx4FZ",
    order: "0661",
    dexNo: "661",
    size: "xsmall",
    name: "fletchling",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/fletchling.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/661.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/fletchling.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/661.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/fletchling.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/661.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/fletchling.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/661.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Fletchinder",
    value: "rec0kAA0dkWgJbKcY",
    order: "0662",
    dexNo: "662",
    size: "small",
    name: "fletchinder",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/fletchinder.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/662.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/fletchinder.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/662.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/fletchinder.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/662.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/fletchinder.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/662.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Talonflame",
    value: "recK8YaUZRVBNbz4N",
    order: "0663",
    dexNo: "663",
    size: "medium",
    name: "talonflame",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/talonflame.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/663.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/talonflame.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/663.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/talonflame.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/663.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/talonflame.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/663.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Scatterbug",
    value: "rechn3vG4gvIh2yKl",
    order: "0664",
    dexNo: "664",
    size: "xsmall",
    name: "scatterbug",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/scatterbug.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/664.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/scatterbug.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/664.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/scatterbug.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/664.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/scatterbug.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/664.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Spewpa",
    value: "recLhSG5SlS2VWx0c",
    order: "0665",
    dexNo: "665",
    size: "xsmall",
    name: "spewpa",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/spewpa.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/665.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/spewpa.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/665.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/spewpa.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/665.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/spewpa.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/665.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Vivillon",
    value: "recER3uUny1lNAAY8",
    order: "0666",
    dexNo: "666",
    size: "medium",
    name: "vivillon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/vivillon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/666.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/vivillon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/666.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/vivillon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/666.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/vivillon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/666.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Litleo",
    value: "recJlogm6IJ1OjAzA",
    order: "0667",
    dexNo: "667",
    size: "small",
    name: "litleo",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/litleo.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/667.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/litleo.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/667.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/litleo.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/667.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/litleo.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/667.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pyroar",
    value: "recwutSlmDFwouKtG",
    order: "0668",
    dexNo: "668",
    size: "medium",
    name: "pyroar",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pyroar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/668.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pyroar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/668.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pyroar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/668.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pyroar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/668.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Flabébé",
    value: "recLLeFawFekJfdV0",
    order: "0669",
    dexNo: "669",
    size: "xsmall",
    name: "flabébé",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/flabébé.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/669.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/flabébé.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/669.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/flabébé.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/669.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/flabébé.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/669.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Floette",
    value: "recPzK7hiVh2LoUJg",
    order: "0670",
    dexNo: "670",
    size: "xsmall",
    name: "floette",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/floette.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/670.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/floette.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/670.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/floette.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/670.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/floette.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/670.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Florges",
    value: "recVsA5g1wYOKf0a8",
    order: "0671",
    dexNo: "671",
    size: "medium",
    name: "florges",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/florges.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/671.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/florges.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/671.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/florges.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/671.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/florges.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/671.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Skiddo",
    value: "recmECvaAJCiUELy6",
    order: "0672",
    dexNo: "672",
    size: "small",
    name: "skiddo",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/skiddo.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/672.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/skiddo.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/672.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/skiddo.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/672.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/skiddo.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/672.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gogoat",
    value: "recd5XaQGU5sRm8tx",
    order: "0673",
    dexNo: "673",
    size: "large",
    name: "gogoat",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gogoat.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/673.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gogoat.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/673.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gogoat.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/673.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gogoat.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/673.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pancham",
    value: "rec5CNB49FvpVMaI7",
    order: "0674",
    dexNo: "674",
    size: "small",
    name: "pancham",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pancham.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/674.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pancham.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/674.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pancham.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/674.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pancham.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/674.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pangoro",
    value: "recxJt8otnBlbsqL7",
    order: "0675",
    dexNo: "675",
    size: "xlarge",
    name: "pangoro",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pangoro.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/675.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pangoro.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/675.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pangoro.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/675.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pangoro.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/675.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Furfrou",
    value: "recphWYSF2hRJsVn5",
    order: "0676",
    dexNo: "676",
    size: "medium",
    name: "furfrou",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/furfrou.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/676.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/furfrou.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/676.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/furfrou.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/676.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/furfrou.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/676.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Espurr",
    value: "rec5GL28BxTw6aYf2",
    order: "0677",
    dexNo: "677",
    size: "xsmall",
    name: "espurr",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/espurr.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/677.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/espurr.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/677.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/espurr.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/677.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/espurr.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/677.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Meowstic",
    value: "recPgIRdvGq62DSDW",
    order: "0678",
    dexNo: "678",
    size: "small",
    name: "meowstic",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/meowstic.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/678.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/meowstic.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/678.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/meowstic.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/678.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/meowstic.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/678.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Honedge",
    value: "recfB4AlVC58BVhi0",
    order: "0679",
    dexNo: "679",
    size: "small",
    name: "honedge",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/honedge.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/679.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/honedge.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/679.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/honedge.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/679.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/honedge.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/679.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Doublade",
    value: "recTqXlARrGGlGAa8",
    order: "0680",
    dexNo: "680",
    size: "small",
    name: "doublade",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/doublade.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/680.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/doublade.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/680.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/doublade.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/680.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/doublade.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/680.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Aegislash",
    value: "recs2ddH1RCc0rdpC",
    order: "0681",
    dexNo: "681",
    size: "large",
    name: "aegislash",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/aegislash.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/681.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/aegislash.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/681.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/aegislash.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/681.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/aegislash.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/681.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Spritzee",
    value: "recN5rbcbeXS8Vs0M",
    order: "0682",
    dexNo: "682",
    size: "xsmall",
    name: "spritzee",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/spritzee.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/682.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/spritzee.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/682.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/spritzee.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/682.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/spritzee.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/682.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Aromatisse",
    value: "recib1kMtX1UnPg9b",
    order: "0683",
    dexNo: "683",
    size: "small",
    name: "aromatisse",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/aromatisse.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/683.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/aromatisse.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/683.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/aromatisse.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/683.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/aromatisse.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/683.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Swirlix",
    value: "recwkJezo5VZXMg8i",
    order: "0684",
    dexNo: "684",
    size: "xsmall",
    name: "swirlix",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/swirlix.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/684.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/swirlix.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/684.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/swirlix.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/684.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/swirlix.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/684.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Slurpuff",
    value: "recIlmU2mdbem7ijE",
    order: "0685",
    dexNo: "685",
    size: "small",
    name: "slurpuff",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/slurpuff.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/685.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/slurpuff.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/685.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/slurpuff.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/685.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/slurpuff.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/685.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Inkay",
    value: "recmLx88OIhxS2ksC",
    order: "0686",
    dexNo: "686",
    size: "xsmall",
    name: "inkay",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/inkay.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/686.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/inkay.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/686.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/inkay.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/686.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/inkay.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/686.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Malamar",
    value: "recP9yqtv74c0hs47",
    order: "0687",
    dexNo: "687",
    size: "medium",
    name: "malamar",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/malamar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/687.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/malamar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/687.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/malamar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/687.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/malamar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/687.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Binacle",
    value: "recmxPWu8xphsnZFq",
    order: "0688",
    dexNo: "688",
    size: "xsmall",
    name: "binacle",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/binacle.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/688.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/binacle.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/688.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/binacle.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/688.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/binacle.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/688.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Barbaracle",
    value: "rechsVquu3UTN5Bow",
    order: "0689",
    dexNo: "689",
    size: "medium",
    name: "barbaracle",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/barbaracle.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/689.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/barbaracle.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/689.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/barbaracle.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/689.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/barbaracle.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/689.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Skrelp",
    value: "receXmBZDIwxfpGDs",
    order: "0690",
    dexNo: "690",
    size: "xsmall",
    name: "skrelp",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/skrelp.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/690.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/skrelp.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/690.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/skrelp.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/690.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/skrelp.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/690.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dragalge",
    value: "recb6o2xsKtsawQrJ",
    order: "0691",
    dexNo: "691",
    size: "large",
    name: "dragalge",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dragalge.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/691.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dragalge.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/691.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dragalge.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/691.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dragalge.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/691.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Clauncher",
    value: "recdBUerhfhcJeCCi",
    order: "0692",
    dexNo: "692",
    size: "xsmall",
    name: "clauncher",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/clauncher.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/692.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/clauncher.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/692.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/clauncher.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/692.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/clauncher.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/692.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Clawitzer",
    value: "reco4VDadNcMYqcTM",
    order: "0693",
    dexNo: "693",
    size: "medium",
    name: "clawitzer",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/clawitzer.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/693.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/clawitzer.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/693.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/clawitzer.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/693.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/clawitzer.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/693.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Helioptile",
    value: "recqw1fOHflNRfvDH",
    order: "0694",
    dexNo: "694",
    size: "xsmall",
    name: "helioptile",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/helioptile.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/694.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/helioptile.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/694.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/helioptile.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/694.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/helioptile.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/694.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Heliolisk",
    value: "reckThnLBFAd1qOLb",
    order: "0695",
    dexNo: "695",
    size: "small",
    name: "heliolisk",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/heliolisk.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/695.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/heliolisk.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/695.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/heliolisk.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/695.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/heliolisk.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/695.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tyrunt",
    value: "recCNMSz1LVGi06La",
    order: "0696",
    dexNo: "696",
    size: "small",
    name: "tyrunt",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tyrunt.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/696.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tyrunt.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/696.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tyrunt.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/696.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tyrunt.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/696.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tyrantrum",
    value: "receC7ZEVq8ZAYL4z",
    order: "0697",
    dexNo: "697",
    size: "xlarge",
    name: "tyrantrum",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tyrantrum.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/697.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tyrantrum.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/697.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tyrantrum.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/697.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tyrantrum.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/697.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Amaura",
    value: "recl5RMNJzQnfwqBg",
    order: "0698",
    dexNo: "698",
    size: "medium",
    name: "amaura",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/amaura.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/698.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/amaura.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/698.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/amaura.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/698.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/amaura.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/698.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Aurorus",
    value: "recrVtDZjIemAmMWD",
    order: "0699",
    dexNo: "699",
    size: "xlarge",
    name: "aurorus",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/aurorus.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/699.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/aurorus.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/699.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/aurorus.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/699.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/aurorus.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/699.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sylveon",
    value: "recLoEWFHGwXbakGz",
    order: "0700",
    dexNo: "700",
    size: "small",
    name: "sylveon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sylveon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/700.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sylveon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/700.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sylveon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/700.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sylveon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/700.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Hawlucha",
    value: "recURC3y8sReeIwAl",
    order: "0701",
    dexNo: "701",
    size: "small",
    name: "hawlucha",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/hawlucha.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/701.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/hawlucha.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/701.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/hawlucha.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/701.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/hawlucha.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/701.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dedenne",
    value: "recImlN0CJi3nZAYK",
    order: "0702",
    dexNo: "702",
    size: "xsmall",
    name: "dedenne",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dedenne.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/702.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dedenne.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/702.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dedenne.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/702.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dedenne.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/702.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Carbink",
    value: "recN9xptq8TGv73in",
    order: "0703",
    dexNo: "703",
    size: "xsmall",
    name: "carbink",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/carbink.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/703.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/carbink.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/703.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/carbink.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/703.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/carbink.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/703.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Goomy",
    value: "recd15UOV6ELMhTPR",
    order: "0704",
    dexNo: "704",
    size: "xsmall",
    name: "goomy",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/goomy.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/704.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/goomy.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/704.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/goomy.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/704.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/goomy.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/704.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sliggoo",
    value: "rec2maYQORphMs3jW",
    order: "0705",
    dexNo: "705",
    size: "small",
    name: "sliggoo",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sliggoo.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/705.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sliggoo.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/705.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sliggoo.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/705.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sliggoo.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/705.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sliggoo (Hisui Form)",
    value: "recCu1QZnL7gREeAj",
    order: "0705",
    dexNo: "705",
    size: "small",
    name: "sliggoo",
    form: "hisui",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sliggoo-hisui.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/705-hisui.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sliggoo-hisui.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/705-hisui.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sliggoo-hisui.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/705-hisui.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sliggoo-hisui.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/705-hisui.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Goodra",
    value: "recKEieNsLqcPbYdW",
    order: "0706",
    dexNo: "706",
    size: "large",
    name: "goodra",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/goodra.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/706.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/goodra.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/706.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/goodra.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/706.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/goodra.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/706.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Goodra (Hisui Form)",
    value: "recAohv69KHxZjZP1",
    order: "0706",
    dexNo: "706",
    size: "large",
    name: "goodra",
    form: "hisui",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/goodra-hisui.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/706-hisui.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/goodra-hisui.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/706-hisui.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/goodra-hisui.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/706-hisui.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/goodra-hisui.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/706-hisui.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Klefki",
    value: "recZ5Amxs5QM62w2G",
    order: "0707",
    dexNo: "707",
    size: "xsmall",
    name: "klefki",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/klefki.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/707.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/klefki.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/707.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/klefki.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/707.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/klefki.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/707.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Phantump",
    value: "recTNJLUEpMSiUTJ1",
    order: "0708",
    dexNo: "708",
    size: "xsmall",
    name: "phantump",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/phantump.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/708.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/phantump.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/708.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/phantump.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/708.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/phantump.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/708.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Trevenant",
    value: "recurL7WNryssKhkK",
    order: "0709",
    dexNo: "709",
    size: "medium",
    name: "trevenant",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/trevenant.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/709.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/trevenant.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/709.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/trevenant.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/709.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/trevenant.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/709.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pumpkaboo",
    value: "recy5OCpcCFyyCh0E",
    order: "0710",
    dexNo: "710",
    size: "xsmall",
    name: "pumpkaboo",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pumpkaboo.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/710.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pumpkaboo.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/710.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pumpkaboo.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/710.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pumpkaboo.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/710.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gourgeist",
    value: "rectbeXbaUjpC4PxX",
    order: "0711",
    dexNo: "711",
    size: "small",
    name: "gourgeist",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gourgeist.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/711.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gourgeist.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/711.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gourgeist.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/711.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gourgeist.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/711.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Bergmite",
    value: "rec4uIRkIr6J3ZfgP",
    order: "0712",
    dexNo: "712",
    size: "small",
    name: "bergmite",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/bergmite.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/712.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/bergmite.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/712.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/bergmite.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/712.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/bergmite.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/712.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Avalugg",
    value: "rec5m1EfXJbI4E3ir",
    order: "0713",
    dexNo: "713",
    size: "large",
    name: "avalugg",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/avalugg.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/713.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/avalugg.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/713.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/avalugg.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/713.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/avalugg.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/713.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Avalugg (Hisui Form)",
    value: "recAQW3M2in6V2npQ",
    order: "0713",
    dexNo: "713",
    size: "large",
    name: "avalugg",
    form: "hisui",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/avalugg-hisui.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/713-hisui.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/avalugg-hisui.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/713-hisui.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/avalugg-hisui.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/713-hisui.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/avalugg-hisui.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/713-hisui.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Noibat",
    value: "recizYK0jG00SSLPI",
    order: "0714",
    dexNo: "714",
    size: "xsmall",
    name: "noibat",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/noibat.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/714.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/noibat.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/714.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/noibat.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/714.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/noibat.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/714.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Noivern",
    value: "recpCRBMjJRLPfJjs",
    order: "0715",
    dexNo: "715",
    size: "medium",
    name: "noivern",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/noivern.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/715.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/noivern.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/715.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/noivern.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/715.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/noivern.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/715.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Xerneas",
    value: "recjX88gm6jtIij5i",
    order: "0716",
    dexNo: "716",
    size: "xlarge",
    name: "xerneas",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/xerneas.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/716.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/xerneas.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/716.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/xerneas.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/716.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/xerneas.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/716.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Yveltal",
    value: "recryyhzFabrPj5XZ",
    order: "0717",
    dexNo: "717",
    size: "xlarge",
    name: "yveltal",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/yveltal.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/717.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/yveltal.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/717.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/yveltal.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/717.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/yveltal.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/717.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Zygarde",
    value: "rec39bMxAB6rrZ6dN",
    order: "0718",
    dexNo: "718",
    size: "xlarge",
    name: "zygarde",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/zygarde.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/718.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/zygarde.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/718.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/zygarde.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/718.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/zygarde.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/718.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Diancie",
    value: "recHoQBWfxEhwaESE",
    order: "0719",
    dexNo: "719",
    size: "small",
    name: "diancie",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/diancie.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/719.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/diancie.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/719.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/diancie.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/719.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/diancie.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/719.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Hoopa",
    value: "recEKdizRJbu62CJb",
    order: "0720",
    dexNo: "720",
    size: "xsmall",
    name: "hoopa",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/hoopa.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/720.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/hoopa.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/720.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/hoopa.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/720.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/hoopa.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/720.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Volcanion",
    value: "recw4GtazElVI8sxh",
    order: "0721",
    dexNo: "721",
    size: "large",
    name: "volcanion",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/volcanion.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/721.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/volcanion.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/721.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/volcanion.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/721.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/volcanion.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/721.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Rowlet",
    value: "recDWPW7OvbHFBmQB",
    order: "0722",
    dexNo: "722",
    size: "xsmall",
    name: "rowlet",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/rowlet.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/722.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/rowlet.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/722.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/rowlet.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/722.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/rowlet.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/722.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dartrix",
    value: "recsgRR7qG5H5XosJ",
    order: "0723",
    dexNo: "723",
    size: "small",
    name: "dartrix",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dartrix.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/723.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dartrix.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/723.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dartrix.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/723.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dartrix.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/723.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Decidueye",
    value: "rechoEw9JHJYtEMD9",
    order: "0724",
    dexNo: "724",
    size: "large",
    name: "decidueye",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/decidueye.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/724.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/decidueye.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/724.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/decidueye.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/724.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/decidueye.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/724.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Decidueye (Hisui Form)",
    value: "recgqpkAofQdbcw4A",
    order: "0724",
    dexNo: "724",
    size: "large",
    name: "decidueye",
    form: "hisui",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/decidueye-hisui.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/724-hisui.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/decidueye-hisui.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/724-hisui.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/decidueye-hisui.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/724-hisui.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/decidueye-hisui.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/724-hisui.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Litten",
    value: "rec5sWHyGfiIgZf1o",
    order: "0725",
    dexNo: "725",
    size: "xsmall",
    name: "litten",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/litten.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/725.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/litten.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/725.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/litten.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/725.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/litten.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/725.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Torracat",
    value: "recVlnuRIxFEq8UuZ",
    order: "0726",
    dexNo: "726",
    size: "small",
    name: "torracat",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/torracat.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/726.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/torracat.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/726.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/torracat.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/726.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/torracat.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/726.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Incineroar",
    value: "rec5hW5fezsHQHcQF",
    order: "0727",
    dexNo: "727",
    size: "large",
    name: "incineroar",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/incineroar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/727.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/incineroar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/727.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/incineroar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/727.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/incineroar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/727.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Popplio",
    value: "rec5kUlDuM3jLHOb5",
    order: "0728",
    dexNo: "728",
    size: "xsmall",
    name: "popplio",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/popplio.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/728.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/popplio.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/728.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/popplio.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/728.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/popplio.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/728.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Brionne",
    value: "rec38HNMEq8LPjPwe",
    order: "0729",
    dexNo: "729",
    size: "small",
    name: "brionne",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/brionne.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/729.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/brionne.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/729.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/brionne.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/729.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/brionne.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/729.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Primarina",
    value: "recetWHUes1NbrW2q",
    order: "0730",
    dexNo: "730",
    size: "large",
    name: "primarina",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/primarina.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/730.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/primarina.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/730.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/primarina.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/730.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/primarina.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/730.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pikipek",
    value: "recj82nm8UqI3tW1I",
    order: "0731",
    dexNo: "731",
    size: "xsmall",
    name: "pikipek",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pikipek.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/731.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pikipek.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/731.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pikipek.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/731.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pikipek.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/731.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Trumbeak",
    value: "rec2MhSMf5IIPVz0c",
    order: "0732",
    dexNo: "732",
    size: "small",
    name: "trumbeak",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/trumbeak.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/732.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/trumbeak.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/732.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/trumbeak.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/732.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/trumbeak.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/732.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Toucannon",
    value: "recmIxLhPtTuaEmBD",
    order: "0733",
    dexNo: "733",
    size: "medium",
    name: "toucannon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/toucannon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/733.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/toucannon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/733.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/toucannon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/733.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/toucannon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/733.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Yungoos",
    value: "recvpXaubQ2LA63K8",
    order: "0734",
    dexNo: "734",
    size: "xsmall",
    name: "yungoos",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/yungoos.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/734.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/yungoos.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/734.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/yungoos.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/734.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/yungoos.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/734.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gumshoos",
    value: "recmmJKsqJvat7o9W",
    order: "0735",
    dexNo: "735",
    size: "small",
    name: "gumshoos",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gumshoos.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/735.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gumshoos.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/735.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gumshoos.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/735.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gumshoos.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/735.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Grubbin",
    value: "recAVf336qB9ez1D5",
    order: "0736",
    dexNo: "736",
    size: "xsmall",
    name: "grubbin",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/grubbin.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/736.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/grubbin.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/736.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/grubbin.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/736.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/grubbin.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/736.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Charjabug",
    value: "rec9SEEybqUEq6VPy",
    order: "0737",
    dexNo: "737",
    size: "xsmall",
    name: "charjabug",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/charjabug.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/737.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/charjabug.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/737.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/charjabug.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/737.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/charjabug.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/737.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Vikavolt",
    value: "recyOhKI7oaceUtzr",
    order: "0738",
    dexNo: "738",
    size: "medium",
    name: "vikavolt",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/vikavolt.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/738.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/vikavolt.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/738.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/vikavolt.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/738.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/vikavolt.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/738.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Crabrawler",
    value: "recNgnynNA4lDJHBo",
    order: "0739",
    dexNo: "739",
    size: "small",
    name: "crabrawler",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/crabrawler.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/739.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/crabrawler.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/739.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/crabrawler.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/739.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/crabrawler.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/739.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Crabominable",
    value: "recpJomDUXI8nQbJU",
    order: "0740",
    dexNo: "740",
    size: "large",
    name: "crabominable",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/crabominable.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/740.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/crabominable.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/740.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/crabominable.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/740.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/crabominable.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/740.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Oricorio",
    value: "recUHiQHuQohLaJMW",
    order: "0741",
    dexNo: "741",
    size: "small",
    name: "oricorio",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/oricorio.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/741.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/oricorio.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/741.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/oricorio.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/741.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/oricorio.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/741.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cutiefly",
    value: "recvEwUPcZZmIM6Sj",
    order: "0742",
    dexNo: "742",
    size: "xsmall",
    name: "cutiefly",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cutiefly.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/742.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cutiefly.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/742.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cutiefly.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/742.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cutiefly.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/742.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ribombee",
    value: "recqHGmgT7enNIX1x",
    order: "0743",
    dexNo: "743",
    size: "xsmall",
    name: "ribombee",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ribombee.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/743.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ribombee.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/743.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ribombee.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/743.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ribombee.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/743.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Rockruff",
    value: "rec2gSMJ6fdIOI6vf",
    order: "0744",
    dexNo: "744",
    size: "xsmall",
    name: "rockruff",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/rockruff.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/744.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/rockruff.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/744.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/rockruff.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/744.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/rockruff.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/744.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Lycanroc",
    value: "recQTh92qlCV6cZiR",
    order: "0745",
    dexNo: "745",
    size: "small",
    name: "lycanroc",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/lycanroc.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/745.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/lycanroc.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/745.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/lycanroc.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/745.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/lycanroc.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/745.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Wishiwashi",
    value: "receUyLDeKu6ZXwMg",
    order: "0746",
    dexNo: "746",
    size: "xsmall",
    name: "wishiwashi",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/wishiwashi.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/746.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/wishiwashi.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/746.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/wishiwashi.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/746.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/wishiwashi.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/746.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mareanie",
    value: "recxQjMXf2NXqVece",
    order: "0747",
    dexNo: "747",
    size: "xsmall",
    name: "mareanie",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mareanie.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/747.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mareanie.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/747.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mareanie.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/747.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mareanie.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/747.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Toxapex",
    value: "recedpiDteq275CqF",
    order: "0748",
    dexNo: "748",
    size: "small",
    name: "toxapex",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/toxapex.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/748.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/toxapex.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/748.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/toxapex.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/748.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/toxapex.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/748.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mudbray",
    value: "recce3fmYHA867SND",
    order: "0749",
    dexNo: "749",
    size: "small",
    name: "mudbray",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mudbray.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/749.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mudbray.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/749.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mudbray.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/749.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mudbray.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/749.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mudsdale",
    value: "recbHgSYoTYfrJmW1",
    order: "0750",
    dexNo: "750",
    size: "xlarge",
    name: "mudsdale",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mudsdale.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/750.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mudsdale.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/750.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mudsdale.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/750.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mudsdale.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/750.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dewpider",
    value: "recbDBefFGDoWRzn5",
    order: "0751",
    dexNo: "751",
    size: "xsmall",
    name: "dewpider",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dewpider.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/751.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dewpider.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/751.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dewpider.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/751.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dewpider.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/751.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Araquanid",
    value: "recAQgjnqY916tlNd",
    order: "0752",
    dexNo: "752",
    size: "large",
    name: "araquanid",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/araquanid.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/752.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/araquanid.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/752.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/araquanid.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/752.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/araquanid.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/752.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Fomantis",
    value: "rec26naZf1MSE8cyi",
    order: "0753",
    dexNo: "753",
    size: "xsmall",
    name: "fomantis",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/fomantis.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/753.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/fomantis.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/753.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/fomantis.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/753.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/fomantis.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/753.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Lurantis",
    value: "recPIflK7R6ndtJZ5",
    order: "0754",
    dexNo: "754",
    size: "small",
    name: "lurantis",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/lurantis.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/754.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/lurantis.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/754.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/lurantis.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/754.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/lurantis.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/754.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Morelull",
    value: "receN3uqkF7i9lwmx",
    order: "0755",
    dexNo: "755",
    size: "xsmall",
    name: "morelull",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/morelull.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/755.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/morelull.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/755.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/morelull.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/755.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/morelull.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/755.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Shiinotic",
    value: "recRjbgslvrUzoOnN",
    order: "0756",
    dexNo: "756",
    size: "small",
    name: "shiinotic",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/shiinotic.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/756.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/shiinotic.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/756.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/shiinotic.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/756.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/shiinotic.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/756.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Salandit",
    value: "rec1MPYQ7Gf9oVhb3",
    order: "0757",
    dexNo: "757",
    size: "small",
    name: "salandit",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/salandit.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/757.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/salandit.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/757.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/salandit.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/757.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/salandit.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/757.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Salazzle",
    value: "rec18iZCDvZwWroVn",
    order: "0758",
    dexNo: "758",
    size: "medium",
    name: "salazzle",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/salazzle.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/758.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/salazzle.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/758.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/salazzle.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/758.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/salazzle.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/758.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Stufful",
    value: "recwa2LdyXTQzaBpZ",
    order: "0759",
    dexNo: "759",
    size: "xsmall",
    name: "stufful",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/stufful.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/759.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/stufful.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/759.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/stufful.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/759.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/stufful.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/759.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Bewear",
    value: "recZhRcCdp1r0IPp9",
    order: "0760",
    dexNo: "760",
    size: "xlarge",
    name: "bewear",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/bewear.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/760.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/bewear.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/760.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/bewear.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/760.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/bewear.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/760.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Bounsweet",
    value: "recDCX7wN435fTiiP",
    order: "0761",
    dexNo: "761",
    size: "xsmall",
    name: "bounsweet",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/bounsweet.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/761.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/bounsweet.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/761.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/bounsweet.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/761.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/bounsweet.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/761.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Steenee",
    value: "recgwxr7fOZugRZMj",
    order: "0762",
    dexNo: "762",
    size: "small",
    name: "steenee",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/steenee.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/762.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/steenee.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/762.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/steenee.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/762.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/steenee.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/762.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tsareena",
    value: "recnlEdWvcqxX5aOr",
    order: "0763",
    dexNo: "763",
    size: "medium",
    name: "tsareena",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tsareena.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/763.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tsareena.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/763.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tsareena.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/763.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tsareena.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/763.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Comfey",
    value: "recmrkSKG3uYNCtC9",
    order: "0764",
    dexNo: "764",
    size: "xsmall",
    name: "comfey",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/comfey.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/764.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/comfey.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/764.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/comfey.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/764.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/comfey.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/764.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Oranguru",
    value: "recnY2RQhfLkWZe07",
    order: "0765",
    dexNo: "765",
    size: "medium",
    name: "oranguru",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/oranguru.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/765.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/oranguru.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/765.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/oranguru.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/765.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/oranguru.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/765.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Passimian",
    value: "recXu6nfP9cOlY54r",
    order: "0766",
    dexNo: "766",
    size: "large",
    name: "passimian",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/passimian.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/766.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/passimian.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/766.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/passimian.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/766.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/passimian.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/766.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Wimpod",
    value: "recFkSma7nO4obmHH",
    order: "0767",
    dexNo: "767",
    size: "xsmall",
    name: "wimpod",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/wimpod.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/767.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/wimpod.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/767.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/wimpod.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/767.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/wimpod.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/767.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Golisopod",
    value: "rec4j0ol0KXpZRlh0",
    order: "0768",
    dexNo: "768",
    size: "large",
    name: "golisopod",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/golisopod.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/768.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/golisopod.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/768.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/golisopod.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/768.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/golisopod.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/768.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sandygast",
    value: "recZ4P5ozXX0FdAz0",
    order: "0769",
    dexNo: "769",
    size: "xsmall",
    name: "sandygast",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sandygast.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/769.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sandygast.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/769.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sandygast.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/769.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sandygast.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/769.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Palossand",
    value: "rechwqJCpKCisupl3",
    order: "0770",
    dexNo: "770",
    size: "medium",
    name: "palossand",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/palossand.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/770.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/palossand.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/770.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/palossand.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/770.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/palossand.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/770.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pyukumuku",
    value: "rectf7KEZEvaZCHiD",
    order: "0771",
    dexNo: "771",
    size: "xsmall",
    name: "pyukumuku",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pyukumuku.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/771.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pyukumuku.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/771.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pyukumuku.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/771.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pyukumuku.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/771.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Type: Null",
    value: "recDORmNxbr9njTaZ",
    order: "0772",
    dexNo: "772",
    size: "large",
    name: "type-null",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/type-null.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/772.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/type-null.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/772.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/type-null.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/772.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/type-null.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/772.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Silvally",
    value: "recdjQTwsTYDwJfjc",
    order: "0773",
    dexNo: "773",
    size: "xlarge",
    name: "silvally",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/silvally.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/773.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/silvally.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/773.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/silvally.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/773.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/silvally.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/773.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Minior",
    value: "recXRnOTnbecLFPnO",
    order: "0774",
    dexNo: "774",
    size: "xsmall",
    name: "minior",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/minior.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/774.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/minior.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/774.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/minior.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/774.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/minior.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/774.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Komala",
    value: "recfIhxWUdMVihPXJ",
    order: "0775",
    dexNo: "775",
    size: "xsmall",
    name: "komala",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/komala.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/775.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/komala.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/775.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/komala.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/775.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/komala.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/775.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Turtonator",
    value: "reczmKaiHtvNnw09L",
    order: "0776",
    dexNo: "776",
    size: "large",
    name: "turtonator",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/turtonator.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/776.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/turtonator.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/776.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/turtonator.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/776.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/turtonator.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/776.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Togedemaru",
    value: "recunZa45nh6u7fhh",
    order: "0777",
    dexNo: "777",
    size: "xsmall",
    name: "togedemaru",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/togedemaru.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/777.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/togedemaru.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/777.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/togedemaru.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/777.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/togedemaru.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/777.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mimikyu",
    value: "rec563nQFHJP6a9Qw",
    order: "0778",
    dexNo: "778",
    size: "xsmall",
    name: "mimikyu",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mimikyu.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/778.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mimikyu.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/778.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mimikyu.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/778.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mimikyu.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/778.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Bruxish",
    value: "recfkDFIhCZTbpouy",
    order: "0779",
    dexNo: "779",
    size: "small",
    name: "bruxish",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/bruxish.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/779.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/bruxish.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/779.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/bruxish.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/779.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/bruxish.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/779.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Drampa",
    value: "rec5zCZYtgVeW0rKR",
    order: "0780",
    dexNo: "780",
    size: "xlarge",
    name: "drampa",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/drampa.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/780.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/drampa.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/780.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/drampa.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/780.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/drampa.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/780.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dhelmise",
    value: "recT8eAiJRQGMUtg6",
    order: "0781",
    dexNo: "781",
    size: "xlarge",
    name: "dhelmise",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dhelmise.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/781.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dhelmise.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/781.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dhelmise.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/781.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dhelmise.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/781.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Jangmo-o",
    value: "rec90wADe3Rs8HHY6",
    order: "0782",
    dexNo: "782",
    size: "small",
    name: "jangmo-o",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/jangmo-o.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/782.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/jangmo-o.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/782.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/jangmo-o.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/782.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/jangmo-o.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/782.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Hakamo-o",
    value: "reclltr2kKeoVmewU",
    order: "0783",
    dexNo: "783",
    size: "medium",
    name: "hakamo-o",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/hakamo-o.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/783.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/hakamo-o.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/783.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/hakamo-o.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/783.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/hakamo-o.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/783.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Kommo-o",
    value: "rec7aYEDmVqdxlZOA",
    order: "0784",
    dexNo: "784",
    size: "large",
    name: "kommo-o",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/kommo-o.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/784.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/kommo-o.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/784.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/kommo-o.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/784.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/kommo-o.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/784.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tapu Koko",
    value: "recFRkRsTdZG7sKty",
    order: "0785",
    dexNo: "785",
    size: "large",
    name: "tapu-koko",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tapu-koko.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/785.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tapu-koko.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/785.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tapu-koko.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/785.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tapu-koko.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/785.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tapu Lele",
    value: "recVXYBoFrYPAMxnX",
    order: "0786",
    dexNo: "786",
    size: "medium",
    name: "tapu-lele",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tapu-lele.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/786.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tapu-lele.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/786.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tapu-lele.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/786.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tapu-lele.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/786.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tapu Bulu",
    value: "rech9f2i6affc3zL8",
    order: "0787",
    dexNo: "787",
    size: "large",
    name: "tapu-bulu",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tapu-bulu.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/787.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tapu-bulu.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/787.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tapu-bulu.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/787.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tapu-bulu.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/787.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tapu Fini",
    value: "recjTBurGaAzSZPxh",
    order: "0788",
    dexNo: "788",
    size: "medium",
    name: "tapu-fini",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tapu-fini.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/788.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tapu-fini.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/788.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tapu-fini.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/788.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tapu-fini.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/788.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cosmog",
    value: "reciX93KILO04iaKC",
    order: "0789",
    dexNo: "789",
    size: "xsmall",
    name: "cosmog",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cosmog.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/789.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cosmog.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/789.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cosmog.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/789.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cosmog.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/789.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cosmoem",
    value: "recGfvuedmNx8nesR",
    order: "0790",
    dexNo: "790",
    size: "xsmall",
    name: "cosmoem",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cosmoem.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/790.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cosmoem.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/790.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cosmoem.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/790.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cosmoem.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/790.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Solgaleo",
    value: "recMES8uvzd7JZcoa",
    order: "0791",
    dexNo: "791",
    size: "xlarge",
    name: "solgaleo",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/solgaleo.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/791.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/solgaleo.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/791.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/solgaleo.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/791.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/solgaleo.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/791.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Lunala",
    value: "rechiMmx33rYC7Da4",
    order: "0792",
    dexNo: "792",
    size: "xlarge",
    name: "lunala",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/lunala.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/792.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/lunala.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/792.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/lunala.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/792.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/lunala.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/792.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Nihilego",
    value: "reckU3gCx5cHUDPjO",
    order: "0793",
    dexNo: "793",
    size: "medium",
    name: "nihilego",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/nihilego.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/793.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/nihilego.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/793.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/nihilego.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/793.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/nihilego.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/793.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Buzzwole",
    value: "recHMRYBrvto8bZzK",
    order: "0794",
    dexNo: "794",
    size: "xlarge",
    name: "buzzwole",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/buzzwole.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/794.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/buzzwole.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/794.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/buzzwole.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/794.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/buzzwole.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/794.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pheromosa",
    value: "rec42uejqn6vn3tIi",
    order: "0795",
    dexNo: "795",
    size: "large",
    name: "pheromosa",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pheromosa.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/795.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pheromosa.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/795.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pheromosa.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/795.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pheromosa.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/795.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Xurkitree",
    value: "reciuyygsZPqbJ0lN",
    order: "0796",
    dexNo: "796",
    size: "xlarge",
    name: "xurkitree",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/xurkitree.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/796.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/xurkitree.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/796.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/xurkitree.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/796.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/xurkitree.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/796.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Celesteela",
    value: "recT6ug3nMSnOTw5d",
    order: "0797",
    dexNo: "797",
    size: "xlarge",
    name: "celesteela",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/celesteela.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/797.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/celesteela.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/797.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/celesteela.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/797.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/celesteela.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/797.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Kartana",
    value: "recNCiuDhCKeMd2fk",
    order: "0798",
    dexNo: "798",
    size: "xsmall",
    name: "kartana",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/kartana.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/798.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/kartana.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/798.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/kartana.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/798.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/kartana.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/798.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Guzzlord",
    value: "recPq72Ztcpkt3hJS",
    order: "0799",
    dexNo: "799",
    size: "xlarge",
    name: "guzzlord",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/guzzlord.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/799.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/guzzlord.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/799.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/guzzlord.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/799.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/guzzlord.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/799.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Necrozma",
    value: "recj4uHMIT5MYCMBb",
    order: "0800",
    dexNo: "800",
    size: "xlarge",
    name: "necrozma",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/necrozma.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/800.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/necrozma.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/800.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/necrozma.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/800.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/necrozma.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/800.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Magearna",
    value: "recfaK5zYi7aHZidt",
    order: "0801",
    dexNo: "801",
    size: "small",
    name: "magearna",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/magearna.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/801.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/magearna.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/801.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/magearna.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/801.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/magearna.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/801.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Marshadow",
    value: "recSHkBnhfMqzKTOY",
    order: "0802",
    dexNo: "802",
    size: "small",
    name: "marshadow",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/marshadow.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/802.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/marshadow.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/802.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/marshadow.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/802.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/marshadow.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/802.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Poipole",
    value: "rec4EZxjEaeQqIwAF",
    order: "0803",
    dexNo: "803",
    size: "small",
    name: "poipole",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/poipole.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/803.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/poipole.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/803.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/poipole.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/803.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/poipole.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/803.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Naganadel",
    value: "recPNHbe6IS2Ax7Kk",
    order: "0804",
    dexNo: "804",
    size: "xlarge",
    name: "naganadel",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/naganadel.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/804.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/naganadel.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/804.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/naganadel.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/804.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/naganadel.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/804.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Stakataka",
    value: "recUGjSQPnMYf1yyn",
    order: "0805",
    dexNo: "805",
    size: "xlarge",
    name: "stakataka",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/stakataka.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/805.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/stakataka.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/805.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/stakataka.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/805.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/stakataka.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/805.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Blacephalon",
    value: "recenlirV7RdqKzhi",
    order: "0806",
    dexNo: "806",
    size: "large",
    name: "blacephalon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/blacephalon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/806.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/blacephalon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/806.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/blacephalon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/806.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/blacephalon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/806.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Zeraora",
    value: "recMjoMWi7GK9area",
    order: "0807",
    dexNo: "807",
    size: "medium",
    name: "zeraora",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/zeraora.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/807.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/zeraora.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/807.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/zeraora.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/807.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/zeraora.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/807.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Meltan",
    value: "rec9S5qHokuyEEQ8N",
    order: "0808",
    dexNo: "808",
    size: "xsmall",
    name: "meltan",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/meltan.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/808.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/meltan.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/808.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/meltan.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/808.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/meltan.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/808.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Melmetal",
    value: "recRkBETml2CwFHa9",
    order: "0809",
    dexNo: "809",
    size: "xlarge",
    name: "melmetal",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/melmetal.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/809.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/melmetal.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/809.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/melmetal.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/809.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/melmetal.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/809.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Grookey",
    value: "recJOLhAm9roz9txO",
    order: "0810",
    dexNo: "810",
    size: "xsmall",
    name: "grookey",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/grookey.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/810.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/grookey.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/810.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/grookey.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/810.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/grookey.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/810.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Thwackey",
    value: "recEZzKTA9SN66h1o",
    order: "0811",
    dexNo: "811",
    size: "small",
    name: "thwackey",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/thwackey.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/811.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/thwackey.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/811.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/thwackey.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/811.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/thwackey.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/811.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Rillaboom",
    value: "recDsBxHbtC6wYonZ",
    order: "0812",
    dexNo: "812",
    size: "xlarge",
    name: "rillaboom",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/rillaboom.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/812.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/rillaboom.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/812.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/rillaboom.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/812.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/rillaboom.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/812.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Scorbunny",
    value: "recoDFQ4VeuMzTLMY",
    order: "0813",
    dexNo: "813",
    size: "xsmall",
    name: "scorbunny",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/scorbunny.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/813.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/scorbunny.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/813.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/scorbunny.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/813.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/scorbunny.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/813.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Raboot",
    value: "rec9i72szYYblLx6X",
    order: "0814",
    dexNo: "814",
    size: "small",
    name: "raboot",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/raboot.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/814.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/raboot.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/814.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/raboot.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/814.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/raboot.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/814.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cinderace",
    value: "recH7bMpLA7EyQW5r",
    order: "0815",
    dexNo: "815",
    size: "medium",
    name: "cinderace",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cinderace.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/815.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cinderace.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/815.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cinderace.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/815.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cinderace.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/815.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sobble",
    value: "rec5CuR2nbE9qrDoV",
    order: "0816",
    dexNo: "816",
    size: "xsmall",
    name: "sobble",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sobble.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/816.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sobble.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/816.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sobble.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/816.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sobble.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/816.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Drizzile",
    value: "recqNXt4v1uqmLCTh",
    order: "0817",
    dexNo: "817",
    size: "small",
    name: "drizzile",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/drizzile.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/817.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/drizzile.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/817.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/drizzile.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/817.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/drizzile.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/817.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Inteleon",
    value: "rec3H4sGMqcJuF8by",
    order: "0818",
    dexNo: "818",
    size: "large",
    name: "inteleon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/inteleon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/818.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/inteleon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/818.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/inteleon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/818.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/inteleon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/818.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Skwovet",
    value: "recDGiMjIhxghNAe3",
    order: "0819",
    dexNo: "819",
    size: "xsmall",
    name: "skwovet",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/skwovet.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/819.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/skwovet.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/819.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/skwovet.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/819.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/skwovet.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/819.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Greedent",
    value: "rec33kTBXPvMG5rhr",
    order: "0820",
    dexNo: "820",
    size: "small",
    name: "greedent",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/greedent.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/820.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/greedent.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/820.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/greedent.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/820.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/greedent.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/820.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Rookidee",
    value: "rec5fDWVbRwpYArdI",
    order: "0821",
    dexNo: "821",
    size: "xsmall",
    name: "rookidee",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/rookidee.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/821.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/rookidee.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/821.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/rookidee.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/821.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/rookidee.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/821.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Corvisquire",
    value: "recGj5KA2ZxI4ALHn",
    order: "0822",
    dexNo: "822",
    size: "small",
    name: "corvisquire",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/corvisquire.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/822.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/corvisquire.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/822.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/corvisquire.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/822.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/corvisquire.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/822.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Corviknight",
    value: "recZPLFVjuUurQSgq",
    order: "0823",
    dexNo: "823",
    size: "xlarge",
    name: "corviknight",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/corviknight.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/823.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/corviknight.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/823.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/corviknight.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/823.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/corviknight.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/823.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Blipbug",
    value: "recaKBUTza7Vlxagm",
    order: "0824",
    dexNo: "824",
    size: "xsmall",
    name: "blipbug",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/blipbug.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/824.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/blipbug.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/824.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/blipbug.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/824.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/blipbug.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/824.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dottler",
    value: "recLaufLN2OQVxypS",
    order: "0825",
    dexNo: "825",
    size: "xsmall",
    name: "dottler",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dottler.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/825.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dottler.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/825.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dottler.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/825.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dottler.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/825.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Orbeetle",
    value: "rec9kGItGGrQP691J",
    order: "0826",
    dexNo: "826",
    size: "xsmall",
    name: "orbeetle",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/orbeetle.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/826.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/orbeetle.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/826.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/orbeetle.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/826.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/orbeetle.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/826.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Nickit",
    value: "recIdrNzZWVEqNWmc",
    order: "0827",
    dexNo: "827",
    size: "small",
    name: "nickit",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/nickit.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/827.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/nickit.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/827.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/nickit.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/827.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/nickit.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/827.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Thievul",
    value: "recNQfyI6SBsApb8F",
    order: "0828",
    dexNo: "828",
    size: "medium",
    name: "thievul",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/thievul.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/828.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/thievul.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/828.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/thievul.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/828.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/thievul.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/828.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gossifleur",
    value: "recqakWTSrPRLZ1yy",
    order: "0829",
    dexNo: "829",
    size: "xsmall",
    name: "gossifleur",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gossifleur.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/829.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gossifleur.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/829.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gossifleur.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/829.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gossifleur.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/829.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Eldegoss",
    value: "reclGdp86Os6IFiBS",
    order: "0830",
    dexNo: "830",
    size: "xsmall",
    name: "eldegoss",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/eldegoss.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/830.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/eldegoss.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/830.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/eldegoss.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/830.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/eldegoss.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/830.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Wooloo",
    value: "recXjFcjWCNsrCZWv",
    order: "0831",
    dexNo: "831",
    size: "small",
    name: "wooloo",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/wooloo.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/831.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/wooloo.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/831.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/wooloo.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/831.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/wooloo.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/831.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dubwool",
    value: "recfB8a8uyJz4i6YB",
    order: "0832",
    dexNo: "832",
    size: "medium",
    name: "dubwool",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dubwool.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/832.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dubwool.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/832.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dubwool.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/832.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dubwool.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/832.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Chewtle",
    value: "recLvsyRu9bj1iG22",
    order: "0833",
    dexNo: "833",
    size: "xsmall",
    name: "chewtle",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/chewtle.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/833.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/chewtle.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/833.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/chewtle.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/833.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/chewtle.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/833.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Drednaw",
    value: "recd6XZykH8xy8sbc",
    order: "0834",
    dexNo: "834",
    size: "small",
    name: "drednaw",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/drednaw.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/834.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/drednaw.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/834.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/drednaw.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/834.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/drednaw.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/834.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Yamper",
    value: "recYGMvMjsmefEjZI",
    order: "0835",
    dexNo: "835",
    size: "xsmall",
    name: "yamper",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/yamper.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/835.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/yamper.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/835.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/yamper.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/835.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/yamper.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/835.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Boltund",
    value: "rec4h5qoQSXi3K58x",
    order: "0836",
    dexNo: "836",
    size: "small",
    name: "boltund",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/boltund.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/836.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/boltund.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/836.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/boltund.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/836.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/boltund.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/836.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Rolycoly",
    value: "recJJQi5rFzokjl6J",
    order: "0837",
    dexNo: "837",
    size: "xsmall",
    name: "rolycoly",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/rolycoly.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/837.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/rolycoly.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/837.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/rolycoly.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/837.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/rolycoly.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/837.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Carkol",
    value: "recPWXj3P8LHWdk7c",
    order: "0838",
    dexNo: "838",
    size: "medium",
    name: "carkol",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/carkol.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/838.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/carkol.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/838.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/carkol.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/838.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/carkol.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/838.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Coalossal",
    value: "recdCIJH9daT9F9n8",
    order: "0839",
    dexNo: "839",
    size: "xlarge",
    name: "coalossal",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/coalossal.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/839.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/coalossal.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/839.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/coalossal.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/839.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/coalossal.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/839.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Applin",
    value: "recZ7CU3OqrIfnz71",
    order: "0840",
    dexNo: "840",
    size: "xsmall",
    name: "applin",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/applin.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/840.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/applin.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/840.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/applin.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/840.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/applin.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/840.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Flapple",
    value: "recF316e5VrKYX66n",
    order: "0841",
    dexNo: "841",
    size: "xsmall",
    name: "flapple",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/flapple.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/841.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/flapple.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/841.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/flapple.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/841.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/flapple.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/841.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Appletun",
    value: "recvrCxwairFpDVAV",
    order: "0842",
    dexNo: "842",
    size: "xsmall",
    name: "appletun",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/appletun.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/842.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/appletun.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/842.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/appletun.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/842.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/appletun.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/842.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Silicobra",
    value: "recJDZ4sDQM5HWOWJ",
    order: "0843",
    dexNo: "843",
    size: "xlarge",
    name: "silicobra",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/silicobra.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/843.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/silicobra.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/843.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/silicobra.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/843.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/silicobra.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/843.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sandaconda",
    value: "recn1FxKUTcBMM6gt",
    order: "0844",
    dexNo: "844",
    size: "xlarge",
    name: "sandaconda",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sandaconda.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/844.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sandaconda.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/844.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sandaconda.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/844.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sandaconda.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/844.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cramorant",
    value: "recaJ5yKMYHE8Yb7m",
    order: "0845",
    dexNo: "845",
    size: "small",
    name: "cramorant",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cramorant.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/845.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cramorant.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/845.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cramorant.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/845.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cramorant.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/845.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Arrokuda",
    value: "recgluj6bNKPGKeAL",
    order: "0846",
    dexNo: "846",
    size: "xsmall",
    name: "arrokuda",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/arrokuda.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/846.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/arrokuda.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/846.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/arrokuda.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/846.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/arrokuda.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/846.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Barraskewda",
    value: "recPS4CfjifKon2JI",
    order: "0847",
    dexNo: "847",
    size: "medium",
    name: "barraskewda",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/barraskewda.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/847.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/barraskewda.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/847.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/barraskewda.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/847.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/barraskewda.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/847.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Toxel",
    value: "recLUvhhhKLRJGA1A",
    order: "0848",
    dexNo: "848",
    size: "xsmall",
    name: "toxel",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/toxel.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/848.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/toxel.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/848.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/toxel.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/848.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/toxel.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/848.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Toxtricity",
    value: "rec0sIqcQ49s9iPCH",
    order: "0849",
    dexNo: "849",
    size: "large",
    name: "toxtricity",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/toxtricity.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/849.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/toxtricity.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/849.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/toxtricity.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/849.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/toxtricity.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/849.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sizzlipede",
    value: "rechtEdkD7VM3URjD",
    order: "0850",
    dexNo: "850",
    size: "small",
    name: "sizzlipede",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sizzlipede.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/850.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sizzlipede.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/850.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sizzlipede.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/850.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sizzlipede.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/850.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Centiskorch",
    value: "recbE8JaejYmkovku",
    order: "0851",
    dexNo: "851",
    size: "xlarge",
    name: "centiskorch",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/centiskorch.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/851.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/centiskorch.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/851.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/centiskorch.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/851.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/centiskorch.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/851.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Clobbopus",
    value: "recKWwQyxwV8liEOw",
    order: "0852",
    dexNo: "852",
    size: "small",
    name: "clobbopus",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/clobbopus.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/852.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/clobbopus.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/852.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/clobbopus.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/852.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/clobbopus.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/852.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Grapploct",
    value: "recR8uo4ghq0po34h",
    order: "0853",
    dexNo: "853",
    size: "large",
    name: "grapploct",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/grapploct.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/853.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/grapploct.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/853.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/grapploct.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/853.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/grapploct.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/853.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sinistea",
    value: "recXUsVnEd0nwqhVd",
    order: "0854",
    dexNo: "854",
    size: "xsmall",
    name: "sinistea",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sinistea.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/854.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sinistea.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/854.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sinistea.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/854.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sinistea.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/854.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Polteageist",
    value: "recUmFsPpHmondAyq",
    order: "0855",
    dexNo: "855",
    size: "xsmall",
    name: "polteageist",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/polteageist.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/855.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/polteageist.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/855.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/polteageist.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/855.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/polteageist.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/855.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Hatenna",
    value: "recRknIETNSxRNJJP",
    order: "0856",
    dexNo: "856",
    size: "xsmall",
    name: "hatenna",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/hatenna.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/856.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/hatenna.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/856.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/hatenna.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/856.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/hatenna.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/856.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Hattrem",
    value: "recZno54SAoxi7O8O",
    order: "0857",
    dexNo: "857",
    size: "small",
    name: "hattrem",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/hattrem.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/857.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/hattrem.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/857.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/hattrem.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/857.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/hattrem.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/857.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Hatterene",
    value: "rec61BFISHJFk9pT1",
    order: "0858",
    dexNo: "858",
    size: "xlarge",
    name: "hatterene",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/hatterene.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/858.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/hatterene.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/858.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/hatterene.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/858.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/hatterene.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/858.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Impidimp",
    value: "recTF1BsfllkgEWS7",
    order: "0859",
    dexNo: "859",
    size: "xsmall",
    name: "impidimp",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/impidimp.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/859.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/impidimp.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/859.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/impidimp.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/859.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/impidimp.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/859.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Morgrem",
    value: "recr67ZhE1DWZ7941",
    order: "0860",
    dexNo: "860",
    size: "small",
    name: "morgrem",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/morgrem.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/860.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/morgrem.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/860.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/morgrem.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/860.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/morgrem.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/860.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Grimmsnarl",
    value: "rec8vfPNRn9zcTWVD",
    order: "0861",
    dexNo: "861",
    size: "medium",
    name: "grimmsnarl",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/grimmsnarl.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/861.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/grimmsnarl.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/861.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/grimmsnarl.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/861.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/grimmsnarl.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/861.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Obstagoon",
    value: "recBxeMZ9OBlSAEkY",
    order: "0862",
    dexNo: "862",
    size: "large",
    name: "obstagoon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/obstagoon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/862.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/obstagoon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/862.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/obstagoon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/862.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/obstagoon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/862.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Perrserker",
    value: "rec5YvncUzJy1fUgr",
    order: "0863",
    dexNo: "863",
    size: "small",
    name: "perrserker",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/perrserker.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/863.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/perrserker.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/863.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/perrserker.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/863.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/perrserker.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/863.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cursola",
    value: "recC6tiae6siLn7sd",
    order: "0864",
    dexNo: "864",
    size: "small",
    name: "cursola",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cursola.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/864.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cursola.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/864.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cursola.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/864.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cursola.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/864.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sirfetch'd",
    value: "recMZPzBL0ePhbbJR",
    order: "0865",
    dexNo: "865",
    size: "small",
    name: "sirfetchd",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sirfetchd.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/865.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sirfetchd.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/865.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sirfetchd.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/865.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sirfetchd.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/865.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mr. Rime",
    value: "recqMnrdR6QD0bz18",
    order: "0866",
    dexNo: "866",
    size: "medium",
    name: "mr-rime",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mr-rime.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/866.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mr-rime.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/866.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mr-rime.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/866.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mr-rime.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/866.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Runerigus",
    value: "reczAWAck4oiNxiiT",
    order: "0867",
    dexNo: "867",
    size: "large",
    name: "runerigus",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/runerigus.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/867.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/runerigus.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/867.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/runerigus.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/867.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/runerigus.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/867.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Milcery",
    value: "rect2JDivkXVsi1Ob",
    order: "0868",
    dexNo: "868",
    size: "xsmall",
    name: "milcery",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/milcery.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/868.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/milcery.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/868.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/milcery.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/868.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/milcery.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/868.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Alcremie",
    value: "recmAUrq0pZcgN1no",
    order: "0869",
    dexNo: "869",
    size: "xsmall",
    name: "alcremie",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/alcremie.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/869.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/alcremie.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/869.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/alcremie.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/869.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/alcremie.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/869.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Falinks",
    value: "rece7mRH784PVkiyE",
    order: "0870",
    dexNo: "870",
    size: "xlarge",
    name: "falinks",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/falinks.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/870.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/falinks.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/870.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/falinks.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/870.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/falinks.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/870.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pincurchin",
    value: "recyj3okCrzcUeZRp",
    order: "0871",
    dexNo: "871",
    size: "xsmall",
    name: "pincurchin",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pincurchin.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/871.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pincurchin.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/871.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pincurchin.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/871.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pincurchin.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/871.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Snom",
    value: "recvGaRCYq3IT82Uw",
    order: "0872",
    dexNo: "872",
    size: "xsmall",
    name: "snom",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/snom.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/872.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/snom.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/872.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/snom.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/872.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/snom.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/872.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Frosmoth",
    value: "recxn7FiFtYND7QJO",
    order: "0873",
    dexNo: "873",
    size: "medium",
    name: "frosmoth",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/frosmoth.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/873.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/frosmoth.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/873.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/frosmoth.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/873.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/frosmoth.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/873.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Stonjourner",
    value: "recL1WDueO37o9yww",
    order: "0874",
    dexNo: "874",
    size: "xlarge",
    name: "stonjourner",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/stonjourner.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/874.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/stonjourner.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/874.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/stonjourner.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/874.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/stonjourner.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/874.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Eiscue",
    value: "recbicje2bBdBRX6j",
    order: "0875",
    dexNo: "875",
    size: "medium",
    name: "eiscue",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/eiscue.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/875.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/eiscue.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/875.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/eiscue.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/875.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/eiscue.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/875.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Indeedee",
    value: "rec2CFa2AXugUBgzJ",
    order: "0876",
    dexNo: "876",
    size: "small",
    name: "indeedee",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/indeedee.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/876.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/indeedee.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/876.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/indeedee.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/876.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/indeedee.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/876.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Morpeko",
    value: "recDDN9qBDMC3oB8w",
    order: "0877",
    dexNo: "877",
    size: "xsmall",
    name: "morpeko",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/morpeko.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/877.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/morpeko.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/877.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/morpeko.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/877.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/morpeko.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/877.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cufant",
    value: "recfPNuzFkHlcdUYb",
    order: "0878",
    dexNo: "878",
    size: "medium",
    name: "cufant",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cufant.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/878.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cufant.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/878.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cufant.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/878.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cufant.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/878.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Copperajah",
    value: "recwM84zEZzdTeWuj",
    order: "0879",
    dexNo: "879",
    size: "xlarge",
    name: "copperajah",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/copperajah.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/879.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/copperajah.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/879.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/copperajah.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/879.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/copperajah.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/879.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dracozolt",
    value: "recd7tF6JAcywDJ0f",
    order: "0880",
    dexNo: "880",
    size: "large",
    name: "dracozolt",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dracozolt.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/880.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dracozolt.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/880.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dracozolt.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/880.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dracozolt.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/880.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Arctozolt",
    value: "recabzOwcEHVUAjsn",
    order: "0881",
    dexNo: "881",
    size: "xlarge",
    name: "arctozolt",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/arctozolt.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/881.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/arctozolt.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/881.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/arctozolt.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/881.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/arctozolt.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/881.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dracovish",
    value: "rec6eUawUGke45vLj",
    order: "0882",
    dexNo: "882",
    size: "xlarge",
    name: "dracovish",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dracovish.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/882.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dracovish.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/882.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dracovish.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/882.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dracovish.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/882.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Arctovish",
    value: "rechiMJHEoNyMTUTD",
    order: "0883",
    dexNo: "883",
    size: "large",
    name: "arctovish",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/arctovish.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/883.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/arctovish.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/883.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/arctovish.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/883.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/arctovish.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/883.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Duraludon",
    value: "recM8Y6LZkaMg57kH",
    order: "0884",
    dexNo: "884",
    size: "large",
    name: "duraludon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/duraludon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/884.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/duraludon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/884.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/duraludon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/884.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/duraludon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/884.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dreepy",
    value: "rec15iR2cFZilDviC",
    order: "0885",
    dexNo: "885",
    size: "xsmall",
    name: "dreepy",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dreepy.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/885.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dreepy.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/885.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dreepy.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/885.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dreepy.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/885.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Drakloak",
    value: "recapY6MaVpsaUWLw",
    order: "0886",
    dexNo: "886",
    size: "medium",
    name: "drakloak",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/drakloak.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/886.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/drakloak.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/886.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/drakloak.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/886.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/drakloak.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/886.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dragapult",
    value: "reczh70dZ73ed9cxH",
    order: "0887",
    dexNo: "887",
    size: "xlarge",
    name: "dragapult",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dragapult.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/887.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dragapult.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/887.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dragapult.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/887.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dragapult.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/887.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Zacian",
    value: "rec392vF1aCTSGS7I",
    order: "0888",
    dexNo: "888",
    size: "xlarge",
    name: "zacian",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/zacian.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/888.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/zacian.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/888.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/zacian.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/888.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/zacian.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/888.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Zamazenta",
    value: "recnUoVgjGEgiYBQ7",
    order: "0889",
    dexNo: "889",
    size: "xlarge",
    name: "zamazenta",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/zamazenta.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/889.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/zamazenta.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/889.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/zamazenta.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/889.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/zamazenta.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/889.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Eternatus",
    value: "recEjtaJ2Fa6hEX3Y",
    order: "0890",
    dexNo: "890",
    size: "xlarge",
    name: "eternatus",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/eternatus.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/890.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/eternatus.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/890.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/eternatus.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/890.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/eternatus.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/890.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Kubfu",
    value: "recWdo8qgnDsUDQcN",
    order: "0891",
    dexNo: "891",
    size: "small",
    name: "kubfu",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/kubfu.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/891.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/kubfu.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/891.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/kubfu.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/891.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/kubfu.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/891.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Urshifu",
    value: "recBpeGPhmJMHN07O",
    order: "0892",
    dexNo: "892",
    size: "large",
    name: "urshifu",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/urshifu.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/892.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/urshifu.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/892.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/urshifu.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/892.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/urshifu.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/892.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Zarude",
    value: "recSopTD2XGYJTCUN",
    order: "0893",
    dexNo: "893",
    size: "medium",
    name: "zarude",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/zarude.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/893.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/zarude.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/893.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/zarude.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/893.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/zarude.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/893.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Regieleki",
    value: "recymh6oZeJVERetU",
    order: "0894",
    dexNo: "894",
    size: "medium",
    name: "regieleki",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/regieleki.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/894.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/regieleki.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/894.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/regieleki.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/894.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/regieleki.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/894.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Regidrago",
    value: "recmTGuwBblvZJKmG",
    order: "0895",
    dexNo: "895",
    size: "large",
    name: "regidrago",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/regidrago.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/895.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/regidrago.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/895.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/regidrago.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/895.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/regidrago.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/895.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Glastrier",
    value: "recRUc91dqrYm99Dx",
    order: "0896",
    dexNo: "896",
    size: "large",
    name: "glastrier",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/glastrier.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/896.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/glastrier.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/896.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/glastrier.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/896.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/glastrier.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/896.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Spectrier",
    value: "recnbsb8TJyjiafSJ",
    order: "0897",
    dexNo: "897",
    size: "large",
    name: "spectrier",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/spectrier.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/897.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/spectrier.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/897.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/spectrier.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/897.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/spectrier.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/897.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Calyrex",
    value: "recFR8K0mGICQRuQo",
    order: "0898",
    dexNo: "898",
    size: "medium",
    name: "calyrex",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/calyrex.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/898.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/calyrex.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/898.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/calyrex.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/898.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/calyrex.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/898.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Wyrdeer",
    value: "recfueHEmWMqNZhkL",
    order: "0899",
    dexNo: "899",
    size: "medium",
    name: "wyrdeer",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/wyrdeer.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/899.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/wyrdeer.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/899.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/wyrdeer.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/899.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/wyrdeer.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/899.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Kleavor",
    value: "recvyClBkpGjBjLXl",
    order: "0900",
    dexNo: "900",
    size: "medium",
    name: "kleavor",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/kleavor.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/900.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/kleavor.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/900.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/kleavor.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/900.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/kleavor.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/900.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ursaluna",
    value: "recbSIHUVvcMaDNem",
    order: "0901",
    dexNo: "901",
    size: "large",
    name: "ursaluna",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ursaluna.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/901.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ursaluna.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/901.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ursaluna.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/901.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ursaluna.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/901.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Basculegion",
    value: "rec6Pn3QqB3vwykwV",
    order: "0902",
    dexNo: "902",
    size: "large",
    name: "basculegion",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/basculegion.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/902.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/basculegion.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/902.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/basculegion.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/902.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/basculegion.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/902.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sneasler",
    value: "reczqoEH2iFcAn6x6",
    order: "0903",
    dexNo: "903",
    size: "medium",
    name: "sneasler",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sneasler.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/903.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sneasler.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/903.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sneasler.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/903.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sneasler.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/903.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Overqwil",
    value: "rechGAfebovtyg3AZ",
    order: "0904",
    dexNo: "904",
    size: "large",
    name: "overqwil",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/overqwil.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/904.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/overqwil.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/904.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/overqwil.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/904.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/overqwil.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/904.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Enamorus",
    value: "recMPkfgQhR75x2bi",
    order: "0905",
    dexNo: "905",
    size: "medium",
    name: "enamorus",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/enamorus.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/905.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/enamorus.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/905.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/enamorus.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/905.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/enamorus.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/905.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sprigatito",
    value: "recdLYZA0gyM2Lut2",
    order: "0906",
    dexNo: "906",
    size: "medium",
    name: "sprigatito",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sprigatito.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/906.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sprigatito.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/906.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sprigatito.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/906.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sprigatito.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/906.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Floragato",
    value: "recZeYzotAiK0xr3O",
    order: "0907",
    dexNo: "907",
    size: "medium",
    name: "floragato",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/floragato.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/907.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/floragato.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/907.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/floragato.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/907.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/floragato.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/907.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Meowscarada",
    value: "recJ5LZUQknfQpsG8",
    order: "0908",
    dexNo: "908",
    size: "medium",
    name: "meowscarada",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/meowscarada.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/908.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/meowscarada.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/908.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/meowscarada.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/908.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/meowscarada.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/908.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Fuecoco",
    value: "recznY23bpTkx4Cjc",
    order: "0909",
    dexNo: "909",
    size: "medium",
    name: "fuecoco",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/fuecoco.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/909.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/fuecoco.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/909.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/fuecoco.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/909.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/fuecoco.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/909.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Crocalor",
    value: "rec4575ObqkqpEqhm",
    order: "0910",
    dexNo: "910",
    size: "medium",
    name: "crocalor",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/crocalor.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/910.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/crocalor.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/910.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/crocalor.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/910.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/crocalor.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/910.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Skeledirge",
    value: "reca695ZSmsDjshW5",
    order: "0911",
    dexNo: "911",
    size: "medium",
    name: "skeledirge",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/skeledirge.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/911.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/skeledirge.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/911.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/skeledirge.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/911.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/skeledirge.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/911.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Quaxly",
    value: "rec5rDUYmesh1aAAM",
    order: "0912",
    dexNo: "912",
    size: "medium",
    name: "quaxly",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/quaxly.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/912.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/quaxly.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/912.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/quaxly.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/912.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/quaxly.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/912.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Quaxwell",
    value: "recVVbVZg6gdDJ2PH",
    order: "0913",
    dexNo: "913",
    size: "medium",
    name: "quaxwell",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/quaxwell.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/913.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/quaxwell.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/913.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/quaxwell.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/913.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/quaxwell.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/913.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Quaquaval",
    value: "recyTFaYGQ5AUCU5p",
    order: "0914",
    dexNo: "914",
    size: "medium",
    name: "quaquaval",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/quaquaval.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/914.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/quaquaval.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/914.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/quaquaval.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/914.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/quaquaval.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/914.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Lechonk",
    value: "recaoawqQSFMCRqH6",
    order: "0915",
    dexNo: "915",
    size: "medium",
    name: "lechonk",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/lechonk.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/915.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/lechonk.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/915.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/lechonk.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/915.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/lechonk.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/915.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Oinkologne",
    value: "reccKwt37YcG8nuU7",
    order: "0916",
    dexNo: "916",
    size: "medium",
    name: "oinkologne",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/oinkologne.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/916.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/oinkologne.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/916.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/oinkologne.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/916.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/oinkologne.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/916.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dudunsparce",
    value: "rec2mnusq2xothxVE",
    order: "0917",
    dexNo: "917",
    size: "medium",
    name: "dudunsparce",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dudunsparce.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/917.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dudunsparce.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/917.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dudunsparce.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/917.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dudunsparce.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/917.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tarountula",
    value: "recwDPtEmvGzCzOMn",
    order: "0918",
    dexNo: "918",
    size: "medium",
    name: "tarountula",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tarountula.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/918.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tarountula.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/918.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tarountula.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/918.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tarountula.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/918.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Spidops",
    value: "recY9NK8u9B13GVbC",
    order: "0919",
    dexNo: "919",
    size: "medium",
    name: "spidops",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/spidops.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/919.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/spidops.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/919.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/spidops.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/919.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/spidops.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/919.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Nymble",
    value: "recqU06p2cL9u6AwM",
    order: "0920",
    dexNo: "920",
    size: "medium",
    name: "nymble",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/nymble.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/920.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/nymble.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/920.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/nymble.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/920.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/nymble.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/920.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Lokix",
    value: "rec3k0yV9KN1rKUOu",
    order: "0921",
    dexNo: "921",
    size: "medium",
    name: "lokix",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/lokix.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/921.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/lokix.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/921.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/lokix.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/921.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/lokix.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/921.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Rellor",
    value: "recKnJ1cS4XS3bfLq",
    order: "0922",
    dexNo: "922",
    size: "medium",
    name: "rellor",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/rellor.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/922.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/rellor.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/922.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/rellor.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/922.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/rellor.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/922.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Rabsca",
    value: "recHjT6K6HGOfKi6p",
    order: "0923",
    dexNo: "923",
    size: "medium",
    name: "rabsca",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/rabsca.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/923.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/rabsca.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/923.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/rabsca.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/923.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/rabsca.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/923.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Greavard",
    value: "recQJuitk4qiCPaO4",
    order: "0924",
    dexNo: "924",
    size: "medium",
    name: "greavard",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/greavard.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/924.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/greavard.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/924.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/greavard.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/924.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/greavard.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/924.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Houndstone",
    value: "recGnCMLZ69B6n3yJ",
    order: "0925",
    dexNo: "925",
    size: "medium",
    name: "houndstone",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/houndstone.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/925.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/houndstone.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/925.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/houndstone.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/925.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/houndstone.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/925.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Flittle",
    value: "recqSVT947VU7hXei",
    order: "0926",
    dexNo: "926",
    size: "medium",
    name: "flittle",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/flittle.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/926.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/flittle.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/926.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/flittle.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/926.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/flittle.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/926.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Espathra",
    value: "rec9wGErpBfmEUibM",
    order: "0927",
    dexNo: "927",
    size: "medium",
    name: "espathra",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/espathra.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/927.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/espathra.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/927.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/espathra.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/927.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/espathra.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/927.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Farigiraf",
    value: "recSgNhQPvgMJHerq",
    order: "0928",
    dexNo: "928",
    size: "medium",
    name: "farigiraf",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/farigiraf.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/928.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/farigiraf.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/928.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/farigiraf.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/928.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/farigiraf.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/928.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Wiglett",
    value: "recSamT0NX2XxONfv",
    order: "0929",
    dexNo: "929",
    size: "medium",
    name: "wiglett",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/wiglett.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/929.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/wiglett.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/929.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/wiglett.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/929.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/wiglett.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/929.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Wugtrio",
    value: "recn5z2wmYHEaXEMN",
    order: "0930",
    dexNo: "930",
    size: "medium",
    name: "wugtrio",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/wugtrio.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/930.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/wugtrio.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/930.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/wugtrio.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/930.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/wugtrio.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/930.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dondozo",
    value: "rec61knfpJ2AEkP6Y",
    order: "0931",
    dexNo: "931",
    size: "medium",
    name: "dondozo",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dondozo.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/931.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dondozo.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/931.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dondozo.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/931.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dondozo.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/931.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Veluza",
    value: "receIpCFTo6g7GOTn",
    order: "0932",
    dexNo: "932",
    size: "medium",
    name: "veluza",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/veluza.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/932.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/veluza.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/932.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/veluza.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/932.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/veluza.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/932.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Finizen",
    value: "recWGFQaJVqWT6CTS",
    order: "0933",
    dexNo: "933",
    size: "medium",
    name: "finizen",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/finizen.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/933.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/finizen.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/933.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/finizen.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/933.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/finizen.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/933.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Palafin",
    value: "recaY3JiIIRPxjscB",
    order: "0934",
    dexNo: "934",
    size: "medium",
    name: "palafin",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/palafin.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/934.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/palafin.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/934.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/palafin.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/934.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/palafin.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/934.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Smoliv",
    value: "rec6nCvGutNnMo9vN",
    order: "0935",
    dexNo: "935",
    size: "medium",
    name: "smoliv",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/smoliv.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/935.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/smoliv.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/935.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/smoliv.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/935.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/smoliv.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/935.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dolliv",
    value: "recTQRoILXRTDcVZq",
    order: "0936",
    dexNo: "936",
    size: "medium",
    name: "dolliv",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dolliv.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/936.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dolliv.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/936.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dolliv.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/936.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dolliv.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/936.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Arboliva",
    value: "recqArmyR1anALl5F",
    order: "0937",
    dexNo: "937",
    size: "medium",
    name: "arboliva",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/arboliva.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/937.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/arboliva.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/937.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/arboliva.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/937.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/arboliva.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/937.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Capsakid",
    value: "recjwMnlZKghIi8nC",
    order: "0938",
    dexNo: "938",
    size: "medium",
    name: "capsakid",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/capsakid.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/938.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/capsakid.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/938.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/capsakid.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/938.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/capsakid.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/938.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Scovillain",
    value: "recuPoTN7ZzwqNDYh",
    order: "0939",
    dexNo: "939",
    size: "medium",
    name: "scovillain",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/scovillain.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/939.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/scovillain.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/939.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/scovillain.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/939.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/scovillain.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/939.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tadbulb",
    value: "rec9o2UJEzty2Ug4R",
    order: "0940",
    dexNo: "940",
    size: "medium",
    name: "tadbulb",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tadbulb.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/940.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tadbulb.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/940.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tadbulb.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/940.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tadbulb.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/940.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Bellibolt",
    value: "recW1zhFOYmFncDaj",
    order: "0941",
    dexNo: "941",
    size: "medium",
    name: "bellibolt",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/bellibolt.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/941.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/bellibolt.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/941.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/bellibolt.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/941.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/bellibolt.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/941.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Varoom",
    value: "recbcrP7ObDOWgoMY",
    order: "0942",
    dexNo: "942",
    size: "medium",
    name: "varoom",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/varoom.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/942.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/varoom.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/942.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/varoom.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/942.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/varoom.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/942.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Revavroom",
    value: "recIWSD6WFmNaOVrv",
    order: "0943",
    dexNo: "943",
    size: "medium",
    name: "revavroom",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/revavroom.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/943.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/revavroom.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/943.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/revavroom.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/943.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/revavroom.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/943.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Orthworm",
    value: "rec4I7NYrGQhPa9l3",
    order: "0944",
    dexNo: "944",
    size: "medium",
    name: "orthworm",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/orthworm.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/944.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/orthworm.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/944.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/orthworm.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/944.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/orthworm.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/944.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tandemaus",
    value: "rectAKRaZkzYPTekW",
    order: "0945",
    dexNo: "945",
    size: "medium",
    name: "tandemaus",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tandemaus.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/945.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tandemaus.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/945.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tandemaus.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/945.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tandemaus.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/945.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Maushold",
    value: "recdCss9mMHlrozdA",
    order: "0946",
    dexNo: "946",
    size: "medium",
    name: "maushold",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/maushold.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/946.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/maushold.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/946.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/maushold.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/946.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/maushold.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/946.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cetoddle",
    value: "recnM4JzHls0fAJdO",
    order: "0947",
    dexNo: "947",
    size: "medium",
    name: "cetoddle",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cetoddle.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/947.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cetoddle.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/947.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cetoddle.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/947.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cetoddle.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/947.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cetitan",
    value: "recfrX7ojM1IuiWQ1",
    order: "0948",
    dexNo: "948",
    size: "medium",
    name: "cetitan",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cetitan.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/948.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cetitan.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/948.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cetitan.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/948.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cetitan.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/948.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Frigibax",
    value: "recEiHr1kAfT2aLzu",
    order: "0949",
    dexNo: "949",
    size: "medium",
    name: "frigibax",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/frigibax.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/949.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/frigibax.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/949.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/frigibax.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/949.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/frigibax.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/949.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Articbax",
    value: "recIi4XNtKj7N1Nk3",
    order: "0950",
    dexNo: "950",
    size: "medium",
    name: "articbax",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/articbax.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/950.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/articbax.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/950.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/articbax.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/950.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/articbax.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/950.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Baxcalibur",
    value: "recUImnKjLi2sF4du",
    order: "0951",
    dexNo: "951",
    size: "medium",
    name: "baxcalibur",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/baxcalibur.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/951.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/baxcalibur.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/951.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/baxcalibur.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/951.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/baxcalibur.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/951.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tatsugiri",
    value: "recDto76JGaCkwFZB",
    order: "0952",
    dexNo: "952",
    size: "medium",
    name: "tatsugiri",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tatsugiri.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/952.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tatsugiri.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/952.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tatsugiri.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/952.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tatsugiri.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/952.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Cyclizar",
    value: "recOuUtJnFOoNlXRu",
    order: "0953",
    dexNo: "953",
    size: "medium",
    name: "cyclizar",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/cyclizar.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/953.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/cyclizar.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/953.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/cyclizar.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/953.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/cyclizar.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/953.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pawmi",
    value: "rechKfPpK21Rbrd6l",
    order: "0954",
    dexNo: "954",
    size: "medium",
    name: "pawmi",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pawmi.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/954.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pawmi.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/954.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pawmi.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/954.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pawmi.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/954.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pawmo",
    value: "recriQPzvIINoTu7q",
    order: "0955",
    dexNo: "955",
    size: "medium",
    name: "pawmo",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pawmo.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/955.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pawmo.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/955.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pawmo.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/955.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pawmo.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/955.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Pawmot",
    value: "rec77MJitTSwY0M0e",
    order: "0956",
    dexNo: "956",
    size: "medium",
    name: "pawmot",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/pawmot.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/956.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/pawmot.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/956.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/pawmot.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/956.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/pawmot.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/956.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Wattrel",
    value: "recN5ospg4JSk97gl",
    order: "0957",
    dexNo: "957",
    size: "medium",
    name: "wattrel",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/wattrel.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/957.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/wattrel.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/957.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/wattrel.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/957.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/wattrel.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/957.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Kilowattrel",
    value: "recq6H09Em9nBHGZ6",
    order: "0958",
    dexNo: "958",
    size: "medium",
    name: "kilowattrel",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/kilowattrel.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/958.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/kilowattrel.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/958.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/kilowattrel.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/958.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/kilowattrel.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/958.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Bombirdier",
    value: "reckpX6qGO7xYLxdN",
    order: "0959",
    dexNo: "959",
    size: "medium",
    name: "bombirdier",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/bombirdier.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/959.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/bombirdier.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/959.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/bombirdier.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/959.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/bombirdier.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/959.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Squawkabilly",
    value: "recLfptIvFvoJJAHo",
    order: "0960",
    dexNo: "960",
    size: "medium",
    name: "squawkabilly",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/squawkabilly.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/960.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/squawkabilly.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/960.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/squawkabilly.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/960.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/squawkabilly.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/960.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Flamigo",
    value: "recRR2wiNRm6oXCon",
    order: "0961",
    dexNo: "961",
    size: "medium",
    name: "flamigo",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/flamigo.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/961.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/flamigo.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/961.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/flamigo.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/961.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/flamigo.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/961.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Klawf",
    value: "recdh3zzAyzte9N4Q",
    order: "0962",
    dexNo: "962",
    size: "medium",
    name: "klawf",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/klawf.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/962.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/klawf.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/962.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/klawf.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/962.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/klawf.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/962.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Nacli",
    value: "recnSWmRgvB1SZDHl",
    order: "0963",
    dexNo: "963",
    size: "medium",
    name: "nacli",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/nacli.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/963.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/nacli.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/963.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/nacli.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/963.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/nacli.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/963.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Naclstack",
    value: "rec2kPbXrVuZKvWV4",
    order: "0964",
    dexNo: "964",
    size: "medium",
    name: "naclstack",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/naclstack.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/964.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/naclstack.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/964.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/naclstack.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/964.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/naclstack.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/964.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Garganacl",
    value: "recZsgafPVHMOaDc8",
    order: "0965",
    dexNo: "965",
    size: "medium",
    name: "garganacl",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/garganacl.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/965.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/garganacl.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/965.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/garganacl.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/965.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/garganacl.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/965.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Glimmet",
    value: "recCZnMSDJ9QCjpZj",
    order: "0966",
    dexNo: "966",
    size: "medium",
    name: "glimmet",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/glimmet.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/966.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/glimmet.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/966.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/glimmet.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/966.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/glimmet.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/966.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Glimmora",
    value: "recqADeQzlbxQzlNz",
    order: "0967",
    dexNo: "967",
    size: "medium",
    name: "glimmora",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/glimmora.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/967.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/glimmora.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/967.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/glimmora.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/967.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/glimmora.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/967.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Shroodle",
    value: "recjMDzLqcPrG3Pui",
    order: "0968",
    dexNo: "968",
    size: "medium",
    name: "shroodle",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/shroodle.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/968.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/shroodle.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/968.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/shroodle.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/968.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/shroodle.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/968.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Grafaiai",
    value: "recrbkec2cMBDBJ3m",
    order: "0969",
    dexNo: "969",
    size: "medium",
    name: "grafaiai",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/grafaiai.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/969.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/grafaiai.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/969.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/grafaiai.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/969.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/grafaiai.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/969.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Fidough",
    value: "recc1ccbzNwq20h0L",
    order: "0970",
    dexNo: "970",
    size: "medium",
    name: "fidough",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/fidough.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/970.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/fidough.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/970.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/fidough.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/970.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/fidough.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/970.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Dachsbun",
    value: "rec2SH6wR8oa5cRP9",
    order: "0971",
    dexNo: "971",
    size: "medium",
    name: "dachsbun",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/dachsbun.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/971.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/dachsbun.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/971.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/dachsbun.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/971.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/dachsbun.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/971.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Maschiff",
    value: "recP61jmQH5JTVHsX",
    order: "0972",
    dexNo: "972",
    size: "medium",
    name: "maschiff",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/maschiff.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/972.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/maschiff.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/972.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/maschiff.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/972.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/maschiff.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/972.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Mabosstiff",
    value: "recNaVJghAo38WxID",
    order: "0973",
    dexNo: "973",
    size: "medium",
    name: "mabosstiff",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/mabosstiff.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/973.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/mabosstiff.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/973.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/mabosstiff.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/973.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/mabosstiff.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/973.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Bramblin",
    value: "recdAU4ZEk9VB580N",
    order: "0974",
    dexNo: "974",
    size: "medium",
    name: "bramblin",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/bramblin.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/974.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/bramblin.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/974.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/bramblin.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/974.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/bramblin.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/974.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Brambleghast",
    value: "recJN3oWeQ5HXHJw1",
    order: "0975",
    dexNo: "975",
    size: "medium",
    name: "brambleghast",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/brambleghast.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/975.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/brambleghast.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/975.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/brambleghast.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/975.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/brambleghast.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/975.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gimmighoul",
    value: "recba3ClFqbKYG9vD",
    order: "0976",
    dexNo: "976",
    size: "medium",
    name: "gimmighoul",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gimmighoul.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/976.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gimmighoul.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/976.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gimmighoul.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/976.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gimmighoul.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/976.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Gholdengo",
    value: "recU7PNYXcsF6oekJ",
    order: "0977",
    dexNo: "977",
    size: "medium",
    name: "gholdengo",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/gholdengo.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/977.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/gholdengo.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/977.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/gholdengo.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/977.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/gholdengo.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/977.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Great Tusk",
    value: "recayP2qdVPrfjPl1",
    order: "0978",
    dexNo: "978",
    size: "medium",
    name: "great-tusk",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/great-tusk.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/978.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/great-tusk.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/978.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/great-tusk.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/978.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/great-tusk.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/978.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Brute Bonnet",
    value: "recwWQbhSkX7ruIfr",
    order: "0979",
    dexNo: "979",
    size: "medium",
    name: "brute-bonnet",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/brute-bonnet.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/979.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/brute-bonnet.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/979.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/brute-bonnet.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/979.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/brute-bonnet.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/979.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ancient Suicune",
    value: "recYW58JSWYTz7hCJ",
    order: "0980",
    dexNo: "980",
    size: "medium",
    name: "ancient-suicune",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ancient-suicune.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/980.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ancient-suicune.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/980.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ancient-suicune.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/980.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ancient-suicune.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/980.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Sandy Shocks",
    value: "recg8rAROaZ4R2tLL",
    order: "0981",
    dexNo: "981",
    size: "medium",
    name: "sandy-shocks",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/sandy-shocks.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/981.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/sandy-shocks.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/981.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/sandy-shocks.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/981.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/sandy-shocks.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/981.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Scream Tail",
    value: "recifTfLFwTJITOQO",
    order: "0982",
    dexNo: "982",
    size: "medium",
    name: "scream-tail",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/scream-tail.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/982.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/scream-tail.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/982.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/scream-tail.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/982.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/scream-tail.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/982.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Flutter Mane",
    value: "rec9fGoAonioPDLpT",
    order: "0983",
    dexNo: "983",
    size: "medium",
    name: "flutter-mane",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/flutter-mane.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/983.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/flutter-mane.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/983.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/flutter-mane.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/983.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/flutter-mane.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/983.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Slither Wing",
    value: "recOyi8Iv9BqGIEdi",
    order: "0984",
    dexNo: "984",
    size: "medium",
    name: "slither-wing",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/slither-wing.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/984.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/slither-wing.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/984.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/slither-wing.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/984.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/slither-wing.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/984.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Roaring Moon",
    value: "recgPIawZoWa2XWl6",
    order: "0985",
    dexNo: "985",
    size: "medium",
    name: "roaring-moon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/roaring-moon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/985.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/roaring-moon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/985.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/roaring-moon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/985.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/roaring-moon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/985.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Iron Treads",
    value: "recDHSS5sh6jdYS47",
    order: "0986",
    dexNo: "986",
    size: "medium",
    name: "iron-treads",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/iron-treads.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/986.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/iron-treads.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/986.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/iron-treads.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/986.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/iron-treads.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/986.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Future Virizion",
    value: "recck3eoLVlZsg3XO",
    order: "0987",
    dexNo: "987",
    size: "medium",
    name: "future-virizion",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/future-virizion.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/987.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/future-virizion.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/987.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/future-virizion.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/987.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/future-virizion.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/987.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Iron Moth",
    value: "rec1jkE0j3wspzXak",
    order: "0988",
    dexNo: "988",
    size: "medium",
    name: "iron-moth",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/iron-moth.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/988.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/iron-moth.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/988.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/iron-moth.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/988.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/iron-moth.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/988.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Iron Hands",
    value: "recSsRbQZL4HV8ovQ",
    order: "0989",
    dexNo: "989",
    size: "medium",
    name: "iron-hands",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/iron-hands.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/989.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/iron-hands.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/989.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/iron-hands.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/989.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/iron-hands.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/989.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Iron Jugulis",
    value: "reckjcRoED925LoL2",
    order: "0990",
    dexNo: "990",
    size: "medium",
    name: "iron-jugulis",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/iron-jugulis.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/990.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/iron-jugulis.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/990.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/iron-jugulis.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/990.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/iron-jugulis.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/990.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Iron Thorns",
    value: "recprfb5H2QlqtrwK",
    order: "0991",
    dexNo: "991",
    size: "medium",
    name: "iron-thorns",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/iron-thorns.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/991.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/iron-thorns.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/991.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/iron-thorns.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/991.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/iron-thorns.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/991.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Iron Bundle",
    value: "recqL6jlWhVt3C7NJ",
    order: "0992",
    dexNo: "992",
    size: "medium",
    name: "iron-bundle",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/iron-bundle.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/992.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/iron-bundle.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/992.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/iron-bundle.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/992.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/iron-bundle.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/992.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Iron Valiant",
    value: "reci0x53os25zDCfx",
    order: "0993",
    dexNo: "993",
    size: "medium",
    name: "iron-valiant",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/iron-valiant.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/993.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/iron-valiant.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/993.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/iron-valiant.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/993.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/iron-valiant.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/993.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ting-Lu",
    value: "recoeGMenKl6FAhiM",
    order: "0994",
    dexNo: "994",
    size: "medium",
    name: "ting-lu",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ting-lu.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/994.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ting-lu.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/994.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ting-lu.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/994.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ting-lu.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/994.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Chien-Pao",
    value: "recFb1C2jAq8MBOUY",
    order: "0995",
    dexNo: "995",
    size: "medium",
    name: "chien-pao",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/chien-pao.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/995.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/chien-pao.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/995.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/chien-pao.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/995.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/chien-pao.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/995.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Wo-Chien",
    value: "recnsVpcmHdvwVrHP",
    order: "0996",
    dexNo: "996",
    size: "medium",
    name: "wo-chien",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/wo-chien.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/996.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/wo-chien.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/996.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/wo-chien.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/996.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/wo-chien.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/996.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },

  {
    label: "Chi-Yu",
    value: "recKjfH4Cp1ibNtGM",
    order: "0997",
    dexNo: "997",
    size: "medium",
    name: "chi-yu",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/chi-yu.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/997.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/chi-yu.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/997.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/chi-yu.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/997.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/chi-yu.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/997.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Koraidon",
    value: "recrdq3nxD9PNU7M8",
    order: "0998",
    dexNo: "998",
    size: "medium",
    name: "koraidon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/koraidon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/998.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/koraidon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/998.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/koraidon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/998.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/koraidon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/998.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Miraidon",
    value: "rec1VedLZWbFZzDu2",
    order: "0999",
    dexNo: "999",
    size: "medium",
    name: "moraidon",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/moraidon.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/999.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/moraidon.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/999.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/moraidon.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/999.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/moraidon.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/999.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tinkatink",
    value: "rec5EmK4PZxcrdwxP",
    order: "1000",
    dexNo: "1000",
    size: "medium",
    name: "tinkatink",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tinkatink.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/1000.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tinkatink.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/1000.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tinkatink.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/1000.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tinkatink.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/1000.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tinkatuff",
    value: "rec97fFOaSepNLAKI",
    order: "1001",
    dexNo: "1001",
    size: "medium",
    name: "tinkatuff",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tinkatuff.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/1001.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tinkatuff.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/1001.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tinkatuff.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/1001.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tinkatuff.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/1001.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Tinkaton",
    value: "recCYGdnazJhKiKAK",
    order: "1002",
    dexNo: "1002",
    size: "medium",
    name: "tinkaton",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/tinkaton.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/1002.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/tinkaton.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/1002.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/tinkaton.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/1002.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/tinkaton.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/1002.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Charcadet",
    value: "recHFRTjt0znt2LUW",
    order: "1003",
    dexNo: "1003",
    size: "medium",
    name: "charcadet",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/charcadet.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/1003.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/charcadet.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/1003.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/charcadet.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/1003.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/charcadet.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/1003.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Armarouge",
    value: "recDe1f5ANifKqoCg",
    order: "1004",
    dexNo: "1004",
    size: "medium",
    name: "armarouge",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/armarouge.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/1004.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/armarouge.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/1004.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/armarouge.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/1004.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/armarouge.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/1004.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Ceruledge",
    value: "recbqHtKUsaHH8YWa",
    order: "1005",
    dexNo: "1005",
    size: "medium",
    name: "ceruledge",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/ceruledge.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/1005.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/ceruledge.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/1005.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/ceruledge.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/1005.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/ceruledge.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/1005.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Toedscool",
    value: "recuCJ9SIYwQB2SX1",
    order: "1006",
    dexNo: "1006",
    size: "medium",
    name: "toedscool",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/toedscool.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/1006.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/toedscool.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/1006.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/toedscool.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/1006.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/toedscool.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/1006.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Toedscruel",
    value: "rec24tIYoYDKubweY",
    order: "1007",
    dexNo: "1007",
    size: "medium",
    name: "toedscruel",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/toedscruel.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/1007.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/toedscruel.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/1007.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/toedscruel.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/1007.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/toedscruel.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/1007.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Kingambit",
    value: "recuy3Y9fIDrYH3FY",
    order: "1008",
    dexNo: "1008",
    size: "medium",
    name: "kingambit",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/kingambit.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/1008.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/kingambit.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/1008.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/kingambit.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/1008.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/kingambit.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/1008.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Clodsire",
    value: "recOlRwgh4Fi7bZAy",
    order: "1009",
    dexNo: "1009",
    size: "medium",
    name: "clodsire",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/clodsire.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/1009.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/clodsire.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/1009.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/clodsire.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/1009.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/clodsire.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/1009.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  },
  {
    label: "Annihilape",
    value: "rec7FPeOThktYi51F",
    order: "1010",
    dexNo: "1010",
    size: "medium",
    name: "annihilape",
    form: "",
    normalAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/normal/annihilape.gif",
    normalAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/normal/1010.gif",
    normalStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/normal/annihilape.png",
    normalStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/normal/1010.png",
    shinyAnimated:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated/shiny/annihilape.gif",
    shinyAnimatedHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/animated-hd/shiny/1010.gif",
    shinyStatic:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static/shiny/annihilape.png",
    shinyStaticHD:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/pokemon/static-hd/shiny/1010.png",
    games: "",
    altForms: "",
    shinyColors: "",
    shinyLocked: ""
  }
];
