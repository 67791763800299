import { ScaledSize } from "react-native";
import { DefaultTheme } from "styled-components/native";

export type DimensionsType = {
  window: ScaledSize;
  screen: ScaledSize;
};

export type BreakpointProps = {
  dimensions: DimensionsType;
  theme: DefaultTheme;
};

const next = (
  bp: keyof DefaultTheme["breaks"]
): keyof DefaultTheme["breaks"] | null => {
  switch (bp) {
    case "xlarge":
      return null;
    case "large":
      return "xlarge";
    case "medium":
      return "large";
    case "small":
      return "medium";
  }
};

export const getBp = ({
  dimensions,
  theme
}: BreakpointProps): keyof DefaultTheme["breaks"] | null => {
  const width = dimensions.window.width;

  if (width > theme.breaks.xlarge) {
    return "xlarge";
  } else if (width > theme.breaks.large) {
    return "large";
  } else if (width > theme.breaks.medium) {
    return "medium";
  } else if (width > theme.breaks.small) {
    return "small";
  } else {
    return null;
  }
};

export const is = (
  { dimensions, theme }: BreakpointProps,
  bp: keyof DefaultTheme["breaks"]
) => {
  const nbp = next(bp);
  const nwidth = nbp && theme.breaks[nbp];
  const width = dimensions.window.width;

  return width >= theme.breaks[bp] && (!nwidth || width < nwidth);
};

export const lte = (
  { dimensions, theme }: BreakpointProps,
  bp: keyof DefaultTheme["breaks"]
) => {
  const nbp = next(bp);
  const nwidth = nbp && theme.breaks[nbp];
  const width = dimensions.window.width;

  return !nwidth || width < nwidth;
};

export const lt = (
  { dimensions, theme }: BreakpointProps,
  bp: keyof DefaultTheme["breaks"]
) => {
  const width = dimensions.window.width;
  return width < theme.breaks[bp];
};

export const gte = (
  { dimensions, theme }: BreakpointProps,
  bp: keyof DefaultTheme["breaks"]
) => {
  const width = dimensions.window.width;
  return width >= theme.breaks[bp];
};

export const gt = (
  { dimensions, theme }: BreakpointProps,
  bp: keyof DefaultTheme["breaks"]
) => {
  const nbp = next(bp);
  const nwidth = nbp && theme.breaks[nbp];
  const width = dimensions.window.width;

  return !nwidth || width >= nwidth;
};
