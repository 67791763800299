import { HuntSetting } from "../model/model";

export type MethodOption = {
  value: HuntSetting;
  image: string;
  label: string;
  description: string;
};

export const HUNT_SETTING_LABELS = {
  [HuntSetting.SHINY_CHARM]: "Shiny Charm",
  [HuntSetting.LURE]: "Lure",
  [HuntSetting.COMMUNITY_DAY]: "Community Day",
  [HuntSetting.MASUDA]: "Masuda Parents",
  [HuntSetting.DIGLETT]: "Diglett Bonus",
  [HuntSetting.SPARKLING_POWER_3]: "Sparkling Power Lv. 3"
};

export const METHOD_OPTIONS: MethodOption[] = [
  {
    value: HuntSetting.SHINY_CHARM,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/shiny-charm.png",
    label: HUNT_SETTING_LABELS[HuntSetting.SHINY_CHARM],
    description:
      "The Shiny Charm is a Key Item introduced in Pokémon Black 2 and White 2. Once obtained, typically by completing the national dex, it effectively triples the chance of encountering Shiny Pokémon compared to the base chance. This stacks with most other modifiers."
  },
  {
    value: HuntSetting.LURE,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/lure.png",
    label: HUNT_SETTING_LABELS[HuntSetting.LURE],
    description:
      "Lures are a type of item introduced in Pokémon Let's Go, Pikachu! and Let's Go, Eevee! When used from the Bag in the overworld, they increase encounter rates of rarer wild Pokémon, including Shiny Pokémon, for 100 to 250 steps."
  },
  {
    value: HuntSetting.COMMUNITY_DAY,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/community-day.png",
    label: HUNT_SETTING_LABELS[HuntSetting.COMMUNITY_DAY],
    description:
      "Community Days in Pokémon GO are designed to get people out and playing together at a certain time. Each limited-time event offers featured Pokémon with a unique and exclusive move and significantly increased shiny rates that will spawn for three hours."
  },
  {
    value: HuntSetting.MASUDA,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/masuda.jpg",
    label: HUNT_SETTING_LABELS[HuntSetting.MASUDA],
    description:
      "Named after the game developer who revealed it, Junichi Masuda, the chances of hatching a shiny increase when you breed two Pokémon together from different nationalities (such as a Japanese Pokémon on an English game)."
  },
  {
    value: HuntSetting.DIGLETT,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/diglett-bonus.png",
    label: HUNT_SETTING_LABELS[HuntSetting.DIGLETT],
    description:
      "Throughout the Underground, you will see various Diglett and Dugtrio at junctions and corners. They will retreat before you can get to them but will leave some energy; 1 Point for Diglett and 3 points for Dugtrio. If you collect 40 points in one session, then you will get a small bonus. For 4 minutes, you will have a slightly increased chance of encountering a Shiny Pokémon in the Underground."
  },
  {
    value: HuntSetting.SPARKLING_POWER_3,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/sparkling-power.png",
    label: HUNT_SETTING_LABELS[HuntSetting.SPARKLING_POWER_3],
    description:
      "Sandwich bonuses do not affect Tera Raids or eggs (except for Egg Power). It's worth combining Sparkling Power with Encounter Power for wild encounters!"
  }
];

export type PokeballOption = {
  label: string;
  value: number;
  id: string;
  name: string;
  image: string;
  games: string;
};

export const POKEBALLS: PokeballOption[] = [
  {
    label: "Beast Ball",
    value: 1,
    id: "recT0EZjwzE7rucVb",
    name: "beastball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/beastball.png",
    games:
      "reciLmuKKqJYIFqFk, recFMiXX23jdCTisU, recXjj05I3Hq81DJn, recqUq4K5O0BFfTfJ, recpE4t7pbOTQVj5m, rect7M6DAn9YelHv7"
  },
  {
    label: "Cherish Ball",
    value: 2,
    id: "recqyuBAorzW3zEtQ",
    name: "cherishball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/cherishball.png",
    games: ""
  },
  {
    label: "Dive Ball",
    value: 3,
    id: "recfy8XB3S9W7qN9b",
    name: "diveball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/diveball.png",
    games: ""
  },
  {
    label: "Dream Ball",
    value: 4,
    id: "recoXxSMBgF7aNiQL",
    name: "dreamball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/dreamball.png",
    games: ""
  },
  {
    label: "Dusk Ball",
    value: 5,
    id: "recKuTUCCtPmWlS84",
    name: "duskball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/duskball.png",
    games: ""
  },
  {
    label: "Fast Ball",
    value: 6,
    id: "recAkwRtSYRYlyihM",
    name: "fastball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/fastball.png",
    games: ""
  },
  {
    label: "Friend Ball",
    value: 7,
    id: "recRCqvoFs9GD65x5",
    name: "friendball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/friendball.png",
    games: ""
  },
  {
    label: "Great Ball",
    value: 8,
    id: "recKB9MGbbATkGHTC",
    name: "greatball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/greatball.png",
    games: ""
  },
  {
    label: "Heal Ball",
    value: 9,
    id: "reczVXoFZ718Q0Uep",
    name: "healball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/healball.png",
    games: ""
  },
  {
    label: "Heavy Ball",
    value: 10,
    id: "recDfu2yt9oS63NKT",
    name: "heavyball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/heavyball.png",
    games: ""
  },
  {
    label: "Level Ball",
    value: 11,
    id: "rec4qjsEYSiamimc6",
    name: "levelball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/levelball.png",
    games: ""
  },
  {
    label: "Love Ball",
    value: 12,
    id: "recUpBJK0cPITbIa9",
    name: "loveball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/loveball.png",
    games: ""
  },
  {
    label: "Lure Ball",
    value: 13,
    id: "rec9fluedFMjgt4rf",
    name: "lureball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/lureball.png",
    games: ""
  },
  {
    label: "Luxury Ball",
    value: 14,
    id: "rec2hE3aMckrIFSzh",
    name: "luxuryball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/luxuryball.png",
    games: ""
  },
  {
    label: "Master Ball",
    value: 15,
    id: "recLJfcfxZZtf46V6",
    name: "masterball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/masterball.png",
    games: ""
  },
  {
    label: "Moon Ball",
    value: 16,
    id: "rect5FrHaC3AeMM9Y",
    name: "moonball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/moonball.png",
    games: ""
  },
  {
    label: "Nest Ball",
    value: 17,
    id: "recb78u5M1c9DwD43",
    name: "nestball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/nestball.png",
    games: ""
  },
  {
    label: "Net Ball",
    value: 18,
    id: "recJDvuH6Z11AEeis",
    name: "netball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/netball.png",
    games: ""
  },
  {
    label: "Park Ball",
    value: 19,
    id: "recCOpw23zLnsjWAl",
    name: "parkball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/parkball.png",
    games: ""
  },
  {
    label: "Poké Ball",
    value: 20,
    id: "rec6BZVELhILnuGML",
    name: "pokeball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/pokeball.png",
    games: ""
  },
  {
    label: "Premier Ball",
    value: 21,
    id: "recu5Lsw5k3o1fi5I",
    name: "premierball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/premierball.png",
    games: ""
  },
  {
    label: "Quick Ball",
    value: 22,
    id: "recAIFlbhgTwWMMtT",
    name: "quickball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/quickball.png",
    games: ""
  },
  {
    label: "Repeat Ball",
    value: 23,
    id: "recOm7j1ktT9iKlNb",
    name: "repeatball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/repeatball.png",
    games: ""
  },
  {
    label: "Safari Ball",
    value: 24,
    id: "rec07VpxiwYispQFF",
    name: "safariball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/safariball.png",
    games: ""
  },
  {
    label: "Sport Ball",
    value: 25,
    id: "recNRLbphslBoQkYV",
    name: "sportball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/sportball.png",
    games: ""
  },
  {
    label: "Timer Ball",
    value: 26,
    id: "recAJfQHb8Mr1AmgD",
    name: "timerball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/timerball.png",
    games: ""
  },
  {
    label: "Ultra Ball",
    value: 27,
    id: "recZK9iN9ABllYVxW",
    name: "ultraball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/ultraball.png",
    games: ""
  },
  {
    label: "Feather Ball",
    value: 28,
    id: "recoS9lLKmjsJwIen",
    name: "featherball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/featherball.png",
    games: ""
  },
  {
    label: "Great Ball Legends",
    value: 29,
    id: "recFpY0m3gfmY6ioP",
    name: "greatballlegends",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/greatballlegends.png",
    games: ""
  },
  {
    label: "Gigaton Ball",
    value: 30,
    id: "recVj15Ygdf5mkhPe",
    name: "gigatonball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/gigatonball.png",
    games: ""
  },
  {
    label: "Heavy Ball Legends",
    value: 31,
    id: "reciOLKA156P1df9f",
    name: "heavyballlegends",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/heavyballlegends.png",
    games: ""
  },
  {
    label: "Jet Ball",
    value: 32,
    id: "reciHxtfxEfeV1GcB",
    name: "jetball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/jetball.png",
    games: ""
  },
  {
    label: "Leaden Ball",
    value: 33,
    id: "reca9HbpDdBpHvfa6",
    name: "leadenball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/leadenball.png",
    games: ""
  },
  {
    label: "Origin Ball",
    value: 34,
    id: "rechcp2LkpAkIcsjw",
    name: "originball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/originball.png",
    games: ""
  },
  {
    label: "Poké Ball Legends",
    value: 35,
    id: "recT0NrmEYCiq36uC",
    name: "pokeballlegends",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/pokeballlegends.png",
    games: ""
  },
  {
    label: "Ultra Ball Legends",
    value: 36,
    id: "recdqc2qszPu1UFOl",
    name: "ultraballlegends",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/ultraballlegends.png",
    games: ""
  },
  {
    label: "Wing Ball",
    value: 37,
    id: "recfcJm6Sd43r9TBJ",
    name: "wingball",
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/items/wingball.png",
    games: ""
  }
];

export type Gender = {
  value: string;
  label: string;
};

export const GENDERS: Gender[] = [
  {
    value: "male",
    label: "Male"
  },
  {
    value: "female",
    label: "Female"
  },
  {
    value: "genderless",
    label: "Genderless"
  }
];
