import styledWeb from "styled-components";
import WebDatePicker from "react-datepicker";
import { useMemo } from "react";
import { useTheme, DefaultTheme } from "styled-components/native";
import "react-datepicker/dist/react-datepicker.css";
import { textDefaults } from "../Text";

export const StyledWebDatePicker = styledWeb(WebDatePicker)<{
  theme: DefaultTheme;
}>`
  width: inherit;
  height: 38px;
  padding: 2px 8px;
  border: ${props =>
    props.selected
      ? `2px solid ${props.theme.colors.success}`
      : `2px solid ${props.theme.colors.primary}`};
  border-radius: 4px;
  background: ${props => props.theme.colors.surface};
  ${props => textDefaults(props.theme)}

  &:focus {
    border: ${props => `2px solid ${props.theme.colors.primaryDark}`};
  }

  .react-datepicker-wrapper {
    display: flex;
  }
`;

export type DatePickerProps = {
  onChange: (date: Date) => void;
  value?: Date;
};

export const DatePicker = ({ value, onChange }: DatePickerProps) => {
  const initDate = useMemo(() => new Date(), []);
  const theme = useTheme();

  const handleChange = (date: any) => {
    if (date && date instanceof Date) {
      onChange(date);
    }
  };

  return (
    <StyledWebDatePicker
      theme={theme}
      selected={value}
      onChange={handleChange}
    />
  );
};
