import { useNavigation } from "@react-navigation/native";
import React, { useRef } from "react";
import { PressableProps } from "react-native";
import { useActive, useHover } from "react-native-web-hooks";
import styled from "styled-components/native";
import styledWeb from "styled-components";
import { Body, StyledTextProps } from "../Text";
import { RootStackParamList } from "../../navTypes";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

const StyledLink = styled.Pressable<{ isHovered: boolean; isActive: boolean }>`
  ${({ isHovered, isActive, theme }) =>
    isHovered || isActive ? `text-decoration: underline;` : ``}
`;

const StyledWebLink = styledWeb.a`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Link: React.FC<
  PressableProps & {
    route?: keyof RootStackParamList;
    href?: string;
    textProps?: StyledTextProps;
  }
> = props => {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const isActive = useActive(ref);
  const nav = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

  const { href, route, children, textProps } = props;

  if (href) {
    return (
      <StyledWebLink ref={ref} href={href} target="_blank">
        <Body {...textProps}>{children}</Body>
      </StyledWebLink>
    );
  }

  const onPress = () => {
    if (route) {
      nav.navigate(route);
    }
  };

  return (
    <StyledLink
      {...props}
      isHovered={isHovered}
      isActive={isActive}
      ref={ref}
      onPress={onPress}
    >
      <Body {...textProps}>{children}</Body>
    </StyledLink>
  );
};
