export type Trainer = {
  value: string;
  label: string;
  access: string;
  variant: string;
  fileName: string;
  avatar: string;
  avatarFull: string;
  generation: string;
};

export const TRAINERS: Trainer[] = [
  {
    value: "recgIjfDsWoubNjSl",
    label: "Aaron",
    access: "free",
    variant: "",
    fileName: "aaron.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/aaron.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/aaron.png",
    generation: ""
  },
  {
    value: "recRvQhuvROyWdBfk",
    label: "Aaron",
    access: "premium",
    variant: "EX",
    fileName: "aaron2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/aaron2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/aaron2.png",
    generation: ""
  },
  {
    value: "recygp8wbNXuEJQND",
    label: "Acerola",
    access: "free",
    variant: "",
    fileName: "acerola.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/acerola.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/acerola.png",
    generation: ""
  },
  {
    value: "recjCRVNpvMQnD1Cb",
    label: "Acerola",
    access: "premium",
    variant: "Fall 2020",
    fileName: "acerola-fall2020.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/acerola-fall2020.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/acerola-fall2020.png",
    generation: ""
  },
  {
    value: "recoNs6txVmOqvYoP",
    label: "Acerola",
    access: "premium",
    variant: "Fall 2020 EX",
    fileName: "acerola-fall20202.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/acerola-fall20202.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/acerola-fall20202.png",
    generation: ""
  },
  {
    value: "recB96Py7UGKHE7Q9",
    label: "Agatha",
    access: "free",
    variant: "",
    fileName: "agatha.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/agatha.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/agatha.png",
    generation: ""
  },
  {
    value: "rec3biXQHACRVdWc8",
    label: "Alder",
    access: "free",
    variant: "",
    fileName: "alder.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/alder.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/alder.png",
    generation: ""
  },
  {
    value: "recHb622tOOm0lqKV",
    label: "Alder",
    access: "premium",
    variant: "EX",
    fileName: "alder2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/alder2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/alder2.png",
    generation: ""
  },
  {
    value: "recXC6euD1RVxZEgh",
    label: "Allister",
    access: "free",
    variant: "",
    fileName: "allister.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/allister.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/allister.png",
    generation: ""
  },
  {
    value: "recYAaHBqU2gWNTfB",
    label: "Allister",
    access: "premium",
    variant: "EX",
    fileName: "allister2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/allister2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/allister2.png",
    generation: ""
  },
  {
    value: "recwZUk6s4b4mz4B5",
    label: "Allister",
    access: "premium",
    variant: "Fall 2022",
    fileName: "allister-fall2022.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/allister-fall2022.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/allister-fall2022.png",
    generation: ""
  },
  {
    value: "recfcCj2AdFW3gkp3",
    label: "Allister",
    access: "premium",
    variant: "Fall 2022 EX",
    fileName: "allister-fall20222.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/allister-fall20222.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/allister-fall20222.png",
    generation: ""
  },
  {
    value: "recaOIJ3LS20TL8bE",
    label: "Archer",
    access: "free",
    variant: "",
    fileName: "archer.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/archer.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/archer.png",
    generation: ""
  },
  {
    value: "recpfVFZuJXWyhdZx",
    label: "Archie",
    access: "free",
    variant: "",
    fileName: "archie.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/archie.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/archie.png",
    generation: ""
  },
  {
    value: "reciLy5Ohdu8BC5UV",
    label: "Archie",
    access: "premium",
    variant: "EX",
    fileName: "archie2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/archie2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/archie2.png",
    generation: ""
  },
  {
    value: "recEkHMr8Rjcq9RnQ",
    label: "Ariana",
    access: "free",
    variant: "",
    fileName: "ariana.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/ariana.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/ariana.png",
    generation: ""
  },
  {
    value: "recnz6yUm2fU6DPOz",
    label: "Ash",
    access: "free",
    variant: "",
    fileName: "ash.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/ash.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/ash.png",
    generation: ""
  },
  {
    value: "recjhxVTmStptU2VT",
    label: "Ash",
    access: "premium",
    variant: "EX",
    fileName: "ash2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/ash2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/ash2.png",
    generation: ""
  },
  {
    value: "recJb3vkrkeE5DIDk",
    label: "Barry",
    access: "free",
    variant: "",
    fileName: "barry.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/barry.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/barry.png",
    generation: ""
  },
  {
    value: "rec0ey5q1zYXCbphm",
    label: "Barry",
    access: "premium",
    variant: "EX",
    fileName: "barry2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/barry2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/barry2.png",
    generation: ""
  },
  {
    value: "recC9qj1O4eNjHjof",
    label: "Bea",
    access: "free",
    variant: "",
    fileName: "bea.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/bea.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/bea.png",
    generation: ""
  },
  {
    value: "recgXuIb6gABFqb8S",
    label: "Bea",
    access: "premium",
    variant: "Palentines",
    fileName: "bea-palentines.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/bea-palentines.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/bea-palentines.png",
    generation: ""
  },
  {
    value: "recsJcAJJPCKcru0K",
    label: "Bea",
    access: "premium",
    variant: "Palentines EX",
    fileName: "bea-palentines2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/bea-palentines2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/bea-palentines2.png",
    generation: ""
  },
  {
    value: "recMflaEB0iPDP7sY",
    label: "Bede",
    access: "free",
    variant: "",
    fileName: "bede.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/bede.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/bede.png",
    generation: ""
  },
  {
    value: "recL7eDvXBUMAJ7zd",
    label: "Bede",
    access: "premium",
    variant: "EX",
    fileName: "bede2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/bede2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/bede2.png",
    generation: ""
  },
  {
    value: "recwZPFKJR4ilZCGY",
    label: "Bertha",
    access: "free",
    variant: "",
    fileName: "bertha.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/bertha.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/bertha.png",
    generation: ""
  },
  {
    value: "rec792XcZlHoi3J8h",
    label: "Bertha",
    access: "premium",
    variant: "EX",
    fileName: "bertha2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/bertha2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/bertha2.png",
    generation: ""
  },
  {
    value: "recgU7VBshdT7MLID",
    label: "Bianca",
    access: "free",
    variant: "",
    fileName: "bianca.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/bianca.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/bianca.png",
    generation: ""
  },
  {
    value: "reckAJi9Lyixso01Q",
    label: "Bianca",
    access: "premium",
    variant: "EX",
    fileName: "bianca2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/bianca2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/bianca2.png",
    generation: ""
  },
  {
    value: "rec1miyk9yy3nxUhN",
    label: "Blaine",
    access: "free",
    variant: "",
    fileName: "blaine.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/blaine.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/blaine.png",
    generation: ""
  },
  {
    value: "rec2owoey8d6HIkqu",
    label: "Blue",
    access: "free",
    variant: "",
    fileName: "blue.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/blue.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/blue.png",
    generation: ""
  },
  {
    value: "recweuwahIlYvFSjG",
    label: "Blue",
    access: "premium",
    variant: "EX",
    fileName: "blue2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/blue2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/blue2.png",
    generation: ""
  },
  {
    value: "recVKSTgX3Lq6ZsVm",
    label: "Blue",
    access: "premium",
    variant: "Arcanine EX",
    fileName: "blue-arcanine2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/blue-arcanine2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/blue-arcanine2.png",
    generation: ""
  },
  {
    value: "recH5muwfekcuesf7",
    label: "Blue",
    access: "premium",
    variant: "Classic",
    fileName: "blue-classic.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/blue-classic.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/blue-classic.png",
    generation: ""
  },
  {
    value: "rec4NMwB71sKVvOHp",
    label: "Blue",
    access: "premium",
    variant: "Classic EX",
    fileName: "blue-classic2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/blue-classic2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/blue-classic2.png",
    generation: ""
  },
  {
    value: "recidx8RKJoGrbxgO",
    label: "Blue",
    access: "premium",
    variant: "Sygna",
    fileName: "blue-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/blue-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/blue-sygna.png",
    generation: ""
  },
  {
    value: "reccOF7MJmxK2AfNx",
    label: "Blue",
    access: "premium",
    variant: "Sygna EX",
    fileName: "blue-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/blue-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/blue-sygna2.png",
    generation: ""
  },
  {
    value: "rec2KZinPYcEGV2iF",
    label: "Brawly",
    access: "free",
    variant: "",
    fileName: "brawly.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/brawly.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/brawly.png",
    generation: ""
  },
  {
    value: "recKE0FZKwlTMgh8G",
    label: "Brendan",
    access: "free",
    variant: "",
    fileName: "brendan.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/brendan.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/brendan.png",
    generation: ""
  },
  {
    value: "rec0ZnzRiMhn60AWn",
    label: "Brendan",
    access: "premium",
    variant: "EX",
    fileName: "brendan2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/brendan2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/brendan2.png",
    generation: ""
  },
  {
    value: "recOuhKWF8my31mAP",
    label: "Brendan",
    access: "premium",
    variant: "Sygna",
    fileName: "brendan-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/brendan-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/brendan-sygna.png",
    generation: ""
  },
  {
    value: "recq7AvSxIrm2qJD6",
    label: "Brendan",
    access: "premium",
    variant: "Sygna EX",
    fileName: "brendan-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/brendan-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/brendan-sygna2.png",
    generation: ""
  },
  {
    value: "recPow4RS6aZuakva",
    label: "Brock",
    access: "free",
    variant: "",
    fileName: "brock.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/brock.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/brock.png",
    generation: ""
  },
  {
    value: "rec71QHMP0keeWBwS",
    label: "Brock",
    access: "premium",
    variant: "Sygna",
    fileName: "brock-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/brock-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/brock-sygna.png",
    generation: ""
  },
  {
    value: "recMdH0Ppyo2j6hUA",
    label: "Brock",
    access: "premium",
    variant: "Sygna EX",
    fileName: "brock-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/brock-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/brock-sygna2.png",
    generation: ""
  },
  {
    value: "recsLR5ygry0ogKpX",
    label: "Bruno",
    access: "free",
    variant: "",
    fileName: "bruno.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/bruno.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/bruno.png",
    generation: ""
  },
  {
    value: "rec57w7nABuVmuOkI",
    label: "Brycen",
    access: "free",
    variant: "",
    fileName: "brycen.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/brycen.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/brycen.png",
    generation: ""
  },
  {
    value: "recUV04EKXMPLG9rg",
    label: "Bugsy",
    access: "free",
    variant: "",
    fileName: "bugsy.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/bugsy.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/bugsy.png",
    generation: ""
  },
  {
    value: "recYfkiQaVf5JNR26",
    label: "Burgh",
    access: "free",
    variant: "",
    fileName: "burgh.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/burgh.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/burgh.png",
    generation: ""
  },
  {
    value: "recmb5grrWqItREaq",
    label: "Burgh",
    access: "premium",
    variant: "EX",
    fileName: "burgh2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/burgh2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/burgh2.png",
    generation: ""
  },
  {
    value: "recYlV2SWI1KFxJLK",
    label: "Burgh",
    access: "premium",
    variant: "Spring 2021",
    fileName: "burgh-spring2021.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/burgh-spring2021.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/burgh-spring2021.png",
    generation: ""
  },
  {
    value: "recY5q0SWYhu1l3FW",
    label: "Burgh",
    access: "premium",
    variant: "Spring 2021 EX",
    fileName: "burgh-spring20212.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/burgh-spring20212.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/burgh-spring20212.png",
    generation: ""
  },
  {
    value: "recHbSZLvxrX0giid",
    label: "Caitlin",
    access: "free",
    variant: "",
    fileName: "caitlin.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/caitlin.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/caitlin.png",
    generation: ""
  },
  {
    value: "rechYFFY85IEhnp97",
    label: "Caitlin",
    access: "premium",
    variant: "EX",
    fileName: "caitlin2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/caitlin2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/caitlin2.png",
    generation: ""
  },
  {
    value: "recEs9Z7RfF3nSN4G",
    label: "Caitlin",
    access: "premium",
    variant: "Fall 2021",
    fileName: "caitlin-fall2021.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/caitlin-fall2021.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/caitlin-fall2021.png",
    generation: ""
  },
  {
    value: "recClPdtnHvnY3cJG",
    label: "Caitlin",
    access: "premium",
    variant: "Fall 2021 EX",
    fileName: "caitlin-fall20212.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/caitlin-fall20212.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/caitlin-fall20212.png",
    generation: ""
  },
  {
    value: "recypJWSHNXGPLO70",
    label: "Calem",
    access: "free",
    variant: "",
    fileName: "calem.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/calem.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/calem.png",
    generation: ""
  },
  {
    value: "recXCLHWo7rJbRh4f",
    label: "Calem",
    access: "premium",
    variant: "EX",
    fileName: "calem2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/calem2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/calem2.png",
    generation: ""
  },
  {
    value: "recdQevKYraQkQGpU",
    label: "Candice",
    access: "free",
    variant: "",
    fileName: "candice.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/candice.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/candice.png",
    generation: ""
  },
  {
    value: "recKgQkBurpZ31YRO",
    label: "Candice",
    access: "premium",
    variant: "EX",
    fileName: "candice2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/candice2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/candice2.png",
    generation: ""
  },
  {
    value: "recoB53t3VTB1KoWO",
    label: "Cheren",
    access: "free",
    variant: "",
    fileName: "cheren.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/cheren.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/cheren.png",
    generation: ""
  },
  {
    value: "reczkL8U3IRkW6WG0",
    label: "Cheren",
    access: "premium",
    variant: "EX",
    fileName: "cheren2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/cheren2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/cheren2.png",
    generation: ""
  },
  {
    value: "recSFhDlIs5mOpdAL",
    label: "Cheryl",
    access: "free",
    variant: "",
    fileName: "cheryl.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/cheryl.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/cheryl.png",
    generation: ""
  },
  {
    value: "rec14vMRg0EBmxvT0",
    label: "Chili",
    access: "free",
    variant: "",
    fileName: "chili.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/chili.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/chili.png",
    generation: ""
  },
  {
    value: "recK4dJPGxM2rV9aw",
    label: "Chuck",
    access: "free",
    variant: "",
    fileName: "chuck.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/chuck.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/chuck.png",
    generation: ""
  },
  {
    value: "rec2wSWMsV796ac2p",
    label: "Cilan",
    access: "free",
    variant: "",
    fileName: "cilan.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/cilan.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/cilan.png",
    generation: ""
  },
  {
    value: "recJxDcWIn55YQzB3",
    label: "Clair",
    access: "free",
    variant: "",
    fileName: "clair.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/clair.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/clair.png",
    generation: ""
  },
  {
    value: "rec1hDznMXoxYRL1y",
    label: "Clay",
    access: "free",
    variant: "",
    fileName: "clay.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/clay.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/clay.png",
    generation: ""
  },
  {
    value: "recMa6SMgtypYPH5d",
    label: "Clemont",
    access: "free",
    variant: "",
    fileName: "clemont.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/clemont.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/clemont.png",
    generation: ""
  },
  {
    value: "recGmRaE3Nt8QmiBY",
    label: "Clemont",
    access: "premium",
    variant: "EX",
    fileName: "clemont2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/clemont2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/clemont2.png",
    generation: ""
  },
  {
    value: "reczkD9fcQp05vqY2",
    label: "Colress",
    access: "free",
    variant: "",
    fileName: "colress.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/colress.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/colress.png",
    generation: ""
  },
  {
    value: "recg4QEy7EsvF7DIa",
    label: "Colress",
    access: "premium",
    variant: "EX",
    fileName: "colress2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/colress2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/colress2.png",
    generation: ""
  },
  {
    value: "recYYukDWYtCZqecK",
    label: "Courtney",
    access: "free",
    variant: "",
    fileName: "courtney.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/courtney.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/courtney.png",
    generation: ""
  },
  {
    value: "recPa524yXuuRkrsH",
    label: "Courtney",
    access: "premium",
    variant: "EX",
    fileName: "courtney2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/courtney2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/courtney2.png",
    generation: ""
  },
  {
    value: "rec4q8QsGZEcATnnI",
    label: "Crasher Wake",
    access: "free",
    variant: "",
    fileName: "crasherwake.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/crasherwake.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/crasherwake.png",
    generation: ""
  },
  {
    value: "recBiqeuLSGEysF70",
    label: "Cress",
    access: "free",
    variant: "",
    fileName: "cress.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/cress.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/cress.png",
    generation: ""
  },
  {
    value: "reczjRxRbezRwsWYR",
    label: "Cynthia",
    access: "free",
    variant: "",
    fileName: "cynthia.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/cynthia.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/cynthia.png",
    generation: ""
  },
  {
    value: "recBeiwGHAHIdB7Vy",
    label: "Cynthia",
    access: "premium",
    variant: "EX",
    fileName: "cynthia2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/cynthia2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/cynthia2.png",
    generation: ""
  },
  {
    value: "recxpVBfiRuAQ0A5q",
    label: "Cynthia",
    access: "premium",
    variant: "Aura",
    fileName: "cynthia-aura.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/cynthia-aura.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/cynthia-aura.png",
    generation: ""
  },
  {
    value: "recyZYRmQchnEcWXl",
    label: "Cynthia",
    access: "premium",
    variant: "Aura EX",
    fileName: "cynthia-aura2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/cynthia-aura2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/cynthia-aura2.png",
    generation: ""
  },
  {
    value: "recMCo1PRqP75oTWJ",
    label: "Cynthia",
    access: "premium",
    variant: "Renegade",
    fileName: "cynthia-renegade.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/cynthia-renegade.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/cynthia-renegade.png",
    generation: ""
  },
  {
    value: "recZ6mCrmGqYzrrVR",
    label: "Cynthia",
    access: "premium",
    variant: "Renegade EX",
    fileName: "cynthia-renegade2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/cynthia-renegade2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/cynthia-renegade2.png",
    generation: ""
  },
  {
    value: "recPesfDESdI4L8xn",
    label: "Cynthia",
    access: "premium",
    variant: "Sygna",
    fileName: "cynthia-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/cynthia-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/cynthia-sygna.png",
    generation: ""
  },
  {
    value: "recZKqZi4bf9KNaGQ",
    label: "Cynthia",
    access: "premium",
    variant: "Sygna EX",
    fileName: "cynthia-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/cynthia-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/cynthia-sygna2.png",
    generation: ""
  },
  {
    value: "recL1l3VnCwrf7X7N",
    label: "Cyrus",
    access: "free",
    variant: "",
    fileName: "cyrus.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/cyrus.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/cyrus.png",
    generation: ""
  },
  {
    value: "recXXUTgbKwcTcyzU",
    label: "Cyrus",
    access: "premium",
    variant: "EX",
    fileName: "cyrus2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/cyrus2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/cyrus2.png",
    generation: ""
  },
  {
    value: "recnTUbIgvjbwaHlo",
    label: "Cyrus",
    access: "premium",
    variant: "Sygna",
    fileName: "cyrus-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/cyrus-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/cyrus-sygna.png",
    generation: ""
  },
  {
    value: "recVLrpIXjlK1I0G3",
    label: "Cyrus",
    access: "premium",
    variant: "Sygna EX",
    fileName: "cyrus-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/cyrus-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/cyrus-sygna2.png",
    generation: ""
  },
  {
    value: "recFfFFNL4XdPx2zx",
    label: "Darach",
    access: "free",
    variant: "",
    fileName: "darach.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/darach.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/darach.png",
    generation: ""
  },
  {
    value: "rec7sjpjD6TN4OayE",
    label: "Darach",
    access: "premium",
    variant: "EX",
    fileName: "darach2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/darach2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/darach2.png",
    generation: ""
  },
  {
    value: "recdRipYOTLV72teL",
    label: "Dawn",
    access: "free",
    variant: "",
    fileName: "dawn.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/dawn.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/dawn.png",
    generation: ""
  },
  {
    value: "recLMwBWbL1Gk0aDW",
    label: "Dawn",
    access: "premium",
    variant: "EX",
    fileName: "dawn2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/dawn2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/dawn2.png",
    generation: ""
  },
  {
    value: "recclXAAws3aHbVPc",
    label: "Dawn",
    access: "premium",
    variant: "Sygna",
    fileName: "dawn-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/dawn-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/dawn-sygna.png",
    generation: ""
  },
  {
    value: "recJgSkRiienWQvi4",
    label: "Dawn",
    access: "premium",
    variant: "Sygna EX",
    fileName: "dawn-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/dawn-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/dawn-sygna2.png",
    generation: ""
  },
  {
    value: "recDS5WCnX8KvWjS2",
    label: "Dawn",
    access: "premium",
    variant: "Valentines",
    fileName: "dawn-valentines.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/dawn-valentines.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/dawn-valentines.png",
    generation: ""
  },
  {
    value: "reck6lNEMPKhPPsm0",
    label: "Dawn",
    access: "premium",
    variant: "Valentines EX",
    fileName: "dawn-valentines2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/dawn-valentines2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/dawn-valentines2.png",
    generation: ""
  },
  {
    value: "recuapIL3yoeDVRuS",
    label: "Diantha",
    access: "free",
    variant: "",
    fileName: "diantha.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/diantha.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/diantha.png",
    generation: ""
  },
  {
    value: "rec6Idl1c2m2ry6RK",
    label: "Diantha",
    access: "premium",
    variant: "EX",
    fileName: "diantha2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/diantha2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/diantha2.png",
    generation: ""
  },
  {
    value: "recCCJRuSyBW9owWm",
    label: "Diantha",
    access: "premium",
    variant: "Special",
    fileName: "diantha-special.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/diantha-special.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/diantha-special.png",
    generation: ""
  },
  {
    value: "recnuEaIyt6nbd8B1",
    label: "Diantha",
    access: "premium",
    variant: "Special EX",
    fileName: "diantha-special2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/diantha-special2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/diantha-special2.png",
    generation: ""
  },
  {
    value: "recaCnqpB9RwYhq58",
    label: "Drake",
    access: "free",
    variant: "",
    fileName: "drake.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/drake.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/drake.png",
    generation: ""
  },
  {
    value: "recwh7l2PIFOMCbYv",
    label: "Drake",
    access: "premium",
    variant: "EX",
    fileName: "drake2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/drake2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/drake2.png",
    generation: ""
  },
  {
    value: "reck4tHqn1WFT40s9",
    label: "Elesa",
    access: "free",
    variant: "",
    fileName: "elesa.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/elesa.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/elesa.png",
    generation: ""
  },
  {
    value: "recAetm2BZ8cyrRN4",
    label: "Elesa",
    access: "premium",
    variant: "EX",
    fileName: "elesa2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/elesa2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/elesa2.png",
    generation: ""
  },
  {
    value: "reclQJEjImIgMcZXM",
    label: "Elesa",
    access: "premium",
    variant: "Original",
    fileName: "elesa-original.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/elesa-original.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/elesa-original.png",
    generation: ""
  },
  {
    value: "reciVkY81sw2gj2ZM",
    label: "Elesa",
    access: "premium",
    variant: "Original EX",
    fileName: "elesa-original2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/elesa-original2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/elesa-original2.png",
    generation: ""
  },
  {
    value: "recPv1vLrqboP5rYW",
    label: "Elesa",
    access: "premium",
    variant: "Sygna",
    fileName: "elesa-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/elesa-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/elesa-sygna.png",
    generation: ""
  },
  {
    value: "recaEfXr2gaBGoxNU",
    label: "Elesa",
    access: "premium",
    variant: "Sygna EX",
    fileName: "elesa-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/elesa-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/elesa-sygna2.png",
    generation: ""
  },
  {
    value: "rec0yfObBLaijuYOr",
    label: "Elio",
    access: "free",
    variant: "",
    fileName: "elio.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/elio.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/elio.png",
    generation: ""
  },
  {
    value: "recunZgVrn8VNkBay",
    label: "Elio",
    access: "premium",
    variant: "EX",
    fileName: "elio2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/elio2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/elio2.png",
    generation: ""
  },
  {
    value: "recLPSZw2eQKGy0Pq",
    label: "Emmet",
    access: "free",
    variant: "",
    fileName: "emmet.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/emmet.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/emmet.png",
    generation: ""
  },
  {
    value: "recV91jfOaFDvqYm8",
    label: "Emmet",
    access: "premium",
    variant: "EX",
    fileName: "emmet2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/emmet2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/emmet2.png",
    generation: ""
  },
  {
    value: "recbgi82FgqRVemDQ",
    label: "Emmet",
    access: "premium",
    variant: "Special",
    fileName: "emmet-special.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/emmet-special.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/emmet-special.png",
    generation: ""
  },
  {
    value: "recyLAYPZ2rcYnN3I",
    label: "Emmet",
    access: "premium",
    variant: "Special EX",
    fileName: "emmet-special2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/emmet-special2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/emmet-special2.png",
    generation: ""
  },
  {
    value: "rec1yZKFlTMlxYS9S",
    label: "Erika",
    access: "free",
    variant: "",
    fileName: "erika.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/erika.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/erika.png",
    generation: ""
  },
  {
    value: "recgVvCHYTllmT4a6",
    label: "Erika",
    access: "premium",
    variant: "EX",
    fileName: "erika2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/erika2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/erika2.png",
    generation: ""
  },
  {
    value: "rec8eLOvHbHU3AEA8",
    label: "Erika",
    access: "premium",
    variant: "Holidays",
    fileName: "erika-holidays.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/erika-holidays.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/erika-holidays.png",
    generation: ""
  },
  {
    value: "recSxJsCiHm9UVW6I",
    label: "Erika",
    access: "premium",
    variant: "Holidays EX",
    fileName: "erika-holidays2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/erika-holidays2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/erika-holidays2.png",
    generation: ""
  },
  {
    value: "recGtxL3IKwHKYPlD",
    label: "Erika",
    access: "premium",
    variant: "Sygna",
    fileName: "erika-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/erika-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/erika-sygna.png",
    generation: ""
  },
  {
    value: "recpxFqqQEtQ5GDTb",
    label: "Erika",
    access: "premium",
    variant: "Sygna EX",
    fileName: "erika-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/erika-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/erika-sygna2.png",
    generation: ""
  },
  {
    value: "recPATT4k88FBICqJ",
    label: "Ethan",
    access: "free",
    variant: "",
    fileName: "ethan.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/ethan.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/ethan.png",
    generation: ""
  },
  {
    value: "recnoP1lf0boewIKD",
    label: "Ethan",
    access: "premium",
    variant: "EX",
    fileName: "ethan2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/ethan2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/ethan2.png",
    generation: ""
  },
  {
    value: "recQKlTeJgCSdeWWk",
    label: "Ethan",
    access: "premium",
    variant: "Sygna",
    fileName: "ethan-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/ethan-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/ethan-sygna.png",
    generation: ""
  },
  {
    value: "rec4yRLbqNUuQnFtA",
    label: "Ethan",
    access: "premium",
    variant: "Sygna EX",
    fileName: "ethan-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/ethan-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/ethan-sygna2.png",
    generation: ""
  },
  {
    value: "recB8UJbsoQB4cLds",
    label: "Evelyn",
    access: "free",
    variant: "",
    fileName: "evelyn.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/evelyn.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/evelyn.png",
    generation: ""
  },
  {
    value: "recAiJeD9dDq2aQqV",
    label: "Falkner",
    access: "free",
    variant: "",
    fileName: "falkner.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/falkner.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/falkner.png",
    generation: ""
  },
  {
    value: "recH9K2r5J98UZg1P",
    label: "Falkner",
    access: "premium",
    variant: "EX",
    fileName: "falkner2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/falkner2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/falkner2.png",
    generation: ""
  },
  {
    value: "recrbkuvuaptFje4u",
    label: "Fantina",
    access: "free",
    variant: "",
    fileName: "fantina.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/fantina.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/fantina.png",
    generation: ""
  },
  {
    value: "reczdeuYe4zGr9LEE",
    label: "Flannery",
    access: "free",
    variant: "",
    fileName: "flannery.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/flannery.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/flannery.png",
    generation: ""
  },
  {
    value: "recuJEM0cWHipkGpB",
    label: "Flint",
    access: "free",
    variant: "",
    fileName: "flint.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/flint.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/flint.png",
    generation: ""
  },
  {
    value: "recMpM4f0mzX6S61I",
    label: "Gardenia",
    access: "free",
    variant: "",
    fileName: "gardenia.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/gardenia.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/gardenia.png",
    generation: ""
  },
  {
    value: "reck7cMIRndB06eVx",
    label: "Ghetsis",
    access: "free",
    variant: "",
    fileName: "ghetsis.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/ghetsis.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/ghetsis.png",
    generation: ""
  },
  {
    value: "recYqPHmiYWx1eTyG",
    label: "Ghetsis",
    access: "premium",
    variant: "EX",
    fileName: "ghetsis2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/ghetsis2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/ghetsis2.png",
    generation: ""
  },
  {
    value: "recV1PZyVWBGTE7wh",
    label: "Giovanni",
    access: "free",
    variant: "",
    fileName: "giovanni.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/giovanni.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/giovanni.png",
    generation: ""
  },
  {
    value: "recrJluYMsazGw1id",
    label: "Giovanni",
    access: "premium",
    variant: "EX",
    fileName: "giovanni2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/giovanni2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/giovanni2.png",
    generation: ""
  },
  {
    value: "recdjE8vOpi5yXajd",
    label: "Giovanni",
    access: "premium",
    variant: "Sygna",
    fileName: "giovanni-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/giovanni-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/giovanni-sygna.png",
    generation: ""
  },
  {
    value: "recNXRBsEldfWgGF8",
    label: "Giovanni",
    access: "premium",
    variant: "Sygna EX",
    fileName: "giovanni-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/giovanni-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/giovanni-sygna2.png",
    generation: ""
  },
  {
    value: "recnHXlS30uuqAetl",
    label: "Glacia",
    access: "free",
    variant: "",
    fileName: "glacia.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/glacia.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/glacia.png",
    generation: ""
  },
  {
    value: "recMU3TxsZmRrVv5J",
    label: "Gladion",
    access: "free",
    variant: "",
    fileName: "gladion.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/gladion.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/gladion.png",
    generation: ""
  },
  {
    value: "recztCWKOTOF9gSdy",
    label: "Gladion",
    access: "premium",
    variant: "EX",
    fileName: "gladion2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/gladion2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/gladion2.png",
    generation: ""
  },
  {
    value: "recxd1K41WHlQUYff",
    label: "Gloria",
    access: "free",
    variant: "",
    fileName: "gloria.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/gloria.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/gloria.png",
    generation: ""
  },
  {
    value: "recaPM4CyOWjkHXa8",
    label: "Gloria",
    access: "premium",
    variant: "EX",
    fileName: "gloria2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/gloria2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/gloria2.png",
    generation: ""
  },
  {
    value: "recZzPlZdR4fcGOGW",
    label: "Gloria",
    access: "premium",
    variant: "Dojo",
    fileName: "gloria-dojo.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/gloria-dojo.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/gloria-dojo.png",
    generation: ""
  },
  {
    value: "recrOAibSEaISr2YD",
    label: "Gloria",
    access: "premium",
    variant: "Dojo EX",
    fileName: "gloria-dojo2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/gloria-dojo2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/gloria-dojo2.png",
    generation: ""
  },
  {
    value: "recEBovtfC7972nfI",
    label: "Gloria",
    access: "premium",
    variant: "Summer",
    fileName: "gloria-summer.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/gloria-summer.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/gloria-summer.png",
    generation: ""
  },
  {
    value: "recyCh0L0BLu2Q3lx",
    label: "Gloria",
    access: "premium",
    variant: "Summer EX",
    fileName: "gloria-summer2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/gloria-summer2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/gloria-summer2.png",
    generation: ""
  },
  {
    value: "recNKwUoV2Xtxw3du",
    label: "Gordie",
    access: "free",
    variant: "",
    fileName: "gordie.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/gordie.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/gordie.png",
    generation: ""
  },
  {
    value: "recpEkeU3xYOtUSOj",
    label: "Gordie",
    access: "premium",
    variant: "EX",
    fileName: "gordie2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/gordie2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/gordie2.png",
    generation: ""
  },
  {
    value: "recw0tZJpJo7foQy6",
    label: "Grant",
    access: "free",
    variant: "",
    fileName: "grant.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/grant.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/grant.png",
    generation: ""
  },
  {
    value: "reculunsxbHVRt3kp",
    label: "Grimsley",
    access: "free",
    variant: "",
    fileName: "grimsley.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/grimsley.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/grimsley.png",
    generation: ""
  },
  {
    value: "recTsHYwOpfgFQkLZ",
    label: "Grimsley",
    access: "premium",
    variant: "EX",
    fileName: "grimsley2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/grimsley2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/grimsley2.png",
    generation: ""
  },
  {
    value: "recWP8wmF4dPJN0AT",
    label: "Grimsley",
    access: "premium",
    variant: "Kimono",
    fileName: "grimsley-kimono.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/grimsley-kimono.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/grimsley-kimono.png",
    generation: ""
  },
  {
    value: "recvB9Mr8nYF01gUz",
    label: "Grimsley",
    access: "premium",
    variant: "Kimono EX",
    fileName: "grimsley-kimono2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/grimsley-kimono2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/grimsley-kimono2.png",
    generation: ""
  },
  {
    value: "rec9VgGLzeCtjwsTW",
    label: "Grimsley",
    access: "premium",
    variant: "Sygna",
    fileName: "grimsley-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/grimsley-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/grimsley-sygna.png",
    generation: ""
  },
  {
    value: "recQnvSDE2pQZgxk4",
    label: "Grimsley",
    access: "premium",
    variant: "Sygna EX",
    fileName: "grimsley-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/grimsley-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/grimsley-sygna2.png",
    generation: ""
  },
  {
    value: "recviUji6EThTKukA",
    label: "Guzma",
    access: "free",
    variant: "",
    fileName: "guzma.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/guzma.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/guzma.png",
    generation: ""
  },
  {
    value: "recTreDXPKS1qUSCd",
    label: "Guzma",
    access: "premium",
    variant: "EX",
    fileName: "guzma2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/guzma2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/guzma2.png",
    generation: ""
  },
  {
    value: "recqAgqYbg5RnadgQ",
    label: "Guzma",
    access: "premium",
    variant: "Special",
    fileName: "guzma-special.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/guzma-special.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/guzma-special.png",
    generation: ""
  },
  {
    value: "rec0E94y37qrTZLBw",
    label: "Guzma",
    access: "premium",
    variant: "Special EX",
    fileName: "guzma-special2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/guzma-special2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/guzma-special2.png",
    generation: ""
  },
  {
    value: "rec827TkDBmeSO4uf",
    label: "Hala",
    access: "free",
    variant: "",
    fileName: "hala.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/hala.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/hala.png",
    generation: ""
  },
  {
    value: "recvnpa60fx31B6u6",
    label: "Hala",
    access: "premium",
    variant: "EX",
    fileName: "hala2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/hala2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/hala2.png",
    generation: ""
  },
  {
    value: "recUgyeY7MdXFSeaK",
    label: "Hapu",
    access: "free",
    variant: "",
    fileName: "hapu.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/hapu.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/hapu.png",
    generation: ""
  },
  {
    value: "recvjT97VF1sFQsuM",
    label: "Hau",
    access: "free",
    variant: "",
    fileName: "hau.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/hau.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/hau.png",
    generation: ""
  },
  {
    value: "recdImT0K8zQKT4mV",
    label: "Hau",
    access: "premium",
    variant: "EX",
    fileName: "hau2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/hau2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/hau2.png",
    generation: ""
  },
  {
    value: "recJ97VDVmfYsYnjT",
    label: "Helena",
    access: "free",
    variant: "",
    fileName: "helena.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/helena.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/helena.png",
    generation: ""
  },
  {
    value: "recXtvMC5cSnfFnl6",
    label: "Hilbert",
    access: "free",
    variant: "",
    fileName: "hilbert.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/hilbert.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/hilbert.png",
    generation: ""
  },
  {
    value: "recOdOBecyosxygfN",
    label: "Hilbert",
    access: "premium",
    variant: "EX",
    fileName: "hilbert2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/hilbert2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/hilbert2.png",
    generation: ""
  },
  {
    value: "rech10VJ1NOfFqQqJ",
    label: "Hilbert",
    access: "premium",
    variant: "Fall 2020",
    fileName: "hilbert-fall2020.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/hilbert-fall2020.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/hilbert-fall2020.png",
    generation: ""
  },
  {
    value: "rec8v25ChLtzkq2dX",
    label: "Hilbert",
    access: "premium",
    variant: "Fall 2020 EX",
    fileName: "hilbert-fall20202.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/hilbert-fall20202.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/hilbert-fall20202.png",
    generation: ""
  },
  {
    value: "recWVUoaFHsfpJWvV",
    label: "Hilbert",
    access: "premium",
    variant: "Sygna",
    fileName: "hilbert-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/hilbert-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/hilbert-sygna.png",
    generation: ""
  },
  {
    value: "recVRbka3HoBJskGT",
    label: "Hilbert",
    access: "premium",
    variant: "Sygna EX",
    fileName: "hilbert-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/hilbert-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/hilbert-sygna2.png",
    generation: ""
  },
  {
    value: "recXUYhrgKsZMVu5l",
    label: "Hilda",
    access: "free",
    variant: "",
    fileName: "hilda.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/hilda.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/hilda.png",
    generation: ""
  },
  {
    value: "recDxx7VwllX9z8fe",
    label: "Hilda",
    access: "premium",
    variant: "EX",
    fileName: "hilda2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/hilda2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/hilda2.png",
    generation: ""
  },
  {
    value: "rec3PXD5yftVbiCqr",
    label: "Hilda",
    access: "premium",
    variant: "Special",
    fileName: "hilda-special.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/hilda-special.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/hilda-special.png",
    generation: ""
  },
  {
    value: "recVAgdoW7VtqMnHk",
    label: "Hilda",
    access: "premium",
    variant: "Special EX",
    fileName: "hilda-special2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/hilda-special2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/hilda-special2.png",
    generation: ""
  },
  {
    value: "reciDCnEJyKzxzf5S",
    label: "Hilda",
    access: "premium",
    variant: "Summer 2022",
    fileName: "hilda-summer2022.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/hilda-summer2022.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/hilda-summer2022.png",
    generation: ""
  },
  {
    value: "recgqpTal94Bp6n1A",
    label: "Hilda",
    access: "premium",
    variant: "Summer 2022 EX",
    fileName: "hilda-summer20222.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/hilda-summer20222.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/hilda-summer20222.png",
    generation: ""
  },
  {
    value: "rec5gjLCv0MArQYPZ",
    label: "Hilda",
    access: "premium",
    variant: "Sygna",
    fileName: "hilda-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/hilda-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/hilda-sygna.png",
    generation: ""
  },
  {
    value: "recQZRO0rys3Zm5Im",
    label: "Hilda",
    access: "premium",
    variant: "Sygna EX",
    fileName: "hilda-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/hilda-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/hilda-sygna2.png",
    generation: ""
  },
  {
    value: "recySqmd4JrPBGK7q",
    label: "Hop",
    access: "free",
    variant: "",
    fileName: "hop.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/hop.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/hop.png",
    generation: ""
  },
  {
    value: "recTezfZX3TWoCYE0",
    label: "Hugh",
    access: "free",
    variant: "",
    fileName: "hugh.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/hugh.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/hugh.png",
    generation: ""
  },
  {
    value: "recRwQiRqDF0mmFNl",
    label: "Hugh",
    access: "premium",
    variant: "EX",
    fileName: "hugh2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/hugh2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/hugh2.png",
    generation: ""
  },
  {
    value: "rec2ySnTA2SXcv176",
    label: "Ingo",
    access: "free",
    variant: "",
    fileName: "ingo.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/ingo.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/ingo.png",
    generation: ""
  },
  {
    value: "recjJvIK01o80NCVX",
    label: "Ingo",
    access: "premium",
    variant: "EX",
    fileName: "ingo2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/ingo2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/ingo2.png",
    generation: ""
  },
  {
    value: "recirgfnwspZw0RBb",
    label: "Ingo",
    access: "premium",
    variant: "Special",
    fileName: "ingo-special.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/ingo-special.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/ingo-special.png",
    generation: ""
  },
  {
    value: "recZ7DjxDuGxnsLQs",
    label: "Ingo",
    access: "premium",
    variant: "Special EX",
    fileName: "ingo-special2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/ingo-special2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/ingo-special2.png",
    generation: ""
  },
  {
    value: "recMX59tFln2dKhmF",
    label: "Iris",
    access: "free",
    variant: "",
    fileName: "iris.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/iris.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/iris.png",
    generation: ""
  },
  {
    value: "recc9oGkSKSezK9kD",
    label: "Iris",
    access: "premium",
    variant: "EX",
    fileName: "iris2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/iris2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/iris2.png",
    generation: ""
  },
  {
    value: "rectbYYIoovLnphtZ",
    label: "Iris",
    access: "premium",
    variant: "Champion",
    fileName: "iris-champion.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/iris-champion.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/iris-champion.png",
    generation: ""
  },
  {
    value: "recVZ6ooYarREMm0a",
    label: "Iris",
    access: "premium",
    variant: "Champion EX",
    fileName: "iris-champion2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/iris-champion2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/iris-champion2.png",
    generation: ""
  },
  {
    value: "recLreTqmdg7E1uu3",
    label: "Iris",
    access: "premium",
    variant: "Fall 2022",
    fileName: "iris-fall2022.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/iris-fall2022.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/iris-fall2022.png",
    generation: ""
  },
  {
    value: "recF649SCHI4ttF29",
    label: "Iris",
    access: "premium",
    variant: "Fall 2022 EX",
    fileName: "iris-fall20222.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/iris-fall20222.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/iris-fall20222.png",
    generation: ""
  },
  {
    value: "recXUz4Eaa0fmKyEc",
    label: "James",
    access: "free",
    variant: "",
    fileName: "james.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/james.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/james.png",
    generation: ""
  },
  {
    value: "recge2vcuoKEMrLy8",
    label: "Janine",
    access: "free",
    variant: "",
    fileName: "janine.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/janine.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/janine.png",
    generation: ""
  },
  {
    value: "recEGhENqdYrWw0vG",
    label: "Jasmine",
    access: "free",
    variant: "",
    fileName: "jasmine.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/jasmine.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/jasmine.png",
    generation: ""
  },
  {
    value: "recPZyyOJuJ7fz2AT",
    label: "Jasmine",
    access: "premium",
    variant: "EX",
    fileName: "jasmine2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/jasmine2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/jasmine2.png",
    generation: ""
  },
  {
    value: "rec0iJWUDlaU3GyID",
    label: "Jasmine",
    access: "premium",
    variant: "Special",
    fileName: "jasmine-special.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/jasmine-special.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/jasmine-special.png",
    generation: ""
  },
  {
    value: "recCSzhOKBT7nUguK",
    label: "Jasmine",
    access: "premium",
    variant: "Special EX",
    fileName: "jasmine-special2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/jasmine-special2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/jasmine-special2.png",
    generation: ""
  },
  {
    value: "rec8IG9TRId7d96Jg",
    label: "Jessie",
    access: "free",
    variant: "",
    fileName: "jessie.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/jessie.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/jessie.png",
    generation: ""
  },
  {
    value: "recrcTWU5xa04U1ib",
    label: "Jupiter",
    access: "free",
    variant: "",
    fileName: "jupiter.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/jupiter.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/jupiter.png",
    generation: ""
  },
  {
    value: "reczTARc2V6VL8QNQ",
    label: "Kahili",
    access: "free",
    variant: "",
    fileName: "kahili.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/kahili.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/kahili.png",
    generation: ""
  },
  {
    value: "recpxichPBbARvAyW",
    label: "Karen",
    access: "free",
    variant: "",
    fileName: "karen.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/karen.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/karen.png",
    generation: ""
  },
  {
    value: "recnBxOKW6UYt4fwF",
    label: "Karen",
    access: "premium",
    variant: "EX",
    fileName: "karen2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/karen2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/karen2.png",
    generation: ""
  },
  {
    value: "reccTcf710qi8Yfmb",
    label: "Kiawe",
    access: "free",
    variant: "",
    fileName: "kiawe.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/kiawe.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/kiawe.png",
    generation: ""
  },
  {
    value: "recm83GhSwS7HSt3L",
    label: "Kiawe",
    access: "premium",
    variant: "EX",
    fileName: "kiawe2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/kiawe2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/kiawe2.png",
    generation: ""
  },
  {
    value: "reclPXmMJulYXkXUS",
    label: "Koga",
    access: "free",
    variant: "",
    fileName: "koga.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/koga.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/koga.png",
    generation: ""
  },
  {
    value: "recWF2qoEU6DbwZrb",
    label: "Korrina",
    access: "free",
    variant: "",
    fileName: "korrina.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/korrina.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/korrina.png",
    generation: ""
  },
  {
    value: "recH3CUxigUxCKZDa",
    label: "Korrina",
    access: "premium",
    variant: "EX",
    fileName: "korrina2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/korrina2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/korrina2.png",
    generation: ""
  },
  {
    value: "recwWv3m1i2IxiMAB",
    label: "Kris",
    access: "free",
    variant: "",
    fileName: "kris.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/kris.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/kris.png",
    generation: ""
  },
  {
    value: "reckZ5uQFl5ucg78j",
    label: "Kris",
    access: "premium",
    variant: "EX",
    fileName: "kris2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/kris2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/kris2.png",
    generation: ""
  },
  {
    value: "recpuW0FX2l6Anj0q",
    label: "Kris",
    access: "premium",
    variant: "Sygna",
    fileName: "kris-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/kris-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/kris-sygna.png",
    generation: ""
  },
  {
    value: "rec2EpDDkYlLzYucX",
    label: "Kris",
    access: "premium",
    variant: "Sygna EX",
    fileName: "kris-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/kris-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/kris-sygna2.png",
    generation: ""
  },
  {
    value: "rec8iWizoMkdaHnCI",
    label: "Lana",
    access: "free",
    variant: "",
    fileName: "lana.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lana.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lana.png",
    generation: ""
  },
  {
    value: "recK0ibIcAK7Njqoi",
    label: "Lana",
    access: "premium",
    variant: "EX",
    fileName: "lana2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lana2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lana2.png",
    generation: ""
  },
  {
    value: "recMhuaWns8EYXtQJ",
    label: "Lance",
    access: "free",
    variant: "",
    fileName: "lance.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lance.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lance.png",
    generation: ""
  },
  {
    value: "recNhakPNxkK6Fgqu",
    label: "Lance",
    access: "premium",
    variant: "EX",
    fileName: "lance2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lance2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lance2.png",
    generation: ""
  },
  {
    value: "recQMEjugdjY6RNQv",
    label: "Lance",
    access: "premium",
    variant: "New Year",
    fileName: "lance-newyear.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lance-newyear.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lance-newyear.png",
    generation: ""
  },
  {
    value: "recBL0lW4rPPSPxjT",
    label: "Lance",
    access: "premium",
    variant: "New Year EX",
    fileName: "lance-newyear2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lance-newyear2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lance-newyear2.png",
    generation: ""
  },
  {
    value: "rectH6VwxNdEfl0hc",
    label: "Leaf",
    access: "free",
    variant: "",
    fileName: "leaf.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/leaf.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/leaf.png",
    generation: ""
  },
  {
    value: "recLPM4niJEFnFapn",
    label: "Leaf",
    access: "premium",
    variant: "EX",
    fileName: "leaf2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/leaf2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/leaf2.png",
    generation: ""
  },
  {
    value: "reck6jK7r3hjt6xPu",
    label: "Leaf",
    access: "premium",
    variant: "Sygna",
    fileName: "leaf-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/leaf-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/leaf-sygna.png",
    generation: ""
  },
  {
    value: "recNSevPFneOaO5Vx",
    label: "Leaf",
    access: "premium",
    variant: "Sygna EX",
    fileName: "leaf-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/leaf-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/leaf-sygna2.png",
    generation: ""
  },
  {
    value: "recEsndEbGyeaJZBt",
    label: "Lear",
    access: "free",
    variant: "",
    fileName: "lear.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lear.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lear.png",
    generation: ""
  },
  {
    value: "recdOpYVhUuql96vG",
    label: "Lear",
    access: "premium",
    variant: "EX",
    fileName: "lear2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lear2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lear2.png",
    generation: ""
  },
  {
    value: "recxZLDi4KLtdANsk",
    label: "Leon",
    access: "free",
    variant: "",
    fileName: "leon.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/leon.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/leon.png",
    generation: ""
  },
  {
    value: "recpwRdKXEEdi1DiH",
    label: "Leon",
    access: "premium",
    variant: "EX",
    fileName: "leon2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/leon2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/leon2.png",
    generation: ""
  },
  {
    value: "recbLe9nlsdM0kOuQ",
    label: "Leon",
    access: "premium",
    variant: "Holidays",
    fileName: "leon-holidays.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/leon-holidays.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/leon-holidays.png",
    generation: ""
  },
  {
    value: "recTu3EDbP7DqjAae",
    label: "Leon",
    access: "premium",
    variant: "Holidays EX",
    fileName: "leon-holidays2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/leon-holidays2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/leon-holidays2.png",
    generation: ""
  },
  {
    value: "recQn3wsiiC4AcwnZ",
    label: "Leon",
    access: "premium",
    variant: "Sygna",
    fileName: "leon-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/leon-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/leon-sygna.png",
    generation: ""
  },
  {
    value: "recTeUYkLbeZ6y0JT",
    label: "Leon",
    access: "premium",
    variant: "Sygna EX",
    fileName: "leon-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/leon-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/leon-sygna2.png",
    generation: ""
  },
  {
    value: "recvgda7YjV0f47Ju",
    label: "Lillie",
    access: "free",
    variant: "",
    fileName: "lillie.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lillie.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lillie.png",
    generation: ""
  },
  {
    value: "recTsKnCfujkCa1vq",
    label: "Lillie",
    access: "premium",
    variant: "EX",
    fileName: "lillie2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lillie2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lillie2.png",
    generation: ""
  },
  {
    value: "recmJKWH2RYaiYRRa",
    label: "Lillie",
    access: "premium",
    variant: "Anniversary 2021",
    fileName: "lillie-anniversary2021.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lillie-anniversary2021.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lillie-anniversary2021.png",
    generation: ""
  },
  {
    value: "recvEr9stviFWjnSz",
    label: "Lillie",
    access: "premium",
    variant: "Anniversary 2021 EX",
    fileName: "lillie-anniversary20212.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lillie-anniversary20212.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lillie-anniversary20212.png",
    generation: ""
  },
  {
    value: "recrrmBtjav9rE2s0",
    label: "Lillie",
    access: "premium",
    variant: "New Year",
    fileName: "lillie-newyear.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lillie-newyear.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lillie-newyear.png",
    generation: ""
  },
  {
    value: "reckVrIbw3nB142mW",
    label: "Lillie",
    access: "premium",
    variant: "New Year EX",
    fileName: "lillie-newyear2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lillie-newyear2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lillie-newyear2.png",
    generation: ""
  },
  {
    value: "reczNlz7hRBkgxkQQ",
    label: "Lillie",
    access: "premium",
    variant: "Special",
    fileName: "lillie-special.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lillie-special.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lillie-special.png",
    generation: ""
  },
  {
    value: "rec48m3zCKYoUIRyf",
    label: "Lillie",
    access: "premium",
    variant: "Special EX",
    fileName: "lillie-special2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lillie-special2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lillie-special2.png",
    generation: ""
  },
  {
    value: "recrYMw0HCO4jxZVH",
    label: "Lisia",
    access: "free",
    variant: "",
    fileName: "lisia.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lisia.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lisia.png",
    generation: ""
  },
  {
    value: "rec8l6KzBWsyUDC4v",
    label: "Lisia",
    access: "premium",
    variant: "EX",
    fileName: "lisia2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lisia2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lisia2.png",
    generation: ""
  },
  {
    value: "recbAtaHgCFPloZWn",
    label: "Liza",
    access: "free",
    variant: "",
    fileName: "liza.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/liza.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/liza.png",
    generation: ""
  },
  {
    value: "recbV8Ef3Kr56CvSJ",
    label: "Looker",
    access: "free",
    variant: "",
    fileName: "looker.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/looker.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/looker.png",
    generation: ""
  },
  {
    value: "rec9U0Jho6gJaJsgG",
    label: "Looker",
    access: "premium",
    variant: "EX",
    fileName: "looker2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/looker2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/looker2.png",
    generation: ""
  },
  {
    value: "recq8sDF3C6i2flxW",
    label: "Lorelei",
    access: "free",
    variant: "",
    fileName: "lorelei.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lorelei.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lorelei.png",
    generation: ""
  },
  {
    value: "recKA9f9XHEVhcpwU",
    label: "Lt. Surge",
    access: "free",
    variant: "",
    fileName: "ltsurge.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/ltsurge.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/ltsurge.png",
    generation: ""
  },
  {
    value: "rec0EJU6CiwR0Ao6y",
    label: "Lucas",
    access: "free",
    variant: "",
    fileName: "lucas.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lucas.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lucas.png",
    generation: ""
  },
  {
    value: "rec5SecLPnubU65Ye",
    label: "Lucas",
    access: "premium",
    variant: "EX",
    fileName: "lucas2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lucas2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lucas2.png",
    generation: ""
  },
  {
    value: "recW5GqqDi9Yb4eV0",
    label: "Lucian",
    access: "free",
    variant: "",
    fileName: "lucian.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lucian.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lucian.png",
    generation: ""
  },
  {
    value: "rectARRiGdcGMaizT",
    label: "Lucian",
    access: "premium",
    variant: "EX",
    fileName: "lucian2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lucian2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lucian2.png",
    generation: ""
  },
  {
    value: "rec11vUDho1dbyzzd",
    label: "Lucy",
    access: "free",
    variant: "",
    fileName: "lucy.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lucy.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lucy.png",
    generation: ""
  },
  {
    value: "recVOro2H3IMey377",
    label: "Lusamine",
    access: "free",
    variant: "",
    fileName: "lusamine.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lusamine.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lusamine.png",
    generation: ""
  },
  {
    value: "recI9o9HnQvC5O0rr",
    label: "Lusamine",
    access: "premium",
    variant: "EX",
    fileName: "lusamine2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lusamine2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lusamine2.png",
    generation: ""
  },
  {
    value: "recxrSE7RE3fdbX4v",
    label: "Lusamine",
    access: "premium",
    variant: "Sygna",
    fileName: "lusamine-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lusamine-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lusamine-sygna.png",
    generation: ""
  },
  {
    value: "recXz355i8GeFF9tp",
    label: "Lusamine",
    access: "premium",
    variant: "Sygna EX",
    fileName: "lusamine-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lusamine-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lusamine-sygna2.png",
    generation: ""
  },
  {
    value: "recyLFNQsTQ9nqoec",
    label: "Lyra",
    access: "free",
    variant: "",
    fileName: "lyra.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lyra.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lyra.png",
    generation: ""
  },
  {
    value: "recjb9bMQNbpYCbDF",
    label: "Lyra",
    access: "premium",
    variant: "EX",
    fileName: "lyra2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lyra2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lyra2.png",
    generation: ""
  },
  {
    value: "reckqk1blIsJzEdpA",
    label: "Lyra",
    access: "premium",
    variant: "Summer",
    fileName: "lyra-summer.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lyra-summer.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lyra-summer.png",
    generation: ""
  },
  {
    value: "recJHAWuQjCLwOT7I",
    label: "Lyra",
    access: "premium",
    variant: "Summer EX",
    fileName: "lyra-summer2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lyra-summer2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lyra-summer2.png",
    generation: ""
  },
  {
    value: "rech8PJbzA1cmGYcR",
    label: "Lyra",
    access: "premium",
    variant: "Sygna",
    fileName: "lyra-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lyra-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lyra-sygna.png",
    generation: ""
  },
  {
    value: "recTQjeM0XYP2CtTO",
    label: "Lyra",
    access: "premium",
    variant: "Sygna EX",
    fileName: "lyra-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lyra-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lyra-sygna2.png",
    generation: ""
  },
  {
    value: "recrY5AKZaQzRISQp",
    label: "Lysandre",
    access: "free",
    variant: "",
    fileName: "lysandre.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lysandre.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lysandre.png",
    generation: ""
  },
  {
    value: "recZXOCoTU3Vo27IV",
    label: "Lysandre",
    access: "premium",
    variant: "EX",
    fileName: "lysandre2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/lysandre2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/lysandre2.png",
    generation: ""
  },
  {
    value: "recqhX1G2GhyzVsf2",
    label: "Main Character",
    access: "free",
    variant: "",
    fileName: "maincharacter.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/maincharacter.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/maincharacter.png",
    generation: ""
  },
  {
    value: "recq1vvFNglzE2ACL",
    label: "Mallow",
    access: "free",
    variant: "",
    fileName: "mallow.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/mallow.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/mallow.png",
    generation: ""
  },
  {
    value: "rec82KsxCbiod3pCZ",
    label: "Mallow",
    access: "premium",
    variant: "EX",
    fileName: "mallow2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/mallow2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/mallow2.png",
    generation: ""
  },
  {
    value: "recrGs9ApKodrbRKg",
    label: "Marley",
    access: "free",
    variant: "",
    fileName: "marley.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/marley.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/marley.png",
    generation: ""
  },
  {
    value: "recPVUiFPXyL4b0af",
    label: "Marlon",
    access: "free",
    variant: "",
    fileName: "marlon.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/marlon.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/marlon.png",
    generation: ""
  },
  {
    value: "rec8r2dggAoIB8diR",
    label: "Marnie",
    access: "free",
    variant: "",
    fileName: "marnie.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/marnie.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/marnie.png",
    generation: ""
  },
  {
    value: "recKfXIQaA47FHmRO",
    label: "Marnie",
    access: "premium",
    variant: "EX",
    fileName: "marnie2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/marnie2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/marnie2.png",
    generation: ""
  },
  {
    value: "recRFyLRRkazPRkab",
    label: "Marnie",
    access: "premium",
    variant: "Palentines",
    fileName: "marnie-palentines.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/marnie-palentines.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/marnie-palentines.png",
    generation: ""
  },
  {
    value: "reclbCQh6CP2OqCga",
    label: "Marnie",
    access: "premium",
    variant: "Palentines EX",
    fileName: "marnie-palentines2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/marnie-palentines2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/marnie-palentines2.png",
    generation: ""
  },
  {
    value: "recsRL3S5yby3Ntml",
    label: "Marnie",
    access: "premium",
    variant: "Summer",
    fileName: "marnie-summer.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/marnie-summer.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/marnie-summer.png",
    generation: ""
  },
  {
    value: "recKWwNJab5GVWWiB",
    label: "Marnie",
    access: "premium",
    variant: "Summer EX",
    fileName: "marnie-summer2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/marnie-summer2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/marnie-summer2.png",
    generation: ""
  },
  {
    value: "recgBfjeo3V6EXHNO",
    label: "Mars",
    access: "free",
    variant: "",
    fileName: "mars.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/mars.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/mars.png",
    generation: ""
  },
  {
    value: "reccXGngKAAkP5TmY",
    label: "Marshal",
    access: "free",
    variant: "",
    fileName: "marshal.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/marshal.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/marshal.png",
    generation: ""
  },
  {
    value: "recgJvhw8J6i5tBUf",
    label: "Matt",
    access: "free",
    variant: "",
    fileName: "matt.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/matt.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/matt.png",
    generation: ""
  },
  {
    value: "recclCCOnwDwFksuw",
    label: "Maxie",
    access: "free",
    variant: "",
    fileName: "maxie.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/maxie.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/maxie.png",
    generation: ""
  },
  {
    value: "recd16uxrIGjZyEiG",
    label: "Maxie",
    access: "premium",
    variant: "EX",
    fileName: "maxie2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/maxie2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/maxie2.png",
    generation: ""
  },
  {
    value: "recMl2fJPzy38hFO6",
    label: "May",
    access: "free",
    variant: "",
    fileName: "may.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/may.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/may.png",
    generation: ""
  },
  {
    value: "rec31Lvlx65o6qMV5",
    label: "May",
    access: "premium",
    variant: "EX",
    fileName: "may2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/may2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/may2.png",
    generation: ""
  },
  {
    value: "recsFyhvvPgdRmJZW",
    label: "May",
    access: "premium",
    variant: "Anniversary 2022",
    fileName: "may-anniversary2022.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/may-anniversary2022.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/may-anniversary2022.png",
    generation: ""
  },
  {
    value: "recDeA8nWizaZswg7",
    label: "May",
    access: "premium",
    variant: "Anniversary 2022 EX",
    fileName: "may-anniversary20222.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/may-anniversary20222.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/may-anniversary20222.png",
    generation: ""
  },
  {
    value: "rec6wwXgZ7K3EmNyK",
    label: "May",
    access: "premium",
    variant: "Spring 2021",
    fileName: "may-spring2021.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/may-spring2021.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/may-spring2021.png",
    generation: ""
  },
  {
    value: "recY5mwMkNN5FFsDp",
    label: "May",
    access: "premium",
    variant: "Spring 2021 EX",
    fileName: "may-spring20212.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/may-spring20212.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/may-spring20212.png",
    generation: ""
  },
  {
    value: "rects0VATwYzmV9Eq",
    label: "May",
    access: "premium",
    variant: "Sygna",
    fileName: "may-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/may-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/may-sygna.png",
    generation: ""
  },
  {
    value: "recxxei3bCGjN4YXu",
    label: "May",
    access: "premium",
    variant: "Sygna EX",
    fileName: "may-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/may-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/may-sygna2.png",
    generation: ""
  },
  {
    value: "recHTg4rdRlpdQxDj",
    label: "Maylene",
    access: "free",
    variant: "",
    fileName: "maylene.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/maylene.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/maylene.png",
    generation: ""
  },
  {
    value: "recQaQaYNR58pjC15",
    label: "Melony",
    access: "free",
    variant: "",
    fileName: "melony.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/melony.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/melony.png",
    generation: ""
  },
  {
    value: "recVaaLloFOFYEyiM",
    label: "Melony",
    access: "premium",
    variant: "EX",
    fileName: "melony2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/melony2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/melony2.png",
    generation: ""
  },
  {
    value: "rec9B2spyhq8F4Rs5",
    label: "Mina",
    access: "free",
    variant: "",
    fileName: "mina.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/mina.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/mina.png",
    generation: ""
  },
  {
    value: "reckvQX9ntrIMdj5s",
    label: "Misty",
    access: "free",
    variant: "",
    fileName: "misty.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/misty.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/misty.png",
    generation: ""
  },
  {
    value: "recD44Q3vufdYabNE",
    label: "Misty",
    access: "premium",
    variant: "Swimsuit",
    fileName: "misty-swimsuit.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/misty-swimsuit.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/misty-swimsuit.png",
    generation: ""
  },
  {
    value: "reczTyGJWQYJyrRwx",
    label: "Misty",
    access: "premium",
    variant: "Swimsuit EX",
    fileName: "misty-swimsuit2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/misty-swimsuit2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/misty-swimsuit2.png",
    generation: ""
  },
  {
    value: "recsRmblTlAO3uHqE",
    label: "Misty",
    access: "premium",
    variant: "Sygna",
    fileName: "misty-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/misty-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/misty-sygna.png",
    generation: ""
  },
  {
    value: "reclaKz1C9d39ppx1",
    label: "Misty",
    access: "premium",
    variant: "Sygna EX",
    fileName: "misty-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/misty-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/misty-sygna2.png",
    generation: ""
  },
  {
    value: "recsGW1L2ySWNzVMm",
    label: "Molayne",
    access: "free",
    variant: "",
    fileName: "molayne.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/molayne.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/molayne.png",
    generation: ""
  },
  {
    value: "recmFPBqzzlZF7IqR",
    label: "Morty",
    access: "free",
    variant: "",
    fileName: "morty.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/morty.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/morty.png",
    generation: ""
  },
  {
    value: "recRnLKpI5DAQGvm6",
    label: "Morty",
    access: "premium",
    variant: "EX",
    fileName: "morty2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/morty2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/morty2.png",
    generation: ""
  },
  {
    value: "recOy0frWeV01X6Qi",
    label: "Morty",
    access: "premium",
    variant: "Fall 2021",
    fileName: "morty-fall2021.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/morty-fall2021.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/morty-fall2021.png",
    generation: ""
  },
  {
    value: "recK0asPzNUI5Tt2M",
    label: "Morty",
    access: "premium",
    variant: "Fall 2021 EX",
    fileName: "morty-fall20212.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/morty-fall20212.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/morty-fall20212.png",
    generation: ""
  },
  {
    value: "recmGODkd025d8R04",
    label: "Morty",
    access: "premium",
    variant: "Sygna",
    fileName: "morty-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/morty-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/morty-sygna.png",
    generation: ""
  },
  {
    value: "rec5RTnYwAQb1CSxb",
    label: "Morty",
    access: "premium",
    variant: "Sygna EX",
    fileName: "morty-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/morty-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/morty-sygna2.png",
    generation: ""
  },
  {
    value: "recoi55BRLnfgh2Zm",
    label: "N",
    access: "free",
    variant: "",
    fileName: "n.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/n.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/n.png",
    generation: ""
  },
  {
    value: "recCksZioxU59cSn8",
    label: "N",
    access: "premium",
    variant: "EX",
    fileName: "n2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/n2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/n2.png",
    generation: ""
  },
  {
    value: "rec68kjM2XNLWj3wu",
    label: "N",
    access: "premium",
    variant: "Anniversary 2021",
    fileName: "n-anniversary2021.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/n-anniversary2021.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/n-anniversary2021.png",
    generation: ""
  },
  {
    value: "reckUcdcXfhMJdrkd",
    label: "N",
    access: "premium",
    variant: "Anniversary 2021 EX",
    fileName: "n-anniversary20212.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/n-anniversary20212.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/n-anniversary20212.png",
    generation: ""
  },
  {
    value: "reczdVRJ9viUOksDV",
    label: "N",
    access: "premium",
    variant: "Summer 2022",
    fileName: "n-summer2022.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/n-summer2022.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/n-summer2022.png",
    generation: ""
  },
  {
    value: "reclcE8UokZWmJmZY",
    label: "N",
    access: "premium",
    variant: "Summer 2022 EX",
    fileName: "n-summer20222.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/n-summer20222.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/n-summer20222.png",
    generation: ""
  },
  {
    value: "recCS3I08wbTJ9Hu0",
    label: "N",
    access: "premium",
    variant: "Sygna",
    fileName: "n-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/n-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/n-sygna.png",
    generation: ""
  },
  {
    value: "recb7EvMvRCdxdTdF",
    label: "N",
    access: "premium",
    variant: "Sygna EX",
    fileName: "n-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/n-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/n-sygna2.png",
    generation: ""
  },
  {
    value: "recEBdXIseTq946kq",
    label: "Nanu",
    access: "free",
    variant: "",
    fileName: "nanu.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/nanu.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/nanu.png",
    generation: ""
  },
  {
    value: "recyRB7Z5zjRndCNT",
    label: "Naomi",
    access: "free",
    variant: "",
    fileName: "naomi.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/naomi.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/naomi.png",
    generation: ""
  },
  {
    value: "recBV19VQMRDvbyLV",
    label: "Nate",
    access: "free",
    variant: "",
    fileName: "nate.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/nate.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/nate.png",
    generation: ""
  },
  {
    value: "rece6clNBKQkpD8Th",
    label: "Nate",
    access: "premium",
    variant: "EX",
    fileName: "nate2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/nate2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/nate2.png",
    generation: ""
  },
  {
    value: "recy50rCsetOAK5Jb",
    label: "Nessa",
    access: "free",
    variant: "",
    fileName: "nessa.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/nessa.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/nessa.png",
    generation: ""
  },
  {
    value: "recZAuZBMmL6kCstP",
    label: "Nessa",
    access: "premium",
    variant: "EX",
    fileName: "nessa2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/nessa2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/nessa2.png",
    generation: ""
  },
  {
    value: "recZCfCn3gLFzZe7T",
    label: "Nessa",
    access: "premium",
    variant: "Holidays",
    fileName: "nessa-holidays.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/nessa-holidays.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/nessa-holidays.png",
    generation: ""
  },
  {
    value: "recO48dp1Uy3UTK6Y",
    label: "Nessa",
    access: "premium",
    variant: "Holidays EX",
    fileName: "nessa-holidays2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/nessa-holidays2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/nessa-holidays2.png",
    generation: ""
  },
  {
    value: "rec9XoiZGdxxypXNd",
    label: "Nita",
    access: "free",
    variant: "",
    fileName: "nita.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/nita.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/nita.png",
    generation: ""
  },
  {
    value: "recETBmDVjzCiIZMz",
    label: "Noland",
    access: "free",
    variant: "",
    fileName: "noland.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/noland.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/noland.png",
    generation: ""
  },
  {
    value: "recArWZ6KGHBEE8jN",
    label: "Norman",
    access: "free",
    variant: "",
    fileName: "norman.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/norman.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/norman.png",
    generation: ""
  },
  {
    value: "recurGtKqlslYiJrl",
    label: "Olivia",
    access: "free",
    variant: "",
    fileName: "olivia.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/olivia.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/olivia.png",
    generation: ""
  },
  {
    value: "recnfD1U9BUnx6ppT",
    label: "Olivia",
    access: "premium",
    variant: "EX",
    fileName: "olivia2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/olivia2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/olivia2.png",
    generation: ""
  },
  {
    value: "recieWKl579nLMb5f",
    label: "Palmer",
    access: "free",
    variant: "",
    fileName: "palmer.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/palmer.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/palmer.png",
    generation: ""
  },
  {
    value: "recgWKdvCRI9xUrKA",
    label: "Petrel",
    access: "free",
    variant: "",
    fileName: "petrel.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/petrel.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/petrel.png",
    generation: ""
  },
  {
    value: "recWhxo78uB0Y8ilu",
    label: "Phoebe",
    access: "free",
    variant: "",
    fileName: "phoebe.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/phoebe.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/phoebe.png",
    generation: ""
  },
  {
    value: "recAUQQDBqnXA27rQ",
    label: "Phoebe",
    access: "premium",
    variant: "EX",
    fileName: "phoebe2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/phoebe2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/phoebe2.png",
    generation: ""
  },
  {
    value: "recwLdIx8wpGgQl19",
    label: "Piers",
    access: "free",
    variant: "",
    fileName: "piers.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/piers.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/piers.png",
    generation: ""
  },
  {
    value: "recq4zvwnxTTE7ub9",
    label: "Piers",
    access: "premium",
    variant: "EX",
    fileName: "piers2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/piers2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/piers2.png",
    generation: ""
  },
  {
    value: "recYKJdMceMeOboyw",
    label: "Plumeria",
    access: "free",
    variant: "",
    fileName: "plumeria.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/plumeria.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/plumeria.png",
    generation: ""
  },
  {
    value: "reck6DMSKYgEFJOhj",
    label: "Plumeria",
    access: "premium",
    variant: "EX",
    fileName: "plumeria2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/plumeria2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/plumeria2.png",
    generation: ""
  },
  {
    value: "recvcjafsurYCBKIm",
    label: "Professor Kukui",
    access: "free",
    variant: "",
    fileName: "professorkukui.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/professorkukui.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/professorkukui.png",
    generation: ""
  },
  {
    value: "reclYMWqhboI135oK",
    label: "Professor Kukui",
    access: "premium",
    variant: "Masked Royal",
    fileName: "themaskedroyal.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/themaskedroyal.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/themaskedroyal.png",
    generation: ""
  },
  {
    value: "recD4af9PHIe8sQuw",
    label: "Professor Kukui",
    access: "premium",
    variant: "Masked Royal EX",
    fileName: "themaskedroyal2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/themaskedroyal2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/themaskedroyal2.png",
    generation: ""
  },
  {
    value: "recPDAfo4J45yP7gr",
    label: "Professor Oak",
    access: "free",
    variant: "",
    fileName: "professoroak.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/professoroak.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/professoroak.png",
    generation: ""
  },
  {
    value: "recbOsrzOdKijxlGR",
    label: "Professor Sycamore",
    access: "free",
    variant: "",
    fileName: "professorsycamore.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/professorsycamore.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/professorsycamore.png",
    generation: ""
  },
  {
    value: "reco0R9rV5sLyQynb",
    label: "Proton",
    access: "free",
    variant: "",
    fileName: "proton.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/proton.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/proton.png",
    generation: ""
  },
  {
    value: "recXBeTZyAPg5HZwZ",
    label: "Pryce",
    access: "free",
    variant: "",
    fileName: "pryce.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/pryce.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/pryce.png",
    generation: ""
  },
  {
    value: "recBc3TAn6WsyBzWQ",
    label: "Pryce",
    access: "premium",
    variant: "EX",
    fileName: "pryce2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/pryce2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/pryce2.png",
    generation: ""
  },
  {
    value: "recQ3o1vzUbA5nHeX",
    label: "Rachel",
    access: "free",
    variant: "",
    fileName: "rachel.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/rachel.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/rachel.png",
    generation: ""
  },
  {
    value: "recO8gYQpXKHS5JZd",
    label: "Raihan",
    access: "free",
    variant: "",
    fileName: "raihan.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/raihan.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/raihan.png",
    generation: ""
  },
  {
    value: "rectKE8cI17YGs3ti",
    label: "Raihan",
    access: "premium",
    variant: "EX",
    fileName: "raihan2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/raihan2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/raihan2.png",
    generation: ""
  },
  {
    value: "recaJBfNNQS3IMM3l",
    label: "Raihan",
    access: "premium",
    variant: "Anniversary 2022",
    fileName: "raihan-anniversary2022.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/raihan-anniversary2022.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/raihan-anniversary2022.png",
    generation: ""
  },
  {
    value: "recCK9ZrCnTy7FRJe",
    label: "Raihan",
    access: "premium",
    variant: "Anniversary 2022 EX",
    fileName: "raihan-anniversary20222.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/raihan-anniversary20222.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/raihan-anniversary20222.png",
    generation: ""
  },
  {
    value: "recH2EwNnDMueqAgL",
    label: "Ramos",
    access: "free",
    variant: "",
    fileName: "ramos.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/ramos.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/ramos.png",
    generation: ""
  },
  {
    value: "recr51OuqvLVPZYfg",
    label: "Red",
    access: "free",
    variant: "",
    fileName: "red.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/red.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/red.png",
    generation: ""
  },
  {
    value: "recCjjZglPP3rYX89",
    label: "Red",
    access: "premium",
    variant: "Sygna",
    fileName: "red-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/red-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/red-sygna.png",
    generation: ""
  },
  {
    value: "recQEV2oT606S4kYp",
    label: "Red",
    access: "premium",
    variant: "Sygna EX",
    fileName: "red-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/red-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/red-sygna2.png",
    generation: ""
  },
  {
    value: "recDGqqM48AVshWQm",
    label: "Red",
    access: "premium",
    variant: "Thunderbolt",
    fileName: "red-thunderbolt.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/red-thunderbolt.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/red-thunderbolt.png",
    generation: ""
  },
  {
    value: "recoJWVKx9r1Y94mL",
    label: "Red",
    access: "premium",
    variant: "Thunderbolt EX",
    fileName: "red-thunderbolt2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/red-thunderbolt2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/red-thunderbolt2.png",
    generation: ""
  },
  {
    value: "recHKsmLwG1cnuLEw",
    label: "Roark",
    access: "free",
    variant: "",
    fileName: "roark.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/roark.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/roark.png",
    generation: ""
  },
  {
    value: "recnTsZtY1bvgAwJI",
    label: "Rosa",
    access: "free",
    variant: "",
    fileName: "rosa.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/rosa.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/rosa.png",
    generation: ""
  },
  {
    value: "recz7nydJwMDOEDZy",
    label: "Rosa",
    access: "premium",
    variant: "EX",
    fileName: "rosa2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/rosa2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/rosa2.png",
    generation: ""
  },
  {
    value: "recLl6LwqJzjUaFfy",
    label: "Rosa",
    access: "premium",
    variant: "Holidays",
    fileName: "rosa-holidays.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/rosa-holidays.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/rosa-holidays.png",
    generation: ""
  },
  {
    value: "recQdVANX4MrcFiRb",
    label: "Rosa",
    access: "premium",
    variant: "Holidays EX",
    fileName: "rosa-holidays2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/rosa-holidays2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/rosa-holidays2.png",
    generation: ""
  },
  {
    value: "rec57ew7pSxXUWDqX",
    label: "Rosa",
    access: "premium",
    variant: "Special",
    fileName: "rosa-special.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/rosa-special.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/rosa-special.png",
    generation: ""
  },
  {
    value: "recRtevh34VNU9FMv",
    label: "Rosa",
    access: "premium",
    variant: "Special EX",
    fileName: "rosa-special2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/rosa-special2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/rosa-special2.png",
    generation: ""
  },
  {
    value: "recXE9tbw5YjmVosU",
    label: "Roxanne",
    access: "free",
    variant: "",
    fileName: "roxanne.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/roxanne.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/roxanne.png",
    generation: ""
  },
  {
    value: "rec1Jh69UeSM34JfY",
    label: "Roxie",
    access: "free",
    variant: "",
    fileName: "roxie.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/roxie.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/roxie.png",
    generation: ""
  },
  {
    value: "recPqZiZt28ZLUlAy",
    label: "Ryuki",
    access: "free",
    variant: "",
    fileName: "ryuki.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/ryuki.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/ryuki.png",
    generation: ""
  },
  {
    value: "reckIpKCUZi0yY3aJ",
    label: "Sabrina",
    access: "free",
    variant: "",
    fileName: "sabrina.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/sabrina.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/sabrina.png",
    generation: ""
  },
  {
    value: "recQ0HerHJefcAQdy",
    label: "Sabrina",
    access: "premium",
    variant: "EX",
    fileName: "sabrina2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/sabrina2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/sabrina2.png",
    generation: ""
  },
  {
    value: "recnCG9jaFgSvalCG",
    label: "Sabrina",
    access: "premium",
    variant: "New Year",
    fileName: "sabrina-newyear.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/sabrina-newyear.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/sabrina-newyear.png",
    generation: ""
  },
  {
    value: "recRfKedumWEZ9YD6",
    label: "Sabrina",
    access: "premium",
    variant: "New Year EX",
    fileName: "sabrina-newyear2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/sabrina-newyear2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/sabrina-newyear2.png",
    generation: ""
  },
  {
    value: "recb8FbhnmVTugOjq",
    label: "Saturn",
    access: "free",
    variant: "",
    fileName: "saturn.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/saturn.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/saturn.png",
    generation: ""
  },
  {
    value: "recK4b1pmu91WYm5Q",
    label: "Sawyer",
    access: "free",
    variant: "",
    fileName: "sawyer.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/sawyer.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/sawyer.png",
    generation: ""
  },
  {
    value: "recAi8wFCnrnTsiB5",
    label: "Selene",
    access: "free",
    variant: "",
    fileName: "selene.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/selene.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/selene.png",
    generation: ""
  },
  {
    value: "rec3mOjvIu6DE5KS9",
    label: "Selene",
    access: "premium",
    variant: "EX",
    fileName: "selene2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/selene2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/selene2.png",
    generation: ""
  },
  {
    value: "reco83k9uoNf3pXYt",
    label: "Serena",
    access: "free",
    variant: "",
    fileName: "serena.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/serena.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/serena.png",
    generation: ""
  },
  {
    value: "rec3VbVaEQ0rSWNDH",
    label: "Serena",
    access: "premium",
    variant: "EX",
    fileName: "serena2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/serena2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/serena2.png",
    generation: ""
  },
  {
    value: "recmpjSgOURymNzcN",
    label: "Serena",
    access: "premium",
    variant: "Sygna",
    fileName: "serena-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/serena-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/serena-sygna.png",
    generation: ""
  },
  {
    value: "recIKRnAqtCvkd5eQ",
    label: "Serena",
    access: "premium",
    variant: "Sygna EX",
    fileName: "serena-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/serena-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/serena-sygna2.png",
    generation: ""
  },
  {
    value: "recwsGiZgcz0ZSs9w",
    label: "Serena",
    access: "premium",
    variant: "Valentines",
    fileName: "serena-valentines.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/serena-valentines.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/serena-valentines.png",
    generation: ""
  },
  {
    value: "recOS1KSqWNgoh19N",
    label: "Serena",
    access: "premium",
    variant: "Valentines EX",
    fileName: "serena-valentines2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/serena-valentines2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/serena-valentines2.png",
    generation: ""
  },
  {
    value: "recjCJdl9qpnwabtI",
    label: "Shadow Triad",
    access: "free",
    variant: "",
    fileName: "shadowtriad.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/shadowtriad.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/shadowtriad.png",
    generation: ""
  },
  {
    value: "rec1PbvHgLIm7EDAG",
    label: "Shauntal",
    access: "free",
    variant: "",
    fileName: "shauntal.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/shauntal.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/shauntal.png",
    generation: ""
  },
  {
    value: "recS8uvYE309oKhIB",
    label: "Shelley",
    access: "free",
    variant: "",
    fileName: "shelley.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/shelley.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/shelley.png",
    generation: ""
  },
  {
    value: "recnkxgnFlxN9uHrb",
    label: "Sidney",
    access: "free",
    variant: "",
    fileName: "sidney.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/sidney.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/sidney.png",
    generation: ""
  },
  {
    value: "recbCy4Llsc4r3paL",
    label: "Sidney",
    access: "premium",
    variant: "EX",
    fileName: "sidney2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/sidney2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/sidney2.png",
    generation: ""
  },
  {
    value: "recr61MwJHhBB7xh8",
    label: "Siebold",
    access: "free",
    variant: "",
    fileName: "siebold.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/siebold.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/siebold.png",
    generation: ""
  },
  {
    value: "rec3xFfliPvDnPJNj",
    label: "Siebold",
    access: "premium",
    variant: "EX",
    fileName: "siebold2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/siebold2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/siebold2.png",
    generation: ""
  },
  {
    value: "recFGYBlDZjFecgUt",
    label: "Siebold",
    access: "premium",
    variant: "Holidays",
    fileName: "siebold-holidays.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/siebold-holidays.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/siebold-holidays.png",
    generation: ""
  },
  {
    value: "recW04tr3FVhdcpWU",
    label: "Siebold",
    access: "premium",
    variant: "Holidays EX",
    fileName: "siebold-holidays2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/siebold-holidays2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/siebold-holidays2.png",
    generation: ""
  },
  {
    value: "recsQZ0PcPrThWTVs",
    label: "Silver",
    access: "free",
    variant: "",
    fileName: "silver.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/silver.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/silver.png",
    generation: ""
  },
  {
    value: "recW3OaR8Cg8sZRKE",
    label: "Silver",
    access: "premium",
    variant: "EX",
    fileName: "silver2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/silver2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/silver2.png",
    generation: ""
  },
  {
    value: "recpABAA3dYigRf3p",
    label: "Skyla",
    access: "free",
    variant: "",
    fileName: "skyla.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/skyla.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/skyla.png",
    generation: ""
  },
  {
    value: "recjy5qyklYiszX4F",
    label: "Skyla",
    access: "premium",
    variant: "EX",
    fileName: "skyla2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/skyla2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/skyla2.png",
    generation: ""
  },
  {
    value: "recOgTfRDf4eQvxp9",
    label: "Skyla",
    access: "premium",
    variant: "Anniversary 2022",
    fileName: "skyla-anniversary2022.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/skyla-anniversary2022.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/skyla-anniversary2022.png",
    generation: ""
  },
  {
    value: "recwkYrMpMhKqfIC1",
    label: "Skyla",
    access: "premium",
    variant: "Anniversary 2022 EX",
    fileName: "skyla-anniversary20222.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/skyla-anniversary20222.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/skyla-anniversary20222.png",
    generation: ""
  },
  {
    value: "recjtKcqGoE1XVMrI",
    label: "Skyla",
    access: "premium",
    variant: "Holidays",
    fileName: "skyla-holidays.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/skyla-holidays.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/skyla-holidays.png",
    generation: ""
  },
  {
    value: "recy2re5bRlvuYtNi",
    label: "Skyla",
    access: "premium",
    variant: "Holidays EX",
    fileName: "skyla-holidays2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/skyla-holidays2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/skyla-holidays2.png",
    generation: ""
  },
  {
    value: "rec1Ngd8oP7Xln4Pi",
    label: "Sonia",
    access: "free",
    variant: "",
    fileName: "sonia.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/sonia.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/sonia.png",
    generation: ""
  },
  {
    value: "recQ2BlOO2dx8vVDh",
    label: "Sonia",
    access: "premium",
    variant: "EX",
    fileName: "sonia2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/sonia2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/sonia2.png",
    generation: ""
  },
  {
    value: "rec2RF7gh5eZVOl2j",
    label: "Sonia",
    access: "premium",
    variant: "Special",
    fileName: "sonia-special.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/sonia-special.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/sonia-special.png",
    generation: ""
  },
  {
    value: "recaNvbzBc4JNqewB",
    label: "Sonia",
    access: "premium",
    variant: "Special EX",
    fileName: "sonia-special2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/sonia-special2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/sonia-special2.png",
    generation: ""
  },
  {
    value: "recGsi8VrqgnBzxRU",
    label: "Sophocles",
    access: "free",
    variant: "",
    fileName: "sophocles.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/sophocles.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/sophocles.png",
    generation: ""
  },
  {
    value: "recTvxLYR5Cy7aqCI",
    label: "Steven",
    access: "free",
    variant: "",
    fileName: "steven.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/steven.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/steven.png",
    generation: ""
  },
  {
    value: "recF3Cq0y4hDfPFbF",
    label: "Steven",
    access: "premium",
    variant: "EX",
    fileName: "steven2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/steven2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/steven2.png",
    generation: ""
  },
  {
    value: "recfSiYgUJWS1LVrc",
    label: "Steven",
    access: "premium",
    variant: "Anniversary 2021",
    fileName: "steven-anniversary2021.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/steven-anniversary2021.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/steven-anniversary2021.png",
    generation: ""
  },
  {
    value: "rectbr0J3BqHn83eC",
    label: "Steven",
    access: "premium",
    variant: "Anniversary 2021 EX",
    fileName: "steven-anniversary20212.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/steven-anniversary20212.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/steven-anniversary20212.png",
    generation: ""
  },
  {
    value: "rechq3ULdzjp77a4v",
    label: "Steven",
    access: "premium",
    variant: "Summer",
    fileName: "steven-summer.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/steven-summer.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/steven-summer.png",
    generation: ""
  },
  {
    value: "rec9F8iA4EuF2mPom",
    label: "Steven",
    access: "premium",
    variant: "Summer EX",
    fileName: "steven-summer2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/steven-summer2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/steven-summer2.png",
    generation: ""
  },
  {
    value: "recoSwfFWNCjxsgIM",
    label: "Steven",
    access: "premium",
    variant: "Sygna",
    fileName: "steven-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/steven-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/steven-sygna.png",
    generation: ""
  },
  {
    value: "rect2lBCIbZbg6vxX",
    label: "Steven",
    access: "premium",
    variant: "Sygna EX",
    fileName: "steven-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/steven-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/steven-sygna2.png",
    generation: ""
  },
  {
    value: "rec8yr8PN4DkuB8Uz",
    label: "Tabitha",
    access: "premium",
    variant: "",
    fileName: "tabitha.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/tabitha.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/tabitha.png",
    generation: ""
  },
  {
    value: "rec4VLGk4Rqm30iO1",
    label: "Tate",
    access: "free",
    variant: "",
    fileName: "tate.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/tate.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/tate.png",
    generation: ""
  },
  {
    value: "recFBBe0lLsbx2I2I",
    label: "Thorton",
    access: "free",
    variant: "",
    fileName: "thorton.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/thorton.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/thorton.png",
    generation: ""
  },
  {
    value: "recPidDPDDoHrDcWk",
    label: "Thorton",
    access: "premium",
    variant: "EX",
    fileName: "thorton2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/thorton2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/thorton2.png",
    generation: ""
  },
  {
    value: "rec1sui1nv41LgZrt",
    label: "Valerie",
    access: "free",
    variant: "",
    fileName: "valerie.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/valerie.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/valerie.png",
    generation: ""
  },
  {
    value: "recDKg5h3HX5DZ1mn",
    label: "Victor",
    access: "free",
    variant: "",
    fileName: "victor.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/victor.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/victor.png",
    generation: ""
  },
  {
    value: "recOMvwjkOVzi03DA",
    label: "Victor",
    access: "premium",
    variant: "EX",
    fileName: "victor2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/victor2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/victor2.png",
    generation: ""
  },
  {
    value: "recHX71IxZRWVvM6J",
    label: "Viola",
    access: "free",
    variant: "",
    fileName: "viola.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/viola.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/viola.png",
    generation: ""
  },
  {
    value: "recRsxZquhx0gUtEy",
    label: "Volkner",
    access: "free",
    variant: "",
    fileName: "volkner.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/volkner.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/volkner.png",
    generation: ""
  },
  {
    value: "recgtJMaSM8CjXiD2",
    label: "Volkner",
    access: "premium",
    variant: "EX",
    fileName: "volkner2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/volkner2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/volkner2.png",
    generation: ""
  },
  {
    value: "recjSzgKENgsFZKg0",
    label: "Volkner",
    access: "premium",
    variant: "New Year",
    fileName: "volkner-newyear.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/volkner-newyear.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/volkner-newyear.png",
    generation: ""
  },
  {
    value: "recUZ9pzmE4Hgyoq3",
    label: "Volkner",
    access: "premium",
    variant: "New Year EX",
    fileName: "volkner-newyear2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/volkner-newyear2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/volkner-newyear2.png",
    generation: ""
  },
  {
    value: "recJGHf1xCkOmNLhC",
    label: "Wallace",
    access: "free",
    variant: "",
    fileName: "wallace.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/wallace.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/wallace.png",
    generation: ""
  },
  {
    value: "recke0xRU2vyYILWz",
    label: "Wallace",
    access: "premium",
    variant: "EX",
    fileName: "wallace2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/wallace2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/wallace2.png",
    generation: ""
  },
  {
    value: "recyaE0QKkRJd8QC1",
    label: "Wally",
    access: "free",
    variant: "",
    fileName: "wally.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/wally.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/wally.png",
    generation: ""
  },
  {
    value: "recLlGE27c3j2ZDCQ",
    label: "Wally",
    access: "premium",
    variant: "EX",
    fileName: "wally2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/wally2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/wally2.png",
    generation: ""
  },
  {
    value: "rec0C5tMopg6rQusH",
    label: "Wally",
    access: "premium",
    variant: "Sygna",
    fileName: "wally-sygna.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/wally-sygna.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/wally-sygna.png",
    generation: ""
  },
  {
    value: "recZUH1EGdyDL3uAQ",
    label: "Wally",
    access: "premium",
    variant: "Sygna EX",
    fileName: "wally-sygna2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/wally-sygna2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/wally-sygna2.png",
    generation: ""
  },
  {
    value: "recULEmgHo4aMutev",
    label: "Whitney",
    access: "free",
    variant: "",
    fileName: "whitney.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/whitney.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/whitney.png",
    generation: ""
  },
  {
    value: "recVWx5eya7FVPtg1",
    label: "Wikstrom",
    access: "free",
    variant: "",
    fileName: "wikstrom.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/wikstrom.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/wikstrom.png",
    generation: ""
  },
  {
    value: "recCSSE5UqnQV4olJ",
    label: "Will",
    access: "free",
    variant: "",
    fileName: "will.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/will.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/will.png",
    generation: ""
  },
  {
    value: "recYD4ifl2BcvuHIP",
    label: "Winona",
    access: "free",
    variant: "",
    fileName: "winona.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/winona.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/winona.png",
    generation: ""
  },
  {
    value: "recJOjc9VsbPMnJDw",
    label: "Wulfric",
    access: "free",
    variant: "",
    fileName: "wulfric.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/wulfric.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/wulfric.png",
    generation: ""
  },
  {
    value: "recOFTk2OomTNObCi",
    label: "Zinnia",
    access: "free",
    variant: "",
    fileName: "zinnia.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/zinnia.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/zinnia.png",
    generation: ""
  },
  {
    value: "rec4JUpJ07XrXPJuW",
    label: "Zinnia",
    access: "premium",
    variant: "EX",
    fileName: "zinnia2.png",
    avatar:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/profile/zinnia2.png",
    avatarFull:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/trainers/full/zinnia2.png",
    generation: ""
  }
];
