import { useState } from "react";
import { Pressable, ScrollView, View } from "react-native";
import { useDimensions } from "react-native-web-hooks";
import styled from "styled-components/native";
import { IconButton } from "../components/atoms/Button";
import { SearchInput } from "../components/atoms/Input";
import { LoadingSpinner } from "../components/atoms/LoadingSpinner";
import { BreakpointProps, lt } from "../components/breakpoints";
import {
  AddCircleIcon,
  AddIcon,
  CloseIcon,
  RefreshIcon,
  SortAscIcon,
  SortDescIcon
} from "../components/Icon";
import { NewHuntmodal } from "../components/modals/NewHuntModal";
import { Counter } from "../components/molecules/Counter";
import { EmptyButton, EmptyState } from "../components/molecules/EmptyState";
import {
  SortOverlay,
  SortOverlayActions,
  SortOverlayFooter,
  SortOverlayHeader,
  SortOverlayIconButton,
  SortOverlayRadioLabel,
  SortOverlayRadioList
} from "../components/molecules/SortOverlay";
import { Body, Caption, Label, SubDisplay } from "../components/Text";
import { useFirestore } from "../hooks/useFirestore";
import { HuntObject } from "../model/model";

const Container = styled.View`
  justify-content: flex-start;
  flex: 1;
  background-color: ${p => p.theme.colors.background};
`;

const CounterGroup = styled.View<BreakpointProps>`
  flex-direction: row;
  justify-content: center;
  flex: 1 1 0%;
  flex-wrap: wrap;
  padding: 20px;
  padding-top: 0;

  ${p =>
    lt(p, "small")
      ? `
    flex-direction: column;
    align-items: center;
  `
      : ``};
`;

const CountersSortOptions: {
  label: string;
  value: keyof HuntObject;
  type: string;
}[] = [
  {
    label: "Created",
    value: "created",
    type: "date"
  },
  {
    label: "Time Elapsed",
    value: "elapsedTime",
    type: "number"
  },
  {
    label: "Encounters",
    value: "encounters",
    type: "number"
  }
];

export const CountersScreen = () => {
  const [sortDir, setSortDir] = useState(false);
  const [sortKey, setSortKey] = useState(CountersSortOptions[0]);
  // const [sortType, setSortType] = useState<keyof HuntObject>("created");
  const [searchInput, setSearchInput] = useState("");
  const [sortOverlayOpen, setSortOverlayOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { hunts, user } = useFirestore();
  const dimensions = useDimensions();

  if (!hunts) {
    return <LoadingSpinner />;
  }

  const resetSort = () => {
    setSortOverlayOpen(false);
    setSortKey(CountersSortOptions[0]);
    setSortDir(false);
  };

  const sorted = sortDir;
  const SortIcon = sorted ? SortAscIcon : SortDescIcon;

  const sortedHunts = [...hunts];

  switch (sortKey.type) {
    case "number":
      sortedHunts.sort((a, b) => {
        const aVal = (a[sortKey.value] as number) || 0;
        const bVal = (b[sortKey.value] as number) || 0;

        return sortDir ? aVal - bVal : bVal - aVal;
      });
      break;
    case "date":
      sortedHunts.sort((a, b) => {
        const aVal = new Date(a[sortKey.value] as string).valueOf() || 0;
        const bVal = new Date(b[sortKey.value] as number).valueOf() || 0;

        return sortDir ? aVal - bVal : bVal - aVal;
      });
      break;
    default:
      sortedHunts.sort((a, b) => {
        const aVal = (a[sortKey.value] as string) || "";
        const bVal = (b[sortKey.value] as string) || "";

        const compare = aVal.toString().localeCompare(bVal.toString());
        return sortDir ? -1 * compare : compare;
      });
      break;
  }

  const filteredHunts = searchInput
    ? sortedHunts.filter(hunt =>
        hunt.target.label.toLowerCase().includes(searchInput.toLowerCase())
      )
    : sortedHunts;

  return (
    <Container>
      {!sortedHunts?.length ? (
        <EmptyState style={{ alignSelf: "center" }}>
          <SubDisplay color="primaryDarkest" weight="semibold">
            Welcome to ShinyHunt!
          </SubDisplay>
          <Label color="neutral" style={{ marginTop: 20 }}>
            Ready to start a hunt? Click the button below.
          </Label>
          <EmptyButton onPress={() => setModalOpen(true)}>
            <AddCircleIcon
              size={50}
              primaryFill="surface"
              secondaryFill="primary"
              primaryFillHover="primaryLight"
              secondaryFillHover="surface"
            />
          </EmptyButton>
        </EmptyState>
      ) : (
        <>
          <View style={{ padding: 20, flexDirection: "row" }}>
            <IconButton
              outline
              onPress={() => setSortOverlayOpen(!sortOverlayOpen)}
              Icon={SortIcon}
              style={{ marginRight: 12 }}
            />

            <IconButton
              outline
              onPress={() => setModalOpen(true)}
              Icon={AddIcon}
              style={{ marginRight: 12 }}
            />

            <SearchInput value={searchInput} onChangeText={setSearchInput} />
          </View>

          {sortOverlayOpen && (
            <SortOverlay>
              <SortOverlayHeader>
                <Caption color="primaryDarkest" weight="semibold">
                  SORT OPTIONS
                </Caption>
                <SortOverlayActions>
                  <SortOverlayIconButton
                    onPress={() => setSortOverlayOpen(false)}
                  >
                    <CloseIcon
                      primaryFill="primary"
                      primaryFillHover="primaryDarkest"
                      size={20}
                    />
                  </SortOverlayIconButton>
                </SortOverlayActions>
              </SortOverlayHeader>
              <SortOverlayRadioList>
                {CountersSortOptions.map((option, index) => (
                  <SortOverlayRadioLabel
                    onPress={() => setSortKey(option)}
                    checked={sortKey.value === `${option.value}`}
                    key={index}
                  >
                    <Body weight="semibold" color="primaryDark">
                      {option.label}
                    </Body>
                  </SortOverlayRadioLabel>
                ))}
              </SortOverlayRadioList>
              <SortOverlayFooter>
                <Pressable onPress={() => setSortDir(!sortDir)}>
                  <SortIcon size={20} primaryFill="primary" />
                </Pressable>
                <Pressable onPress={resetSort}>
                  <RefreshIcon
                    primaryFill="primaryLight"
                    secondaryFill="white"
                    primaryFillHover="primaryDark"
                    secondaryFillHover="white"
                    size={20}
                  />
                </Pressable>
              </SortOverlayFooter>
            </SortOverlay>
          )}

          <ScrollView style={{ flex: 1 }}>
            <CounterGroup dimensions={dimensions}>
              {filteredHunts.map(hunt => (
                <Counter key={hunt.uid} hunt={hunt} isAdmin={!!user?.isAdmin} />
              ))}
            </CounterGroup>
          </ScrollView>
        </>
      )}
      <NewHuntmodal visible={modalOpen} close={() => setModalOpen(false)} />
    </Container>
  );
};
