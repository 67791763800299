import { useNavigation } from "@react-navigation/core";
import React, { useRef } from "react";
import { useDimensions, useHover } from "react-native-web-hooks";
import styled, { useTheme } from "styled-components/native";
import { lt } from "./breakpoints";
import { IconProps } from "./Icon";
import { Caption } from "./Text";
import { NavigationContainerRef } from "@react-navigation/core";
import { RootStackParamList } from "../navTypes";
import { View } from "react-native";

const StyledTopMenuButton = styled.Pressable<{
  isHovered: boolean;
  active: boolean;
  mobile: boolean;
}>`
  height: 100%;
  padding: 0 10px;
  margin: 0 4px;
  position: relative;
  display: flex;
  flex-direction: ${p => (p.mobile ? "column" : "row")};
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  border: 3px solid
    ${props =>
      props.active || props.isHovered
        ? props.theme.colors.primary
        : "transparent"};
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 1.5px;
  ${p => !!p.mobile && `flex: 1;`}
`;

export const TopMenuButton = ({
  label,
  Icon,
  route,
  navRef,
  active
}: {
  Icon: React.FC<IconProps>;
  label: string;
  route: keyof RootStackParamList;
  navRef: NavigationContainerRef<RootStackParamList> | null;
  active: boolean;
}) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const dimensions = useDimensions();
  const theme = useTheme();
  const isMobile = !!lt({ dimensions, theme }, "small");

  return (
    <StyledTopMenuButton
      ref={ref}
      isHovered={isHovered}
      active={active}
      onPress={() => navRef?.navigate(route)}
      mobile={isMobile}
    >
      <View style={isMobile ? undefined : { paddingRight: 8 }}>
        <Icon
          primaryFill={active ? "primary" : "primaryDarkest"}
          secondaryFill="primaryDarkest"
          size={18}
        />
      </View>
      <Caption color="primaryDarkest" weight="semibold">
        {label}
      </Caption>
    </StyledTopMenuButton>
  );
};
