import { useNavigation } from "@react-navigation/native";
import React, { useRef, useState } from "react";
import { Platform, Pressable, TextInput, View } from "react-native";
import styled from "styled-components/native";
import { Button } from "../components/atoms/Button";
import { Hr } from "../components/atoms/Hr";
import { Input } from "../components/atoms/Input";
import { Link } from "../components/atoms/Link";
import { LoadingSpinner } from "../components/atoms/LoadingSpinner";
import { getErrorMessage } from "../components/errors";
import {
  AuthFormCard,
  AuthFormContainer,
  AuthFormInputs,
  AuthFormLogo
} from "../components/modals/AuthForm";
import { Caption } from "../components/Text";
import { useAuth } from "../hooks/useAuth";
import { Nav } from "../navTypes";

const wormholeImage = require("../assets/images/backgrounds/wormhole-bg.png");
const logoWhiteImage = require("../assets/images/logo-new-white.png");

export const SignInScreen = () => {
  const nav = useNavigation<Nav>();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const pwRef = useRef<TextInput>(null);

  const { signin } = useAuth();

  const isInvalid = !password || !email;

  const onSubmit = () => {
    if (!isInvalid) {
      setError(null);
      setIsSubmitting(true);

      signin(email, password)
        .then(
          user => {
            setEmail("");
            setPassword("");

            if (!user.user.emailVerified) {
              nav.navigate("Verify");
            }
          },
          e => {
            console.log("then error >>", e);
            setError(getErrorMessage(e));
          }
        )
        .catch((e: any) => {
          console.log("catch error >>", e);
          setError(getErrorMessage(e));
        })
        .finally(() => setTimeout(() => setIsSubmitting(false), 300));
    }
  };
  return (
    <AuthFormContainer source={wormholeImage}>
      <Pressable
        onPress={() =>
          nav.navigate(Platform.OS === "web" ? "Landing" : "SignIn")
        }
      >
        <AuthFormLogo source={logoWhiteImage} resizeMode="contain" />
      </Pressable>
      <AuthFormCard>
        <Caption weight="semibold">SIGN IN</Caption>
        <View style={{ marginTop: 10 }}>
          <AuthFormInputs>
            <Input
              textContentType="emailAddress"
              onChangeText={setEmail}
              value={email}
              placeholder="Email Address"
              autoCapitalize="none"
              returnKeyType="next"
              onSubmitEditing={() => pwRef.current?.focus()}
              blurOnSubmit={false}
            />
            <Input
              textContentType="password"
              secureTextEntry={true}
              onChangeText={setPassword}
              value={password}
              placeholder="Password"
              onSubmitEditing={onSubmit}
              autoCapitalize="none"
              returnKeyType="done"
              externalRef={pwRef}
            />
            <Button disabled={isInvalid} onPress={onSubmit}>
              Sign In
            </Button>
          </AuthFormInputs>
          {!!error && <Caption color="danger">{error}</Caption>}
        </View>
        <Link route="ForgotPassword">Forgot your password?</Link>
        <Hr />
        <Button onPress={() => nav.navigate("SignUp")}>
          Create New Account
        </Button>
      </AuthFormCard>
      {!!isSubmitting && <LoadingSpinner />}
    </AuthFormContainer>
  );
};
