import React from "react";
import { Image, View } from "react-native";
import styled from "styled-components/native";
import { Link } from "./atoms/Link";
import { EnterIcon } from "./Icon";
import { Caption } from "./Text";

const Container = styled.View`
  top: 0;
  position: absolute;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  z-index: 2;
`;

const LogoButton = styled.View`
  height: 30px;
  width: 140px;
`;

const Login = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const Header = () => {
  return (
    <Container>
      <Link
        accessibilityLabel="Go Home"
        accessibilityRole="button"
        route="Landing"
      >
        <LogoButton>
          <Image
            source={require("../assets/images/logo-new-white.png")}
            style={{ width: "100%", height: "100%" }}
            resizeMode="contain"
          />
        </LogoButton>
      </Link>
      <Link route="SignIn">
        <Login>
          <EnterIcon
            primaryFill="primaryLight"
            secondaryFill="white"
            size={20}
          />
          <Caption color="white" margin="0 0 0 8px">
            Sign In
          </Caption>
        </Login>
      </Link>
    </Container>
  );
};
