import React, { useRef } from "react";
import { PressableProps } from "react-native";
import { useActive, useHover } from "react-native-web-hooks";
import styled from "styled-components/native";
import { IconProps } from "../Icon";
import { Body } from "../Text";

const StyledButton = styled.Pressable<
  {
    isHovered: boolean;
    isActive?: boolean;
    disabled?: boolean | null;
  } & ButtonProps
>`
  background-color: ${props => props.theme.colors.primary};
  border: none;
  border-radius: 4px;
  height: 38px;
  max-width: 270px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 20px;

  ${({ isHovered, isActive, theme, danger }) =>
    isHovered && danger
      ? `background-color: ${theme.colors.danger}`
      : isHovered || isActive
      ? `background-color: ${theme.colors.primaryDark}`
      : ""}

  ${({ disabled, theme }) =>
    disabled &&
    `
    background-color: ${theme.colors.neutralLight};
  `}
`;

export type ButtonProps = {
  danger?: boolean;
  isActive?: boolean;
  noWrapper?: boolean;
};

export const Button: React.FC<PressableProps & ButtonProps> = props => {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const isActive = useActive(ref);

  return (
    <StyledButton
      {...props}
      isHovered={isHovered}
      isActive={props.isActive}
      ref={ref}
    >
      {props.noWrapper ? (
        props.children
      ) : (
        <Body weight="semibold" color="white">
          {props.children}
        </Body>
      )}
    </StyledButton>
  );
};

const StyledIconButton = styled.Pressable<{
  isHovered: boolean;
  outline?: boolean;
}>`
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 100px;

  ${({ outline, isHovered, theme }) =>
    outline
      ? `
      border: 2px solid ${theme.colors.primaryLight};
      background-color: ${
        isHovered ? theme.colors.primaryLight : theme.colors.surface
      };
      `
      : `
      background-color: ${
        isHovered ? theme.colors.primaryLight : theme.colors.primary
      };
  `}
`;

export const IconButton: React.FC<
  PressableProps & { Icon: React.FC<IconProps>; outline?: boolean }
> = ({ Icon, outline, children, ...props }) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  return (
    <StyledIconButton
      isHovered={isHovered}
      ref={ref}
      outline={outline}
      {...props}
    >
      <Icon
        size={20}
        primaryFill={isHovered ? "white" : outline ? "primary" : "white"}
      />
    </StyledIconButton>
  );
};
