import { useRef } from "react";
import { PressableProps } from "react-native";
import { useDimensions, useHover } from "react-native-web-hooks";
import styled, { useTheme } from "styled-components/native";
import { useAuth } from "../hooks/useAuth";
import { useFirestore } from "../hooks/useFirestore";
import { Link } from "./atoms/Link";
import { lt } from "./breakpoints";
import { getAvatarImage } from "./helpers";
import { ExitIcon, SettingsIcon } from "./Icon";
import { Body, Caption, Legal } from "./Text";
import { ModalType, UserMenuButton } from "./TopNav";

const ProfileAreaButtonImg = styled.Image`
  width: 20px;
`;

const ProfileAreaAvatarImg = styled.Image`
  width: 30px;
`;

const UserMenuContainer = styled.View`
  position: absolute;
  top: 0;
  right: 0;
  margin: 60px 10px 0;
  z-index: 20;
  background: ${props => props.theme.colors.surface};
  min-width: 270px;
  border-radius: 8px;
  box-shadow: ${props => props.theme.shadows.light};
`;

const UserHeaderArea = styled.View`
  align-items: center;
  flex-direction: row;
  padding: 20px;
`;

const UserHeaderAvatar = styled.View`
  height: 40px;
  width: 40px;
`;

const StyledUserAvatarButton = styled.Pressable`
  border: none;
  margin: 0;
  padding: 0;
  height: 100%;
  position: relative;
`;

const EditText = styled.View`
  position: absolute;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const UserAvatarButton: React.FC<PressableProps & { mobile: boolean }> = ({
  children,
  mobile,
  ...props
}) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);

  return (
    <StyledUserAvatarButton {...props} ref={ref}>
      <>
        {children}
        {isHovered || mobile ? (
          <EditText>
            <Body color="white">edit</Body>
          </EditText>
        ) : null}
      </>
    </StyledUserAvatarButton>
  );
};

const UserHeaderAvatarImg = styled.Image<{ screenSize: number }>`
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;

const UserHeaderInfo = styled.View`
  margin-left: 10px;
`;

const UserMainContent = styled.View`
  flex-direction: column;
  padding: 8px 0;
`;

const UserFooterArea = styled.View`
  padding: 20px;
`;

const FooterLegal = styled.View`
  flex-direction: row;
`;

const Paragraph = styled.Text`
  line-height: 14px;
`;

const Separator = styled.View`
  height: 3px;
  border-radius: 1.5px;
  background: ${props => props.theme.colors.background};
  width: 50%;
  margin: auto;
`;

export type UserMenuProps = {
  setModal: (type: ModalType) => void;
};

export const UserMenu = (props: UserMenuProps) => {
  const { setModal } = props;
  const dimensions = useDimensions();
  const theme = useTheme();
  const { user } = useFirestore();
  const { authUser, signout } = useAuth();

  if (!user || !authUser) {
    return null;
  }

  return (
    <UserMenuContainer>
      <UserHeaderArea>
        <UserHeaderAvatar>
          <UserAvatarButton
            mobile={lt({ dimensions, theme }, "small")}
            // onPress={() => setModal(ModalType.AVATAR)}
            onPress={() => setModal(ModalType.SETTING)}
          >
            <UserHeaderAvatarImg
              screenSize={dimensions.window.width}
              resizeMode="contain"
              source={{ uri: getAvatarImage(user.avatar) }}
            />
          </UserAvatarButton>
        </UserHeaderAvatar>
        <UserHeaderInfo>
          <Body color="text" weight="semibold">
            {user.displayName}
          </Body>
          <Caption color="neutralDark">{authUser.email}</Caption>
        </UserHeaderInfo>
      </UserHeaderArea>
      <Separator />
      <UserMainContent>
        <UserMenuButton
          onPress={() => setModal(ModalType.SETTING)}
          Icon={SettingsIcon}
          label="Settings"
        />
        <UserMenuButton onPress={signout} Icon={ExitIcon} label="Sign out" />
      </UserMainContent>
      <Separator />
      <UserFooterArea>
        <Caption color="neutral" style={{ marginBottom: 4 }}>
          © Poketch, LLC., v2.2.1
        </Caption>
        <FooterLegal style={{ marginTop: 4 }}>
          <Link href="https://discord.gg/DbVSfns">
            <Legal color="neutral" style={{ marginRight: 8 }}>
              Discord
            </Legal>
          </Link>
          <Link href="https://twitter.com/poketchapp">
            <Legal color="neutral">Twitter</Legal>
          </Link>
        </FooterLegal>
      </UserFooterArea>
    </UserMenuContainer>
  );
};
