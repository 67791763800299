import { useEffect, useMemo, useState } from "react";
import {
  FlatList,
  Linking,
  ListRenderItemInfo,
  Platform,
  Pressable,
  StyleSheet,
  View
} from "react-native";
import { useDimensions } from "react-native-web-hooks";
import styled, { useTheme } from "styled-components/native";
import { IconButton } from "../components/atoms/Button";
import { SearchInput } from "../components/atoms/Input";
import { LoadingSpinner } from "../components/atoms/LoadingSpinner";
import { getBp, lt } from "../components/breakpoints";
import {
  CloseIcon,
  RefreshIcon,
  SortAscIcon,
  SortDescIcon
} from "../components/Icon";
import {
  SortOverlay,
  SortOverlayActions,
  SortOverlayFooter,
  SortOverlayHeader,
  SortOverlayIconButton,
  SortOverlayRadioLabel,
  SortOverlayRadioList
} from "../components/molecules/SortOverlay";
import { Body, Caption, Headline } from "../components/Text";
import { Guide, GUIDES } from "../constants/guides";

const Container = styled.View`
  justify-content: flex-start;
  flex: 1;
  background-color: ${p => p.theme.colors.background};
`;

const VideoGroup = styled.View`
  padding: 20px;
  width: 100%;
  background-color: ${p => p.theme.colors.background};
`;

const VideoCardContainer = styled.View`
  flex: 1;
`;

const VideoCard = styled.Pressable<{ hovered?: boolean }>`
  width: 100%;
  flex-direction: column;
  background: ${props => props.theme.colors.surface};
  border-radius: 10px;
  color: ${props => props.theme.colors.primaryDarkest};
`;

const VideoThumbnail = styled.Image`
  ${Platform.OS === "web" ? `aspect-ratio: 16 / 9` : `height: 150px`};
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const VideoInfo = styled.View`
  padding: 12px;
`;

// const sorted = [...GUIDES];
// sorted.sort((a, b) => a.order - b.order);

const guideSortOptions: {
  label: string;
  value: keyof Guide;
  type: string;
}[] = [
  {
    label: "Published Date",
    value: "order",
    type: "number"
  },
  {
    label: "Title",
    value: "title",
    type: "string"
  }
];

const colsMap = {
  small: 2,
  medium: 3,
  large: 3,
  xlarge: 3
};

export const GuidesScreen = () => {
  const [sortKey, setSortKey] = useState(guideSortOptions[0]);
  const [sortAsc, setSortAsc] = useState(false);
  const guides = [...GUIDES];
  const [sorted, setSorted] = useState<Guide[]>();
  const [searchInput, setSearchInput] = useState("");
  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [statsModalOpen, setStatsModalOpen] = useState(false);
  const theme = useTheme();
  const dimensions = useDimensions();

  const bp = getBp({ dimensions, theme });
  const numCols = bp ? colsMap[bp] : 1;

  useEffect(() => {
    const newSorted = [...GUIDES];

    switch (sortKey.type) {
      case "number":
        newSorted.sort((a, b) => {
          const aVal = (a[sortKey.value] as number) || 0;
          const bVal = (b[sortKey.value] as number) || 0;

          return sortAsc ? bVal - aVal : aVal - bVal;
        });
        setSorted(newSorted);
        break;
      default:
        newSorted.sort((a, b) => {
          const aVal = (a[sortKey.value] as string) || "";
          const bVal = (b[sortKey.value] as string) || "";

          const compare = aVal.toString().localeCompare(bVal.toString());
          return sortAsc ? compare : -1 * compare;
        });
        setSorted(newSorted);
        break;
    }
  }, [sortAsc, sortKey]);

  const guideStyleSheet = useMemo(() => {
    const s = lt({ dimensions, theme }, "small");

    return theme
      ? StyleSheet.create({
          container: {
            position: "relative",
            width: `${(1 / numCols) * 100}%`,
            // height: s ? 140 : 250,
            padding: 5,
            overflow: "hidden",
            borderRadius: 10
          }
        })
      : null;
  }, [theme, dimensions]);

  const renderGuideItem = useMemo(
    () =>
      guideStyleSheet
        ? ({ item }: ListRenderItemInfo<Guide>) => (
            <View style={guideStyleSheet.container}>
              {/* <VideoCardContainer> */}
              <VideoCard onPress={() => Linking.openURL(item.videoUrl)}>
                <VideoThumbnail source={{ uri: item.thumbnail }} />
                <VideoInfo>
                  <Caption color="primaryDarkest">{item.tags}</Caption>
                  {/* <View style={{ height: 2 }} /> */}
                  <Headline color="primaryDarkest">{item.title}</Headline>
                </VideoInfo>
              </VideoCard>
              {/* </VideoCardContainer> */}
            </View>
          )
        : null,
    [guideStyleSheet]
  );

  if (!sorted) {
    return <LoadingSpinner />;
  }

  const filtered = sorted.filter(guide =>
    guide.tags.toLowerCase().includes(searchInput.toLowerCase())
  );
  const SortIcon = sortAsc ? SortAscIcon : SortDescIcon;
  const isSorted = sortAsc || sortKey !== guideSortOptions[0];

  return (
    <Container>
      <View style={{ padding: 20, flexDirection: "row" }}>
        <IconButton
          outline
          onPress={() => setSortModalOpen(!sortModalOpen)}
          Icon={SortIcon}
          style={{ marginRight: 12 }}
        />

        {/* <IconButton
          outline
          onPress={() => setStatsModalOpen(!statsModalOpen)}
          Icon={MonitorIcon}
          style={{ marginRight: 12 }}
        /> */}

        <SearchInput
          onChangeText={setSearchInput}
          value={searchInput}
          placeholder="Search..."
        />
      </View>

      {sortModalOpen && (
        <SortOverlay>
          <SortOverlayHeader>
            <Caption weight="semibold">SORT OPTIONS</Caption>
            <SortOverlayActions>
              <SortOverlayIconButton onPress={() => setSortModalOpen(false)}>
                <CloseIcon
                  primaryFill="primaryLight"
                  primaryFillHover="primaryDarkest"
                  size={20}
                />
              </SortOverlayIconButton>
            </SortOverlayActions>
          </SortOverlayHeader>

          <SortOverlayRadioList>
            {guideSortOptions.map((option, index) => (
              <SortOverlayRadioLabel
                checked={sortKey === option}
                key={index}
                onPress={() => setSortKey(option)}
              >
                <Body weight="semibold" color="primaryDark">
                  {option.label}
                </Body>
              </SortOverlayRadioLabel>
            ))}
          </SortOverlayRadioList>
          <SortOverlayFooter>
            <Pressable onPress={() => setSortAsc(!sortAsc)}>
              <SortIcon
                primaryFill={isSorted ? "primaryDarkest" : "primaryLight"}
                secondaryFill={isSorted ? "primaryDarkest" : "primaryLight"}
                primaryFillHover="primaryDarkest"
                secondaryFillHover="primaryDarkest"
                size={20}
              />
            </Pressable>

            <Pressable
              onPress={() => {
                setSortAsc(false);
                setSortKey(guideSortOptions[0]);
              }}
              disabled={!isSorted}
            >
              <RefreshIcon
                primaryFill="primaryLight"
                secondaryFill="white"
                primaryFillHover="primaryDark"
                secondaryFillHover="white"
                size={20}
              />
            </Pressable>
          </SortOverlayFooter>
        </SortOverlay>
      )}

      <FlatList
        key={`guide${numCols}`}
        numColumns={numCols}
        data={filtered}
        keyExtractor={item => item.videoUrl}
        style={{ paddingHorizontal: 20, paddingBottom: 20 }}
        removeClippedSubviews
        windowSize={2}
        renderItem={renderGuideItem}
      />
      {/*       
      <VideoGroup>
        {sorted.map((guide, i) => (
          <VideoCardContainer key={i}>
            <VideoCard onPress={() => Linking.openURL(guide.videoUrl)}>
              <VideoThumbnail source={{ uri: guide.thumbnail }} />
              <VideoInfo>
                <Caption color="primaryDarkest">{guide.tags}</Caption>
                <View style={{ height: 2 }} />
                <Headline color="primaryDarkest">{guide.title}</Headline>
              </VideoInfo>
            </VideoCard>
          </VideoCardContainer>
        ))}
      </VideoGroup> */}
    </Container>
  );
};
