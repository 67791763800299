import React from "react";
import { View } from "react-native";
import { useDimensions } from "react-native-web-hooks";
import styled from "styled-components/native";
import { Link } from "./atoms/Link";
import { BreakpointProps, lt } from "./breakpoints";
import { Legal } from "./Text";

const Container = styled(View)<BreakpointProps>`
  background: ${props => props.theme.colors.neutralLightest};
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 20px 16px;

  ${p =>
    lt(p, "large") &&
    `
    flex-direction: column-reverse;
  `}
`;

const FooterInfo = styled(View)`
  flex: 1 0 50%;
  margin-top: 16px;
  padding: 0;
`;

const FooterMenu = styled(View)<BreakpointProps>`
  flex: 1 0 50%;
  margin: 0;
  padding: 0;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;

  ${p =>
    lt(p, "large") &&
    `
    justify-content: flex-start;
    `}
`;

export const Footer = () => {
  const dims = useDimensions();
  return (
    <Container dimensions={dims}>
      <FooterInfo>
        <Legal>
          <Link textProps={{ fontSize: "legal" }} href="https://poketchapp.com">
            © <Legal>Poketch, LLC</Legal>
          </Link>{" "}
          |{" "}
          <Link textProps={{ fontSize: "legal" }} route="Terms">
            <Legal>Terms & Privacy</Legal>
          </Link>{" "}
        </Legal>
        <Legal margin="8px 0 0">
          Pokémon, Pokémon character names, Nintendo 3DS, Nintendo DS, Wii, Wii
          U, Wiiware, and Nintendo Switch are trademarks of Nintendo. Other
          trademarks are the property of their respective owners.
        </Legal>
      </FooterInfo>
      <FooterMenu dimensions={dims} accessibilityRole="menu">
        <Link
          textProps={{ fontSize: "legal" }}
          href="https://discord.gg/DbVSfns"
        >
          <Legal>Discord</Legal>
        </Link>
        <Link href="https://twitter.com/poketchapp">
          <Legal style={{ marginLeft: 8 }}>Twitter</Legal>
        </Link>
      </FooterMenu>
    </Container>
  );
};
