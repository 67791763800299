import React from "react";
import { Platform, StyleSheet, Text, TextProps, TextStyle } from "react-native";
import styled, { DefaultTheme } from "styled-components/native";

export type StyledTextProps = {
  color?: keyof DefaultTheme["colors"];
  weight?: keyof DefaultTheme["weights"];
  font?: keyof DefaultTheme["fonts"];
  fontSize?: keyof DefaultTheme["fontSizes"];
  lineHeightNormal?: boolean;
  uppercase?: boolean;
  noTextFix?: boolean;
};

export const StyledText = styled.Text<StyledTextProps>`
  ${({ theme, ...props }) => textDefaults(theme, props)}
`;

export const textDefaults = (
  theme: DefaultTheme,
  props: StyledTextProps = {}
) => {
  const {
    font,
    fontSize,
    lineHeightNormal,
    weight,
    color,
    uppercase,
    noTextFix
  } = props;

  const size = fontSize ? theme.fontSizes[fontSize] : theme.fontSizes.body;
  return `
    font-family: ${font ? theme.fonts[font] : theme.fonts.body}${
    weight ? theme.weights[weight] : theme.weights.regular
  };
    font-size: ${size}px;
    color: ${color ? theme.colors[color] : theme.colors.text};
    text-transform: ${uppercase ? "uppercase" : "none"};

    ${
      Platform.OS === "web"
        ? `line-height: ${lineHeightNormal ? `${size * 1.5}px` : `${size}px`};`
        : `${
            !noTextFix
              ? `
        line-height: ${size}px;
        padding-top: ${size - size * 0.75}px;
      `
              : ``
          }`
    }
  `;
};

export const Heading: React.FC<
  { level?: number } & StyledTextProps & TextProps
> = ({ children, level, ...rest }) => (
  <StyledText {...rest} accessibilityRole="header" aria-level={level || 1}>
    {children}
  </StyledText>
);

export const Display: React.FC<StyledTextProps & TextProps> = ({
  children,
  ...rest
}) => (
  <Heading
    {...rest}
    level={1}
    font="header"
    weight="semibold"
    fontSize="display"
  >
    {children}
  </Heading>
);

export const SubDisplay: React.FC<StyledTextProps & TextProps> = ({
  children,
  ...rest
}) => (
  <Heading
    {...rest}
    level={2}
    font="header"
    fontSize="subdisplay"
    weight="semibold"
  >
    {children}
  </Heading>
);

export const Headline: React.FC<StyledTextProps & TextProps> = ({
  children,
  ...rest
}) => (
  <Heading
    {...rest}
    level={3}
    font="header"
    fontSize="headline"
    uppercase
    weight="semibold"
  >
    {children}
  </Heading>
);

export const Title: React.FC<StyledTextProps & TextProps> = ({
  children,
  ...rest
}) => (
  <Heading
    level={4}
    font="header"
    fontSize="title"
    uppercase
    weight="semibold"
    {...rest}
  >
    {children}
  </Heading>
);

export const Subtitle: React.FC<StyledTextProps & TextProps> = ({
  children,
  ...rest
}) => (
  <Heading
    {...rest}
    level={5}
    font="body"
    fontSize="subtitle"
    weight="semibold"
  >
    {children}
  </Heading>
);

export const Label: React.FC<StyledTextProps & TextProps> = ({
  children,
  ...rest
}) => (
  <StyledText {...rest} font="body" fontSize="label" lineHeightNormal={true}>
    {children}
  </StyledText>
);

export const Body: React.FC<StyledTextProps & TextProps> = ({
  children,
  ...rest
}) => (
  <StyledText
    {...rest}
    font="body"
    fontSize="body"
    lineHeightNormal={true}
    accessibilityRole="text"
  >
    {children}
  </StyledText>
);

export const Caption: React.FC<StyledTextProps & TextProps> = ({
  children,
  ...rest
}) => (
  <StyledText
    {...rest}
    font="body"
    fontSize="caption"
    lineHeightNormal={true}
    accessibilityRole="text"
  >
    {children}
  </StyledText>
);

export const Legal: React.FC<StyledTextProps & TextProps> = ({
  children,
  ...rest
}) => (
  <StyledText
    font="body"
    fontSize="legal"
    lineHeightNormal={true}
    accessibilityRole="text"
    {...rest}
  >
    {children}
  </StyledText>
);
