export type Guide = {
  thumbnail: string;
  videoUrl: string;
  description: string;
  title: string;
  tags: string;
  order: number;
};

export const GUIDES: Guide[] = [
  {
    thumbnail: "https://i.ytimg.com/vi/4nfRmV28QbM/mqdefault.jpg",
    videoUrl: "https://www.youtube.com/watch?v=4nfRmV28QbM",
    title: "Horde Guide",
    description:
      "This guide gives you the basics on Shiny hunting using the Horde method! It's a very simple and straightfoward method to finding shinies and usually yields pretty good results.",
    tags: "Pokémon Omega Ruby and Alpha Sapphire",
    order: 11
  },
  {
    thumbnail: "https://i.ytimg.com/vi/nI1LZZu8ctw/mqdefault.jpg",
    videoUrl: "https://www.youtube.com/watch?v=nI1LZZu8ctw",
    title: "DexNav Guide (Caves/Desert/Water)",
    description:
      "This tutorial shows you how to find shinies in Caves, in the desert and while surfing using the Dexnav device in Pokemon Omega Ruby Alpha Sapphire. There has been a lot of questions about this on my stream so I figured I'd make a quick video discussing the ins and outs! If you want to learn the basics from my original dexnav tutorial, you can view it below!",
    tags: "Pokémon Omega Ruby and Alpha Sapphire",
    order: 10
  },
  {
    thumbnail: "https://i.ytimg.com/vi/CHP146TOAmw/mqdefault.jpg",
    videoUrl: "https://www.youtube.com/watch?v=CHP146TOAmw",
    title: "DexNav Guide",
    description:
      "Dexnav Chaining Shiny Tutorial Video for Omega Ruby Alpha Sapphire. I've mastered the chain at this point and I've encountered a few shinies in doing so. I'm truly positive at this point there is a correlation between shinies and the chain.",
    tags: "Pokémon Omega Ruby and Alpha Sapphire",
    order: 9
  },
  {
    thumbnail: "https://i.ytimg.com/vi/uifzGY5GpE0/mqdefault.jpg",
    videoUrl: "https://www.youtube.com/watch?v=uifzGY5GpE0&t",
    title: "SOS Calling Guide",
    description:
      "How to get easy Shiny Pokemon in Pokemon Sun and Moon with the SOS method! In this video I discuss how to use Exeggutor and/or Trevenant to infinitely chain for Shiny Pokemon using the SOS Method! I've been getting questions about this a lot on stream and I wanted to share the knowledge. This method is incredibly easy to use once you've set up your Skill Swap/Harvest Pokemon and makes chaining Shiny Pokemon easier than ever! Be sure to Like and Subscribe for more Pokemon content and Shiny Reactions!",
    tags: "Pokémon Sun and Moon",
    order: 8
  },
  {
    thumbnail: "https://i.ytimg.com/vi/NTZ3v-skNuw/mqdefault.jpg",
    videoUrl: "https://www.youtube.com/watch?v=NTZ3v-skNuw&t",
    title: "Catch Combo Guide",
    description:
      "With the release of Pokemon Let's GO Pikachu and Eevee there has been a lot of passion and controversy about Shiny Hunting over the previous few weeks! In this video I break down the catch combo mechanic, talk about the debunked RAM theory, and also show you how to get competitive Shiny Pokemon! I sincerely hope this video helps you find Shiny Pokemon and clears up any misconceptions about how to find Shiny Pokemon in Pokemon Lets Go Pikachu and Eevee!",
    tags: "Pokémon Let's Go Pikachu and Let's Go Eevee",
    order: 7
  },
  {
    thumbnail: "https://i.ytimg.com/vi/_vO3UkXQAq8/mqdefault.jpg",
    videoUrl: "https://www.youtube.com/watch?v=_vO3UkXQAq8",
    title: "Ultimate Breeding Guide",
    description:
      "The Ultimate Pokemon Sword and Shield Breeding Guide teaching you how to breed for Shiny Pokemon and Competitive Pokemon from start to finish! In this video I show the basics of Breeding pokemon from obtaining an everstone to passing along a hidden ability! This is a great video for new breeders or anyone who needs a refresher course!",
    tags: "Pokémon Shield and Sword",
    order: 6
  },
  {
    thumbnail: "https://i.ytimg.com/vi/8W_gmOzN2S0/mqdefault.jpg",
    videoUrl: "https://www.youtube.com/watch?v=8W_gmOzN2S0",
    title: "Dynamax Adventures Guide",
    description:
      "All the details you need for the Shiny Hunting Method for Pokemon Sword and Shield Crown Tundra! In this video I break down the NEW Shiny Hunting Method and the odds to get a Shiny, one of our best Shiny Hunting Methods of all time! Breaking it all down including the Shiny Odds, how to get Shiny Pokemon easily and much more!",
    tags: "Pokémon Shield and Sword DLC",
    order: 5
  },
  {
    thumbnail: "https://i.ytimg.com/vi/K5YT_DDtcHY/mqdefault.jpg",
    videoUrl: "https://www.youtube.com/watch?v=K5YT_DDtcHY",
    title: "Pokéradar Guide",
    description:
      "How to get Shiny Pokemon in Pokemon Brilliant Diamond and Pokemon Shining Pearl! In this video I show you exactly how to track down Shiny Pokemon with the Pokeradar, a revamped Shiny Hunting tool from the old games! Pokeradar allows you to chain for a Shiny Pokemon with your max odds reaching 1/99! This is incredible and very easy to use once you get the hang of it. Pokemon has made Pokeradar much easier to use than years past!",
    tags: "Pokémon Brilliant Diamond and Shining Pearl",
    order: 4
  },
  {
    thumbnail: "https://i.ytimg.com/vi/Ab7CrjKRdco/mqdefault.jpg",
    videoUrl: "https://www.youtube.com/watch?v=Ab7CrjKRdco",
    title: "Underground Guide",
    description:
      "How to get Shiny Pokemon in Pokemon Brilliant Diamond and Pokemon Shining Pearl! In this video I show you how to use the Underground method taking advantage of the boost provided by Diglett! This diglett boost will also help you find color statues, but those have no impact on Shiny Chance. There are a few tips you can use to increase the speed of this method! Let me know if you have any luck Shiny Hunting in Pokemon Brilliant Diamond and SHining Pearl utilizing this method!",
    tags: "Pokémon Brilliant Diamond and Shining Pearl",
    order: 3
  },
  {
    thumbnail: "https://i.ytimg.com/vi/RwHOUmiI8AU/mqdefault.jpg",
    videoUrl: "https://www.youtube.com/watch?v=RwHOUmiI8AU",
    title: "Outbreak Method Guide",
    description:
      "The NEW Shiny Hunting Method in Pokemon Legends Arceus! The OUTBREAK Method! How to get Easy Shiny Pokemon in Pokemon Legends Arceus! Shiny Odds as well!",
    tags: "Pokémon Legends Arceus",
    order: 2
  },
  {
    thumbnail: "https://i.ytimg.com/vi/VVtP5ykilEk/maxresdefault.jpg",
    videoUrl: "https://www.youtube.com/watch?v=VVtP5ykilEk&t=1s",
    title: "EASY SHINY Guide",
    description:
      "How to Get Shiny Pokemon in Pokemon Scarlet and Violet! Showing you the new Mass Outbreaks, how to get Sparkling Power 3 to boost your chances, as well as how to find Shiny Pokemon in Tera Raid Dens, Wandering and everything in between!",
    tags: "Pokémon Scarlet and Violet",
    order: 1
  }
];
