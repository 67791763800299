import styled from "styled-components/native";

const StyledHr = styled.View`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.colors.neutralLightest};
  margin: 16px 0;
`;

export const Hr: React.FC = () => <StyledHr />;
