import {
  addDoc,
  collection,
  deleteDoc,
  getDocs,
  doc,
  getFirestore,
  onSnapshot,
  setDoc,
  QuerySnapshot,
  DocumentData
} from "firebase/firestore";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { CollectionObject, HuntObject, UserObject } from "../model/model";
import { useAuth } from "./useAuth";
import { CustomerInfo } from "react-native-purchases";
import { User } from "firebase/auth";

export const useFirestoreProvider = () => {
  const firestore = useMemo(() => getFirestore(), []);
  const { authUser } = useAuth();
  const [user, setUser] = useState<UserObject | null>(null);
  const [hunts, setHunts] = useState<HuntObject[] | null>(null);
  const [collections, setCollections] = useState<CollectionObject[] | null>(
    null
  );

  useEffect(() => {
    if (authUser) {
      const userSub = onSnapshot(
        doc(firestore, `users/${authUser?.uid}`),
        doc => {
          setUser({ uid: doc.id, ...doc.data() } as UserObject);
        }
      );

      const huntSub = onSnapshot(
        collection(firestore, `users/${authUser?.uid}/hunts`),
        collection => {
          setHunts(
            collection.docs.map(doc => ({
              ...(doc.data() as HuntObject),
              uid: doc.id
            }))
          );
        }
      );

      const collectionSub = onSnapshot(
        collection(firestore, `users/${authUser?.uid}/collection`),
        collection => {
          setCollections(
            collection.docs.map(doc => ({
              ...(doc.data() as CollectionObject),
              uid: doc.id
            }))
          );
        }
      );

      return () => {
        userSub();
        huntSub();
        collectionSub();
      };
    }
  }, [authUser]);

  const getAllUsers = () =>
    getDocs(collection(firestore, `users`)).then(querySnapshot => {
      const users: UserObject[] = [];
      querySnapshot.forEach(doc => {
        users.push({ uid: doc.id, ...doc.data() } as UserObject);
      });
      return users;
    });

  const updateUser = (data: Partial<UserObject>) =>
    setDoc(doc(firestore, `users/${authUser?.uid}`), data, {
      merge: true
    });

  const addHunt = (hunt: Partial<HuntObject>) =>
    addDoc(collection(firestore, `users/${authUser?.uid}/hunts`), hunt);

  const updateHunt = (huntId: string, data: Partial<HuntObject>) =>
    setDoc(doc(firestore, `users/${authUser?.uid}/hunts/${huntId}`), data, {
      merge: true
    });

  const deleteHunt = (huntId: string) =>
    deleteDoc(doc(firestore, `users/${authUser?.uid}/hunts/${huntId}`));

  const deleteCollection = (collectionId: string) =>
    deleteDoc(
      doc(firestore, `users/${authUser?.uid}/collection/${collectionId}`)
    );

  const addCollectionFromHunt = (hunt: HuntObject) => {
    const collectionItem: CollectionObject = {
      ...hunt,
      favorite: false,
      collected: new Date().valueOf()
    };

    return addDoc(
      collection(firestore, `users/${authUser?.uid}/collection`),
      collectionItem
    );
  };

  const addCollection = (collectionItem: Partial<CollectionObject>) =>
    addDoc(
      collection(firestore, `users/${authUser?.uid}/collection`),
      collectionItem
    );

  const updateCollection = (
    collectionId: string,
    data: Partial<CollectionObject>
  ) =>
    setDoc(
      doc(firestore, `users/${authUser?.uid}/collection/${collectionId}`),
      data,
      {
        merge: true
      }
    );

  const value = {
    user,
    hunts,
    collections,
    getAllUsers,
    updateUser,
    updateHunt,
    addHunt,
    deleteCollection,
    deleteHunt,
    addCollection,
    addCollectionFromHunt,
    updateCollection
  };

  return value;
};

const firestoreContext = createContext<ReturnType<
  typeof useFirestoreProvider
> | null>(null);

export const useFirestore = () => {
  const context = useContext(firestoreContext);
  if (context === null) {
    throw "useFirestore must be within FirestoreProvider";
  }
  return context;
};

export const FirestoreProvider: React.FC = ({ children }) => {
  const firestore = useFirestoreProvider();
  return (
    <firestoreContext.Provider value={firestore}>
      {children}
    </firestoreContext.Provider>
  );
};
