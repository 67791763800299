import { useNavigation } from "@react-navigation/core";
import React, { useRef } from "react";
import { useDimensions, useHover } from "react-native-web-hooks";
import styled, { useTheme } from "styled-components/native";
import { lt } from "./breakpoints";
import { IconProps } from "./Icon";
import { Caption, Body } from "./Text";
import { NavigationContainerRef } from "@react-navigation/core";
import { RootStackParamList } from "../navTypes";
import { View } from "react-native";

const StyledSideMenuButton = styled.Pressable<{
  isHovered?: boolean;
  active?: boolean;
}>`
  height: 50px;
  margin: 2px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0;
  color: black;
  flex-direction: row;
  padding-left: 20px;

  ${({ active, theme }) =>
    !!active &&
    `
  background: ${theme.colors.background};
  `}

  ${({ isHovered, theme }) =>
    !!isHovered &&
    `
  background: ${theme.colors.background};
  `}
`;

export const SideMenuButton = ({
  label,
  Icon,
  route,
  navRef,
  active
}: {
  Icon: React.FC<IconProps>;
  label: string;
  route: keyof RootStackParamList;
  navRef: NavigationContainerRef<RootStackParamList> | null;
  active: boolean;
}) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);

  return (
    <StyledSideMenuButton
      ref={ref}
      isHovered={isHovered}
      active={active}
      onPress={() => navRef?.navigate(route)}
    >
      <Icon
        size={18}
        primaryFill={active ? "primary" : "primaryDarkest"}
        secondaryFill="primaryDarkest"
      />
      <Body color="primaryDarkest" style={{ marginLeft: 20 }} weight="semibold">
        {label}
      </Body>
    </StyledSideMenuButton>
  );
};
