import styled from "styled-components/native";

export const EmptyState = styled.View`
  text-align: center;
  padding: 100px 10px;
  max-width: 375px;
  margin: auto;
  align-items: center;
`;

export const EmptyButton = styled.Pressable`
  margin: 40px auto 50px;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.surface};
  border: 2px solid ${props => props.theme.colors.primaryLight};
  height: 50px;
  width: 50px;
  border-radius: 100px;
  overflow: hidden;
`;
