import { Platform } from "react-native";

const devApiKey = Platform.select({
  ios: "AIzaSyBKhH4Xgdfd88cYhkra4p44UhUB1MSejP0",
  android: "AIzaSyBKhH4Xgdfd88cYhkra4p44UhUB1MSejP0",
  web: "AIzaSyBeExekLVPnKdLgZp_eQ2iOA-rT_NVhy9I"
});

const devAppId = Platform.select({
  ios: "1:554836949315:ios:ecd2f1f7e2595a820557f6",
  android: "1:554836949315:android:89ba067aff0178c60557f6",
  web: "1:554836949315:web:76d0c06408901564"
});

const prodApiKey = Platform.select({
  ios: "AIzaSyAs6obbw2MIxAl4QQT_HSTI_fU2gFJgbtc",
  android: "AIzaSyA-NPP6WbUTSSb8RyuIuhNM4Kucg9TRue0",
  web: "AIzaSyAmVeVQ3zHR0_DVCR-o7Sl-3T9JF4csLrc"
});

const prodAppId = Platform.select({
  ios: "1:357788431653:ios:232c77c47d2963c21e16cc",
  android: "1:357788431653:android:ad123ad128208d1f1e16cc",
  web: "1:357788431653:web:acfbba3132cc79961e16cc"
});

export const getConfig = () => {
  // if (process.env.NODE_ENV === "development") {
  //   return {
  //     apiKey: devApiKey,
  //     authDomain: "shinyhunt-app.firebaseapp.com",
  //     databaseURL: "https://shinyhunt-app.firebaseio.com",
  //     projectId: "shinyhunt-app",
  //     storageBucket: "shinyhunt-app.appspot.com",
  //     messagingSenderId: "554836949315",
  //     appId: devAppId,
  //     measurementId: "G-7KNK57W2H2"
  //   };
  // } else {
  return {
    apiKey: prodApiKey,
    authDomain: "shinyhunt-prod.firebaseapp.com",
    databaseURL: "https://shinyhunt-prod.firebaseio.com",
    projectId: "shinyhunt-prod",
    storageBucket: "shinyhunt-prod.appspot.com",
    messagingSenderId: "357788431653",
    appId: prodAppId,
    measurementId: "G-Y124L3KRR4"
  };
  // }
};
