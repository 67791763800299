import { Picker } from "@react-native-picker/picker";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  FlatList,
  Image,
  ListRenderItemInfo,
  Platform,
  Pressable,
  PressableProps,
  StyleSheet,
  Text,
  View,
  ViewStyle
} from "react-native";
import { useDimensions, useHover } from "react-native-web-hooks";
import styled, { useTheme } from "styled-components/native";
import { Game, GAMES } from "../../constants/games";
import { Method, METHODS } from "../../constants/methods";
import {
  GENDERS,
  MethodOption,
  METHOD_OPTIONS,
  POKEBALLS
} from "../../constants/options";
import { POKEMON, Pokemon } from "../../constants/pokemon-new";
import { useFirestore } from "../../hooks/useFirestore";
import { usePayments } from "../../hooks/usePayments";
import { CollectionObject, HuntObject, HuntSetting } from "../../model/model";
import { IconButton } from "../atoms/Button";
import { DatePicker } from "../atoms/DatePicker";
import { Input, SearchInput } from "../atoms/Input";
import { BreakpointProps, getBp, gte, lt } from "../breakpoints";
import { getSpriteSrc } from "../helpers";
import {
  AddCircleIcon,
  CheckIcon,
  CloseIcon,
  EditIcon,
  HardDriveIcon,
  PokeBallIcon,
  ReceiptIcon,
  SettingsIcon,
  ShuffleIcon,
  SortAscIcon,
  SortDescIcon,
  TagIcon,
  UploadIcon
} from "../Icon";
import { Collapser } from "../molecules/Collapser";
import {
  SortOverlay,
  SortOverlayActions,
  SortOverlayHeader,
  SortOverlayIconButton,
  SortOverlayRadioLabel,
  SortOverlayRadioList
} from "../molecules/SortOverlay";
import { Body, Caption, Title } from "../Text";
import {
  BaseModal,
  ModalMenuButton,
  ModalMenuButtons,
  ModalMenuTitle,
  ModalProps,
  ModalSideMenus
} from "./BaseModal";

const StyledStartButton = styled.Pressable<{ isHovered: boolean }>`
  height: 50px;
  background: ${props => props.theme.colors.primary};
  margin: 10px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;

  ${props =>
    props.isHovered &&
    `
    background: ${props.theme.colors.success};
  `}

  ${props =>
    props.disabled &&
    `
    background: ${props.theme.colors.neutral};
    color: white;
  `}
`;

const StartButton: React.FC<PressableProps> = ({ children, ...props }) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);

  return (
    <StyledStartButton {...props} ref={ref} isHovered={isHovered}>
      {children}
    </StyledStartButton>
  );
};

const ListOptions = styled.View<BreakpointProps>`
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;

  ${p =>
    lt(p, "medium") &&
    `
    flex-direction: column;
    margin-bottom: 10px;
  `}
`;

const ListOptionsTop = styled.View`
  margin-bottom: 10px;
  flex-direction: row;
`;

const ListOptionsBottom = styled.View<BreakpointProps>`
  height: 40px;

  ${p =>
    gte(p, "medium") &&
    `
    flex: 1;
  `}
`;

const SearchItemImage = styled.Image`
  width: 56px;
  height: 56px;
  border-radius: 150px;
  padding: 10px;
`;

const DetailRow = styled.View<{ last?: boolean }>``;

const MenuIconImage = styled.Image`
  height: 44px;
  width: 44px;
  border-radius: 1px;
`;

type SortOption<T> = { label: string; value: keyof T };

const pokemonSortOptions: SortOption<Pokemon>[] = [
  {
    label: "Number",
    value: "order"
  },
  {
    label: "Name",
    value: "label"
  }
];

const gameSortOptions: SortOption<Game>[] = [
  {
    label: "Release Date",
    value: "release"
  },
  {
    label: "Name",
    value: "label"
  }
];

const methodSortOptions: SortOption<Method>[] = [
  {
    label: "Name",
    value: "label"
  }
];

export type NewHuntModalProps = {
  hunt?: HuntObject;
  collection?: CollectionObject;
  isImport?: boolean;
  close: () => void;
} & ModalProps;

let advanceStep: "target" | "method" | "game" | null = null;

const buttonBase: ViewStyle = {
  borderRadius: 10,
  flex: 1,
  borderWidth: 2,
  borderStyle: "solid"
};

const itemButtonBase: ViewStyle = {
  ...buttonBase,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  height: "100%",
  paddingLeft: 20,
  paddingRight: 20,
  overflow: "hidden"
};

const pokemonColsMap = {
  small: 2,
  medium: 3,
  large: 4,
  xlarge: 5
};

const itemsColsMap = {
  small: 1,
  medium: 2,
  large: 2,
  xlarge: 2
};

export const NewHuntmodal = ({
  hunt,
  collection,
  isImport,
  visible,
  close
}: NewHuntModalProps) => {
  const [active, setActive] = useState("pokemon");
  const [selectedTarget, setSelectedTarget] = useState<Pokemon>();
  const [selectedGame, setSelectedGame] = useState<Game>();
  const [selectedMethod, setSelectedMethod] = useState<Method>();
  const [methodOptions, setMethodOptions] = useState<MethodOption[]>();
  const [searchInput, setSearchInput] = useState("");
  const [sortAsc, setSortAsc] = useState(false);
  const [pokemonList, setPokemonList] = useState([...POKEMON]);
  const [pokemonSortValue, setPokemonSortValue] = useState(
    pokemonSortOptions[0]
  );
  const [gameList, setGameList] = useState([...GAMES]);
  const [gameSortValue, setGameSortValue] = useState(gameSortOptions[0]);
  const [methodList, setMethodList] = useState([...METHODS]);
  const [methodSortValue, setMethodSortValue] = useState(methodSortOptions[0]);
  const [sortOverlayOpen, setSortOverlayOpen] = useState(false);
  const [settingsOptions, setSettingsOptions] = useState<HuntSetting[]>([]);
  const [selectedSettings, setSelectedSettings] = useState<HuntSetting[]>([]);
  const [detailsSeen, setDetailsSeen] = useState(false);
  const [collectedDate, setCollectedDate] = useState<Date>();
  const [elapsedTime, setElapsedTime] = useState<number>();
  const [encounters, setEncounters] = useState<number>();
  const [nickname, setNickname] = useState<string>();
  const [ballUsed, setBallUsed] = useState<string>();
  const [gender, setGender] = useState<string>();
  const [journalNote, setJournalNote] = useState<string>();
  const [reactionLink, setReactionLink] = useState<string>();
  const [error, setError] = useState<any>();
  const dimensions = useDimensions();
  const { isPro } = usePayments();

  const theme = useTheme();
  const { user } = useFirestore();

  const bp = getBp({ dimensions, theme });
  const numPokemonCols = bp ? pokemonColsMap[bp] : 1;
  const numItemCols = bp ? itemsColsMap[bp] : 1;

  const reset = () => {
    setActive("pokemon");
    setSelectedTarget(undefined);
    setSelectedGame(undefined);
    setSelectedMethod(undefined);
    setSearchInput("");
    setSortAsc(false);
    setPokemonSortValue(pokemonSortOptions[0]);
    setGameSortValue(gameSortOptions[0]);
    setMethodSortValue(methodSortOptions[0]);
    setSortOverlayOpen(false);
    setSettingsOptions([]);
    setSelectedSettings([]);
    setDetailsSeen(false);
    setCollectedDate(undefined);
    setElapsedTime(undefined);
    setEncounters(undefined);
    setNickname(undefined);
    setBallUsed(undefined);
    setGender(undefined);
    setJournalNote(undefined);
    setReactionLink(undefined);
    setError(undefined);

    close();
  };

  useEffect(() => {
    const list = [...pokemonList];
    list.sort((a, b) => {
      const aVal = a[pokemonSortValue.value] || "";
      const bVal = b[pokemonSortValue.value] || "";

      const compare = aVal.toString().localeCompare(bVal.toString());
      return sortAsc ? compare * -1 : compare;
    });
    setPokemonList(list);
  }, [pokemonSortValue, sortAsc]);

  useEffect(() => {
    const list = [...gameList];
    list.sort((a, b) => {
      const aVal = a[gameSortValue.value] || "";
      const bVal = b[gameSortValue.value] || "";

      const compare = aVal.toString().localeCompare(bVal.toString());
      return sortAsc ? compare : compare * -1;
    });
    setGameList(list);
  }, [gameSortValue, sortAsc]);

  useEffect(() => {
    const list = [...methodList];
    list.sort((a, b) => {
      const aVal = a[methodSortValue.value] || "";
      const bVal = b[methodSortValue.value] || "";

      const compare = aVal.toString().localeCompare(bVal.toString());
      return sortAsc ? compare * -1 : compare;
    });
    setMethodList(list);
  }, [methodSortValue, sortAsc]);

  useEffect(() => {
    const huntSettings = new Set<HuntSetting>();

    // enable shiny charm option
    if (selectedGame?.charm) {
      if (selectedMethod?.value !== "tera-raid") {
        huntSettings.add(HuntSetting.SHINY_CHARM);
      }
    }

    // enable lure option
    if (selectedGame?.lure) {
      huntSettings.add(HuntSetting.LURE);
    }

    // enable community day - Pokemon Go
    if (selectedGame?.value === "recBSxD5PSz5dGK0d") {
      huntSettings.add(HuntSetting.COMMUNITY_DAY);
    }

    // enable masuda option
    if (selectedMethod?.value === "breeding") {
      huntSettings.add(HuntSetting.MASUDA);
    }

    // enable underground option
    if (selectedMethod?.value === "underground") {
      huntSettings.add(HuntSetting.DIGLETT);
    }

    // enable sparkling power
    if (
      (selectedGame?.value === "recKpTD9URdio0NJy" ||
        selectedGame?.value === "recxvgp09rQnqzjxG") &&
      (selectedMethod?.value === "outbreak-method" ||
        selectedMethod?.value === "random")
    ) {
      huntSettings.add(HuntSetting.SPARKLING_POWER_3);
    }

    setMethodOptions(METHOD_OPTIONS.filter(m => huntSettings.has(m.value)));
  }, [selectedGame, selectedMethod]);

  const pokemonStyleSheet = useMemo(() => {
    const singleCol = lt({ dimensions, theme }, "small");

    return theme
      ? StyleSheet.create({
          container: {
            position: "relative",
            // width: singleCol ? "100%" : 136,
            width: `${(1 / numPokemonCols) * 100}%`,
            height: singleCol ? 70 : 136,
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: singleCol ? 0 : 5,
            paddingRight: singleCol ? 0 : 5
          },
          button: {
            ...buttonBase,
            backgroundColor: theme.colors.surface,
            borderColor: theme.colors.surface
          },
          header: {
            position: "absolute",
            alignItems: "flex-start",
            justifyContent: "space-between",
            padding: 10,
            width: "100%",
            textAlign: "left"
          },
          podium: {
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: singleCol ? "flex-end" : "center"
          },
          sprite: {
            height: singleCol ? 50 : 90,
            width: singleCol ? 50 : 90,
            marginRight: singleCol ? 4 : undefined
          },
          hoveredButton: {
            ...buttonBase,
            backgroundColor: theme.colors.surface,
            borderColor: theme.colors.primary
          },
          selectedButton: {
            ...buttonBase,
            backgroundColor: theme.colors.surface,
            borderColor: theme.colors.success
          }
        })
      : null;
  }, [theme, dimensions]);

  const renderPokemonItem = useMemo(
    () =>
      pokemonStyleSheet
        ? ({ item }: ListRenderItemInfo<Pokemon>) => (
            <View style={pokemonStyleSheet.container}>
              <Pressable
                style={(props: any) =>
                  selectedTarget?.value === item.value
                    ? pokemonStyleSheet.selectedButton
                    : props.hovered
                    ? pokemonStyleSheet.hoveredButton
                    : pokemonStyleSheet.button
                }
                onPress={() => selectTarget(item)}
              >
                <View style={pokemonStyleSheet.header}>
                  <Title color="primaryDark" weight="semibold">
                    {item.label}
                  </Title>
                  <Caption color="primary">#{item.dexNo}</Caption>
                </View>
                <View style={pokemonStyleSheet.podium}>
                  <Image
                    style={pokemonStyleSheet.sprite}
                    source={{
                      uri: getSpriteSrc(item, false, !!user?.isAdmin, isPro)
                    }}
                    resizeMode="contain"
                  />
                </View>
              </Pressable>
            </View>
          )
        : null,
    [pokemonStyleSheet, selectedTarget, user]
  );

  const itemStyleSheet = useMemo(() => {
    const singleCol = lt({ dimensions, theme }, "medium");

    return theme
      ? StyleSheet.create({
          container: {
            width: `${(1 / numItemCols) * 100}%`,
            height: 66,
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: singleCol ? 0 : 5,
            paddingRight: singleCol ? 0 : 5
          },
          button: {
            ...itemButtonBase,
            backgroundColor: theme.colors.surface,
            borderColor: theme.colors.surface
          },
          hoveredButton: {
            ...itemButtonBase,
            backgroundColor: theme.colors.surface,
            borderColor: theme.colors.primary
          },
          selectedButton: {
            ...itemButtonBase,
            backgroundColor: theme.colors.surface,
            borderColor: theme.colors.success
          },
          image: {
            width: 36,
            height: 36,
            borderRadius: 28,
            padding: 10
          }
        })
      : null;
  }, [theme, dimensions]);

  const renderItemBase = useMemo(
    () =>
      <T extends { value: string; label: string; image: string }>(
        { item }: ListRenderItemInfo<T>,
        selected: T | undefined,
        select: (item: T) => void
      ) =>
        itemStyleSheet ? (
          <View style={itemStyleSheet.container}>
            <Pressable
              style={(props: any) =>
                selected?.value === item.value
                  ? itemStyleSheet.selectedButton
                  : props.hovered
                  ? itemStyleSheet.hoveredButton
                  : itemStyleSheet.button
              }
              onPress={() => select(item)}
            >
              <Title color="primaryDark" weight="semibold">
                {item.label}
              </Title>
              <Image
                style={itemStyleSheet.image}
                source={{ uri: item.image }}
              />
            </Pressable>
          </View>
        ) : null,
    [itemStyleSheet]
  );

  const renderGameItem = useMemo(
    () => (info: ListRenderItemInfo<Game>) =>
      renderItemBase(info, selectedGame, selectGame),
    [itemStyleSheet, selectedGame]
  );

  const renderMethodItem = useMemo(
    () => (info: ListRenderItemInfo<Method>) =>
      renderItemBase(info, selectedMethod, selectMethod),
    [itemStyleSheet, selectedMethod]
  );

  const renderMethodOptionItem = useMemo(
    () => (info: ListRenderItemInfo<MethodOption>) => {
      const selectedMethodOption = selectedSettings.includes(info.item.value)
        ? info.item
        : undefined;
      const selectMethodOption = (item: MethodOption) =>
        selectSettings(item.value);
      return renderItemBase(info, selectedMethodOption, selectMethodOption);
    },
    [itemStyleSheet, selectedSettings]
  );

  const { addHunt, updateHunt, addCollection, updateCollection } =
    useFirestore();

  useEffect(() => {
    const item = hunt || collection;
    if (item) {
      setSelectedTarget(POKEMON.find(p => p.value === item.target.value));
      setSelectedGame(GAMES.find(g => g.value === item.game.value));
      setSelectedMethod(METHODS.find(m => m.id === item.method.id));
      setSettingsOptions([]);
      setSelectedSettings([...item.selectedSettings]);
    }

    if (collection) {
      setDetailsSeen(true);
      setCollectedDate(new Date(collection.collected));
      setElapsedTime(collection.elapsedTime / 60);
      setEncounters(collection.encounters);
      setNickname(collection.nickname);
      setBallUsed(collection.ballUsed);
      setGender(collection.gender);
      setJournalNote(collection.journalNote);
      setReactionLink(collection.reactionLink);
    }
  }, [visible]);

  const filterMethodList = () => {
    if (selectedGame) {
      const filtered = METHODS.filter(m =>
        m.games.includes(selectedGame.value)
      );
      setMethodList(filtered);
    }
  };

  const updateSettingsOptions = () => {
    const settings: HuntSetting[] = [];

    if (selectedGame?.charm) {
      settings.push(HuntSetting.SHINY_CHARM);
    }

    if (selectedGame?.lure) {
      settings.push(HuntSetting.LURE);
    }

    if (selectedGame?.label === "Pokémon GO") {
      settings.push(HuntSetting.COMMUNITY_DAY);
    }

    if (selectedMethod?.value === "breeding") {
      settings.push(HuntSetting.MASUDA);
    }

    if (selectedMethod?.value === "underground") {
      settings.push(HuntSetting.DIGLETT);
    }

    setSettingsOptions(settings);
  };

  const menuClick = (tab: string) => {
    setActive(tab);
    setSearchInput("");
    setSortOverlayOpen(false);

    if (tab === "method") {
      filterMethodList();
    }

    if (tab === "settings") {
      updateSettingsOptions();
    }

    if (tab === "details") {
      setDetailsSeen(true);
    }
  };

  useEffect(() => {}, []);

  const selectTarget = (pokemon: Pokemon) => {
    setSelectedTarget(pokemon);
    advanceStep = "target";
  };

  const selectGame = (game: Game) => {
    setSelectedGame(game);
    setSettingsOptions([]);
    setSelectedSettings([]);
    advanceStep = "game";
  };

  const selectMethod = (method: Method) => {
    setSelectedMethod(method);
    setSettingsOptions([]);
    setSelectedSettings([]);
    advanceStep = "method";
  };

  useEffect(() => {
    switch (advanceStep) {
      case "target":
        menuClick("game");
        break;

      case "game":
        menuClick("method");
        break;

      case "method":
        menuClick("settings");
        break;
    }

    advanceStep = null;
  }, [selectedMethod, selectedTarget, selectedGame]);

  const selectSettings = (setting: HuntSetting) => {
    // Remove if already selected
    const newSettings: HuntSetting[] = selectedSettings.filter(
      s => s !== setting
    );

    //Otherwise add
    newSettings.length === selectedSettings.length && newSettings.push(setting);

    setSelectedSettings(newSettings);
  };

  const startHunt = () => {
    if (selectedGame && selectedMethod && selectedTarget) {
      addHunt({
        elapsedTime: 0,
        encounters: 0,
        incrementAmount: 1,
        shinyCharm: selectedSettings.includes(HuntSetting.SHINY_CHARM),
        favorite: false,
        game: {
          label: selectedGame.label,
          value: selectedGame.value
        },
        method: {
          label: selectedMethod.label,
          value: selectedMethod.value,
          id: selectedMethod.id
        },
        target: {
          label: selectedTarget.label,
          value: selectedTarget.value
        },
        chainCount: 0,
        selectedSettings,
        created: new Date().valueOf()
      })
        .then(reset)
        .catch(setError);
    }
  };

  const editHunt = () => {
    if (hunt && selectedGame && selectedMethod && selectedTarget) {
      updateHunt(hunt.uid, {
        game: {
          label: selectedGame.label,
          value: selectedGame.value
        },
        method: {
          label: selectedMethod.label,
          value: selectedMethod.value,
          id: selectedMethod.id
        },
        target: {
          label: selectedTarget.label,
          value: selectedTarget.value
        },
        selectedSettings
      })
        .then(reset)
        .catch(setError);
    }
  };

  const importHunt = () => {
    if (selectedGame && selectedMethod && selectedTarget) {
      addCollection({
        elapsedTime: elapsedTime ? elapsedTime * 60 : 0,
        encounters: encounters ? encounters : 0,
        shinyCharm: selectedSettings.includes(HuntSetting.SHINY_CHARM),
        favorite: false,
        game: {
          label: selectedGame.label,
          value: selectedGame.value
        },
        method: {
          label: selectedMethod.label,
          value: selectedMethod.value,
          id: selectedMethod.id
        },
        target: {
          label: selectedTarget.label,
          value: selectedTarget.value
        },
        collected: collectedDate
          ? collectedDate.valueOf()
          : new Date().valueOf(),
        created: new Date().valueOf(),
        selectedSettings,
        journalNote: journalNote || "",
        reactionLink: reactionLink || "",
        imported: true,
        nickname: nickname || "",
        ballUsed: ballUsed || "",
        gender: gender || ""
      })
        .then(reset)
        .catch(setError);
    }
  };

  const editCollection = () => {
    if (collection && selectedGame && selectedMethod && selectedTarget) {
      updateCollection(collection?.uid, {
        elapsedTime: elapsedTime ? elapsedTime * 60 : 0,
        encounters: encounters ? encounters : 0,
        game: {
          label: selectedGame.label,
          value: selectedGame.value
        },
        method: {
          label: selectedMethod.label,
          value: selectedMethod.value,
          id: selectedMethod.id
        },
        target: {
          label: selectedTarget.label,
          value: selectedTarget.value
        },
        collected: collectedDate
          ? collectedDate.valueOf()
          : new Date().valueOf(),
        selectedSettings,
        journalNote: journalNote || "",
        reactionLink: reactionLink || "",
        imported: true,
        nickname: nickname || "",
        ballUsed: ballUsed || "",
        gender: gender || ""
      })
        .then(reset)
        .catch(setError);
    }
  };

  let filteredList: Pokemon[] | Game[] | Method[];

  const searchText = searchInput.toLowerCase();
  switch (active) {
    case "pokemon":
      filteredList = pokemonList.filter(
        ({ label, dexNo }) =>
          label.toLowerCase().includes(searchText) || dexNo.includes(searchText)
      );
      break;

    case "game":
      filteredList = gameList.filter(
        ({ label, generation }) =>
          label.toLowerCase().includes(searchText) ||
          generation.toString().includes(searchText)
      );
      break;

    case "method":
      filteredList = methodList.filter(({ label }) =>
        label.toLowerCase().includes(searchText)
      );
      break;

    default:
      filteredList = [];
  }

  const randomSelect = () => {
    if (active === "pokemon") {
      const randomVal = Math.ceil(Math.random() * pokemonList.length) - 1;
      const randomTarget = pokemonList[randomVal];
      setSelectedTarget(randomTarget);
    } else if (active === "game") {
      const randomVal = Math.ceil(Math.random() * gameList.length) - 1;
      const randomGame = gameList[randomVal];
      setSelectedGame(randomGame);
    } else if (active === "method") {
      const randomVal = Math.ceil(Math.random() * methodList.length) - 1;
      const randomMethod = methodList[randomVal];
      setSelectedMethod(randomMethod);
    }
  };

  const HuntIcon = hunt ? EditIcon : AddCircleIcon;
  const SortIcon = sortAsc ? SortAscIcon : SortDescIcon;

  return (
    <BaseModal
      visible={visible}
      close={reset}
      viewType={active === "details" ? "keyboard" : "normal"}
      sidebar={
        <>
          <ModalSideMenus>
            <ModalMenuTitle dimensions={dimensions}>
              <Caption color="primaryDark" weight="semibold">
                1. Hunt Selection
              </Caption>
            </ModalMenuTitle>
            <ModalMenuButtons>
              <ModalMenuButton
                active={active === "pokemon"}
                selected={!!selectedTarget}
                onPress={() => menuClick("pokemon")}
                label={selectedTarget?.label || "Pokémon"}
                icon={
                  <PokeBallIcon
                    primaryFill="primaryDarkest"
                    secondaryFill="primary"
                    size={24}
                  />
                }
                selectedIcon={
                  selectedTarget && (
                    <>
                      {gte({ dimensions, theme }, "large") ? (
                        <CheckIcon
                          primaryFill="success"
                          secondaryFill="white"
                          size={24}
                        />
                      ) : (
                        <MenuIconImage
                          source={{
                            uri: getSpriteSrc(
                              selectedTarget,
                              false,
                              !!user?.isAdmin
                            )
                          }}
                        />
                      )}
                    </>
                  )
                }
              />

              <ModalMenuButton
                active={active === "game"}
                selected={!!selectedGame}
                onPress={() => menuClick("game")}
                label={selectedGame?.label || "Game"}
                icon={
                  <HardDriveIcon
                    primaryFill="primaryDarkest"
                    secondaryFill="primary"
                    size={24}
                  />
                }
                selectedIcon={
                  selectedGame && (
                    <>
                      {gte({ dimensions, theme }, "large") ? (
                        <CheckIcon
                          primaryFill="success"
                          secondaryFill="white"
                          size={24}
                        />
                      ) : (
                        <MenuIconImage source={{ uri: selectedGame.image }} />
                      )}
                    </>
                  )
                }
              />

              <ModalMenuButton
                active={active === "method"}
                selected={!!selectedMethod}
                onPress={() => menuClick("method")}
                label={selectedMethod?.label || "Method"}
                icon={
                  <TagIcon
                    primaryFill="primaryDarkest"
                    secondaryFill="primary"
                    size={24}
                  />
                }
                selectedIcon={
                  selectedMethod && (
                    <>
                      {gte({ dimensions, theme }, "large") ? (
                        <CheckIcon
                          primaryFill="success"
                          secondaryFill="white"
                          size={24}
                        />
                      ) : (
                        <MenuIconImage
                          resizeMode="contain"
                          source={{ uri: selectedMethod.image }}
                        />
                      )}
                    </>
                  )
                }
              />
            </ModalMenuButtons>
            <ModalMenuTitle dimensions={dimensions}>
              <Caption color="primaryDark" weight="semibold">
                2. Hunt Options
              </Caption>
            </ModalMenuTitle>
            <ModalMenuButtons>
              <ModalMenuButton
                active={active === "settings"}
                selected={false}
                onPress={() => menuClick("settings")}
                disabled={!selectedTarget || !selectedGame || !selectedMethod}
                label="Settings"
                icon={
                  <SettingsIcon
                    primaryFill={
                      !selectedTarget || !selectedGame || !selectedMethod
                        ? "neutral"
                        : "primaryDarkest"
                    }
                    secondaryFill={
                      !selectedTarget || !selectedGame || !selectedMethod
                        ? "surface"
                        : "primary"
                    }
                    size={24}
                  />
                }
              />
            </ModalMenuButtons>
            {!!isImport && (
              <>
                <ModalMenuTitle dimensions={dimensions}>
                  <Caption color="primaryDark" weight="semibold">
                    3. Collected Info
                  </Caption>
                </ModalMenuTitle>
                <ModalMenuButtons>
                  <ModalMenuButton
                    active={active === "details"}
                    onPress={() => menuClick("details")}
                    selected={false}
                    disabled={
                      !selectedTarget || !selectedGame || !selectedMethod
                    }
                    label="Details"
                    icon={
                      <ReceiptIcon
                        primaryFill={
                          !selectedTarget || !selectedGame || !selectedMethod
                            ? "neutral"
                            : "primaryDarkest"
                        }
                        secondaryFill={
                          !selectedTarget || !selectedGame || !selectedMethod
                            ? "surface"
                            : "primary"
                        }
                        size={24}
                      />
                    }
                  />
                </ModalMenuButtons>
              </>
            )}
          </ModalSideMenus>
          {!!isImport ? (
            <StartButton
              onPress={collection ? editCollection : importHunt}
              disabled={
                !collection && (!settingsOptions.length || !detailsSeen)
              }
            >
              {bp ? (
                <Body weight="semibold" color="white">
                  {collection ? "Save Changes" : "Add to Collection"}
                </Body>
              ) : (
                <UploadIcon
                  size={24}
                  primaryFill="white"
                  secondaryFill="white"
                />
              )}
            </StartButton>
          ) : (
            <StartButton
              onPress={hunt ? editHunt : startHunt}
              disabled={!(selectedGame && selectedMethod && selectedTarget)}
            >
              {bp ? (
                <Body weight="semibold" color="white">
                  {hunt ? "Update Hunt" : "Start Hunt"}
                </Body>
              ) : (
                <AddCircleIcon
                  size={24}
                  primaryFill="white"
                  secondaryFill="neutral"
                />
              )}
            </StartButton>
          )}
        </>
      }
      title={
        <>
          Choose {active}
          {active === "pokemon" && selectedTarget && (
            <Text style={{ color: "#3F9142" }}>: {selectedTarget.label}</Text>
          )}
          {active === "game" && selectedGame && (
            <Text style={{ color: "#3F9142" }}>: {selectedGame.label}</Text>
          )}
          {active === "method" && selectedMethod && (
            <Text style={{ color: "#3F9142" }}>: {selectedMethod.label}</Text>
          )}
        </>
      }
      content={
        <>
          {active !== "settings" && active !== "details" && (
            <ListOptions dimensions={dimensions}>
              <ListOptionsTop>
                <IconButton
                  style={{ marginRight: 8 }}
                  onPress={() => setSortOverlayOpen(!sortOverlayOpen)}
                  Icon={SortIcon}
                />

                {!isImport && (
                  <IconButton
                    style={{ marginRight: 8 }}
                    onPress={randomSelect}
                    Icon={ShuffleIcon}
                  />
                )}
              </ListOptionsTop>
              <ListOptionsBottom dimensions={dimensions}>
                <SearchInput
                  onChangeText={setSearchInput}
                  value={searchInput}
                  placeholder="Search..."
                />
              </ListOptionsBottom>
            </ListOptions>
          )}
          {sortOverlayOpen && (
            <SortOverlay>
              <SortOverlayHeader>
                <Caption color="primaryDarkest" weight="semibold">
                  SORT OPTIONS
                </Caption>
                <SortOverlayActions>
                  <SortOverlayIconButton
                    onPress={() => setSortOverlayOpen(!sortOverlayOpen)}
                  >
                    <CloseIcon
                      primaryFill="primaryDarkest"
                      primaryFillHover="primary"
                      size={20}
                    />
                  </SortOverlayIconButton>
                </SortOverlayActions>
              </SortOverlayHeader>

              {active === "pokemon" && (
                <SortOverlayRadioList>
                  {pokemonSortOptions.map((option, index) => {
                    const checked = pokemonSortValue.value === option.value;
                    return (
                      <SortOverlayRadioLabel
                        checked={checked}
                        key={index}
                        onPress={() =>
                          checked
                            ? setSortAsc(!sortAsc)
                            : setPokemonSortValue(option)
                        }
                        style={(props: any) =>
                          !!props.hovered && {
                            borderColor: theme.colors.primary
                          }
                        }
                      >
                        <Body weight="semibold" color="primaryDark">
                          {option.label}
                        </Body>
                        <SortIcon
                          primaryFill="primary"
                          secondaryFill="primary"
                          size={16}
                        />
                      </SortOverlayRadioLabel>
                    );
                  })}
                </SortOverlayRadioList>
              )}

              {active === "game" && (
                <SortOverlayRadioList>
                  {gameSortOptions.map((option, index) => {
                    const checked = gameSortValue.value === option.value;
                    return (
                      <SortOverlayRadioLabel
                        checked={checked}
                        key={index}
                        onPress={() =>
                          checked
                            ? setSortAsc(!sortAsc)
                            : setGameSortValue(option)
                        }
                      >
                        <Body weight="semibold" color="primaryDark">
                          {option.label}
                        </Body>
                        <SortIcon
                          primaryFill="primary"
                          secondaryFill="primary"
                          size={16}
                        />
                      </SortOverlayRadioLabel>
                    );
                  })}
                </SortOverlayRadioList>
              )}

              {active === "method" && (
                <SortOverlayRadioList>
                  {methodSortOptions.map((option, index) => {
                    const checked = methodSortValue.value === option.value;
                    return (
                      <SortOverlayRadioLabel
                        checked={checked}
                        key={index}
                        onPress={() =>
                          checked
                            ? setSortAsc(!sortAsc)
                            : setMethodSortValue(option)
                        }
                      >
                        <Body weight="semibold" color="primaryDark">
                          {option.label}
                        </Body>
                        <SortIcon
                          primaryFill="primary"
                          secondaryFill="primary"
                          size={16}
                        />
                      </SortOverlayRadioLabel>
                    );
                  })}
                </SortOverlayRadioList>
              )}
            </SortOverlay>
          )}

          {active === "pokemon" && (
            <FlatList
              key={`pokemon${numPokemonCols}`}
              numColumns={numPokemonCols}
              data={filteredList as Pokemon[]}
              keyExtractor={item => item.value}
              removeClippedSubviews
              windowSize={2}
              renderItem={renderPokemonItem}
              columnWrapperStyle={
                numItemCols > 1
                  ? { marginLeft: -5, marginRight: -5 }
                  : undefined
              }
            />
          )}

          {active === "game" && (
            <FlatList
              key={`game${numItemCols}`}
              numColumns={numItemCols}
              data={filteredList as Game[]}
              keyExtractor={item => item.value}
              removeClippedSubviews
              windowSize={2}
              renderItem={renderGameItem}
              columnWrapperStyle={
                numItemCols > 1
                  ? { marginLeft: -5, marginRight: -5 }
                  : undefined
              }
            />
          )}

          {active === "method" && (
            <FlatList
              key={`method${numItemCols}`}
              numColumns={numItemCols}
              data={filteredList as Method[]}
              keyExtractor={item => item.value}
              removeClippedSubviews
              windowSize={2}
              renderItem={renderMethodItem}
              columnWrapperStyle={
                numItemCols > 1
                  ? { marginLeft: -5, marginRight: -5 }
                  : undefined
              }
            />
          )}
          {active === "settings" && (
            <>
              {settingsOptions.length > 0 ? (
                <>
                  <Body color="primary" weight="semibold">
                    Select all that apply
                  </Body>
                  <FlatList
                    key={`settings${numItemCols}`}
                    numColumns={numItemCols}
                    data={methodOptions}
                    keyExtractor={item => item.value}
                    removeClippedSubviews
                    windowSize={2}
                    renderItem={renderMethodOptionItem}
                    columnWrapperStyle={
                      numItemCols > 1
                        ? { marginLeft: -5, marginRight: -5 }
                        : undefined
                    }
                  />
                </>
              ) : (
                <Body color="primaryDarkest">
                  Your hunt selection does not include any additional setting
                  selections. Click "Start Hunt" to begin! Good luck!
                </Body>
              )}
            </>
          )}
          {active === "details" && (
            <>
              <DetailRow
                style={{
                  zIndex: 1,
                  alignItems: "flex-start",
                  justifyContent: "flex-start"
                }}
              >
                <Body
                  color="primary"
                  weight="semibold"
                  style={{ marginBottom: 4 }}
                >
                  Collected On
                </Body>
                <Collapser
                  display={collectedDate?.toDateString() || "Enter Date"}
                >
                  <DatePicker
                    value={collectedDate}
                    onChange={setCollectedDate}
                  />
                </Collapser>
              </DetailRow>
              <DetailRow>
                <Body
                  color="primary"
                  weight="semibold"
                  style={{ marginBottom: 4 }}
                >
                  Elapsed Time (min)
                </Body>
                <Input
                  keyboardType="numeric"
                  onChangeText={value => {
                    if (!value) {
                      setElapsedTime(undefined);
                    } else if (!isNaN(parseFloat(value))) {
                      setElapsedTime(parseFloat(value));
                    }
                  }}
                  placeholder="ex. 30 (leave blank if unsure)"
                  value={elapsedTime?.toString()}
                  returnKeyType="done"
                />
              </DetailRow>
              <DetailRow>
                <Body
                  color="primary"
                  weight="semibold"
                  style={{ marginBottom: 4 }}
                >
                  Encounters
                </Body>
                <Input
                  collapsable
                  keyboardType="numeric"
                  onChangeText={value => {
                    if (!value) {
                      setEncounters(undefined);
                    } else if (!isNaN(parseFloat(value))) {
                      setEncounters(parseFloat(value));
                    }
                  }}
                  placeholder="ex. 123 (leave blank if unsure)"
                  value={encounters?.toString()}
                  returnKeyType="done"
                />
              </DetailRow>
              <DetailRow>
                <Body
                  color="primary"
                  weight="semibold"
                  style={{ marginBottom: 4 }}
                >
                  Nickname
                </Body>
                <Input
                  onChangeText={value => setNickname(value)}
                  placeholder="max 13 characters"
                  value={nickname}
                  returnKeyType="done"
                />
              </DetailRow>
              <DetailRow>
                <Body
                  color="primary"
                  weight="semibold"
                  style={{ marginBottom: 4 }}
                >
                  Ball Used
                </Body>
                <Collapser
                  disable={Platform.OS === "android"}
                  display={
                    POKEBALLS.find(b => b.value.toString() === ballUsed)
                      ?.label || "Choose Ball"
                  }
                >
                  <Picker
                    selectedValue={ballUsed}
                    onValueChange={(value, index) =>
                      setBallUsed(value.toString() as string)
                    }
                    mode="dropdown"
                    style={
                      Platform.OS === "android"
                        ? { backgroundColor: "white" }
                        : {}
                    }
                  >
                    {[
                      <Picker.Item
                        value=""
                        label="None"
                        key="None"
                        color={
                          Platform.OS == "android" ? "black" : theme.colors.text
                        }
                      />,
                      POKEBALLS.map((option, i) => (
                        <Picker.Item
                          value={option.value.toString()}
                          label={option.label}
                          key={i}
                          color={
                            Platform.OS == "android"
                              ? "black"
                              : theme.colors.text
                          }
                        />
                      ))
                    ]}
                  </Picker>
                </Collapser>
              </DetailRow>
              <DetailRow>
                <Body
                  color="primary"
                  weight="semibold"
                  style={{ marginBottom: 4 }}
                >
                  Gender
                </Body>
                <Collapser
                  disable={Platform.OS === "android"}
                  display={
                    GENDERS.find(b => b.value === gender)?.label ||
                    "Choose Gender"
                  }
                >
                  <Picker
                    selectedValue={gender}
                    onValueChange={(value, index) => setGender(value as string)}
                    style={
                      Platform.OS === "android"
                        ? { backgroundColor: "white" }
                        : {}
                    }
                  >
                    {[
                      <Picker.Item
                        value=""
                        label="None"
                        key="None"
                        color={
                          Platform.OS == "android" ? "black" : theme.colors.text
                        }
                      />,
                      GENDERS.map((option, i) => (
                        <Picker.Item
                          value={option.value}
                          label={option.label}
                          key={i}
                          color={
                            Platform.OS == "android"
                              ? "black"
                              : theme.colors.text
                          }
                        />
                      ))
                    ]}
                  </Picker>
                </Collapser>
              </DetailRow>
              <DetailRow>
                <Body
                  color="primary"
                  weight="semibold"
                  style={{ marginBottom: 4 }}
                >
                  Note
                </Body>
                <Input
                  onChangeText={setJournalNote}
                  placeholder="Journal entry..."
                  maxLength={1000}
                  multiline
                  style={{ height: 100 }}
                  value={journalNote}
                  returnKeyType="default"
                />
              </DetailRow>
              <DetailRow>
                <Body
                  color="primary"
                  weight="semibold"
                  style={{ marginBottom: 4 }}
                >
                  Reaction Link
                </Body>
                <Input
                  onChangeText={setReactionLink}
                  placeholder="Link to reaction video"
                  value={reactionLink}
                  returnKeyType="done"
                />
              </DetailRow>
            </>
          )}
        </>
      }
    />
  );
};
