import {
  DefaultTheme,
  LinkingOptions,
  NavigationContainer,
  useNavigationContainerRef
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
// import AppLoading from "expo-app-loading";
import { StatusBar } from "expo-status-bar";
import { initializeApp } from "firebase/app";
import "firebase/firestore";
import { useEffect, useState } from "react";
import { Platform } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { ThemeProvider } from "styled-components/native";
import { AppLayout } from "./components/AppLayout";
import { AuthProvider, useAuth } from "./hooks/useAuth";
// import useCachedResources from "./hooks/useCachedResources";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import AppLoading from "expo-app-loading";
import { initializeFirestore } from "firebase/firestore";
import { LogBox } from "react-native";
import { getConfig } from "./firebaseConfig";
import useCachedResources from "./hooks/useCachedResources";
import { FirestoreProvider } from "./hooks/useFirestore";
import { PaymentsProvider } from "./hooks/usePayments";
import { RootStackParamList } from "./navTypes";
import { CollectionScreen } from "./screens/CollectionsScreen";
import { CountersScreen } from "./screens/CountersScreen";
import { PasswordForgetScreen } from "./screens/ForgotPasswordScreen";
import { GuidesScreen } from "./screens/GuidesScreen";
import { LandingScreen } from "./screens/LandingScreen";
import { ShinyDexScreen } from "./screens/ShinyDexScreen";
import { ShowcasesScreen } from "./screens/ShowcaseScreen";
import { SignInScreen } from "./screens/SignInScreen";
import { SignUpScreen } from "./screens/SignUpScreen";
import { TermsScreen } from "./screens/TermsScreen";
import { VerifyScreen } from "./screens/VerifyScreen";
import { lightTheme } from "./theme/theme";
import { getFunctions, httpsCallable } from "firebase/functions";

LogBox.ignoreLogs([
  "Setting a timer for a long period of time",
  "AsyncStorage has been extracted"
]);

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [],
  config: {
    screens: {
      Landing: "",
      Counters: "counters",
      Collection: {
        screens: {
          CollectionList: "collection",
          CollectionDetail: "collection/:uid"
        }
      },
      Verify: "verify",
      ShinyDex: "dex",
      Guides: "guides",
      Terms: "terms",
      SignIn: "signin",
      SignUp: "signup",
      ForgotPassword: "forgotpassword",
      Showcases: {
        screens: {
          Showcases: "showcases",
          ShowcaseList: "showcases/:uid",
          ShowcaseDetail: "showcase/:uid/:uid"
        }
      }
    }
  }
};

const app = initializeApp(getConfig());
initializeFirestore(app, { experimentalAutoDetectLongPolling: true });
const functions = getFunctions(app);
export const getStripePrices = httpsCallable<{ productIds: string[] }, any[]>(
  functions,
  "getStripePrices"
);
export const createCheckoutSession = httpsCallable<
  { priceId: string; local: boolean; subscription: boolean },
  string
>(functions, "createCheckoutSession");

const Stack = createNativeStackNavigator<RootStackParamList>();
const Tab = createBottomTabNavigator<RootStackParamList>();

export default function App() {
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {
    return <AppLoading />;
  } else {
    return (
      <SafeAreaProvider style={{ backgroundColor: "white" }}>
        <AuthProvider>
          <FirestoreProvider>
            <PaymentsProvider>
              <Navigation />
            </PaymentsProvider>
          </FirestoreProvider>
        </AuthProvider>
      </SafeAreaProvider>
    );
  }
}

const Navigation = () => {
  const { authUser } = useAuth();
  const navRef = useNavigationContainerRef();
  const [currentRoute, setCurrentRoute] = useState<string>();

  useEffect(() => {
    navRef.addListener("state", () => {
      setCurrentRoute(navRef.getCurrentRoute()?.name);
    });
  }, []);

  if (authUser === undefined) {
    return <AppLoading />;
  }

  return (
    <NavigationContainer
      ref={navRef}
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: "transparent"
        }
      }}
      linking={linking}
    >
      {authUser?.emailVerified ? (
        <AppLayout navRef={navRef} currentRoute={currentRoute}>
          <Tab.Navigator
            initialRouteName="Counters"
            screenOptions={{
              headerShown: false
            }}
            tabBar={() => null}
            backBehavior="order"
          >
            <Tab.Screen name="Counters" component={CountersScreen} />
            <Tab.Screen name="Collection" component={CollectionScreen} />
            <Tab.Screen name="ShinyDex" component={ShinyDexScreen} />
            <Tab.Screen name="Guides" component={GuidesScreen} />
            <Tab.Screen name="Showcases" component={ShowcasesScreen} />
          </Tab.Navigator>
        </AppLayout>
      ) : (
        <ThemeProvider theme={lightTheme}>
          <StatusBar style="light" />
          <Stack.Navigator
            initialRouteName={Platform.OS === "web" ? "Landing" : "SignIn"}
            screenOptions={{
              headerShown: false,
              animation: "none"
            }}
          >
            {Platform.OS === "web" && (
              <Stack.Screen name="Landing" component={LandingScreen} />
            )}
            <Stack.Screen name="SignIn" component={SignInScreen} />
            <Stack.Screen name="SignUp" component={SignUpScreen} />
            <Stack.Screen
              name="ForgotPassword"
              component={PasswordForgetScreen}
            />
            <Stack.Screen name="Verify" component={VerifyScreen} />
            <Stack.Screen name="Terms" component={TermsScreen} />
            <Stack.Screen name="Showcases" component={ShowcasesScreen} />
          </Stack.Navigator>
        </ThemeProvider>
      )}
    </NavigationContainer>
  );
};
