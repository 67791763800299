import { ReactElement, useRef } from "react";
import { Modal, PressableProps, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useDimensions, useHover } from "react-native-web-hooks";
import styled, { useTheme } from "styled-components/native";
import { BreakpointProps, gte, lt, lte } from "../breakpoints";
import { CheckIcon, CloseIcon } from "../Icon";
import { Body, Headline } from "../Text";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

export const ModalOuter = styled.View<BreakpointProps>`
  flex: 1;
  padding: ${p => (lt(p, "small") ? `10px` : `70px`)};
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
`;

export const ModalInner = styled.View<BreakpointProps>`
  height: 100%;
  width: 100%;
  flex-direction: row;
  background: ${props => props.theme.colors.background};
  border-radius: 8px;
`;

export const ModalSideBar = styled.View<BreakpointProps>`
  max-width: 160px;
  background: ${props => props.theme.colors.surface};
  flex-direction: column;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  ${p =>
    lte(p, "medium") &&
    `
    width: 68px;
    padding-top: 40px;
  `}

  ${p =>
    gte(p, "large") &&
    `
    min-width: 250px;
  `}
`;

export const ModalSideMenus = styled.View``;

export const ModalMenuTitle = styled.View<BreakpointProps>`
  margin-top: 15px;
  padding: 10px 20px;

  ${p =>
    lt(p, "large") &&
    `
    display: none;
  `}
`;

export const ModalMenuButtons = styled.View`
  flex-direction: column;
`;

export const StyledModalMenuButton = styled.Pressable<{
  fill: boolean;
  mobile: boolean;
  active?: boolean;
  selected?: boolean;
}>`
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  margin: 2.5px 10px;
  border-radius: 4px;
  height: 35px;

  ${props =>
    props.fill &&
    !props.disabled &&
    `
    background-color: ${props.theme.colors.background};
  `}

  ${props =>
    props.disabled &&
    `
  color: ${props.theme.colors.neutral};
  `}

  ${p =>
    p.mobile
      ? `
    justify-content: center;
    height: 48px;
    width: 48px;
    margin: 4px auto;
    background-color: ${p.theme.colors.background};
    border: 2px solid ${p.theme.colors.background};
    ${
      p.active
        ? `
      border: 2px solid ${p.theme.colors.primary};
    `
        : ``
    }

    ${
      p.selected
        ? `
      border: 2px solid ${p.theme.colors.success};
    `
        : ``
    }
  `
      : ``}
`;

export const ModalMenuButton = (
  props: PressableProps & {
    active: boolean;
    selected?: boolean;
    selectedIcon?: ReactElement;
    icon: ReactElement;
    label: string;
  }
) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const dimensions = useDimensions();
  const theme = useTheme();
  const mobile = lt({ dimensions, theme }, "large");

  return (
    <StyledModalMenuButton
      ref={ref}
      {...props}
      fill={isHovered || props.active}
      mobile={mobile}
      active={props.active}
      selected={props.selected}
    >
      {props.selected && props.selectedIcon ? props.selectedIcon : props.icon}
      {!mobile && (
        <View style={{ marginLeft: 8 }}>
          <Body
            weight="semibold"
            color={props.selected ? "success" : "primaryDarkest"}
          >
            {props.label}
          </Body>
        </View>
      )}
    </StyledModalMenuButton>
  );
};

export const ModalMain = styled.View`
  overflow: hidden;
  flex: 1;
`;

export const ModalContentHeader = styled.View<BreakpointProps>`
  justify-content: space-between;
  flex-direction: row;
  padding-left: 40px;
  height: 50px;
  align-items: center;

  ${p =>
    lte(p, "small") &&
    `
    padding-left: 20px;
  `}
`;

export const ModalCloseButton = styled.Pressable`
  width: 50px;
  height: 50px;
  border: none;
  padding: 10px;
`;

export const ModalContent = styled.View<BreakpointProps>`
  padding: 30px 40px 40px;
  width: 100%;
  flex: 1;
  height: ${p => p.dimensions.window.height - 50}px;

  ${p =>
    lte(p, "small") &&
    `
    padding: 15px 20px 0px;
  `}
`;

export const ModalScrollView = styled.ScrollView<BreakpointProps>`
  padding: 30px 40px 40px;
  width: 100%;
  height: ${p => p.dimensions.window.height - 50}px;

  ${p =>
    lte(p, "small") &&
    `
    padding: 15px 20px 30px;
  `}
`;

export const ModalKeyboardView = styled(
  KeyboardAwareScrollView
)<BreakpointProps>`
  padding: 30px 40px 40px;
  width: 100%;
  height: ${p => p.dimensions.window.height - 50}px;

  ${p =>
    lte(p, "small") &&
    `
    padding: 15px 20px 30px;
  `}
`;

export type ModalProps = {
  visible: boolean;
  close?: () => void;
};

export type ModalContentProps = {
  sidebar?: ReactElement;
  content: ReactElement | null;
  title?: string | ReactElement;
  viewType?: "normal" | "scroll" | "keyboard";
};

export const BaseModal = ({
  visible,
  close,
  sidebar,
  title,
  content,
  viewType
}: ModalProps & ModalContentProps) => {
  const dimensions = useDimensions();

  return (
    <Modal visible={visible} onRequestClose={close} transparent>
      <SafeAreaView style={{ flex: 1 }}>
        <ModalOuter dimensions={dimensions}>
          <ModalInner dimensions={dimensions}>
            {!!sidebar && (
              <ModalSideBar dimensions={dimensions}>{sidebar}</ModalSideBar>
            )}
            <ModalMain>
              <ModalContentHeader dimensions={dimensions}>
                <Headline
                  style={{ alignSelf: "flex-end", flexWrap: "wrap", flex: 1 }}
                  color="primaryDarkest"
                >
                  {title}
                </Headline>
                {!!close && (
                  <ModalCloseButton onPress={close}>
                    <CloseIcon
                      size={30}
                      primaryFill="primaryDarkest"
                      primaryFillHover="primary"
                    />
                  </ModalCloseButton>
                )}
              </ModalContentHeader>
              {viewType === "scroll" ? (
                <ModalScrollView dimensions={dimensions}>
                  {content}
                </ModalScrollView>
              ) : viewType === "keyboard" ? (
                <ModalKeyboardView dimensions={dimensions}>
                  {content}
                </ModalKeyboardView>
              ) : (
                <ModalContent dimensions={dimensions}>{content}</ModalContent>
              )}
            </ModalMain>
          </ModalInner>
        </ModalOuter>
      </SafeAreaView>
    </Modal>
  );
};
