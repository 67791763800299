import { useEffect, useMemo, useRef, useState } from "react";
import {
  FlatList,
  Image,
  ListRenderItemInfo,
  Platform,
  Pressable,
  StyleSheet,
  TextInput,
  View,
  ViewStyle,
  ActivityIndicator
} from "react-native";
import { useDimensions } from "react-native-web-hooks";
import styled, { useTheme } from "styled-components/native";
import { Trainer, TRAINERS } from "../../constants/trainers";
import { useAuth } from "../../hooks/useAuth";
import { useFirestore } from "../../hooks/useFirestore";
import { usePayments } from "../../hooks/usePayments";
import { Button, IconButton } from "../atoms/Button";
import { Input, SearchInput } from "../atoms/Input";
import { LoadingSpinner } from "../atoms/LoadingSpinner";
import { BreakpointProps, getBp, gte, lt } from "../breakpoints";
import { getErrorMessage } from "../errors";
import { getAvatarImage } from "../helpers";
import {
  CheckIcon,
  CloseIcon,
  DashboardIcon,
  PokeBallIcon,
  ShuffleIcon,
  SortAscIcon,
  SortDescIcon,
  SparklesIcon
} from "../Icon";
import {
  SortOverlay,
  SortOverlayActions,
  SortOverlayHeader,
  SortOverlayIconButton,
  SortOverlayRadioLabel,
  SortOverlayRadioList
} from "../molecules/SortOverlay";
import Constants from "expo-constants";

import { Body, Caption, Title } from "../Text";
import {
  BaseModal,
  ModalMenuButton,
  ModalMenuButtons,
  ModalMenuTitle,
  ModalProps,
  ModalSideMenus
} from "./BaseModal";

const StyledOverlayContainer = styled.View<{
  position: "right" | "left";
}>`
  ${props => (props.position === "right" ? `right: 0;` : ``)};
  ${props => (props.position === "left" ? `left: 0;` : ``)};
  position: absolute;
  margin-top: 8px;
  background: ${props => props.theme.colors.surface};
  box-shadow: ${props => props.theme.shadows.light};
  border-radius: 10px;
  padding: 20px;
  z-index: 5;
  width: 270px;
`;

const ListOptions = styled.View<BreakpointProps>`
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;

  ${p =>
    lt(p, "medium") &&
    `
    flex-direction: column;
    margin-bottom: 10px;
  `}
`;

const ListOptionsTop = styled.View`
  margin-bottom: 10px;
  flex-direction: row;
`;

const ListOptionsBottom = styled.View<BreakpointProps>`
  height: 40px;

  ${p =>
    gte(p, "medium") &&
    `
    flex: 1;
  `}
`;

const OverlayHeader = styled.View`
  justify-content: space-between;
  margin-bottom: 10px;
  flex-direction: row;
`;

const OverlayActions = styled.View`
  margin-top: -5px;
  margin-right: -5px;
`;

const OverlayIconButton = styled.Pressable`
  padding: 0;
  border: none;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
`;

const OverlayMain = styled.View`
  margin-bottom: 10px;
`;

const OverlayFooterButtons = styled.View`
  justify-content: flex-end;
`;

const DetailRow = styled.View<{ last?: boolean }>`
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  padding-bottom: 16px;
  position: relative;

  ${props =>
    !props.last &&
    `
    border: 1px solid ${props.theme.colors.surface};
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    margin-bottom: 16px;  `}
`;

const buttonBase: ViewStyle = {
  borderRadius: 10,
  flex: 1,
  borderWidth: 2,
  borderStyle: "solid"
};

enum OverlayType {
  NAME = "name",
  PASSWORD = "password",
  DEACTIVATE = "deactivate"
}

type SortOption<T> = { label: string; value: keyof T };

const trainerSortOptions: SortOption<Trainer>[] = [
  {
    label: "Name",
    value: "label"
  }
];

const trainerColsMap = {
  small: 3,
  medium: 4,
  large: 5,
  xlarge: 6
};

export const SettingsModal = (props: ModalProps) => {
  console.log("Constants :>> ", Constants);
  const [active, setActive] = useState("account");
  const [overlay, setOverlay] = useState<OverlayType | null>(null);
  const [error, setError] = useState<string | null>(null);
  const dimensions = useDimensions();
  const [sortAsc, setSortAsc] = useState(false);
  const theme = useTheme();
  const {
    authUser,
    updateUserPassword,
    deactivateUserAccount,
    updateProfileData
  } = useAuth();
  const { user, updateUser } = useFirestore();
  const [nameInput, setNameInput] = useState(user?.displayName || "");
  const [oldPwInput, setOldPwInput] = useState("");
  const [newPwInput, setNewPwInput] = useState("");
  const [confirmPwInput, setConfirmPwInput] = useState("");
  const [deactivatePwInput, setDeactivatePwInput] = useState("");
  const [sortOverlayOpen, setSortOverlayOpen] = useState(false);
  const newPwRef = useRef<TextInput>(null);
  const confirmPwRef = useRef<TextInput>(null);
  const [selectedTrainer, setSelectedTrainer] = useState<Trainer>();
  const [trainerList, setTrainerList] = useState([
    ...TRAINERS.filter(t => t.access === "free")
  ]);
  const [searchInput, setSearchInput] = useState("");
  const [trainerSortValue, setTrainerSortValue] = useState(
    trainerSortOptions[0]
  );
  const { packages, isAdFree, isPro, makePurchase, loading, getOfferings } =
    usePayments();

  useEffect(() => {
    if (isPro || user?.isAdmin) {
      setTrainerList([...TRAINERS]);
    }
  }, [isPro, user]);

  const bp = getBp({ dimensions, theme });
  const numTrainerCols = bp ? trainerColsMap[bp] : 1;

  const updateDisplayName = () => {
    setError("");
    updateUser({ displayName: nameInput })
      .then(() => setOverlay(null))
      .catch(e => setError(e));
  };

  const updatePassword = () => {
    setError("");
    updateUserPassword(oldPwInput, newPwInput, confirmPwInput)
      .then(
        () => setOverlay(null),
        e => setError(getErrorMessage(e))
      )
      .catch((e: any) => setError(getErrorMessage(e)));
  };

  const deactivateAccount = () => {
    setError("");
    deactivateUserAccount(deactivatePwInput)
      .then(
        () => setOverlay(null),
        e => setError(getErrorMessage(e))
      )
      .catch((e: any) => setError(getErrorMessage(e)));
  };

  useEffect(() => {
    if (user) {
      setSelectedTrainer(TRAINERS.find(t => t.avatar === user.avatar));
    }
  }, [user]);

  useEffect(() => {
    if (active === "upgrades") {
      if (!packages && getOfferings) {
        getOfferings();
      }
    }
  }, [active]);

  let filteredList: Trainer[];

  const searchText = searchInput.toLowerCase();
  switch (active) {
    case "avatar":
      filteredList = trainerList.filter(({ label }) =>
        label.toLowerCase().includes(searchText)
      );
      break;

    default:
      filteredList = [];
  }

  const randomSelect = () => {
    if (active === "avatar") {
      const randomVal = Math.ceil(Math.random() * trainerList.length) - 1;
      const randomTrainer = trainerList[randomVal];
      selectTrainer(randomTrainer);
    }
  };

  const selectTrainer = (trainer: Trainer) => {
    setSelectedTrainer(trainer);

    const photoURL = TRAINERS.find(t => t.value === trainer.value)?.avatar;
    if (photoURL && authUser?.photoURL !== photoURL) {
      updateProfileData({ photoURL });
      updateUser({ avatar: photoURL });
    }
  };

  const trainerStyleSheet = useMemo(() => {
    const singleCol = lt({ dimensions, theme }, "small");

    return theme
      ? StyleSheet.create({
          container: {
            position: "relative",
            // width: singleCol ? "100%" : 136,
            width: `${(1 / numTrainerCols) * 100}%`,
            height: singleCol ? 70 : 136,
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: singleCol ? 0 : 5,
            paddingRight: singleCol ? 0 : 5
          },
          button: {
            ...buttonBase,
            backgroundColor: theme.colors.surface,
            borderColor: theme.colors.surface
          },
          header: {
            position: "absolute",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: 10,
            width: "100%",
            textAlign: "left",
            zIndex: 2
          },
          podium: {
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: singleCol ? "flex-end" : "center"
          },
          sprite: {
            height: singleCol ? 50 : 90,
            width: singleCol ? 50 : 90,
            marginRight: singleCol ? 4 : undefined
          },
          hoveredButton: {
            ...buttonBase,
            backgroundColor: theme.colors.surface,
            borderColor: theme.colors.primary
          },
          selectedButton: {
            ...buttonBase,
            backgroundColor: theme.colors.surface,
            borderColor: theme.colors.success
          }
        })
      : null;
  }, [theme, dimensions]);

  const renderTrainerItem = useMemo(
    () =>
      trainerStyleSheet
        ? ({ item }: ListRenderItemInfo<Trainer>) => (
            <View style={trainerStyleSheet.container}>
              <Pressable
                style={(props: any) =>
                  selectedTrainer?.value === item.value
                    ? trainerStyleSheet.selectedButton
                    : props.hovered
                    ? trainerStyleSheet.hoveredButton
                    : trainerStyleSheet.button
                }
                onPress={() => selectTrainer(item)}
              >
                <View style={trainerStyleSheet.header}>
                  <Title
                    color={
                      selectedTrainer?.value === item.value
                        ? "success"
                        : "primaryDark"
                    }
                    weight="semibold"
                    style={{ marginRight: 8 }}
                  >
                    {item.label}
                  </Title>
                  {item.access === "premium" && (
                    <SparklesIcon size={15} primaryFill="primaryDarkest" />
                  )}
                </View>
                <View style={trainerStyleSheet.podium}>
                  <Image
                    style={trainerStyleSheet.sprite}
                    source={{ uri: getAvatarImage(item.avatar) }}
                    resizeMode="contain"
                  />
                </View>
              </Pressable>
            </View>
          )
        : null,
    [trainerStyleSheet, selectedTrainer, user]
  );

  const SortIcon = sortAsc ? SortAscIcon : SortDescIcon;

  return (
    <BaseModal
      {...props}
      title={
        active === "avatar"
          ? `${active}: ${TRAINERS.find(t => t.avatar === user?.avatar)?.label}`
          : active
      }
      sidebar={
        <ModalSideMenus>
          <ModalMenuTitle dimensions={dimensions}>
            <Caption color="primaryDark" weight="semibold">
              Settings
            </Caption>
          </ModalMenuTitle>
          <ModalMenuButtons>
            <ModalMenuButton
              active={active === "account"}
              onPress={() => setActive("account")}
              icon={<DashboardIcon size={24} primaryFill="primaryDarkest" />}
              label="Account"
            />
          </ModalMenuButtons>
          <ModalMenuButtons>
            <ModalMenuButton
              active={active === "avatar"}
              onPress={() => setActive("avatar")}
              icon={<PokeBallIcon size={24} primaryFill="primaryDarkest" />}
              label="Avatar"
            />
          </ModalMenuButtons>
          <ModalMenuButtons>
            <ModalMenuButton
              active={active === "upgrades"}
              onPress={() => setActive("upgrades")}
              icon={<SparklesIcon size={24} primaryFill="primaryDarkest" />}
              label="Upgrades"
            />
          </ModalMenuButtons>
        </ModalSideMenus>
      }
      content={
        <>
          {active === "avatar" && (
            <ListOptions dimensions={dimensions}>
              <ListOptionsTop>
                {/* <IconButton
                  style={{ marginRight: 8 }}
                  onPress={() => setSortOverlayOpen(!sortOverlayOpen)}
                  Icon={SortIcon}
                /> */}
                <IconButton
                  style={{ marginRight: 8 }}
                  onPress={randomSelect}
                  Icon={ShuffleIcon}
                />
              </ListOptionsTop>
              <ListOptionsBottom dimensions={dimensions}>
                <SearchInput
                  onChangeText={setSearchInput}
                  value={searchInput}
                  placeholder="Search..."
                />
              </ListOptionsBottom>
            </ListOptions>
          )}
          {sortOverlayOpen && (
            <SortOverlay>
              <SortOverlayHeader>
                <Caption color="primaryDarkest" weight="semibold">
                  SORT OPTIONS
                </Caption>
                <SortOverlayActions>
                  <SortOverlayIconButton
                    onPress={() => setSortOverlayOpen(!sortOverlayOpen)}
                  >
                    <CloseIcon
                      primaryFill="primaryDarkest"
                      primaryFillHover="primary"
                      size={20}
                    />
                  </SortOverlayIconButton>
                </SortOverlayActions>
              </SortOverlayHeader>

              <SortOverlayRadioList>
                {trainerSortOptions.map((option, index) => {
                  const checked = trainerSortValue.value === option.value;
                  return (
                    <SortOverlayRadioLabel
                      checked={checked}
                      key={index}
                      onPress={() =>
                        checked
                          ? setSortAsc(!sortAsc)
                          : setTrainerSortValue(option)
                      }
                      style={(props: any) =>
                        !!props.hovered && {
                          borderColor: theme.colors.primary
                        }
                      }
                    >
                      <Body weight="semibold" color="primaryDark">
                        {option.label}
                      </Body>
                      <SortIcon
                        primaryFill="primary"
                        secondaryFill="primary"
                        size={16}
                      />
                    </SortOverlayRadioLabel>
                  );
                })}
              </SortOverlayRadioList>
            </SortOverlay>
          )}

          {active === "account" && (
            <View style={{ width: "100%" }}>
              <DetailRow>
                <View>
                  <Caption uppercase color="primaryDark" weight="semibold">
                    Email
                  </Caption>
                  <Body color="primaryDarkest" weight="semibold">
                    {authUser?.email}
                  </Body>
                </View>
              </DetailRow>
              <DetailRow>
                <View>
                  <Caption uppercase color="primaryDark" weight="semibold">
                    Display Name
                  </Caption>
                  <Body color="primaryDarkest" weight="semibold">
                    {user?.displayName}
                  </Body>
                </View>
                <View>
                  <Button onPress={() => setOverlay(OverlayType.NAME)}>
                    Change Name
                  </Button>
                </View>
              </DetailRow>
              {overlay === OverlayType.NAME ? (
                <StyledOverlayContainer position="right">
                  <OverlayHeader>
                    <Caption color="primaryDarkest" weight="semibold">
                      UPDATE DISPLAY NAME
                    </Caption>
                    <OverlayActions>
                      <OverlayIconButton onPress={() => setOverlay(null)}>
                        <CloseIcon
                          size={20}
                          primaryFill="primaryDarkest"
                          primaryFillHover="primary"
                        />
                      </OverlayIconButton>
                    </OverlayActions>
                  </OverlayHeader>
                  <OverlayMain>
                    <Input
                      placeholder={user?.displayName}
                      onChangeText={setNameInput}
                      value={nameInput}
                      returnKeyType="done"
                    />
                  </OverlayMain>
                  <OverlayFooterButtons>
                    <OverlayIconButton
                      onPress={updateDisplayName}
                      disabled={!nameInput}
                    >
                      <Body color="primary" weight="semibold">
                        Confirm{" "}
                      </Body>
                      <CheckIcon
                        primaryFill="primary"
                        secondaryFill="white"
                        primaryFillHover="success"
                        secondaryFillHover="white"
                        size={20}
                      />
                    </OverlayIconButton>
                  </OverlayFooterButtons>
                </StyledOverlayContainer>
              ) : null}
              <DetailRow>
                <View>
                  <Caption uppercase color="primaryDark" weight="semibold">
                    Password
                  </Caption>
                  <Body color="primaryDarkest" weight="semibold">
                    ************
                  </Body>
                </View>
                <View style={{ position: "relative" }}>
                  <Button onPress={() => setOverlay(OverlayType.PASSWORD)}>
                    Change Password
                  </Button>
                </View>
              </DetailRow>
              {overlay === OverlayType.PASSWORD ? (
                <StyledOverlayContainer position="right">
                  <OverlayHeader>
                    <Caption color="primaryDarkest" weight="semibold">
                      UPDATE PASSWORD
                    </Caption>
                    <OverlayActions>
                      <OverlayIconButton onPress={() => setOverlay(null)}>
                        <CloseIcon
                          primaryFill="primaryDarkest"
                          primaryFillHover="primary"
                          size={20}
                        />
                      </OverlayIconButton>
                    </OverlayActions>
                  </OverlayHeader>
                  <OverlayMain>
                    <Input
                      secureTextEntry
                      value={oldPwInput}
                      onChangeText={setOldPwInput}
                      placeholder="Old Password"
                      returnKeyType="next"
                      onSubmitEditing={() => newPwRef.current?.focus()}
                      blurOnSubmit={false}
                    />
                    <Input
                      secureTextEntry
                      value={newPwInput}
                      onChangeText={setNewPwInput}
                      externalRef={newPwRef}
                      returnKeyType="next"
                      placeholder="New Password"
                      onSubmitEditing={() => confirmPwRef.current?.focus()}
                      blurOnSubmit={false}
                    />
                    <Input
                      secureTextEntry
                      value={confirmPwInput}
                      onChangeText={setConfirmPwInput}
                      placeholder="Confirm New Password"
                      externalRef={confirmPwRef}
                      returnKeyType="done"
                    />
                    {!!error && <Caption color="danger">{error}</Caption>}
                  </OverlayMain>
                  <OverlayFooterButtons>
                    <OverlayIconButton
                      onPress={updatePassword}
                      disabled={!oldPwInput || !newPwInput || !confirmPwInput}
                    >
                      <Body color="primary" weight="semibold">
                        Confirm{" "}
                      </Body>
                      <CheckIcon
                        primaryFill="primary"
                        secondaryFill="white"
                        primaryFillHover="success"
                        secondaryFillHover="white"
                        size={20}
                      />
                    </OverlayIconButton>
                  </OverlayFooterButtons>
                </StyledOverlayContainer>
              ) : null}
              <DetailRow last>
                <View>
                  <View style={{ marginBottom: 24 }}>
                    <Caption uppercase color="primaryDark" weight="semibold">
                      Account Info
                    </Caption>
                    <Caption color="primaryDarkest">
                      Account ID:{" "}
                      <Caption weight="semibold">{authUser?.uid}</Caption>
                    </Caption>
                    <Caption color="primaryDarkest">
                      Account Creation Date:{" "}
                      <Caption weight="semibold">
                        {new Date(
                          authUser?.metadata.creationTime || 0
                        ).toLocaleString()}
                      </Caption>
                    </Caption>
                  </View>
                  <View style={{ position: "relative" }}>
                    <Button
                      danger
                      onPress={() => setOverlay(OverlayType.DEACTIVATE)}
                    >
                      Deactivate Account
                    </Button>
                    {overlay === OverlayType.DEACTIVATE ? (
                      <StyledOverlayContainer position="left">
                        <OverlayHeader>
                          <Caption color="primaryDarkest" weight="semibold">
                            DEACTIVATE ACCOUNT
                          </Caption>
                          <OverlayActions>
                            <OverlayIconButton onPress={() => setOverlay(null)}>
                              <CloseIcon
                                primaryFill="primaryDarkest"
                                primaryFillHover="primary"
                                size={20}
                              />
                            </OverlayIconButton>
                          </OverlayActions>
                        </OverlayHeader>
                        <OverlayMain>
                          <Input
                            secureTextEntry
                            value={deactivatePwInput}
                            onChangeText={setDeactivatePwInput}
                            placeholder="Confirm Password"
                            returnKeyType="done"
                          />
                          {!!error && <Caption color="danger">{error}</Caption>}
                        </OverlayMain>
                        <OverlayFooterButtons>
                          <OverlayIconButton
                            onPress={deactivateAccount}
                            disabled={!deactivatePwInput}
                          >
                            <Body color="primary" weight="semibold">
                              Confirm{" "}
                            </Body>
                            <CheckIcon
                              primaryFill="primary"
                              secondaryFill="white"
                              primaryFillHover="success"
                              secondaryFillHover="white"
                              size={20}
                            />
                          </OverlayIconButton>
                        </OverlayFooterButtons>
                      </StyledOverlayContainer>
                    ) : null}
                  </View>
                </View>
              </DetailRow>
            </View>
          )}
          {active === "avatar" && (
            <FlatList
              key={`trainer${numTrainerCols}`}
              numColumns={numTrainerCols}
              data={filteredList as Trainer[]}
              keyExtractor={item => item.value}
              removeClippedSubviews
              windowSize={2}
              renderItem={renderTrainerItem}
              columnWrapperStyle={
                numTrainerCols > 1
                  ? { marginLeft: -5, marginRight: -5 }
                  : undefined
              }
            />
          )}

          {active === "upgrades" && (
            <>
              {Platform.OS !== "web" &&
              Constants.nativeAppVersion !== "2.2.1" ? (
                <Body>Please update your app to view upgrades.</Body>
              ) : Platform.OS === "web" ? (
                <Body>
                  {isPro
                    ? "You are currently subscribed to ShinyHunt Pro. To modify this subscription, please use the iOS or Android app the subscription was purchased on."
                    : "Web payments are coming soon! To purchase ShinyHunt Pro please download our app on iOS or Android."}
                </Body>
              ) : (
                <View style={{ width: "100%" }}>
                  {packages && packages.lifetime && (
                    <DetailRow style={{ flexDirection: "column" }}>
                      <View>
                        <Caption
                          uppercase
                          color="primaryDark"
                          weight="semibold"
                        >
                          Remove Ads
                        </Caption>
                        <Body
                          color="primaryDarkest"
                          weight="semibold"
                          style={{ marginTop: 5, marginBottom: 20 }}
                        >
                          Lifetime removal of all ads on all devices.
                        </Body>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%"
                        }}
                      >
                        <Button
                          onPress={() =>
                            makePurchase(packages.lifetime!, "lifetime")
                          }
                          disabled={loading === "lifetime" || isPro || isAdFree}
                        >
                          {loading === "lifetime" ? (
                            <ActivityIndicator
                              style={{ paddingTop: 10 }}
                              color={theme.colors.text}
                            />
                          ) : isPro || isAdFree ? (
                            "Already Active"
                          ) : (
                            packages.lifetime.product.priceString + "/lifetime"
                          )}
                        </Button>
                      </View>
                    </DetailRow>
                  )}
                  {packages && (packages.monthly || packages.annual) && (
                    <DetailRow style={{ flexDirection: "column" }}>
                      <View>
                        <Caption
                          uppercase
                          color="primaryDark"
                          weight="semibold"
                        >
                          ShinyHunt Pro
                        </Caption>
                        <View
                          style={{ flexDirection: "column", paddingTop: 5 }}
                        >
                          <Body style={{ marginBottom: 5 }}>- No ads</Body>
                          <Body style={{ marginBottom: 5 }}>
                            - Animated models (where availabe Gen1 - Gen8)
                          </Body>
                          <Body style={{ marginBottom: 5 }}>
                            - Counter customizations
                          </Body>
                          <Body style={{ marginBottom: 5 }}>- Shiny theme</Body>
                          <Body style={{ marginBottom: 5 }}>
                            - Additional avatar options
                          </Body>
                          <Body style={{ marginBottom: 5 }}>
                            - And much more to come!
                          </Body>
                          <Body style={{ marginBottom: 5 }}></Body>
                        </View>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%"
                        }}
                      >
                        {packages.monthly && (
                          <Button
                            onPress={() =>
                              makePurchase(packages.monthly!, "monthly")
                            }
                            style={{ marginBottom: 20 }}
                            disabled={isPro || loading === "monthly"}
                          >
                            {loading === "monthly" ? (
                              <ActivityIndicator
                                style={{ paddingTop: 10 }}
                                color={theme.colors.text}
                              />
                            ) : isPro ? (
                              "Already Subscribed"
                            ) : (
                              packages.monthly.product.priceString + "/month"
                            )}
                          </Button>
                        )}
                        {packages.annual && (
                          <Button
                            onPress={() =>
                              makePurchase(packages.annual!, "annual")
                            }
                            disabled={isPro || loading === "annual"}
                          >
                            {loading === "annual" ? (
                              <ActivityIndicator
                                style={{ paddingTop: 10 }}
                                color={theme.colors.text}
                              />
                            ) : isPro ? (
                              "Already Subscribed"
                            ) : (
                              packages.annual.product.priceString + "/year"
                            )}
                          </Button>
                        )}
                      </View>
                    </DetailRow>
                  )}
                </View>
              )}
            </>
          )}
        </>
      }
    />
  );
};
