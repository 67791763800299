import { Picker } from "@react-native-picker/picker";
import { useState } from "react";
import { Image, Platform, Pressable } from "react-native";
import { useDimensions } from "react-native-web-hooks";
import styled, { useTheme } from "styled-components/native";
import { Input } from "../atoms/Input";
import { CheckIcon, CloseIcon } from "../Icon";
import { Body, Headline } from "../Text";

export const PromptModalOuter = styled.View`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
`;

export const PromptModalInner = styled.View<{ screenSize: number }>`
  margin: 70px auto;
  padding: 20px 30px;
  max-width: 700px;
  width: ${p => p.screenSize - 80}px;
  background: ${p => p.theme.colors.background};
  border-radius: 8px;
`;

const HeaderArea = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const MainContent = styled.View``;

const FooterArea = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
`;

export type PromptModalProps = {
  defaultPromptValue: string;
  title: string;
  desc: string;
  label?: string;
  isNumber?: boolean;
  isClearable?: boolean;
  select?: boolean;
  options?: { label: string; value: string }[];
  placeholder?: string;
  isOnboard?: boolean;
  callback: (prompt: string) => void;
  close?: () => void;
  force?: boolean;
};

export const PromptModal = (props: PromptModalProps) => {
  const [inputValue, setInputValue] = useState(props.defaultPromptValue);
  const dims = useDimensions();
  const theme = useTheme();

  const setValue = () => {
    if (inputValue !== props.defaultPromptValue || props.force) {
      props.callback(inputValue);
    }

    props.close && props.close();
  };

  return (
    <PromptModalOuter>
      <PromptModalInner screenSize={dims.window.width}>
        {props.isOnboard && (
          <Image
            source={require("../../assets/images/adrive-gen8-banner.png")}
            style={{
              width: "auto",
              height: 200,
              marginBottom: 20,
              borderRadius: 10
            }}
            resizeMode="cover"
          />
        )}
        <HeaderArea>
          <Headline color="primaryDarkest">{props.title}</Headline>

          {!!props.close && (
            <Pressable onPress={props.close}>
              <CloseIcon
                primaryFill="primaryDarkest"
                primaryFillHover="primary"
                size={30}
              />
            </Pressable>
          )}
        </HeaderArea>
        <MainContent>
          <Body color="primaryDarkest" style={{ marginBottom: 20 }}>
            {props.desc}
          </Body>
          {!!props.label && (
            <Body
              color="primaryDarkest"
              weight="semibold"
              style={{ marginBottom: 4 }}
            >
              {props.label}
            </Body>
          )}
          {props.select && props.options ? (
            <Picker
              selectedValue={inputValue}
              mode="dialog"
              onValueChange={value => setInputValue(value)}
              style={
                Platform.OS === "web"
                  ? {
                      height: 38,
                      backgroundColor: theme.colors.background,
                      borderWidth: 2,
                      borderStyle: "solid",
                      borderColor: theme.colors.primary,
                      borderRadius: 4,
                      color: theme.colors.primaryDarkest
                    }
                  : {
                      backgroundColor:
                        Platform.OS === "android"
                          ? "white"
                          : theme.colors.background
                    }
              }
            >
              <Picker.Item
                label="Select a trainer"
                value=""
                color={Platform.OS == "android" ? "black" : theme.colors.text}
              />
              {props.options.map((option, i) => (
                <Picker.Item
                  value={option.value}
                  label={option.label}
                  key={i}
                  color={Platform.OS == "android" ? "black" : theme.colors.text}
                />
              ))}
            </Picker>
          ) : (
            <Input
              keyboardType={props.isNumber ? "numeric" : undefined}
              value={inputValue}
              onChangeText={setInputValue}
              placeholder={props.placeholder}
              returnKeyType="done"
            />
          )}
        </MainContent>
        <FooterArea>
          <Pressable
            onPress={setValue}
            disabled={!props.isClearable && !inputValue}
          >
            <CheckIcon
              primaryFill="primary"
              secondaryFill="white"
              primaryFillHover="success"
              secondaryFillHover="white"
              size={30}
            />
          </Pressable>
        </FooterArea>
      </PromptModalInner>
    </PromptModalOuter>
  );
};
