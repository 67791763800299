import { useNavigation } from "@react-navigation/core";
import { useEffect, useState } from "react";
import {
  Alert,
  Image,
  Linking,
  Modal,
  Platform,
  Pressable
} from "react-native";
import { useDimensions } from "react-native-web-hooks";
import styled, { useTheme } from "styled-components/native";
import { Game, GAMES } from "../../constants/games";
import { Method, METHODS } from "../../constants/methods";
import { Pokemon, POKEMON } from "../../constants/pokemon-new";
import { useFirestore } from "../../hooks/useFirestore";
import { usePayments } from "../../hooks/usePayments";
import { HuntObject, HuntSetting } from "../../model/model";
import { Nav } from "../../navTypes";
import { BreakpointProps, DimensionsType, gt, lt } from "../breakpoints";
import {
  formatTimer,
  getSerebiiLink,
  getSpriteSize,
  getSpriteSrc
} from "../helpers";
import {
  CheckIcon,
  CloseIcon,
  DotsVerticalIcon,
  EditIcon,
  ExternalIcon,
  LinkIcon,
  MonitorIcon,
  PauseIcon,
  PlayIcon,
  RefreshIcon,
  TargetIcon,
  TrashIcon,
  UndoIcon
} from "../Icon";
import { NewHuntmodal } from "../modals/NewHuntModal";
import { PromptModal } from "../modals/PromptModal";
import { Caption, Display, Label, SubDisplay } from "../Text";

const marathonChevronImage = require("../../assets/images/backgrounds/sv-counter-bg.png");
const marathonPodiumImage = require("../../assets/images/podiums/sv-podium-bg.png");
const counterImage = require("../../assets/images/backgrounds/sv-counter-text-bg.png");

const Container = styled.ImageBackground<BreakpointProps>`
  position: relative;
  flex-direction: row;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: black;

  ${Platform.OS === "web"
    ? `
    margin: 5px;
  `
    : ""}

  ${p => (gt(p, "small") ? "max-width: 300px" : "")};
  ${p => (lt(p, "small") ? `margin-bottom: 20px;` : ``)};
`;

const CounterArea = styled.View<{ chromaKey: string }>`
  background-color: ${p => (p.chromaKey ? p.chromaKey : "transparent")};
  padding: 20px;
  flex: 1;
`;

const Header = styled.View<{ dimensions: DimensionsType }>`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  ${Platform.OS === "web"
    ? `
    top: 0;
    left: 0;
    padding: 20px;
  `
    : `
    top: 20px;
    left: 20px;
  `}

  z-index: 1;
`;

const HeaderInfo = styled.View`
  text-align: left;
`;

const Main = styled.Pressable`
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`;

const Podium = styled.ImageBackground`
  height: 200px;
  width: 200px;
  align-items: center;
  justify-content: center;
`;

const EncounterGroup = styled.ImageBackground`
  position: relative;
  align-items: baseline;
  margin-top: -20px;
  z-index: 2;
  width: 135px;
  align-items: center;
  justify-content: center;
`;

const EncounterDecrement = styled.Pressable`
  position: absolute;
  bottom: 4px;
  right: -4px;
`;

const ChainInfo = styled.Pressable`
  align-items: center;
  z-index: 2;
`;

const Sprite = styled.Image<{ size: string[] }>`
  max-width: ${props => props.size[0]};
  max-height: ${props => props.size[1]};
`;

const Footer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const MoreMenuOverlay = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background-color: ${props => props.theme.colors.surface};
  flex-direction: column;
  border-radius: 10px;
`;

const MoreMenuContainer = styled.View`
  background: ${props => props.theme.colors.surface};
  border-radius: 10px;
  padding: 20px;
`;

const OddsModalOverlay = styled.View`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
`;

const OddsModalContainer = styled.View`
  background: ${props => props.theme.colors.surface};
  border-radius: 10px;
  padding: 20px;
`;

const OddsOverlayHeader = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const OddsOverlayActions = styled.View`
  margin-top: -5px;
  margin-right: -5px;
`;

const OddsOverlayList = styled.View``;

const OverlayButtonList = styled.View`
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
`;

const OverlayFooterButtons = styled.View`
  justify-content: space-between;
  flex-direction: row;
  margin-top: 10px;
  padding-top: 10px;
  border: 0px solid ${props => props.theme.colors.background};
  border-top-width: 1px;
`;

const OverlayFooterButtonsLeft = styled.View`
  flex-direction: row;
`;

const OverlayButton = styled.Pressable`
  justify-content: center;
  margin-bottom: 8px;
  align-items: center;
  height: 40px;
  border-radius: 4px;
  color: ${props => props.theme.colors.primaryDarkest};
  background: ${props => props.theme.colors.background};
`;

const OddsOverlayItem = styled.View`
  justify-content: space-between;
  padding-bottom: 4px;
  flex-direction: row;
`;

const OddsOverlaySummary = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-top: 6px;
  padding-top: 10px;
  border: 0px solid ${props => props.theme.colors.background};
  border-top-width: 1px;
`;

const ConfirmationModalOverlay = styled.View`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
`;

const ConfirmationModalContainer = styled.View`
  background: ${props => props.theme.colors.surface};
  border-radius: 10px;
  padding: 20px;
`;

const ConfirmationOverlayHeader = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const ConfirmationOverlayActions = styled.View`
  margin-top: -5px;
  margin-right: -5px;
`;

const ConfirmationOverlayList = styled.View``;

const ConfirmationOverlayItem = styled.View`
  justify-content: space-between;
  padding-bottom: 4px;
  flex-direction: row;
`;

enum ModalType {
  MORE = "more",
  ODDS = "odds",
  CONFIRM = "confirm",
  EDIT = "edit",
  HUNT = "hunt",
  EDIT_INCREMENT = "editIncrement",
  EDIT_TIMER = "editTimer",
  EDIT_ENCOUNTERS = "editEncounters",
  SET_CHROMAKEY = "setChromaKey"
}

export type CounterProps = {
  hunt: HuntObject;
  isAdmin: boolean;
};

type HuntOdds = {
  baseRate: number;
  charmMult: number;
  methodMult: number;
  shinyRate: string;
};

export const Counter = ({ hunt, isAdmin }: CounterProps) => {
  const { encounters, incrementAmount, elapsedTime } = hunt;
  const { isPro } = usePayments();
  const [intervalId, setIntervalId] = useState<number>();
  const [intervalTimer, setIntervalTimer] = useState(0);
  const [huntStarted, setHuntStarted] = useState(encounters > 0);
  const [timerRunning, setTimerRunning] = useState(false);
  const [chromaKey, setChromaKey] = useState("");
  const [modalOpen, setModalOpen] = useState<ModalType | null>(null);
  const [huntOdds, setHuntOdds] = useState<HuntOdds>();
  const { user, updateHunt, deleteHunt, addCollectionFromHunt } =
    useFirestore();
  const [pokemon, setPokemon] = useState<Pokemon>();
  const [method, setMethod] = useState<Method>();
  const [image, setImage] = useState<{
    uri: string;
    width: number;
    height: number;
  }>();
  const nav = useNavigation<Nav>();
  const theme = useTheme();
  const dimensions = useDimensions();

  useEffect(() => {
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const pokemon = POKEMON.find(p => p.value === hunt.target.value);
    setPokemon(pokemon);
    setMethod(METHODS.find(m => m.id === hunt.method.id));

    if (pokemon) {
      const uri = getSpriteSrc(pokemon, true, isAdmin, isPro);
      const size = Image.getSize(uri, (width, height) => {
        setImage({ uri, width, height });
      });
    }
  }, [hunt]);

  const incrememt = () => {
    const updEncounters = encounters + incrementAmount;
    const updElapsedTime = elapsedTime + intervalTimer;
    const updChainCount = METHODS.find(method => method.id === hunt.method.id)
      ?.chain
      ? hunt.chainCount + hunt.incrementAmount
      : 0;

    updateHunt(hunt.uid, {
      encounters: updEncounters,
      elapsedTime: updElapsedTime,
      chainCount: updChainCount
    }).then(hunt => {
      setIntervalTimer(0);
    });
  };

  const decrement = () => {
    const updEncounters = Math.max(0, hunt.encounters - hunt.incrementAmount);
    const updChainCount = METHODS.find(method => method.id === hunt.method.id)
      ?.chain
      ? Math.max(0, hunt.chainCount - hunt.incrementAmount)
      : 0;

    updateHunt(hunt.uid, {
      encounters: updEncounters,
      chainCount: updChainCount
    }).then(hunt => {
      setIntervalTimer(0);
    });
  };

  const chainReset = () => {
    updateHunt(hunt.uid, { chainCount: 0 });
  };

  const timerIncrement = () => {
    setIntervalTimer(intervalTimer => intervalTimer + 1);
  };

  const startTimer = () => {
    if (!huntStarted) {
      setHuntStarted(true);
    }

    const intervalId = setInterval(() => {
      timerIncrement();
    }, 1000);
    setIntervalId(intervalId as any);
    setTimerRunning(true);
  };

  const stopTimer = () => {
    clearInterval(intervalId);
    setTimerRunning(false);
  };

  const clearTimer = () => {
    stopTimer();
    setIntervalTimer(0);
  };

  const removeHunt = () => {
    if (Platform.OS === "web") {
      if (window.confirm("Are you sure you want to delete this hunt?")) {
        clearTimer();
        deleteHunt(hunt.uid);
      }
    } else {
      Alert.alert(
        "Are you sure?",
        "You cannot recover hunts once they are deleted.",
        [
          { text: "Cancel", style: "cancel" },
          {
            text: "Delete Hunt",
            onPress: () => {
              clearTimer();
              deleteHunt(hunt.uid);
            },
            style: "destructive"
          }
        ]
      );
    }
  };

  const updateIncrement = (value?: string) => {
    const incrementAmount = value && parseInt(value);
    if (incrementAmount && Number.isInteger(incrementAmount)) {
      updateHunt(hunt.uid, { incrementAmount });
    }
  };

  const updateTimer = (value?: string) => {
    const elapsedTime = value && parseFloat(value) * 60;
    if (elapsedTime && Number.isFinite(elapsedTime)) {
      updateHunt(hunt.uid, { elapsedTime, altered: true });
    }
  };

  const updateEncouters = (value?: string) => {
    const encounters = value && parseInt(value);
    if (encounters && Number.isInteger(encounters)) {
      updateHunt(hunt.uid, { encounters, altered: true });
    }
  };

  const resetHunt = () => {
    if (Platform.OS === "web") {
      if (window.confirm("Are you sure you want to reset this hunt?")) {
        clearTimer();
        updateHunt(hunt.uid, {
          encounters: 0,
          elapsedTime: 0,
          chainCount: 0
        });
      }
    } else {
      Alert.alert(
        "Are you sure?",
        "You cannot recover old hunt data once reset.",
        [
          { text: "Cancel", style: "cancel" },
          {
            text: "Reset Hunt",
            onPress: () => {
              clearTimer();
              updateHunt(hunt.uid, {
                encounters: 0,
                elapsedTime: 0,
                chainCount: 0
              });
            },
            style: "destructive"
          }
        ]
      );
    }
  };

  const encounterShiny = () => {
    addCollectionFromHunt(hunt).then(() => {
      stopTimer();
      deleteHunt(hunt.uid).then(() => {
        nav.navigate("Collection");
      });
    });
  };

  const toggleOddsModal = () => {
    if (modalOpen === ModalType.ODDS) {
      setModalOpen(null);
    } else {
      setModalOpen(ModalType.ODDS);
    }
    calculateOdds();
  };

  const calculateOdds = () => {
    const game = GAMES.find(game => game.value === hunt.game.value) as Game;
    const generation = game.generation;
    let method = METHODS.find(method => method.id === hunt.method.id)
      ?.value as string;

    if (method === "breeding") {
      if (hunt.selectedSettings.includes(HuntSetting.MASUDA)) {
        method = "masuda";
      }
    }

    const { chainCount, battledCount } = hunt;
    const shinyCharm = hunt.selectedSettings.includes(HuntSetting.SHINY_CHARM);
    const lure = hunt.selectedSettings.includes(HuntSetting.LURE);
    const diglettBonus = hunt.selectedSettings.includes(HuntSetting.DIGLETT);
    const sparklingPower = hunt.selectedSettings.includes(
      HuntSetting.SPARKLING_POWER_3
    );

    let baseRate = method === "dynamax" ? 300 : generation >= 6 ? 4096 : 8192;

    let shinyCharmMultiplier = shinyCharm && generation >= 5 ? 2 : 0;

    //SHINY CHARM IS BROKEN IN BDSP UNLESS BREEDING
    if (
      (game.value === "recvi03paia311RCm" ||
        game.value === "reckhe1SAChkMKRoX") &&
      method !== "breeding" &&
      method !== "masuda"
    ) {
      shinyCharmMultiplier = 0;
    }

    //SHINY CHARM DOESN'T AFFECT TERA RAID
    if (method === "tera-raid") {
      shinyCharmMultiplier = 0;
    }

    let methodMultiplier: number = 0;

    switch (method) {
      case "masuda":
        if (generation === 4) {
          methodMultiplier = 4;
        } else if (generation >= 5) {
          methodMultiplier = 5;
        } else {
          methodMultiplier = 0;
        }
        break;
      case "pokeradar":
        shinyCharmMultiplier = 0;
        if (
          game.value === "recvi03paia311RCm" ||
          game.value === "reckhe1SAChkMKRoX"
        ) {
          baseRate = baseRate * 16;
          if (chainCount < 1) {
            methodMultiplier = 15;
          } else if (chainCount < 30) {
            methodMultiplier = chainCount + 15;
          } else if (chainCount <= 35) {
            methodMultiplier = chainCount + 19;
          } else if (chainCount === 36) {
            methodMultiplier = chainCount + 29;
          } else if (chainCount === 37) {
            methodMultiplier = chainCount + 44;
          } else if (chainCount === 38) {
            methodMultiplier = chainCount + 125;
          } else if (chainCount === 39) {
            methodMultiplier = chainCount + 288;
          } else {
            methodMultiplier = 660;
          }
        } else {
          baseRate = baseRate * 8;
          if (chainCount < 1) {
            methodMultiplier = 7;
          } else if (chainCount <= 4) {
            methodMultiplier = 8;
          } else if (chainCount <= 8) {
            methodMultiplier = 9;
          } else if (chainCount <= 11) {
            methodMultiplier = 10;
          } else if (chainCount <= 13) {
            methodMultiplier = 11;
          } else if (chainCount <= 15) {
            methodMultiplier = 12;
          } else if (chainCount <= 17) {
            methodMultiplier = 13;
          } else if (chainCount <= 19) {
            methodMultiplier = 14;
          } else if (chainCount <= 26) {
            methodMultiplier = 15 + (chainCount - 20);
          } else if (chainCount === 27) {
            methodMultiplier = 23;
          } else if (chainCount === 28) {
            methodMultiplier = 25;
          } else if (chainCount === 29) {
            methodMultiplier = 27;
          } else if (chainCount === 30) {
            methodMultiplier = 29;
          } else if (chainCount === 31) {
            methodMultiplier = 32;
          } else if (chainCount === 32) {
            methodMultiplier = 36;
          } else if (chainCount === 33) {
            methodMultiplier = 40;
          } else if (chainCount === 34) {
            methodMultiplier = 46;
          } else if (chainCount === 35) {
            methodMultiplier = 54;
          } else if (chainCount === 36) {
            methodMultiplier = 65;
          } else if (chainCount === 37) {
            methodMultiplier = 81;
          } else if (chainCount === 38) {
            methodMultiplier = 109;
          } else if (chainCount === 39) {
            methodMultiplier = 163;
          } else {
            methodMultiplier = 327;
          }
        }
        break;
      case "friend":
        shinyCharmMultiplier = 0;
        methodMultiplier = 7;
        break;
      case "fishing":
        if (chainCount <= 20) {
          methodMultiplier = 2 * chainCount;
        } else {
          methodMultiplier = 40;
        }
        break;
      case "dexnav":
        methodMultiplier = 0;
        break;
      case "sos":
        if (chainCount <= 10) {
          methodMultiplier = 0;
        } else if (chainCount <= 20) {
          methodMultiplier = 4;
        } else if (chainCount <= 30) {
          methodMultiplier = 8;
        } else {
          methodMultiplier = 12;
        }
        break;
      case "combo":
        if (chainCount < 11) {
          methodMultiplier = 0;
        } else if (chainCount <= 20) {
          methodMultiplier = 3;
        } else if (chainCount <= 30) {
          methodMultiplier = 7;
        } else {
          methodMultiplier = 11;
        }

        if (lure) {
          methodMultiplier += 1;
        }
        break;
      case "battled":
        if (!battledCount || battledCount < 50) {
          methodMultiplier = 0;
        } else if (battledCount < 100) {
          methodMultiplier = 1;
        } else if (battledCount < 200) {
          methodMultiplier = 2;
        } else if (battledCount < 300) {
          methodMultiplier = 3;
        } else if (battledCount < 500) {
          methodMultiplier = 4;
        } else {
          methodMultiplier = 5;
        }
        break;
      case "underground":
        if (diglettBonus) {
          methodMultiplier = 1;
        } else {
          methodMultiplier = 0;
        }
        break;
      case "outbreak-method":
        if (
          game.value === "recKpTD9URdio0NJy" ||
          game.value === "recxvgp09rQnqzjxG"
        ) {
          if (chainCount < 30) {
            methodMultiplier = 0;
          } else if (chainCount < 60) {
            methodMultiplier = 1;
          } else {
            methodMultiplier = 2;
          }
          if (sparklingPower) {
            methodMultiplier = methodMultiplier + 3;
          }
        }
        break;
      case "random":
        if (
          game.value === "recKpTD9URdio0NJy" ||
          game.value === "recxvgp09rQnqzjxG"
        ) {
          if (sparklingPower) {
            methodMultiplier = 3;
          }
        }
        break;
      default:
        methodMultiplier = 0;
    }

    let shinyRate =
      "1/" +
      Math.round(baseRate / (1 + shinyCharmMultiplier + methodMultiplier));

    if (method === "dexnav" || method === "warphole" || method === "battled") {
      shinyRate = shinyRate + "*";
    }

    setHuntOdds({
      baseRate: baseRate,
      charmMult: shinyCharmMultiplier,
      methodMult: methodMultiplier,
      shinyRate: shinyRate
    });
  };

  return (
    <Container
      source={chromaKey ? null : marathonChevronImage}
      dimensions={dimensions}
    >
      <CounterArea chromaKey={chromaKey}>
        <Header dimensions={dimensions}>
          <HeaderInfo>
            <SubDisplay color="white" weight="semibold">
              {hunt.target.label}
            </SubDisplay>
            <Label color="white">{formatTimer(hunt.elapsedTime)}</Label>
            <Label color="white">{formatTimer(intervalTimer)}</Label>
          </HeaderInfo>
          <Pressable onPress={() => setModalOpen(ModalType.MORE)}>
            <DotsVerticalIcon primaryFill="white" size={20} />
          </Pressable>
        </Header>
        <Main onPress={incrememt} disabled={!timerRunning}>
          <Podium source={chromaKey ? null : marathonPodiumImage}>
            {pokemon && image && (
              <Sprite
                resizeMethod="auto"
                resizeMode="contain"
                size={getSpriteSize(pokemon)}
                source={image}
                style={{ width: image.width, height: image.height }}
              />
            )}
          </Podium>
          <EncounterGroup source={counterImage} resizeMode="contain">
            <Display
              weight="bold"
              color="white"
              noTextFix
              style={{ padding: 10 }}
            >
              {hunt.encounters}
            </Display>
            <EncounterDecrement onPress={decrement}>
              <UndoIcon
                primaryFill="white"
                primaryFillHover="primary"
                size={16}
              />
            </EncounterDecrement>
          </EncounterGroup>
          {timerRunning && !!method?.chain ? (
            <ChainInfo onPress={chainReset}>
              <LinkIcon
                primaryFill="white"
                secondaryFill="primaryLight"
                secondaryFillHover="primaryDarkest"
                size={12}
              />
              <Caption color="white">{hunt.chainCount}</Caption>
            </ChainInfo>
          ) : huntStarted ? (
            <Caption color="white" style={timerRunning && { opacity: 0 }}>
              {timerRunning ? "running" : "paused"}
            </Caption>
          ) : (
            <Caption color="white">click play to start</Caption>
          )}
        </Main>
        <Footer>
          <Pressable onPress={toggleOddsModal}>
            <MonitorIcon
              primaryFill="white"
              secondaryFill="white"
              secondaryFillHover="primary"
              size={20}
            />
          </Pressable>
          {timerRunning ? (
            <Pressable onPress={stopTimer}>
              <PauseIcon
                primaryFill="white"
                secondaryFill="black"
                secondaryFillHover="primary"
                size={40}
              />
            </Pressable>
          ) : (
            <Pressable onPress={startTimer}>
              <PlayIcon
                primaryFill="white"
                secondaryFill="black"
                secondaryFillHover="primary"
                size={40}
              />
            </Pressable>
          )}
          <Pressable onPress={() => setModalOpen(ModalType.CONFIRM)}>
            <TargetIcon
              primaryFill="white"
              secondaryFill="white"
              secondaryFillHover="primary"
              size={20}
            />
          </Pressable>
        </Footer>
      </CounterArea>
      {modalOpen === ModalType.MORE ? (
        <MoreMenuOverlay>
          <MoreMenuContainer>
            <OddsOverlayHeader>
              <Caption weight="semibold">HUNT SETTINGS</Caption>
              <OddsOverlayActions>
                <Pressable onPress={() => setModalOpen(null)}>
                  <CloseIcon
                    primaryFill="primaryLight"
                    primaryFillHover="primaryDark"
                    size={20}
                  />
                </Pressable>
              </OddsOverlayActions>
            </OddsOverlayHeader>
            <OddsOverlayList>
              <OddsOverlayItem>
                <Caption>Game</Caption>
                <Caption>{hunt.game.label}</Caption>
              </OddsOverlayItem>

              <OddsOverlayItem>
                <Caption>Method</Caption>
                <Caption>
                  <Caption>{hunt.method.label}</Caption>
                </Caption>
              </OddsOverlayItem>
            </OddsOverlayList>

            <OverlayButtonList>
              <OverlayButton
                style={(props: any) =>
                  !!props.hovered && { backgroundColor: theme.colors.primary }
                }
                onPress={() => setModalOpen(ModalType.EDIT_INCREMENT)}
              >
                <Caption color="primaryDarkest" weight="semibold">
                  Adjust Increment
                </Caption>
              </OverlayButton>
              <OverlayButton
                style={(props: any) =>
                  !!props.hovered && { backgroundColor: theme.colors.primary }
                }
                onPress={() => setModalOpen(ModalType.EDIT_TIMER)}
              >
                <Caption color="primaryDarkest" weight="semibold">
                  Adjust Timer
                </Caption>
              </OverlayButton>
              <OverlayButton
                style={(props: any) =>
                  !!props.hovered && { backgroundColor: theme.colors.primary }
                }
                onPress={() => setModalOpen(ModalType.EDIT_ENCOUNTERS)}
              >
                <Caption color="primaryDarkest" weight="semibold">
                  Adjust Encounters
                </Caption>
              </OverlayButton>
              {(!!isPro || !!user?.isAdmin) && (
                <OverlayButton
                  style={(props: any) =>
                    !!props.hovered && { backgroundColor: theme.colors.primary }
                  }
                  onPress={() => setModalOpen(ModalType.SET_CHROMAKEY)}
                >
                  <Caption color="primaryDarkest" weight="semibold">
                    Set Chroma Key
                  </Caption>
                </OverlayButton>
              )}
            </OverlayButtonList>
            <OverlayFooterButtons>
              <OverlayFooterButtonsLeft>
                <Pressable onPress={resetHunt} style={{ marginRight: 8 }}>
                  <RefreshIcon
                    primaryFill="primaryLight"
                    primaryFillHover="primaryDark"
                    secondaryFill="white"
                    size={20}
                  />
                </Pressable>

                <Pressable
                  onPress={() =>
                    hunt.target.value
                      ? Linking.openURL(getSerebiiLink(hunt.target))
                      : null
                  }
                >
                  <ExternalIcon
                    primaryFill="primaryLight"
                    primaryFillHover="primaryDark"
                    secondaryFillHover="primaryDark"
                    size={20}
                  />
                </Pressable>
              </OverlayFooterButtonsLeft>
              <Pressable onPress={removeHunt}>
                <TrashIcon
                  primaryFill="primaryLight"
                  primaryFillHover="dangerLight"
                  secondaryFillHover="dangerLight"
                  size={20}
                />
              </Pressable>
            </OverlayFooterButtons>
          </MoreMenuContainer>
        </MoreMenuOverlay>
      ) : modalOpen === ModalType.ODDS ? (
        <OddsModalOverlay>
          <OddsModalContainer>
            <OddsOverlayHeader>
              <Caption weight="semibold">SHINY ODDS</Caption>
              <OddsOverlayActions>
                <Pressable onPress={() => setModalOpen(null)}>
                  <CloseIcon
                    primaryFill="primaryLight"
                    primaryFillHover="primaryDark"
                    size={20}
                  />
                </Pressable>
              </OddsOverlayActions>
            </OddsOverlayHeader>
            <OddsOverlayList>
              <OddsOverlayItem>
                <Caption>Base Rate</Caption>
                <Caption>1/{huntOdds?.baseRate}</Caption>
              </OddsOverlayItem>
              <OddsOverlayItem>
                <Caption>Charm Rerolls</Caption>
                <Caption>+{huntOdds?.charmMult}</Caption>
              </OddsOverlayItem>
              <OddsOverlayItem>
                <Caption>Method Rerolls</Caption>
                <Caption>+{huntOdds?.methodMult}</Caption>
              </OddsOverlayItem>
              <OddsOverlaySummary>
                <Caption weight="semibold">Current Rate</Caption>
                <Caption weight="semibold">{huntOdds?.shinyRate}</Caption>
              </OddsOverlaySummary>
            </OddsOverlayList>
          </OddsModalContainer>
        </OddsModalOverlay>
      ) : modalOpen === ModalType.CONFIRM ? (
        <ConfirmationModalOverlay>
          <ConfirmationModalContainer>
            <ConfirmationOverlayHeader>
              <Caption weight="semibold">CONGRATULATIONS!</Caption>
              <ConfirmationOverlayActions>
                <Pressable onPress={() => setModalOpen(null)}>
                  <CloseIcon
                    primaryFill="primaryLight"
                    primaryFillHover="primaryDark"
                    size={20}
                  />
                </Pressable>
              </ConfirmationOverlayActions>
            </ConfirmationOverlayHeader>
            <ConfirmationOverlayList>
              <ConfirmationOverlayItem>
                <Caption>Pokémon</Caption>
                <Caption>{pokemon?.label}</Caption>
              </ConfirmationOverlayItem>
              <ConfirmationOverlayItem>
                <Caption>Encounters</Caption>
                <Caption>{hunt.encounters}</Caption>
              </ConfirmationOverlayItem>
              <ConfirmationOverlayItem>
                <Caption>Total Time</Caption>
                <Caption>{formatTimer(hunt.elapsedTime)}</Caption>
              </ConfirmationOverlayItem>
            </ConfirmationOverlayList>
            <OverlayFooterButtons>
              <Pressable onPress={() => setModalOpen(ModalType.HUNT)}>
                <EditIcon
                  primaryFill="primaryLight"
                  secondaryFill="primaryLight"
                  primaryFillHover="primaryDark"
                  secondaryFillHover="primaryDark"
                  size={20}
                />
              </Pressable>
              <Pressable onPress={encounterShiny}>
                <CheckIcon
                  primaryFill="primary"
                  secondaryFill="white"
                  primaryFillHover="success"
                  secondaryFillHover="white"
                  size={20}
                />
              </Pressable>
            </OverlayFooterButtons>
          </ConfirmationModalContainer>
        </ConfirmationModalOverlay>
      ) : null}

      <NewHuntmodal
        visible={modalOpen === ModalType.HUNT}
        close={() => setModalOpen(null)}
        hunt={hunt}
      />

      <Modal
        visible={modalOpen === ModalType.EDIT_INCREMENT}
        onRequestClose={() => setModalOpen(null)}
        transparent
      >
        <PromptModal
          callback={updateIncrement}
          title="Adjust Increment"
          desc="Update hunt increment amount with a non-negative integer."
          placeholder="Ex. 1"
          defaultPromptValue={incrementAmount.toString()}
          isNumber
          close={() => setModalOpen(null)}
        />
      </Modal>

      <Modal
        visible={modalOpen === ModalType.EDIT_TIMER}
        onRequestClose={() => setModalOpen(null)}
        transparent
      >
        <PromptModal
          callback={updateTimer}
          title="Adjust Timer"
          desc="Update hunt timer length (in minutes) with a non-negative integer."
          placeholder="Ex. 30"
          defaultPromptValue={(elapsedTime / 60).toFixed(3).toString()}
          isNumber
          close={() => setModalOpen(null)}
        />
      </Modal>

      <Modal
        visible={modalOpen === ModalType.EDIT_ENCOUNTERS}
        onRequestClose={() => setModalOpen(null)}
        transparent
      >
        <PromptModal
          callback={updateEncouters}
          title="Adjust Encounters"
          desc="Update hunt encounter count with a non-negative integer."
          placeholder="Ex. 200"
          defaultPromptValue={encounters.toString()}
          isNumber
          close={() => setModalOpen(null)}
        />
      </Modal>

      <Modal
        visible={modalOpen === ModalType.SET_CHROMAKEY}
        onRequestClose={() => setModalOpen(null)}
        transparent
      >
        <PromptModal
          callback={setChromaKey}
          title="Set Chroma Key"
          desc="Add a hex color code to set the counter colors."
          placeholder="Ex. #00B140"
          defaultPromptValue={chromaKey.toString()}
          isClearable={true}
          // isString
          close={() => setModalOpen(null)}
        />
      </Modal>
    </Container>
  );
};
