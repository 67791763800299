export type Game = {
  label: string;
  value: string;
  release: string;
  generation: number;
  type: string;
  console: string;
  charm: boolean;
  lure: boolean;
  breeding: boolean;
  active: boolean;
  image: string;
};
export const GAMES: Game[] = [
  {
    label: "Pokémon Red",
    value: "recLbOR0csXJMUeZ7",
    release: "1998-09-30",
    generation: 1,
    type: "main",
    console: "Game Boy",
    charm: false,
    lure: false,
    breeding: false,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-red.png"
  },
  {
    label: "Pokémon Blue",
    value: "recxLYHpJi0xdBk8c",
    release: "1998-09-30",
    generation: 1,
    type: "main",
    console: "Game Boy",
    charm: false,
    lure: false,
    breeding: false,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-blue.png"
  },
  {
    label: "Pokémon Yellow",
    value: "recGmPJ0yrlTDfDyn",
    release: "1999-10-19",
    generation: 1,
    type: "main",
    console: "Game Boy",
    charm: false,
    lure: false,
    breeding: false,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-yellow.png"
  },
  {
    label: "Pokémon Gold",
    value: "recqV8ShD7W1Rzc1L",
    release: "2000-10-14",
    generation: 2,
    type: "main",
    console: "Game Boy",
    charm: false,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-gold.png"
  },
  {
    label: "Pokémon Silver",
    value: "recDZOY9N13G6XaAL",
    release: "2000-10-14",
    generation: 2,
    type: "main",
    console: "Game Boy",
    charm: false,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-silver.png"
  },
  {
    label: "Pokémon Crystal",
    value: "recjbdkecweNXnNvG",
    release: "2001-07-29",
    generation: 2,
    type: "main",
    console: "Game Boy",
    charm: false,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-crystal.png"
  },
  {
    label: "Pokémon Ruby",
    value: "rec7bJcO5rgGauBzx",
    release: "2003-03-18",
    generation: 3,
    type: "main",
    console: "Game Boy Advance",
    charm: false,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-ruby.png"
  },
  {
    label: "Pokémon Sapphire",
    value: "recad25La0lDYvAQk",
    release: "2003-03-18",
    generation: 3,
    type: "main",
    console: "Game Boy Advance",
    charm: false,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-sapphire.png"
  },
  {
    label: "Pokémon FireRed",
    value: "rec6MK9YeN9zusEKM",
    release: "2004-09-07",
    generation: 3,
    type: "main",
    console: "Game Boy Advance",
    charm: false,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-firered.png"
  },
  {
    label: "Pokémon LeafGreen",
    value: "rec9ziSJaL2TKShhA",
    release: "2004-09-07",
    generation: 3,
    type: "main",
    console: "Game Boy Advance",
    charm: false,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-leafgreen.png"
  },
  {
    label: "Pokémon Emerald",
    value: "recz57NvBdAFDzzpO",
    release: "2005-04-30",
    generation: 3,
    type: "main",
    console: "Game Boy Advance",
    charm: false,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-emerald.png"
  },
  {
    label: "Pokémon Diamond",
    value: "recEq5jjGwJeqMHDQ",
    release: "2007-04-22",
    generation: 4,
    type: "main",
    console: "Nintendo DS",
    charm: false,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-diamond.png"
  },
  {
    label: "Pokémon Pearl",
    value: "recUfjDMhSFHDKa4x",
    release: "2007-04-22",
    generation: 4,
    type: "main",
    console: "Nintendo DS",
    charm: false,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-pearl.png"
  },
  {
    label: "Pokémon Platinum",
    value: "rec4ZxQ1oYlbQdZjO",
    release: "2009-03-22",
    generation: 4,
    type: "main",
    console: "Nintendo DS",
    charm: false,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-platinum.png"
  },
  {
    label: "Pokémon HeartGold",
    value: "recc6ToJ6us0IEpYj",
    release: "2010-03-14",
    generation: 4,
    type: "main",
    console: "Nintendo DS",
    charm: false,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-heartgold.png"
  },
  {
    label: "Pokémon SoulSilver",
    value: "recyyrAtdfyZCemJ4",
    release: "2010-03-14",
    generation: 4,
    type: "main",
    console: "Nintendo DS",
    charm: false,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-soulsilver.png"
  },
  {
    label: "Pokémon Black",
    value: "rectY3kYG9AiV3NWd",
    release: "2011-03-06",
    generation: 5,
    type: "main",
    console: "Nintendo DS",
    charm: false,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-black.png"
  },
  {
    label: "Pokémon White",
    value: "rectY3kYG9AiV3NWe",
    release: "2011-03-06",
    generation: 5,
    type: "main",
    console: "Nintendo DS",
    charm: false,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-white.png"
  },
  {
    label: "Pokémon Black 2",
    value: "recg8XjbNQcj4t1wP",
    release: "2012-10-07",
    generation: 5,
    type: "main",
    console: "Nintendo DS",
    charm: true,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-black-2.png"
  },
  {
    label: "Pokémon White 2",
    value: "rec90UkbKJpld1kTk",
    release: "2012-10-07",
    generation: 5,
    type: "main",
    console: "Nintendo DS",
    charm: true,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-white-2.png"
  },
  {
    label: "Pokémon X",
    value: "recHqCAA3vJUhHXKj",
    release: "2013-10-12",
    generation: 6,
    type: "main",
    console: "Nintendo 3DS",
    charm: true,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-x.png"
  },
  {
    label: "Pokémon Y",
    value: "recQBjYJ90hq6XMqV",
    release: "2013-10-12",
    generation: 6,
    type: "main",
    console: "Nintendo 3DS",
    charm: true,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-y.png"
  },
  {
    label: "Pokémon Omega Ruby",
    value: "reccO0Fx4hGMfLVv8",
    release: "2014-11-21",
    generation: 6,
    type: "main",
    console: "Nintendo 3DS",
    charm: true,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-omega-ruby.png"
  },
  {
    label: "Pokémon Alpha Sapphire",
    value: "reccs47mvpNYEESEa",
    release: "2014-11-21",
    generation: 6,
    type: "main",
    console: "Nintendo 3DS",
    charm: true,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-alpha-sapphire.png"
  },
  {
    label: "Pokémon Sun",
    value: "reciLmuKKqJYIFqFk",
    release: "2016-11-18",
    generation: 7,
    type: "main",
    console: "Nintendo 3DS",
    charm: true,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-sun.png"
  },
  {
    label: "Pokémon Moon",
    value: "recFMiXX23jdCTisU",
    release: "2016-11-18",
    generation: 7,
    type: "main",
    console: "Nintendo 3DS",
    charm: true,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-moon.png"
  },
  {
    label: "Pokémon Ultra Sun",
    value: "recXjj05I3Hq81DJn",
    release: "2017-11-17",
    generation: 7,
    type: "main",
    console: "Nintendo 3DS",
    charm: true,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-ultra-sun.png"
  },
  {
    label: "Pokémon Ultra Moon",
    value: "recqUq4K5O0BFfTfJ",
    release: "2017-11-17",
    generation: 7,
    type: "main",
    console: "Nintendo 3DS",
    charm: true,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-ultra-moon.png"
  },
  {
    label: "Pokémon Let's Go, Pikachu!",
    value: "recFnh6olRbY8v68k",
    release: "2018-11-16",
    generation: 7,
    type: "main",
    console: "Nintendo Switch",
    charm: true,
    lure: true,
    breeding: false,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-lets-go-pikachu.png"
  },
  {
    label: "Pokémon Let's Go, Eevee!",
    value: "recB0lfOmidrYlqnS",
    release: "2018-11-16",
    generation: 7,
    type: "main",
    console: "Nintendo Switch",
    charm: true,
    lure: true,
    breeding: false,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-lets-go-eevee.png"
  },
  {
    label: "Pokémon Sword",
    value: "recpE4t7pbOTQVj5m",
    release: "2019-11-15",
    generation: 8,
    type: "main",
    console: "Nintendo Switch",
    charm: true,
    lure: false,
    breeding: false,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-sword.png"
  },
  {
    label: "Pokémon Shield",
    value: "rect7M6DAn9YelHv7",
    release: "2019-11-15",
    generation: 8,
    type: "main",
    console: "Nintendo Switch",
    charm: true,
    lure: false,
    breeding: false,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-shield.png"
  },
  {
    label: "Pokémon Brilliant Diamond",
    value: "recvi03paia311RCm",
    release: "2021-11-19",
    generation: 8,
    type: "main",
    console: "Nintendo Switch",
    charm: true,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-brilliant-diamond.png"
  },
  {
    label: "Pokémon Shining Pearl",
    value: "reckhe1SAChkMKRoX",
    release: "2021-11-19",
    generation: 8,
    type: "main",
    console: "Nintendo Switch",
    charm: true,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-shining-pearl.png"
  },
  {
    label: "Pokémon Legends Arceus",
    value: "recylguUH7iY1aUqy",
    release: "2022-01-28",
    generation: 8,
    type: "main",
    console: "Nintendo Switch",
    charm: true,
    lure: false,
    breeding: false,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-legends-arceus.png"
  },
  {
    label: "Pokémon Scarlet",
    value: "recKpTD9URdio0NJy",
    release: "2022-11-18",
    generation: 9,
    type: "main",
    console: "Nintendo Switch",
    charm: true,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-scarlet.jpeg"
  },
  {
    label: "Pokémon Violet",
    value: "recxvgp09rQnqzjxG",
    release: "2022-11-18",
    generation: 9,
    type: "main",
    console: "Nintendo Switch",
    charm: true,
    lure: false,
    breeding: true,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-violet.jpeg"
  },
  {
    label: "Pokémon Colosseum",
    value: "recWuZvgcc2lJaCRu",
    release: "2003-11-21",
    generation: 3,
    type: "spin",
    console: "Nintendo GameCube",
    charm: false,
    lure: false,
    breeding: false,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-colosseum.png"
  },
  {
    label: "Pokémon XD: Gale of Darkness",
    value: "recLuYYUydL9SX3jG",
    release: "2005-08-04",
    generation: 3,
    type: "spin",
    console: "Nintendo GameCube",
    charm: false,
    lure: false,
    breeding: false,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-xd-gale-of-darkness.png"
  },
  {
    label: "Pokémon GO",
    value: "recBSxD5PSz5dGK0d",
    release: "2016-07-06",
    generation: 7,
    type: "spin",
    console: "Mobile",
    charm: false,
    lure: false,
    breeding: false,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-go.png"
  },
  {
    label: "Pokémon Quest",
    value: "rec6umOJr3FD1R6tz",
    release: "2018-05-30",
    generation: 7,
    type: "spin",
    console: "Mobile,Nintendo Switch",
    charm: false,
    lure: false,
    breeding: false,
    active: true,
    image:
      "https://poketch-cdn-assets.s3.amazonaws.com/images/boxart/pokemon-quest.png"
  }
];
