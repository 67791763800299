import React from "react";
import styled from "styled-components/native";
import { Header } from "../components/Header";
import { Body, Headline, Label, SubDisplay } from "../components/Text";
import { Footer } from "../components/Footer";
import { Wave } from "../components/atoms/Wave";
import { View } from "react-native";
const heroBg = require("../assets/images/backgrounds/wormhole-bg.png");

const Container = styled.View`
  background: ${props => props.theme.colors.neutralLightest};
  min-height: 100%;
`;

const HeroArea = styled.View`
  height: 300px;
  background: ${props => props.theme.colors.primaryDarkest};
  background-image: url(${heroBg});
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 100px;
`;

const Main = styled.View`
  max-width: 1060px;
  margin: auto;
  padding: 0 20px;
  padding-bottom: 40px;
`;

const WaveBottom = styled.View`
  position: absolute;
  width: 100%;
  bottom: -5px;
`;

const Content = styled.View`
  margin: auto;
  background: ${props => props.theme.colors.white};
  padding: 40px;
  padding-left: 40px;
  padding-right: 40px;
  max-width: 800px;
`;

export const TermsScreen = () => {
  return (
    <Container>
      <Header />
      <HeroArea>
        <SubDisplay uppercase color="white">
          Terms of Service & Privacy
        </SubDisplay>
        <WaveBottom>
          <Wave fill="#F0F4F8" duration={1500} />
        </WaveBottom>
      </HeroArea>
      <Main>
        <Content>
          <Headline>Terms of Service</Headline>
          <Label weight="semibold" margin="24px 0 12px">
            1. Terms
          </Label>
          <Body margin="0 0 10px">
            By accessing the website at{" "}
            <a href="https://shinyhunt.com">https://shinyhunt.com</a>, you are
            agreeing to be bound by these terms of service, all applicable laws
            and regulations, and agree that you are responsible for compliance
            with any applicable local laws. If you do not agree with any of
            these terms, you are prohibited from using or accessing this site.
            The materials contained in this website are protected by applicable
            copyright and trademark law.
          </Body>
          <Label weight="semibold" margin="0 0 12px">
            2. Use License
          </Label>
          <View>
            <View>
              <Body>
                Permission is granted to temporarily download one copy of the
                materials (information or software) on PokétchApp's website for
                personal, non-commercial transitory viewing only. This is the
                grant of a license, not a transfer of title, and under this
                license you may not:
              </Body>
            </View>
            <View style={{ marginTop: 8, marginBottom: 8 }}>
              <View>
                <Body>modify or copy the materials;</Body>
              </View>
              <View>
                <Body>
                  use the materials for any commercial purpose, or for any
                  public display (commercial or non-commercial);
                </Body>
              </View>
              <View>
                <Body>
                  attempt to decompile or reverse engineer any software
                  contained on Poketch's website;
                </Body>
              </View>
              <View>
                <Body>
                  remove any copyright or other proprietary notations from the
                  materials; or
                </Body>
              </View>
              <View>
                <Body>
                  transfer the materials to another person or "mirror" the
                  materials on any other server.
                </Body>
              </View>
            </View>
            <View>
              <Body>
                This license shall automatically terminate if you violate any of
                these restrictions and may be terminated by PokétchApp at any
                time. Upon terminating your viewing of these materials or upon
                the termination of this license, you must destroy any downloaded
                materials in your possession whether in electronic or printed
                format.
              </Body>
            </View>
          </View>
          <Label weight="semibold" margin="0 0 12px">
            3. Disclaimer
          </Label>
          <View>
            <View>
              <Body>
                The materials on PokétchApp's website are provided on an 'as is'
                basis. PokétchApp makes no warranties, expressed or implied, and
                hereby disclaims and negates all other warranties including,
                without limitation, implied warranties or conditions of
                merchantability, fitness for a particular purpose, or
                non-infringement of intellectual property or other violation of
                rights.
              </Body>
            </View>
            <View>
              <Body>
                Further, PokétchApp does not warrant or make any representations
                concerning the accuracy, likely results, or reliability of the
                use of the materials on its website or otherwise relating to
                such materials or on any sites linked to this site.
              </Body>
            </View>
          </View>
          <Label weight="semibold" margin="0 0 12px">
            4. Limitations
          </Label>
          <Body margin="0 0 20px">
            In no event shall PokétchApp or its suppliers be liable for any
            damages (including, without limitation, damages for loss of data or
            profit, or due to business interruption) arising out of the use or
            inability to use the materials on PokétchApp's website, even if
            PokétchApp or a PokétchApp authorized representative has been
            notified orally or in writing of the possibility of such damage.
            Because some jurisdictions do not allow limitations on implied
            warranties, or limitations of liability for consequential or
            incidental damages, these limitations may not apply to you.
          </Body>
          <Label weight="semibold" margin="0 0 12px">
            5. Accuracy of materials
          </Label>
          <Body margin="0 0 20px">
            The materials appearing on PokétchApp's website could include
            technical, typographical, or photographic errors. PokétchApp does
            not warrant that any of the materials on its website are accurate,
            complete or current. PokétchApp may make changes to the materials
            contained on its website at any time without notice. However
            PokétchApp does not make any commitment to update the materials.
          </Body>
          <Label weight="semibold" margin="0 0 12px">
            6. Links
          </Label>
          <Body margin="0 0 20px">
            PokétchApp has not reviewed all of the sites linked to its website
            and is not responsible for the contents of any such linked site. The
            inclusion of any link does not imply endorsement by PokétchApp of
            the site. Use of any such linked website is at the user's own risk.
          </Body>
          <Label weight="semibold" margin="0 0 12px">
            7. Modifications
          </Label>
          <Body margin="0 0 20px">
            PokétchApp may revise these terms of service for its website at any
            time without notice. By using this website you are agreeing to be
            bound by the then current version of these terms of service.
          </Body>
          <Label weight="semibold" margin="0 0 12px">
            8. Governing Law
          </Label>
          <Body>
            These terms and conditions are governed by and construed in
            accordance with the laws of Maryland and you irrevocably submit to
            the exclusive jurisdiction of the courts in that State or location.
          </Body>

          <Headline margin="40px 0 24px">Privacy Policy</Headline>
          <Body margin="0 0 20px">
            Your privacy is important to us. It is PokétchApp's policy to
            respect your privacy regarding any information we may collect from
            you across our website,{" "}
            <a href="https://shinyhunt.com">https://shinyhunt.com</a>, and other
            sites we own and operate.
          </Body>
          <Body margin="0 0 20px">
            We only ask for personal information when we truly need it to
            provide a service to you. We collect it by fair and lawful means,
            with your knowledge and consent. We also let you know why we’re
            collecting it and how it will be used.
          </Body>
          <Body margin="0 0 20px">
            We only retain collected information for as long as necessary to
            provide you with your requested service. What data we store, we’ll
            protect within commercially acceptable means to prevent loss and
            theft, as well as unauthorised access, disclosure, copying, use or
            modification.
          </Body>
          <Body margin="0 0 20px">
            We don’t share any personally identifying information publicly or
            with third-parties, except when required to by law.
          </Body>
          <Body margin="0 0 20px">
            Our website may link to external sites that are not operated by us.
            Please be aware that we have no control over the content and
            practices of these sites, and cannot accept responsibility or
            liability for their respective privacy policies.
          </Body>
          <Body margin="0 0 20px">
            You are free to refuse our request for your personal information,
            with the understanding that we may be unable to provide you with
            some of your desired services.
          </Body>
          <Body margin="0 0 20px">
            Your continued use of our website will be regarded as acceptance of
            our practices around privacy and personal information. If you have
            any questions about how we handle user data and personal
            information, feel free to contact us.
          </Body>
          <Body margin="0 0 20px">
            This policy is effective as of 22 October 2019.
          </Body>
        </Content>
      </Main>
      <Footer />
    </Container>
  );
};
