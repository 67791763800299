export type Method = {
  id: number;
  label: string;
  value: string;
  background: string;
  podium: string;
  image: string;
  fullOdds: boolean;
  chain: boolean;
  hunt: boolean;
  import: boolean;
  games: string[];
  guides: string[];
};

export const METHODS: Method[] = [
  {
    id: 1,
    label: "DV Method",
    value: "dv",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/DV.png",
    fullOdds: false,
    chain: false,
    hunt: true,
    import: true,
    games: ["recqV8ShD7W1Rzc1L", "recDZOY9N13G6XaAL", "recjbdkecweNXnNvG"],
    guides: []
  },
  {
    id: 2,
    label: "Random Encounter",
    value: "random",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/RE.png",
    fullOdds: true,
    chain: false,
    hunt: true,
    import: true,
    games: [
      "recqV8ShD7W1Rzc1L",
      "recDZOY9N13G6XaAL",
      "recjbdkecweNXnNvG",
      "rec7bJcO5rgGauBzx",
      "recad25La0lDYvAQk",
      "rec6MK9YeN9zusEKM",
      "rec9ziSJaL2TKShhA",
      "recz57NvBdAFDzzpO",
      "recEq5jjGwJeqMHDQ",
      "recUfjDMhSFHDKa4x",
      "rec4ZxQ1oYlbQdZjO",
      "recc6ToJ6us0IEpYj",
      "recyyrAtdfyZCemJ4",
      "rectY3kYG9AiV3NWd",
      "recoq5hQuJf2fBOhu",
      "recg8XjbNQcj4t1wP",
      "rec90UkbKJpld1kTk",
      "recHqCAA3vJUhHXKj",
      "recQBjYJ90hq6XMqV",
      "reccO0Fx4hGMfLVv8",
      "reccs47mvpNYEESEa",
      "reciLmuKKqJYIFqFk",
      "recFMiXX23jdCTisU",
      "recXjj05I3Hq81DJn",
      "recqUq4K5O0BFfTfJ",
      "recFnh6olRbY8v68k",
      "recB0lfOmidrYlqnS",
      "recpE4t7pbOTQVj5m",
      "rect7M6DAn9YelHv7",
      "recvi03paia311RCm",
      "reckhe1SAChkMKRoX",
      "recWuZvgcc2lJaCRu",
      "recLuYYUydL9SX3jG",
      "recBSxD5PSz5dGK0d",
      "rec6umOJr3FD1R6tz",
      "recylguUH7iY1aUqy",
      "recKpTD9URdio0NJy",
      "recxvgp09rQnqzjxG"
    ],
    guides: []
  },
  {
    id: 3,
    label: "Fossil Restore",
    value: "fossil",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/FO.png",
    fullOdds: false,
    chain: false,
    hunt: true,
    import: true,
    games: [
      "recqV8ShD7W1Rzc1L",
      "recDZOY9N13G6XaAL",
      "recjbdkecweNXnNvG",
      "rec7bJcO5rgGauBzx",
      "recad25La0lDYvAQk",
      "rec6MK9YeN9zusEKM",
      "rec9ziSJaL2TKShhA",
      "recz57NvBdAFDzzpO",
      "recEq5jjGwJeqMHDQ",
      "recUfjDMhSFHDKa4x",
      "rec4ZxQ1oYlbQdZjO",
      "recc6ToJ6us0IEpYj",
      "recyyrAtdfyZCemJ4",
      "rectY3kYG9AiV3NWd",
      "recoq5hQuJf2fBOhu",
      "recg8XjbNQcj4t1wP",
      "rec90UkbKJpld1kTk",
      "recHqCAA3vJUhHXKj",
      "recQBjYJ90hq6XMqV",
      "reccO0Fx4hGMfLVv8",
      "reccs47mvpNYEESEa",
      "reciLmuKKqJYIFqFk",
      "recFMiXX23jdCTisU",
      "recXjj05I3Hq81DJn",
      "recqUq4K5O0BFfTfJ",
      "recFnh6olRbY8v68k",
      "recB0lfOmidrYlqnS",
      "recpE4t7pbOTQVj5m",
      "rect7M6DAn9YelHv7",
      "recvi03paia311RCm",
      "reckhe1SAChkMKRoX"
    ],
    guides: []
  },
  {
    id: 4,
    label: "Soft Resetting",
    value: "resetting",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/SR.png",
    fullOdds: true,
    chain: false,
    hunt: true,
    import: true,
    games: [
      "recqV8ShD7W1Rzc1L",
      "recDZOY9N13G6XaAL",
      "recjbdkecweNXnNvG",
      "rec7bJcO5rgGauBzx",
      "recad25La0lDYvAQk",
      "rec6MK9YeN9zusEKM",
      "rec9ziSJaL2TKShhA",
      "recz57NvBdAFDzzpO",
      "recEq5jjGwJeqMHDQ",
      "recUfjDMhSFHDKa4x",
      "rec4ZxQ1oYlbQdZjO",
      "recc6ToJ6us0IEpYj",
      "recyyrAtdfyZCemJ4",
      "rectY3kYG9AiV3NWd",
      "recoq5hQuJf2fBOhu",
      "recg8XjbNQcj4t1wP",
      "rec90UkbKJpld1kTk",
      "recHqCAA3vJUhHXKj",
      "recQBjYJ90hq6XMqV",
      "reccO0Fx4hGMfLVv8",
      "reccs47mvpNYEESEa",
      "reciLmuKKqJYIFqFk",
      "recFMiXX23jdCTisU",
      "recXjj05I3Hq81DJn",
      "recqUq4K5O0BFfTfJ",
      "recFnh6olRbY8v68k",
      "recB0lfOmidrYlqnS",
      "recpE4t7pbOTQVj5m",
      "rect7M6DAn9YelHv7",
      "recvi03paia311RCm",
      "reckhe1SAChkMKRoX",
      "recylguUH7iY1aUqy"
    ],
    guides: []
  },
  {
    id: 5,
    label: "Masuda Method",
    value: "masuda",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/MM.png",
    fullOdds: false,
    chain: false,
    hunt: true,
    import: true,
    games: [
      "recEq5jjGwJeqMHDQ",
      "recUfjDMhSFHDKa4x",
      "rec4ZxQ1oYlbQdZjO",
      "recc6ToJ6us0IEpYj",
      "recyyrAtdfyZCemJ4",
      "rectY3kYG9AiV3NWd",
      "recoq5hQuJf2fBOhu",
      "recg8XjbNQcj4t1wP",
      "rec90UkbKJpld1kTk",
      "recHqCAA3vJUhHXKj",
      "recQBjYJ90hq6XMqV",
      "reccO0Fx4hGMfLVv8",
      "reccs47mvpNYEESEa",
      "reciLmuKKqJYIFqFk",
      "recFMiXX23jdCTisU",
      "recXjj05I3Hq81DJn",
      "recqUq4K5O0BFfTfJ",
      "recpE4t7pbOTQVj5m",
      "rect7M6DAn9YelHv7",
      "recvi03paia311RCm",
      "reckhe1SAChkMKRoX",
      "recxvgp09rQnqzjxG",
      "recKpTD9URdio0NJy"
    ],
    guides: []
  },
  {
    id: 6,
    label: "Breeding",
    value: "breeding",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/BR.png",
    fullOdds: false,
    chain: false,
    hunt: true,
    import: true,
    games: [
      "recqV8ShD7W1Rzc1L",
      "recDZOY9N13G6XaAL",
      "recjbdkecweNXnNvG",
      "rec7bJcO5rgGauBzx",
      "recad25La0lDYvAQk",
      "rec6MK9YeN9zusEKM",
      "rec9ziSJaL2TKShhA",
      "recz57NvBdAFDzzpO",
      "recEq5jjGwJeqMHDQ",
      "recUfjDMhSFHDKa4x",
      "rec4ZxQ1oYlbQdZjO",
      "recc6ToJ6us0IEpYj",
      "recyyrAtdfyZCemJ4",
      "rectY3kYG9AiV3NWd",
      "recoq5hQuJf2fBOhu",
      "recg8XjbNQcj4t1wP",
      "rec90UkbKJpld1kTk",
      "recHqCAA3vJUhHXKj",
      "recQBjYJ90hq6XMqV",
      "reccO0Fx4hGMfLVv8",
      "reccs47mvpNYEESEa",
      "reciLmuKKqJYIFqFk",
      "recFMiXX23jdCTisU",
      "recXjj05I3Hq81DJn",
      "recqUq4K5O0BFfTfJ",
      "recpE4t7pbOTQVj5m",
      "rect7M6DAn9YelHv7",
      "recvi03paia311RCm",
      "reckhe1SAChkMKRoX",
      "recKpTD9URdio0NJy",
      "recxvgp09rQnqzjxG"
    ],
    guides: []
  },
  {
    id: 7,
    label: "Chain Fishing",
    value: "fishing",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/CF.png",
    fullOdds: false,
    chain: true,
    hunt: true,
    import: true,
    games: [
      "recHqCAA3vJUhHXKj",
      "recQBjYJ90hq6XMqV",
      "reccO0Fx4hGMfLVv8",
      "reccs47mvpNYEESEa"
    ],
    guides: []
  },
  {
    id: 8,
    label: "Friend Safari",
    value: "friend",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/FS.png",
    fullOdds: false,
    chain: false,
    hunt: true,
    import: true,
    games: ["recHqCAA3vJUhHXKj", "recQBjYJ90hq6XMqV"],
    guides: []
  },
  {
    id: 9,
    label: "PokéRadar",
    value: "pokeradar",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/PR.png",
    fullOdds: false,
    chain: true,
    hunt: true,
    import: true,
    games: [
      "recEq5jjGwJeqMHDQ",
      "recUfjDMhSFHDKa4x",
      "rec4ZxQ1oYlbQdZjO",
      "recvi03paia311RCm",
      "reckhe1SAChkMKRoX"
    ],
    guides: []
  },
  {
    id: 10,
    label: "Poké Pelago",
    value: "pokepelago",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/PP.png",
    fullOdds: false,
    chain: false,
    hunt: true,
    import: true,
    games: [
      "reciLmuKKqJYIFqFk",
      "recFMiXX23jdCTisU",
      "recXjj05I3Hq81DJn",
      "recqUq4K5O0BFfTfJ"
    ],
    guides: []
  },
  {
    id: 11,
    label: "DexNav",
    value: "dexnav",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/DN.png",
    fullOdds: false,
    chain: true,
    hunt: true,
    import: true,
    games: ["reccO0Fx4hGMfLVv8", "reccs47mvpNYEESEa"],
    guides: []
  },
  {
    id: 12,
    label: "Horde Hunting",
    value: "horde",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/HH.png",
    fullOdds: false,
    chain: false,
    hunt: true,
    import: true,
    games: [
      "recHqCAA3vJUhHXKj",
      "recQBjYJ90hq6XMqV",
      "reccO0Fx4hGMfLVv8",
      "reccs47mvpNYEESEa"
    ],
    guides: []
  },
  {
    id: 13,
    label: "SOS Calling",
    value: "sos",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/SOS.png",
    fullOdds: false,
    chain: true,
    hunt: true,
    import: true,
    games: [
      "reciLmuKKqJYIFqFk",
      "recFMiXX23jdCTisU",
      "recXjj05I3Hq81DJn",
      "recqUq4K5O0BFfTfJ"
    ],
    guides: []
  },
  {
    id: 14,
    label: "Island Scan",
    value: "island",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/IS.png",
    fullOdds: false,
    chain: false,
    hunt: true,
    import: true,
    games: [
      "reciLmuKKqJYIFqFk",
      "recFMiXX23jdCTisU",
      "recXjj05I3Hq81DJn",
      "recqUq4K5O0BFfTfJ"
    ],
    guides: []
  },
  {
    id: 15,
    label: "Ultra Wormhole",
    value: "warphole",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/UW.png",
    fullOdds: false,
    chain: false,
    hunt: true,
    import: true,
    games: ["recXjj05I3Hq81DJn", "recqUq4K5O0BFfTfJ"],
    guides: []
  },
  {
    id: 16,
    label: "Run Away",
    value: "runaway",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/RA.png",
    fullOdds: true,
    chain: false,
    hunt: true,
    import: true,
    games: [
      "recqV8ShD7W1Rzc1L",
      "recDZOY9N13G6XaAL",
      "recjbdkecweNXnNvG",
      "rec7bJcO5rgGauBzx",
      "recad25La0lDYvAQk",
      "rec6MK9YeN9zusEKM",
      "rec9ziSJaL2TKShhA",
      "recz57NvBdAFDzzpO",
      "recEq5jjGwJeqMHDQ",
      "recUfjDMhSFHDKa4x",
      "rec4ZxQ1oYlbQdZjO",
      "recc6ToJ6us0IEpYj",
      "recyyrAtdfyZCemJ4",
      "rectY3kYG9AiV3NWd",
      "recoq5hQuJf2fBOhu",
      "recg8XjbNQcj4t1wP",
      "rec90UkbKJpld1kTk",
      "recHqCAA3vJUhHXKj",
      "recQBjYJ90hq6XMqV",
      "reccO0Fx4hGMfLVv8",
      "reccs47mvpNYEESEa",
      "reciLmuKKqJYIFqFk",
      "recFMiXX23jdCTisU",
      "recXjj05I3Hq81DJn",
      "recqUq4K5O0BFfTfJ",
      "recFnh6olRbY8v68k",
      "recB0lfOmidrYlqnS",
      "recpE4t7pbOTQVj5m",
      "rect7M6DAn9YelHv7",
      "recvi03paia311RCm",
      "reckhe1SAChkMKRoX"
    ],
    guides: []
  },
  {
    id: 17,
    label: "Catch Combo",
    value: "combo",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/CC.png",
    fullOdds: false,
    chain: true,
    hunt: true,
    import: true,
    games: ["recFnh6olRbY8v68k", "recB0lfOmidrYlqnS"],
    guides: []
  },
  {
    id: 18,
    label: "Battle Method",
    value: "bm",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/BM.png",
    fullOdds: false,
    chain: false,
    hunt: true,
    import: true,
    games: ["recpE4t7pbOTQVj5m", "rect7M6DAn9YelHv7"],
    guides: []
  },
  {
    id: 19,
    label: "Raid Battle",
    value: "raid",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/RB.png",
    fullOdds: false,
    chain: false,
    hunt: true,
    import: true,
    games: ["recBSxD5PSz5dGK0d", "recpE4t7pbOTQVj5m", "rect7M6DAn9YelHv7"],
    guides: []
  },
  {
    id: 20,
    label: "Dynamax Adventures",
    value: "dynamax",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/DA.png",
    fullOdds: false,
    chain: false,
    hunt: true,
    import: true,
    games: ["recpE4t7pbOTQVj5m", "rect7M6DAn9YelHv7"],
    guides: []
  },
  {
    id: 21,
    label: "Underground",
    value: "underground",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/UG.png",
    fullOdds: false,
    chain: false,
    hunt: true,
    import: true,
    games: ["recvi03paia311RCm", "reckhe1SAChkMKRoX"],
    guides: []
  },
  {
    id: 22,
    label: "Event",
    value: "event",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/EV.png",
    fullOdds: false,
    chain: false,
    hunt: false,
    import: true,
    games: [
      "rec7bJcO5rgGauBzx",
      "recad25La0lDYvAQk",
      "rec6MK9YeN9zusEKM",
      "rec9ziSJaL2TKShhA",
      "recz57NvBdAFDzzpO",
      "recEq5jjGwJeqMHDQ",
      "recUfjDMhSFHDKa4x",
      "rec4ZxQ1oYlbQdZjO",
      "recc6ToJ6us0IEpYj",
      "recyyrAtdfyZCemJ4",
      "rectY3kYG9AiV3NWd",
      "recoq5hQuJf2fBOhu",
      "recg8XjbNQcj4t1wP",
      "rec90UkbKJpld1kTk",
      "recHqCAA3vJUhHXKj",
      "recQBjYJ90hq6XMqV",
      "reccO0Fx4hGMfLVv8",
      "reccs47mvpNYEESEa",
      "reciLmuKKqJYIFqFk",
      "recFMiXX23jdCTisU",
      "recXjj05I3Hq81DJn",
      "recqUq4K5O0BFfTfJ",
      "recFnh6olRbY8v68k",
      "recB0lfOmidrYlqnS",
      "recpE4t7pbOTQVj5m",
      "rect7M6DAn9YelHv7",
      "recvi03paia311RCm",
      "reckhe1SAChkMKRoX"
    ],
    guides: []
  },
  {
    id: 23,
    label: "Wonder Trade",
    value: "wonder",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/WT.png",
    fullOdds: false,
    chain: false,
    hunt: false,
    import: true,
    games: [
      "recHqCAA3vJUhHXKj",
      "recQBjYJ90hq6XMqV",
      "reccO0Fx4hGMfLVv8",
      "reccs47mvpNYEESEa",
      "reciLmuKKqJYIFqFk",
      "recFMiXX23jdCTisU",
      "recXjj05I3Hq81DJn",
      "recqUq4K5O0BFfTfJ"
    ],
    guides: []
  },
  {
    id: 24,
    label: "Mystery Gift",
    value: "mystery",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/MG.png",
    fullOdds: false,
    chain: false,
    hunt: false,
    import: true,
    games: [
      "recEq5jjGwJeqMHDQ",
      "recUfjDMhSFHDKa4x",
      "rec4ZxQ1oYlbQdZjO",
      "recc6ToJ6us0IEpYj",
      "recyyrAtdfyZCemJ4",
      "rectY3kYG9AiV3NWd",
      "recoq5hQuJf2fBOhu",
      "recg8XjbNQcj4t1wP",
      "rec90UkbKJpld1kTk",
      "recHqCAA3vJUhHXKj",
      "recQBjYJ90hq6XMqV",
      "reccO0Fx4hGMfLVv8",
      "reccs47mvpNYEESEa",
      "reciLmuKKqJYIFqFk",
      "recFMiXX23jdCTisU",
      "recXjj05I3Hq81DJn",
      "recqUq4K5O0BFfTfJ",
      "recFnh6olRbY8v68k",
      "recB0lfOmidrYlqnS",
      "recpE4t7pbOTQVj5m",
      "rect7M6DAn9YelHv7",
      "recvi03paia311RCm",
      "reckhe1SAChkMKRoX"
    ],
    guides: []
  },
  {
    id: 25,
    label: "Trade",
    value: "trade",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/TR.png",
    fullOdds: false,
    chain: false,
    hunt: false,
    import: true,
    games: [
      "recqV8ShD7W1Rzc1L",
      "recDZOY9N13G6XaAL",
      "recjbdkecweNXnNvG",
      "rec7bJcO5rgGauBzx",
      "recad25La0lDYvAQk",
      "rec6MK9YeN9zusEKM",
      "rec9ziSJaL2TKShhA",
      "recz57NvBdAFDzzpO",
      "recEq5jjGwJeqMHDQ",
      "recUfjDMhSFHDKa4x",
      "rec4ZxQ1oYlbQdZjO",
      "recc6ToJ6us0IEpYj",
      "recyyrAtdfyZCemJ4",
      "rectY3kYG9AiV3NWd",
      "recoq5hQuJf2fBOhu",
      "recg8XjbNQcj4t1wP",
      "rec90UkbKJpld1kTk",
      "recHqCAA3vJUhHXKj",
      "recQBjYJ90hq6XMqV",
      "reccO0Fx4hGMfLVv8",
      "reccs47mvpNYEESEa",
      "reciLmuKKqJYIFqFk",
      "recFMiXX23jdCTisU",
      "recXjj05I3Hq81DJn",
      "recqUq4K5O0BFfTfJ",
      "recFnh6olRbY8v68k",
      "recB0lfOmidrYlqnS",
      "recpE4t7pbOTQVj5m",
      "rect7M6DAn9YelHv7",
      "recvi03paia311RCm",
      "reckhe1SAChkMKRoX",
      "recWuZvgcc2lJaCRu",
      "recLuYYUydL9SX3jG",
      "recBSxD5PSz5dGK0d",
      "rec6umOJr3FD1R6tz"
    ],
    guides: []
  },
  {
    id: 26,
    label: "Field Research",
    value: "field-research",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/FR.png",
    fullOdds: false,
    chain: false,
    hunt: true,
    import: true,
    games: ["recBSxD5PSz5dGK0d"],
    guides: []
  },
  {
    id: 27,
    label: "Outbreak Method",
    value: "outbreak-method",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/OM.png",
    fullOdds: false,
    chain: true,
    hunt: true,
    import: true,
    games: ["recylguUH7iY1aUqy", "recKpTD9URdio0NJy", "recxvgp09rQnqzjxG"],
    guides: []
  },
  {
    id: 28,
    label: "Tera Raid",
    value: "tera-raid",
    background: "",
    podium: "",
    image: "https://poketch-cdn-assets.s3.amazonaws.com/images/methods/TR.png",
    fullOdds: false,
    chain: false,
    hunt: true,
    import: true,
    games: ["recKpTD9URdio0NJy", "recxvgp09rQnqzjxG"],
    guides: []
  }
];
