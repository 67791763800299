import { NavigationContainerRef } from "@react-navigation/core";
import { StatusBar } from "expo-status-bar";
import { useMemo, useState } from "react";
import { useColorScheme, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useDimensions } from "react-native-web-hooks";
import styled, { ThemeProvider } from "styled-components/native";
import { useAuth } from "../hooks/useAuth";
import { useFirestore } from "../hooks/useFirestore";
import { usePayments } from "../hooks/usePayments";
import { UserTheme } from "../model/model";
import { RootStackParamList } from "../navTypes";
import { darkTheme, lightTheme, shinyTheme } from "../theme/theme";
import { Ad } from "./Ad";
import { gte, lt } from "./breakpoints";
import { PromptModal } from "./modals/PromptModal";
import { SettingsModal } from "./modals/SettingsModal";
import { SideBar } from "./SideBar";
import { ModalType, NavButtons, TopNav } from "./TopNav";
import { UserMenu } from "./UserMenu";

const MobileNav = styled.View`
  width: 100%;
  z-index: 999;
  flex-direction: row;
  justify-content: space-evenly;
  height: 50px;
  align-items: center;
  background: ${props => props.theme.colors.surface};
`;

export const AppLayout: React.FC<{
  navRef: NavigationContainerRef<RootStackParamList> | null;
  currentRoute?: string;
}> = ({ children, navRef, currentRoute }) => {
  const dimensions = useDimensions();
  const { authUser, updateProfileData } = useAuth();
  const { user, updateUser } = useFirestore();
  const colorScheme = useColorScheme();
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [modal, setModal] = useState<ModalType | null>(null);
  const { isPro, isAdFree } = usePayments();

  const userTheme = user?.theme
    ? user.theme
    : colorScheme === "dark"
    ? UserTheme.DARK
    : UserTheme.LIGHT;

  const updateInfo = (displayName: string) => {
    updateUser({ displayName, onboarded: true });
    updateProfileData({
      displayName
    });
  };

  const theme =
    userTheme === UserTheme.DARK
      ? darkTheme
      : userTheme === UserTheme.SHINY
      ? shinyTheme
      : lightTheme;

  const defaultDisplay = useMemo(() => {
    const email = authUser?.email;
    return email ? email.substring(0, email.indexOf("@")) : "";
  }, [authUser]);

  return (
    <ThemeProvider theme={theme}>
      <StatusBar style={userTheme === UserTheme.DARK ? "light" : "dark"} />
      <SafeAreaView
        style={{
          flexDirection: "row",
          flex: 1,
          backgroundColor: theme.colors.surface
        }}
      >
        {!!gte({ dimensions, theme }, "large") && (
          <SideBar
            theme={userTheme}
            navRef={navRef}
            currentRoute={currentRoute}
          />
        )}
        <View style={{ flex: 1 }}>
          <TopNav
            navRef={navRef}
            currentRoute={currentRoute}
            setUserMenuOpen={setUserMenuOpen}
            userMenuOpen={userMenuOpen}
            setModal={setModal}
            modal={modal}
          />
          <View style={{ flex: 1 }}>{children}</View>

          {userMenuOpen ? <UserMenu setModal={setModal} /> : null}
          <SettingsModal
            visible={modal === ModalType.SETTING}
            close={() => setModal(null)}
          />

          {!isAdFree && !isPro && <Ad />}

          {!!lt({ dimensions, theme }, "small") && (
            <MobileNav>
              <NavButtons navRef={navRef} currentRoute={currentRoute} />
            </MobileNav>
          )}
        </View>
      </SafeAreaView>
      {!!user && !user.onboarded && (
        <PromptModal
          callback={updateInfo}
          title="HELLO THERE! Glad to meet you!"
          desc="Welcome to the world of shiny Pokémon! My name is Dan aka aDrive! This world is inhabited far and wide by rare creatures called shiny Pokémon! For some people, shiny Pokémon are trophies. Others use them for battling. As for myself...I collect shiny Pokémon as a profession. Now, why don't you tell me about yourself."
          label="What is your name?"
          placeholder={authUser?.displayName || ""}
          defaultPromptValue={defaultDisplay}
          isOnboard
          force
        />
      )}
    </ThemeProvider>
  );
};
