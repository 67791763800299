import {
  OpenSans_300Light,
  OpenSans_400Regular,
  OpenSans_600SemiBold,
  OpenSans_700Bold
} from "@expo-google-fonts/open-sans";
import {
  SairaExtraCondensed_300Light,
  SairaExtraCondensed_400Regular,
  SairaExtraCondensed_500Medium,
  SairaExtraCondensed_600SemiBold,
  SairaExtraCondensed_700Bold
} from "@expo-google-fonts/saira-extra-condensed";
import { FontAwesome } from "@expo/vector-icons";
import * as Font from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import { useEffect, useState } from "react";

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();

        // Load fonts
        await Font.loadAsync({
          OpenSans_300Light,
          OpenSans_400Regular,
          OpenSans_600SemiBold,
          OpenSans_700Bold,
          SairaExtraCondensed_300Light,
          SairaExtraCondensed_400Regular,
          SairaExtraCondensed_500Medium,
          SairaExtraCondensed_600SemiBold,
          SairaExtraCondensed_700Bold
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
}
