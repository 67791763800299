import React, {
  Children,
  cloneElement,
  useEffect,
  useMemo,
  useState
} from "react";
import { Animated, Easing, Platform, View } from "react-native";
import { Button, IconButton } from "../atoms/Button";
import { EditIcon } from "../Icon";
import { Label } from "../Text";

export type CollapserProps = {
  display?: string;
  disable?: boolean;
};

const AnimatedView = Animated.createAnimatedComponent(View);

export const Collapser: React.FC<CollapserProps> = props => {
  const animatedValue = useMemo(() => new Animated.Value(0), []);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    Animated.timing(animatedValue, {
      toValue: open ? 200 : 0,
      duration: 300,
      easing: Easing.cubic,
      useNativeDriver: false
    }).start();
  }, [open]);

  if (props.disable) {
    return <>{props.children}</>;
  }

  if (Platform.OS === "android") {
    const childrenWithProps = Children.map(props.children, (child: any) =>
      cloneElement(child, { collapseCb: () => setOpen(false) })
    );
    return (
      <View style={{ marginTop: 4, marginBottom: 12, width: "100%" }}>
        <Button
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row"
          }}
          onPress={() => setOpen(!open)}
          isActive={open}
          noWrapper
        >
          <Label noTextFix>{props.display}</Label>
          <View style={{ marginLeft: 4 }}>
            <EditIcon size={18} primaryFill="text" />
          </View>
        </Button>
        {!!open && childrenWithProps}
      </View>
    );
  } else {
    return (
      <View style={{ marginTop: 4, marginBottom: 12, width: "100%" }}>
        <Button
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row"
          }}
          onPress={() => setOpen(!open)}
          isActive={open}
          noWrapper
        >
          <Label noTextFix>{props.display}</Label>
          <View style={{ marginLeft: 4 }}>
            <EditIcon size={18} primaryFill="text" />
          </View>
        </Button>
        <AnimatedView
          style={{
            height: animatedValue,
            overflow: "hidden"
          }}
        >
          {props.children}
        </AnimatedView>
      </View>
    );
  }
};
