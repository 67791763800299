import { createNativeStackNavigator } from "@react-navigation/native-stack";
import styled from "styled-components/native";
import { CollectionDetail } from "./CollectionDetail";
import { CollectionList } from "./CollectionList";
import { ShowcaseDetail } from "./ShowcaseDetail";
import { ShowcaseList } from "./ShowcaseList";
import { Showcases } from "./Showcases";

const Stack = createNativeStackNavigator();

export const ShowcasesScreen = () => (
  <Stack.Navigator
    initialRouteName="Showcases"
    screenOptions={{ headerShown: false }}
  >
    <Stack.Screen name="Showcases" component={Showcases} />
    <Stack.Screen name="ShowcaseList" component={ShowcaseList} />
    <Stack.Screen name="ShowcaseDetail" component={ShowcaseDetail} />
  </Stack.Navigator>
);
