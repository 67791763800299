import { useNavigation } from "@react-navigation/native";
import { Linking, View } from "react-native";
import styled from "styled-components/native";
import { POKEMON, Pokemon } from "../constants/pokemon-new";
import { useFirestore } from "../hooks/useFirestore";
import { CollectionObject } from "../model/model";
import { formatTimer, getSpriteSize, getSpriteSrc } from "./helpers";
import { HeartIcon, VideoCamIcon } from "./Icon";
import { Body, SubDisplay } from "./Text";
import { CollectionStackParamList } from "../navTypes";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useDimensions } from "react-native-web-hooks";
import { BreakpointProps, lt } from "./breakpoints";
import { usePayments } from "../hooks/usePayments";
const mrathonImage = require("../assets/images/backgrounds/bdsp-marathon-bg.png");
const marathonPodiumImage = require("../assets/images/podiums/bdsp-marathon.png");

const Container = styled.Pressable`
  position: relative;
  background: ${props => props.theme.colors.surface};
  border-radius: 10px;
  overflow: hidden;
  flex: 1;
`;

const CollectionArea = styled.ImageBackground`
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 0;
  flex: 1;
`;
const Header = styled.View`
  flex-direction: row;
  z-index: 1;
  position: absolute;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
`;

const HeaderInfo = styled.View`
  text-align: left;
`;

const IconButton = styled.Pressable`
  padding: 0;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
`;

const Main = styled.View`
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Podium = styled.ImageBackground<BreakpointProps>`
  height: ${p => (lt(p, "small") ? "100px" : "200px")};
  width: ${p => (lt(p, "small") ? "100px" : "200px")};
  align-items: center;
  justify-content: center;
`;

const Sprite = styled.Image<{ size: string[] } & BreakpointProps>`
  width: ${p => (lt(p, "small") ? "120px" : p.size[0])};
  height: ${p => (lt(p, "small") ? "120px" : p.size[1])};
`;

const Footer = styled.View`
  position: absolute;
  align-items: flex-end;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  bottom: 0;
`;

const FooterInfo = styled.View`
  text-align: right;
  margin-left: auto;
`;

export const CollectionItem = ({
  collection,
  isAdmin
}: {
  collection: CollectionObject;
  isAdmin: boolean;
}) => {
  const { updateCollection } = useFirestore();
  const { isPro } = usePayments();
  const nav =
    useNavigation<NativeStackNavigationProp<CollectionStackParamList>>();
  const dimensions = useDimensions();

  const setFavorite = () => {
    updateCollection(collection.uid, {
      favorite: !collection.favorite
    });
  };

  const pokemon = POKEMON.find(p => p.value === collection.target.value);

  return (
    <Container
      onPress={() => nav.navigate("CollectionDetail", { uid: collection.uid })}
    >
      <CollectionArea
        source={mrathonImage}
        resizeMode="cover"
        resizeMethod="scale"
      >
        <Header>
          <HeaderInfo>
            <SubDisplay color="white" weight="semibold">
              {collection.nickname || collection.target.label}
            </SubDisplay>
            {collection.elapsedTime !== 0 && (
              <Body color="white">{formatTimer(collection.elapsedTime)}</Body>
            )}
          </HeaderInfo>
          <View>
            <IconButton onPress={setFavorite}>
              <HeartIcon
                size={20}
                primaryFill={collection.favorite ? "success" : "neutral"}
                primaryFillHover="primary"
                secondaryFill="white"
              />
            </IconButton>
            {!!collection.reactionLink && (
              <IconButton
                onPress={() =>
                  Linking.openURL(collection.reactionLink as string)
                }
              >
                <VideoCamIcon
                  primaryFill="white"
                  secondaryFill="primaryLight"
                  size={20}
                />
              </IconButton>
            )}
          </View>
        </Header>

        <Main>
          <Podium source={marathonPodiumImage} dimensions={dimensions}>
            {!!pokemon && (
              <Sprite
                dimensions={dimensions}
                size={getSpriteSize(pokemon)}
                source={{ uri: getSpriteSrc(pokemon, false, isAdmin, isPro) }}
              />
            )}
          </Podium>
        </Main>
        <Footer>
          {!!collection.encounters && (
            <FooterInfo>
              <SubDisplay
                color="white"
                style={{ textAlign: "right", marginBottom: -10 }}
              >
                {collection.encounters}
              </SubDisplay>
              <Body color="white"> encounters</Body>
            </FooterInfo>
          )}
        </Footer>
      </CollectionArea>
    </Container>
  );
};
