import { NavigationContainerRef } from "@react-navigation/core";
import { useEffect, useRef, useState } from "react";
import { Image, Modal, PressableProps, useColorScheme } from "react-native";
import { useDimensions, useHover } from "react-native-web-hooks";
import styled, { useTheme } from "styled-components/native";
import {
  CollectionIcon,
  SparklesIcon,
  VideoCamIcon,
  WalletIcon,
  WidgetAddIcon
} from "../components/Icon";
import { TopMenuButton } from "../components/TopMenuButton";
import { TRAINERS } from "../constants/trainers";
import { useAuth } from "../hooks/useAuth";
import { useFirestore } from "../hooks/useFirestore";
import { usePayments } from "../hooks/usePayments";
import { UserTheme } from "../model/model";
import { RootStackParamList } from "../navTypes";
import { LoadingSpinner } from "./atoms/LoadingSpinner";
import { Logo } from "./atoms/Logo";
import { gte, lt } from "./breakpoints";
import { getAvatarImage } from "./helpers";
import { IconProps, MoonIcon, SettingsIcon, SunIcon } from "./Icon";
import { PromptModal } from "./modals/PromptModal";
import { Body } from "./Text";

const Container = styled.View`
  width: 100%;
  height: 50px;
  align-items: center;
  background: ${props => props.theme.colors.surface};
  position: relative;
  box-shadow: ${props => props.theme.shadows.header};
  z-index: 2;
  flex-direction: row;
`;

const PageMenuContainer = styled.View<{ isMobile: boolean; isTablet: boolean }>`
  height: 100%;
  width: ${props => (props.isMobile ? "100%" : "auto")};
  margin-left: ${props => (props.isMobile ? 0 : "16px")};
  display: ${props => (props.isTablet ? "flex" : "none")};
  flex-direction: row;
`;

const ProfileArea = styled.View`
  margin-left: auto;
  margin-right: 20px;
  z-index: 5;
  flex-direction: row;
`;

const ProfileAreaButton = styled.Pressable`
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0 5px;
  margin: 0 5px;
`;

const ProfileAreaAvatar = styled.Pressable`
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.background};
  border: none;
  border-radius: 4px;
  padding: 0;
  margin-left: 10px;
  overflow: hidden;
  width: 30px;
  height: 30px;
`;

const StyledUserMenuButton = styled.Pressable<{ isHovered: boolean }>`
  height: 40px;
  margin: 2px 10px;
  border-radius: 4px;
  align-items: center;
  flex-direction: row;
  padding: 0 0 0 12px;
  border: none;
  flex: 1 0 auto;
  background: ${props =>
    props.isHovered
      ? props.theme.colors.background
      : props.theme.colors.surface};
`;

export const UserMenuButton = ({
  onPress,
  Icon,
  label
}: PressableProps & { Icon: React.FC<IconProps>; label: string }) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  return (
    <StyledUserMenuButton isHovered={isHovered} onPress={onPress} ref={ref}>
      <Icon primaryFill="primaryDarkest" size={16} />
      <Body style={{ marginLeft: 12 }} weight="semibold" color="primaryDarkest">
        {label}
      </Body>
    </StyledUserMenuButton>
  );
};

enum Tab {
  COUNTERS = "counters"
}

export enum ModalType {
  SETTING = "setting",
  AVATAR = "avatar"
}

export const NavButtons = ({
  navRef,
  currentRoute
}: {
  navRef: NavigationContainerRef<RootStackParamList> | null;
  currentRoute?: string;
}) => {
  const dimensions = useDimensions();
  const theme = useTheme();
  const isMobile = !!lt({ dimensions, theme }, "small");
  const isTablet = !!lt({ dimensions, theme }, "large");

  return (
    <PageMenuContainer isMobile={isMobile} isTablet={isTablet}>
      <TopMenuButton
        route="Counters"
        Icon={WidgetAddIcon}
        label="Counters"
        navRef={navRef}
        active={currentRoute === "Counters"}
      />
      <TopMenuButton
        route="Collection"
        Icon={CollectionIcon}
        label="Collection"
        navRef={navRef}
        active={!!currentRoute?.startsWith("Collection")}
      />
      <TopMenuButton
        route="ShinyDex"
        Icon={WalletIcon}
        label="ShinyDex"
        navRef={navRef}
        active={currentRoute === "ShinyDex"}
      />
      <TopMenuButton
        route="Guides"
        Icon={VideoCamIcon}
        label="Guides"
        navRef={navRef}
        active={currentRoute === "Guides"}
      />
    </PageMenuContainer>
  );
};

export const TopNav = ({
  navRef,
  currentRoute,
  userMenuOpen,
  setUserMenuOpen,
  modal,
  setModal
}: {
  navRef: NavigationContainerRef<RootStackParamList> | null;
  currentRoute?: string;
  userMenuOpen: boolean;
  setUserMenuOpen: (open: boolean) => void;
  modal: ModalType | null;
  setModal: (type: ModalType | null) => void;
}) => {
  const dimensions = useDimensions();
  const theme = useTheme();
  const { authUser, updateProfileData, signout } = useAuth();
  const { updateUser, user } = useFirestore();
  const colorScheme = useColorScheme();
  const { isPro } = usePayments();
  const [userTheme, setUserTheme] = useState<UserTheme>();

  useEffect(() => {
    if (user) {
      user?.theme
        ? setUserTheme(user.theme)
        : colorScheme === "dark"
        ? setUserTheme(UserTheme.DARK)
        : setUserTheme(UserTheme.LIGHT);
    }
  }, [user]);

  const updateTheme = () => {
    switch (user?.theme) {
      case UserTheme.DARK:
        if (isPro || user.isAdmin) {
          updateUser({ theme: UserTheme.SHINY });
        } else {
          updateUser({ theme: UserTheme.LIGHT });
        }
        break;
      case UserTheme.SHINY:
        updateUser({ theme: UserTheme.LIGHT });
        break;
      default:
        updateUser({ theme: UserTheme.DARK });
        break;
    }
  };

  const updateAvatar = (avatar: string) => {
    const photoURL = TRAINERS.find(t => t.value === avatar)?.avatar;
    if (photoURL && authUser?.photoURL !== photoURL) {
      updateProfileData({ photoURL });
      updateUser({ avatar: photoURL });
    }
  };

  if (!user || !authUser) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      {dimensions.window.width < theme.breaks.large && <Logo />}
      {!!gte({ dimensions, theme }, "small") && (
        <NavButtons navRef={navRef} currentRoute={currentRoute} />
      )}
      <ProfileArea>
        <ProfileAreaButton onPress={() => setModal(ModalType.SETTING)}>
          <SettingsIcon
            primaryFill="primaryDarkest"
            secondaryFill="primaryDarkest"
            primaryFillHover="primary"
            secondaryFillHover="primaryDarkest"
            size={24}
          />
        </ProfileAreaButton>
        <ProfileAreaButton onPress={updateTheme}>
          {userTheme === UserTheme.DARK ? (
            <MoonIcon
              size={24}
              primaryFill="primaryDarkest"
              primaryFillHover="primary"
            />
          ) : userTheme === UserTheme.SHINY ? (
            <SparklesIcon
              size={24}
              primaryFill="primaryDarkest"
              primaryFillHover="primary"
            />
          ) : (
            <SunIcon
              size={24}
              primaryFill="primaryDarkest"
              primaryFillHover="primary"
            />
          )}
        </ProfileAreaButton>

        <ProfileAreaAvatar onPress={() => setUserMenuOpen(!userMenuOpen)}>
          <Image
            style={{
              width: "100%",
              height: "100%"
            }}
            resizeMode="contain"
            source={{ uri: getAvatarImage(user.avatar) }}
          />
        </ProfileAreaAvatar>
      </ProfileArea>

      <Modal
        animationType="fade"
        visible={modal === ModalType.AVATAR}
        onRequestClose={() => setModal(null)}
        transparent
      >
        <PromptModal
          select
          title="Update Avatar"
          desc="Don't see your favorite trainer? Send your trainer request to support@shinyhunt.com and we'll add them to the list!"
          label="Who is your favorite trainer?"
          options={TRAINERS.filter(t => t.access === "free")}
          defaultPromptValue={
            TRAINERS.find(t => t.avatar === user.avatar)?.value || ""
          }
          close={() => setModal(null)}
          callback={updateAvatar}
        />
      </Modal>
    </Container>
  );
};
