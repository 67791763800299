import {
  NavigationContainerRef,
  useNavigation
} from "@react-navigation/native";
import { useEffect, useState } from "react";
import { Modal } from "react-native";
import styled from "styled-components/native";
import { SideMenuButton } from "../components/SideMenuButton";
import { POKEMON } from "../constants/pokemon-new";
import { useFirestore } from "../hooks/useFirestore";
import { UserTheme } from "../model/model";
import { RootStackParamList } from "../navTypes";
import {
  CollectionIcon,
  VideoCamIcon,
  WalletIcon,
  WidgetAddIcon
} from "./Icon";
import { AlertModal } from "./modals/AlertModal";
import { Display, Legal } from "./Text";

const logo6ImageColor = require("../assets/images/logo-new.png");
const logo6ImageWhite = require("../assets/images/logo-new-white.png");
const logo6ImageShiny = require("../assets/images/logo-new-shiny.png");

const Container = styled.View`
  min-width: 270px;
  background: ${props => props.theme.colors.surface};
  box-shadow: ${props => props.theme.shadows.dark};
  z-index: 3;
`;

const LogoArea = styled.View`
  height: 50px;
  padding: 10px;
`;

const Logo = styled.Image`
  width: 100%;
  height: 100%;
`;

const SideMenu = styled.View`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
`;

const SideMenuIcon = styled.Image`
  padding: 0 20px;
  width: 18px;
`;

const MenuSeparator = styled.View`
  height: 3px;
  border-radius: 1.5px;
  background: ${p => p.theme.colors.background};
  width: 50%;
  margin: 0 auto;
`;

const UserMetrics = styled.View`
  display: flex;
  flex-direction: column;
  margin: 20px;
  justify-content: center;
  align-items: center;
`;

const UserImage = styled.Image`
  width: 16px;
  height: 100%;
`;

const Metric = styled.View`
  text-align: center;
  margin: 10px 0;
`;

const FavoriteStreamers = styled.View`
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const SideBar = ({
  theme,
  navRef,
  currentRoute
}: {
  theme: UserTheme;
  navRef: NavigationContainerRef<RootStackParamList> | null;
  currentRoute?: string;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [dexCmplt, setDexCmplt] = useState(0);
  const { hunts, collections } = useFirestore();
  const nav = useNavigation();

  useEffect(() => {
    let dexCompletion = 0;

    const caught = new Set<string>();
    collections?.forEach(collection => caught.add(collection.target.value));

    POKEMON.forEach(pokemon => {
      if (caught.has(pokemon.value)) {
        dexCompletion++;
      }
    });
    setDexCmplt(Math.round(10 * (dexCompletion / POKEMON.length) * 100) / 10);
  }, [collections]);

  return (
    <Container>
      <LogoArea>
        <Logo
          source={
            theme === UserTheme.DARK
              ? logo6ImageWhite
              : theme === UserTheme.SHINY
              ? logo6ImageShiny
              : logo6ImageColor
          }
          resizeMode="contain"
        />
      </LogoArea>
      <SideMenu>
        <SideMenuButton
          route="Counters"
          Icon={WidgetAddIcon}
          label="Counters"
          navRef={navRef}
          active={currentRoute === "Counters"}
        />
        <SideMenuButton
          route="Collection"
          Icon={CollectionIcon}
          label="Collection"
          navRef={navRef}
          active={!!currentRoute?.startsWith("Collection")}
        />
        <SideMenuButton
          route="ShinyDex"
          Icon={WalletIcon}
          label="ShinyDex"
          navRef={navRef}
          active={currentRoute === "ShinyDex"}
        />
        <SideMenuButton
          route="Guides"
          Icon={VideoCamIcon}
          label="Guides"
          navRef={navRef}
          active={currentRoute === "Guides"}
        />
        {/* <SideMenuButton
          onPress={() => nav.navigate("Counters")}
          navRef={navRef}
          active={currentRoute === "Counters"}
        >
          <HomeIcon
            size={18}
            primaryFill={
              currentRoute === "Counters" ? "primary" : "primaryDarkest"
            }
            secondaryFill="primaryDarkest"
          />
          <Body style={{ marginLeft: "20px" }} weight="semibold">
            Home
          </Body>
        </SideMenuButton> */}
        {/* <SideMenuButton
          onPress={() => setModalOpen(true)}
          navRef={navRef}
          active={currentRoute === "Community"}
        >
          <UserGroupIcon size={18} primaryFill="primary" />
          <Body style={{ marginLeft: "20px" }} weight="semibold">
            Community
          </Body>
        </SideMenuButton> */}
      </SideMenu>
      <MenuSeparator />

      <UserMetrics>
        <Metric>
          <Display weight="bold" color="primary">
            {hunts?.length || 0}
          </Display>
          <Legal weight="semibold" color="primaryDarkest">
            HUNTING
          </Legal>
        </Metric>
        <Metric>
          <Display weight="bold" color="primary">
            {collections?.length || 0}
          </Display>
          <Legal weight="semibold" color="primaryDarkest">
            COLLECTED
          </Legal>
        </Metric>
        <Metric>
          <Display weight="bold" color="primary">
            {dexCmplt}%
          </Display>
          <Legal weight="semibold" color="primaryDarkest">
            DEX CMPLT
          </Legal>
        </Metric>
      </UserMetrics>
      <MenuSeparator />
      <FavoriteStreamers></FavoriteStreamers>
      <Modal
        visible={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        transparent
      >
        <AlertModal
          title="COMMUNITY FEATURES COMING SOON"
          desc="This section is being actively worked on. Join aDrive's Twitch streams to get involved in the process and check progress."
          close={() => setModalOpen(false)}
        />
      </Modal>
    </Container>
  );
};
