import { Pressable } from "react-native";
import { useDimensions } from "react-native-web-hooks";
import styled from "styled-components/native";
import { IconButton } from "../atoms/Button";
import { DimensionsType } from "../breakpoints";
import { CheckIcon, CloseIcon } from "../Icon";
import { Headline, Body } from "../Text";

export const PromptModalOuter = styled.View`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
`;

export const PromptModalInner = styled.View<{ dimensions: DimensionsType }>`
  margin: 70px auto;
  padding: 20px 30px;
  overflow: hidden;
  max-width: 700px;
  width: ${p => p.dimensions.window.width - 80}px;
  background: ${props => props.theme.colors.background};
  border-radius: 8px;
`;

const HeaderArea = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const MainContent = styled.View``;

const FooterArea = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
`;

export type AlertModalProps = {
  title: string;
  desc: string;
  close: () => void;
};

export const AlertModal = (props: AlertModalProps) => {
  const dimensions = useDimensions();

  return (
    <PromptModalOuter>
      <PromptModalInner dimensions={dimensions}>
        <HeaderArea>
          <Headline>{props.title}</Headline>
          <Pressable onPress={props.close}>
            <CloseIcon
              primaryFill="primaryDarkest"
              primaryFillHover="primary"
              size={30}
            />
          </Pressable>
        </HeaderArea>
        <MainContent style={{ marginBottom: 20 }}>
          <Body color="primaryDarkest">{props.desc}</Body>
        </MainContent>
        <FooterArea>
          <Pressable onPress={props.close}>
            <CheckIcon
              primaryFill="primaryLight"
              primaryFillHover="successLight"
              secondaryFill="white"
              size={30}
            />
          </Pressable>
        </FooterArea>
      </PromptModalInner>
    </PromptModalOuter>
  );
};
