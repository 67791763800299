import { useEffect, useMemo, useState } from "react";
import {
  FlatList,
  ListRenderItemInfo,
  Pressable,
  StyleSheet,
  View
} from "react-native";
import { useDimensions } from "react-native-web-hooks";
import styled, { useTheme } from "styled-components/native";
import { IconButton } from "../components/atoms/Button";
import { SearchInput } from "../components/atoms/Input";
import { getBp, lt } from "../components/breakpoints";
import { CollectionItem } from "../components/CollectionItem";
import {
  CloseIcon,
  RefreshIcon,
  SortAscIcon,
  SortDescIcon,
  UploadIcon
} from "../components/Icon";
import { NewHuntmodal } from "../components/modals/NewHuntModal";
import { EmptyButton, EmptyState } from "../components/molecules/EmptyState";
import {
  SortOverlay,
  SortOverlayActions,
  SortOverlayFooter,
  SortOverlayHeader,
  SortOverlayIconButton,
  SortOverlayRadioLabel,
  SortOverlayRadioList
} from "../components/molecules/SortOverlay";
import { Body, Caption, Label, SubDisplay } from "../components/Text";
import { POKEMON } from "../constants/pokemon-new";
import { useFirestore } from "../hooks/useFirestore";
import { CollectionObject } from "../model/model";

const ListOptions = styled.View`
  margin-bottom: 24px;
  justify-content: space-between;
  flex-direction: row;
`;

const OptionButton = styled.Pressable<{ active?: boolean; hovered?: boolean }>`
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: ${props =>
    props.active
      ? props.theme.colors.primary
      : props.hovered
      ? props.theme.colors.primaryLight
      : props.theme.colors.white};
  border: 2px solid ${props => props.theme.colors.primaryLight};
  border-radius: 100px;
`;

const Container = styled.View`
  justify-content: flex-start;
  flex: 1;
  background-color: ${p => p.theme.colors.background};
`;

const CollectionGroup = styled.View`
  flex: 1;
  /* padding: 0 20px; */
`;

const LoadingSpinner = styled.View`
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const collectionSortOptions: {
  label: string;
  value?: keyof CollectionObject;
  type: string;
}[] = [
  {
    label: "Collected",
    value: "collected",
    type: "date"
  },
  // {
  //   label: "Created",
  //   value: "created",
  //   type: "date"
  // },
  {
    label: "Time Elapsed",
    value: "elapsedTime",
    type: "number"
  },
  {
    label: "Encounters",
    value: "encounters",
    type: "number"
  },
  {
    label: "DexNo",
    type: "dex"
  }
];

const colsMap = {
  small: 2,
  medium: 3,
  large: 4,
  xlarge: 5
};

const valueToDexMap: { [key: string]: string } = {};
POKEMON.forEach(p => {
  valueToDexMap[p.value] = p.dexNo;
});

export const CollectionList = () => {
  const [sortKey, setSortKey] = useState(collectionSortOptions[0]);
  const [sortAsc, setSortAsc] = useState(false);
  const { collections } = useFirestore();
  const [sorted, setSorted] = useState<CollectionObject[]>();
  const [searchInput, setSearchInput] = useState("");
  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const theme = useTheme();
  const dimensions = useDimensions();
  const { user } = useFirestore();

  const bp = getBp({ dimensions, theme });
  const numCols = bp ? colsMap[bp] : 1;

  useEffect(() => {
    if (collections) {
      const newSorted = [...collections];

      switch (sortKey.type) {
        case "number":
          newSorted.sort((a, b) => {
            const aVal = (a[sortKey.value!] as number) || 0;
            const bVal = (b[sortKey.value!] as number) || 0;

            return sortAsc ? aVal - bVal : bVal - aVal;
          });
          setSorted(newSorted);
          break;
        case "date":
          newSorted.sort((a, b) => {
            const aVal = new Date(a[sortKey.value!] as string).valueOf() || 0;
            const bVal = new Date(b[sortKey.value!] as number).valueOf() || 0;

            return sortAsc ? aVal - bVal : bVal - aVal;
          });
          setSorted(newSorted);
          break;
        case "dex":
          newSorted.sort((a, b) => {
            const aVal = a.target.value || "0";
            const bVal = b.target.value || "0";

            const aDex = parseInt(valueToDexMap[aVal]);
            const bDex = parseInt(valueToDexMap[bVal]);

            const compare = aDex - bDex;
            return sortAsc ? compare : -1 * compare;
          });
          setSorted(newSorted);
          break;
        default:
          newSorted.sort((a, b) => {
            const aVal = (a[sortKey.value!] as string) || "";
            const bVal = (b[sortKey.value!] as string) || "";

            const compare = aVal.toString().localeCompare(bVal.toString());
            return sortAsc ? -1 * compare : compare;
          });
          setSorted(newSorted);
          break;
      }
    }
  }, [collections, sortAsc, sortKey]);

  const collectionStyleSheet = useMemo(() => {
    const s = lt({ dimensions, theme }, "small");

    return theme
      ? StyleSheet.create({
          container: {
            position: "relative",
            width: `${(1 / numCols) * 100}%`,
            height: s ? 140 : 250,
            padding: 5,
            overflow: "hidden",
            borderRadius: 10
          }
        })
      : null;
  }, [theme, dimensions]);

  const renderCollectionItem = useMemo(
    () =>
      collectionStyleSheet
        ? ({ item }: ListRenderItemInfo<CollectionObject>) => (
            <View style={collectionStyleSheet.container}>
              <CollectionItem collection={item} isAdmin={!!user?.isAdmin} />
            </View>
          )
        : null,
    [collectionStyleSheet, user]
  );

  if (!collections) {
    return <LoadingSpinner />;
  }

  const filtered =
    sorted?.filter(collection =>
      collection.target.label.toLowerCase().includes(searchInput.toLowerCase())
    ) || [];
  const SortIcon = sortAsc ? SortAscIcon : SortDescIcon;
  const isSorted = sortAsc || sortKey !== collectionSortOptions[0];

  return (
    <Container>
      {!sorted?.length ? (
        <EmptyState
          style={{
            alignSelf: "center"
          }}
        >
          <SubDisplay
            color="primaryDarkest"
            weight="semibold"
            style={{ textAlign: "center" }}
          >
            Your collection is empty!
          </SubDisplay>
          <Label color="neutral" style={{ textAlign: "center", marginTop: 20 }}>
            Start a new hunt in 'My Counters' or click the button below to
            manually import your previously caught shinies.
          </Label>
          <EmptyButton onPress={() => setImportModalOpen(true)}>
            <UploadIcon
              size={40}
              primaryFill="primary"
              primaryFillHover="primaryLight"
            />
          </EmptyButton>
        </EmptyState>
      ) : (
        <>
          <View style={{ padding: 20, flexDirection: "row" }}>
            <IconButton
              outline
              onPress={() => setSortModalOpen(!sortModalOpen)}
              Icon={SortIcon}
              style={{ marginRight: 12 }}
            />

            <IconButton
              outline
              onPress={() => setImportModalOpen(!importModalOpen)}
              Icon={UploadIcon}
              style={{ marginRight: 12 }}
            />

            <SearchInput
              onChangeText={setSearchInput}
              value={searchInput}
              placeholder="Search..."
            />
          </View>

          {sortModalOpen && (
            <SortOverlay>
              <SortOverlayHeader>
                <Caption weight="semibold">SORT OPTIONS</Caption>
                <SortOverlayActions>
                  <SortOverlayIconButton
                    onPress={() => setSortModalOpen(false)}
                  >
                    <CloseIcon
                      primaryFill="primaryLight"
                      primaryFillHover="primaryDarkest"
                      size={20}
                    />
                  </SortOverlayIconButton>
                </SortOverlayActions>
              </SortOverlayHeader>

              <SortOverlayRadioList>
                {collectionSortOptions.map((option, index) => (
                  <SortOverlayRadioLabel
                    checked={sortKey === option}
                    key={index}
                    onPress={() => setSortKey(option)}
                  >
                    <Body weight="semibold" color="primaryDark">
                      {option.label}
                    </Body>
                  </SortOverlayRadioLabel>
                ))}
              </SortOverlayRadioList>
              <SortOverlayFooter>
                <Pressable onPress={() => setSortAsc(!sortAsc)}>
                  <SortIcon
                    primaryFill={isSorted ? "primaryDarkest" : "primaryLight"}
                    secondaryFill={isSorted ? "primaryDarkest" : "primaryLight"}
                    primaryFillHover="primaryDarkest"
                    secondaryFillHover="primaryDarkest"
                    size={20}
                  />
                </Pressable>

                <Pressable
                  onPress={() => {
                    setSortAsc(false);
                    setSortKey(collectionSortOptions[0]);
                  }}
                  disabled={!isSorted}
                >
                  <RefreshIcon
                    primaryFill="primaryLight"
                    secondaryFill="white"
                    primaryFillHover="primaryDark"
                    secondaryFillHover="white"
                    size={20}
                  />
                </Pressable>
              </SortOverlayFooter>
            </SortOverlay>
          )}
          <CollectionGroup>
            <FlatList
              key={`collections${numCols}`}
              numColumns={numCols}
              data={filtered}
              keyExtractor={item => item.uid}
              // columnWrapperStyle={
              //   numCols > 1 ? { marginLeft: -5, marginRight: -5 } : undefined
              // }
              style={{ paddingHorizontal: 20, paddingBottom: 20 }}
              removeClippedSubviews
              windowSize={2}
              renderItem={renderCollectionItem}
            />
          </CollectionGroup>
        </>
      )}

      <NewHuntmodal
        visible={importModalOpen}
        isImport
        close={() => setImportModalOpen(false)}
      />
    </Container>
  );
};
