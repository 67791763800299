import { getAuth } from "firebase/auth";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useAuth } from "./useAuth";
import { useFirestore } from "./useFirestore";
import { PackageList, PaymentsContext } from "./PaymentsInterface";
import type { PACKAGE_TYPE, PurchasesPackage } from "react-native-purchases";
import { createCheckoutSession, getStripePrices } from "../App";

const REV_CAT_KEY = "strp_JPEivhYtCYrPhBRnJCADUVxYdrm";

// const STRIPE_KEY =
//   process.env.NODE_ENV === "development"
//     ? "pk_test_51JvlBRCINnnMu4NUBpLtHkr0vzKj9XzvnNK7XqC6AfKtwefTFoqQdGZBvNtRXzhVKhX4fZzqO865SzVrm0aJPNUc00EEZjJP9R"
//     : "pk_live_51JvlBRCINnnMu4NUQL17G4KaJwUf8TB6zmRvskWTd8UGKEBncQCkPe2Buj4zTgsGDI46nBgfKa3RN0WzgoJt3pQ200zt9FXetj

const STRIPE_KEY =
  "pk_live_51JvlBRCINnnMu4NUQL17G4KaJwUf8TB6zmRvskWTd8UGKEBncQCkPe2Buj4zTgsGDI46nBgfKa3RN0WzgoJt3pQ200zt9FXetj";
const stripePromise = loadStripe(STRIPE_KEY);

const formatCost = (price: number) => `$${(price / 100).toFixed(2)}`;

export const usePaymentsProvider = () => {
  const { authUser } = useAuth();

  const [isPro, setIsPro] = useState(false);
  const [isAdFree, setIsAdFree] = useState(false);
  const [loading, setLoading] = useState<string | null>(null);
  const [packages, setPackages] = useState<PackageList | null>(null);

  const [stripe, setStripe] = useState<Stripe | null>(null);

  const updateEntitlements = () => {
    if (authUser?.uid) {
      fetch("https://api.revenuecat.com/v1/subscribers/" + authUser.uid, {
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + REV_CAT_KEY
        }
      })
        .then(res => {
          if (res.ok) {
            res.json().then(data => {
              const entitlements = data.subscriber?.entitlements;
              if (entitlements["ShinyHunt Pro"]) {
                const expired =
                  new Date().valueOf() >
                  new Date(
                    entitlements["ShinyHunt Pro"].expires_date
                  ).valueOf();

                if (!expired) {
                  setIsPro(true);
                }
              }

              if (entitlements["ShinyHunt Remove Ads"]) {
                setIsAdFree(true);
              }
            });
          }
        })
        .finally(() => setLoading(null));
    }
  };

  const makePurchase = (pkg: PurchasesPackage, loadingId: string) => {
    setLoading(loadingId);
    if (stripe) {
      console.log("pkg :>> ", pkg);
      createCheckoutSession({
        priceId: (pkg as any).product.priceId,
        local: process.env.NODE_ENV === "development",
        subscription: !pkg.identifier.includes("lifetime")
      }).then(({ data }) => {
        return stripe.redirectToCheckout({
          sessionId: data
        });
      });
    }
  };

  const getOfferings = () => {
    if (authUser?.uid) {
      fetch(
        "https://api.revenuecat.com/v1/subscribers/" +
          authUser.uid +
          "/offerings",
        {
          headers: {
            "content-type": "application/json",
            authorization: "Bearer " + REV_CAT_KEY,
            "X-Platform": "stripe"
          }
        }
      ).then(res => {
        if (res.ok) {
          res.json().then(data => {
            const packages = data.offerings?.[0]?.packages;
            const productIds: string[] = [];
            const lifetime = packages.find(
              (p: any) => p.identifier === "$rc_lifetime"
            );
            lifetime && productIds.push(lifetime.platform_product_identifier);

            const monthly = packages.find(
              (p: any) => p.identifier === "$rc_monthly"
            );
            monthly && productIds.push(monthly.platform_product_identifier);

            const annual = packages.find(
              (p: any) => p.identifier === "$rc_annual"
            );
            annual && productIds.push(annual.platform_product_identifier);

            getStripePrices({ productIds }).then(({ data }) => {
              const packages: PackageList = {};
              data.forEach(p => {
                if (lifetime && p.id === lifetime.platform_product_identifier) {
                  packages.lifetime = {
                    ...lifetime,
                    product: {
                      priceId: p.price.id,
                      priceString: formatCost(p.price.unit_amount)
                    }
                  };
                }
                if (monthly && p.id === monthly.platform_product_identifier) {
                  packages.monthly = {
                    ...monthly,
                    product: {
                      priceId: p.price.id,
                      priceString: formatCost(p.price.unit_amount)
                    }
                  };
                }
                if (annual && p.id === annual.platform_product_identifier) {
                  packages.annual = {
                    ...annual,
                    product: {
                      priceId: p.price.id,
                      priceString: formatCost(p.price.unit_amount)
                    }
                  };
                }
              });
              setPackages(packages);
            });
          });
        }
      });
    }
  };

  useEffect(() => {
    stripePromise.then(stripe => {
      if (stripe) {
        setStripe(stripe);
      }
    });
  }, []);

  useEffect(() => {
    if (authUser && stripe) {
      updateEntitlements();
    }
  }, [authUser, stripe]);

  const value: PaymentsContext = {
    getOfferings,
    packages,
    isPro,
    isAdFree,
    makePurchase,
    loading
  };

  return value;
};

const paymentsContext = createContext<PaymentsContext>(null);

export const usePayments = () => {
  const context = useContext(paymentsContext);
  if (context === null) {
    throw "usePayments must be within PaymentsProvider";
  }
  return context;
};

export const PaymentsProvider: React.FC = ({ children }) => {
  const payments = usePaymentsProvider();
  return (
    <paymentsContext.Provider value={payments}>
      {children}
    </paymentsContext.Provider>
  );
};
