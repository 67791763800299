import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  User,
  UserCredential,
  updateProfile,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  sendEmailVerification,
  updateCurrentUser
} from "firebase/auth";
import { useNavigation } from "@react-navigation/core";
import { Nav } from "../navTypes";

export type ProfileData = {
  photoURL?: string;
  displayName?: string;
};

export type AuthProviderValue = {
  authUser: User | null | undefined;
  signin: (email: string, password: string) => Promise<UserCredential>;
  signup: (email: string, password: string) => Promise<void>;
  signout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  confirmResetPassword: (code: string, password: string) => Promise<void>;
  updateProfileData: (data: ProfileData) => Promise<void> | null;
  updateUserPassword: (
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
  ) => Promise<unknown>;
  deactivateUserAccount: (confirmPassword: string) => Promise<unknown>;
  resendEmailVerification: () => Promise<unknown>;
  startPolling: () => void;
};

let interval: NodeJS.Timer;
let count = 0;

export const useAuthProvider = () => {
  const auth = useMemo(() => getAuth(), []);
  const [authUser, setAuthUser] = useState<User | null>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, authUser => {
      if (authUser) {
        setAuthUser(authUser);

        if (authUser.emailVerified) {
          clearInterval(interval);
        }
      } else {
        setAuthUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const startPolling = () => {
    if (authUser && !authUser.emailVerified) {
      clearInterval(interval);
      count = 0;

      interval = setInterval(() => {
        auth.currentUser?.reload().then(() => {
          if (auth.currentUser && auth.currentUser.emailVerified) {
            setAuthUser({ ...authUser, emailVerified: true });
            clearInterval(interval);
          }
        });
        count++;

        if (count > 20) {
          clearInterval(interval);
        }
      }, 3000);
    }
  };

  const signin = (email: string, password: string) =>
    signInWithEmailAndPassword(auth, email, password).then(user => {
      if (authUser && user.user.emailVerified) {
        setAuthUser({ ...authUser, emailVerified: true });
        clearInterval(interval);
      }
      return user;
    });

  const signup = (email: string, password: string) =>
    createUserWithEmailAndPassword(auth, email.trim(), password).then(user => {
      sendEmailVerification(user.user, { url: "https://shinyhunt.com" });
    });

  const signout = () => signOut(auth);

  const resetPassword = (email: string) => sendPasswordResetEmail(auth, email);

  const confirmResetPassword = (code: string, password: string) =>
    confirmPasswordReset(auth, code, password);

  const updateProfileData = ({ photoURL, displayName }: ProfileData) =>
    authUser ? updateProfile(authUser, { photoURL, displayName }) : null;

  const updateUserPassword = (
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
  ) => {
    if (newPassword !== confirmPassword || !newPassword) {
      return new Promise((res, rej) => rej("Passwords do not match"));
    }

    if (authUser) {
      return reauthenticateWithCredential(
        authUser,
        EmailAuthProvider.credential(authUser.email as string, oldPassword)
      )
        .then(() => {
          updatePassword(authUser, newPassword);
        })
        .catch(e => {
          throw "Old password invalid";
        });
    } else {
      return new Promise((res, rej) => rej("User is not logged in."));
    }
  };

  const deactivateUserAccount = (confirmPassword: string) => {
    if (authUser) {
      return reauthenticateWithCredential(
        authUser,
        EmailAuthProvider.credential(authUser.email as string, confirmPassword)
      )
        .then(() => {
          return authUser.delete();
        })
        .catch(() => {
          throw "Password incorrect!";
        });
    } else {
      return new Promise((res, rej) => rej("User is not logged in."));
    }
  };

  const resendEmailVerification = () => {
    if (authUser) {
      return sendEmailVerification(authUser);
    } else {
      return new Promise((res, rej) => rej("User is not logged in."));
    }
  };

  const value: AuthProviderValue = {
    authUser,
    signin,
    signup,
    signout,
    resetPassword,
    confirmResetPassword,
    updateProfileData,
    updateUserPassword,
    deactivateUserAccount,
    resendEmailVerification,
    startPolling
  };

  return value;
};

const authContext = createContext<AuthProviderValue | null>(null);

export const useAuth = () => {
  const context = useContext(authContext);
  if (context === null) {
    throw "useAuth must be within AuthProvider";
  }
  return context;
};

export const AuthProvider: React.FC = ({ children }) => {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
