import { useNavigation } from "@react-navigation/core";
import { useState } from "react";
import { Platform, Pressable, View } from "react-native";
import styled from "styled-components/native";
import { Button } from "../components/atoms/Button";
import { Input } from "../components/atoms/Input";
import { Link } from "../components/atoms/Link";
import { LoadingSpinner } from "../components/atoms/LoadingSpinner";
import { getErrorMessage } from "../components/errors";
import {
  AuthFormCard,
  AuthFormContainer,
  AuthFormInputs,
  AuthFormLogo
} from "../components/modals/AuthForm";
import { Caption } from "../components/Text";
import { useAuth } from "../hooks/useAuth";
import { Nav } from "../navTypes";

const wormholeImage = require("../assets/images/backgrounds/wormhole-bg.png");
const logoWhiteImage = require("../assets/images/logo-new-white.png");

export const PasswordForgetScreen = () => {
  const nav = useNavigation<Nav>();
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { resetPassword } = useAuth();

  const onSubmit = () => {
    setError(null);
    setIsSubmitting(true);

    resetPassword(email)
      .then(
        () => {
          setEmail("");
        },
        e => setError(getErrorMessage(e))
      )
      .catch((e: any) => setError(getErrorMessage(e)))
      .finally(() => setIsSubmitting(false));
  };

  const isInvalid = !email;

  return (
    <AuthFormContainer source={wormholeImage}>
      <Pressable
        onPress={() =>
          nav.navigate(Platform.OS === "web" ? "Landing" : "SignIn")
        }
      >
        <AuthFormLogo source={logoWhiteImage} resizeMode="contain" />
      </Pressable>
      <AuthFormCard>
        <Caption weight="semibold">RESET PASSWORD</Caption>
        <View style={{ marginTop: 10 }}>
          <AuthFormInputs>
            <Input
              textContentType="emailAddress"
              onChangeText={setEmail}
              value={email}
              placeholder="Email Adddress"
              returnKeyType="done"
              onSubmitEditing={onSubmit}
            />
            <Button disabled={isInvalid} onPress={onSubmit}>
              Send Reset Email
            </Button>
          </AuthFormInputs>
          {!!error && <Caption color="danger">{error}</Caption>}
        </View>
        <Button onPress={() => nav.navigate("SignIn")}>Back to sign in</Button>
      </AuthFormCard>
      {!!isSubmitting && <LoadingSpinner />}
    </AuthFormContainer>
  );
};
