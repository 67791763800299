import { useNavigation } from "@react-navigation/core";
import { useEffect, useState } from "react";
import { Platform, View } from "react-native";
import styled from "styled-components/native";
import { Button } from "../components/atoms/Button";
import { Input } from "../components/atoms/Input";
import { Link } from "../components/atoms/Link";
import { LoadingSpinner } from "../components/atoms/LoadingSpinner";
import { getErrorMessage } from "../components/errors";
import {
  AuthFormCard,
  AuthFormContainer,
  AuthFormInputs,
  AuthFormLogo
} from "../components/modals/AuthForm";
import { Body, Caption, Headline } from "../components/Text";
import { useAuth } from "../hooks/useAuth";
import { Nav } from "../navTypes";

const wormholeImage = require("../assets/images/backgrounds/wormhole-bg.png");
const logoWhiteImage = require("../assets/images/logo-new-white.png");

export const VerifyScreen = () => {
  const nav = useNavigation<Nav>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [sent, setSent] = useState(false);

  const { resendEmailVerification, startPolling } = useAuth();

  useEffect(() => {
    startPolling();
  }, []);

  const onSubmit = () => {
    setError(null);
    setIsSubmitting(true);

    resendEmailVerification()
      .then(
        () => setSent(true),
        e => setError(getErrorMessage(e))
      )
      .catch((e: any) => setError(getErrorMessage(e)))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <AuthFormContainer source={wormholeImage}>
      <AuthFormLogo source={logoWhiteImage} resizeMode="contain" />
      <AuthFormCard>
        {sent ? (
          <Caption weight="semibold">EMAIL CONFIRMATION SENT</Caption>
        ) : (
          <Caption weight="semibold">VERIFY YOUR E-MAIL</Caption>
        )}
        <View style={{ marginTop: 10 }}>
          <AuthFormInputs>
            {sent ? (
              <>
                <Body color="neutralDarker">
                  Check your email for a confirmation email. Be sure to check
                  your junk or spam folder.
                </Body>
                <View style={{ height: 12 }} />
                <Body color="neutralDarker">
                  We have noticed some mail providers (hotmail.com, comcast.net,
                  web.de, gmx.de, etc.) don't always receive the verfication
                  email. If you haven't recieved the email within a few minutes,
                  please email support@shinyhunt.com.
                </Body>
              </>
            ) : (
              <Body color="neutralDarker">
                Check your email for a confirmation email or send another.
              </Body>
            )}
            <Button style={{ marginTop: 16 }} onPress={onSubmit}>
              Resend Confirmation Email
            </Button>
          </AuthFormInputs>
          {!!error && <Caption color="danger">{error}</Caption>}
        </View>
        <Button style={{ marginTop: 8 }} onPress={() => nav.navigate("SignIn")}>
          Back to sign in
        </Button>
      </AuthFormCard>
      {!!isSubmitting && <LoadingSpinner />}
    </AuthFormContainer>
  );
};
